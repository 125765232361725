import { Component, OnInit, Input, Output, EventEmitter,ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EmppShiftMapService } from 'src/app/@core/services/empp-shift-map.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-sm-applicability',
  templateUrl: './sm-applicability.component.html',
  styleUrls: ['./sm-applicability.component.scss']
})
export class SmApplicabilityComponent implements OnInit {

  limit             = 40
  offset            = 0
  loadMe            = false
  searchTerm        = new Subject<string>()
  searchString      = ''
  is_active         :boolean = true
  empList           :any     = [];
  submitted     :boolean     = false

  @Input()exportData: any

  @Input()errorLog  : any
  @Input()successMsg: any
  @Input()errorMsg  : any


  @Input() downloadMsg:any

  @Input() employeeDropdown  :any
  @Input() formGp   : FormGroup = this.fb.group({
    applicable      : [false,[Validators.required]],
    assignees       : this.fb.group({
      employee: [[], [Validators.required]],
      company: [[], [Validators.required]],
      businessunit: [[], [Validators.required]],
      department: [[], [Validators.required]],
      designation: [[], [Validators.required]],
      grade: [[], [Validators.required]],
      branch: [[], [Validators.required]]
    })
  })

  @Input()companyDD :any =  [{
    id: 0,
    company_name: ''}];

  @Input()buDD = [{
    id: 0,
    name: ''}];

  @Input()DepDD = [{
    id: 0,
    name: ''}];

  @Input()DesignationDD = [{
    id: 0,
    name: ''}];

  @Input()BranchDD = [{
    id: 0,
    name: ''}];

  @Input()gradeDD = [{
    id: 0,
    name: ''}];

  @Output() submitmethod = new EventEmitter();
  // @Output() companyChange = new EventEmitter();

  @ViewChild('ngInput') public ngInput!: NgSelectComponent;

  constructor(
    public fb             : FormBuilder,
    public messageService : MessageService,
    public appService     : AppService,
    public emppShift      : EmppShiftMapService,
    public router         : Router,
    private cd            : ChangeDetectorRef,
    private rs            : RoleService
  ) { }

  ngOnInit(): void {

    // Applicable
    if (this.companyDD.length != 0) {
      this.selectAllForDropdownItems(this.companyDD);
    }
    if (this.DepDD.length != 0) {
      this.selectAllForDropdownItems(this.DepDD);
    }
    if (this.DesignationDD.length != 0) {
      this.selectAllForDropdownItems(this.DesignationDD);
    }
    if (this.BranchDD.length != 0) {
      this.selectAllForDropdownItems(this.BranchDD);
    }
    if (this.gradeDD.length != 0) {
      this.selectAllForDropdownItems(this.gradeDD);
    }
    // if (this.employeeDropdown.length != 0) {
    //   this.selectAllForDropdownItems(this.employeeDropdown);
    // }

    this.initiateErrorStatus();
  }

  // ngOnChanges(){

  //   this.cd.detectChanges()
  // }

  // Controls
  get f(){
    return this.formGp.controls
  }

  get assignees(){
    return this.formGp.controls.assignees
  }

  // Company Change
  companyChangeFunction(data:any){
    this.buDD = [];

    this.rs.getBuList(data).subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
        this.buDD = res.body
        this.selectAllForDropdownItems(this.buDD)
      }
    });
    this.formGp.controls.assignees.patchValue({
      businessunit : this.buDD
    })
  }

  // Clear Selection
  clear(data:any,index:any){
    if(data == "company"){
      let daata = this.formGp.value.assignees.company;
      daata.splice(index, 1);
      const myForm = this.formGp.get("assignees")
      myForm?.patchValue({
        company: daata,
        businessunit: []
      });
      this.companyChangeFunction(this.formGp.value.assignees.company);
    } else if(data == "businessunit"){
      let daata = this.formGp.value.assignees.businessunit;
      daata.splice(index, 1);
      const myForm = this.formGp.get("assignees")
      myForm?.patchValue({
        businessunit: daata
      });
    } else if(data == "department"){
      let daata = this.formGp.value.assignees.department;
      daata.splice(index, 1);
      const myForm = this.formGp.get("assignees")
      myForm?.patchValue({
        department: daata
      });
    } else if(data == "city"){
      let daata = this.formGp.value.assignees.city;
      daata.splice(index, 1);
      const myForm = this.formGp.get("assignees")
      myForm?.patchValue({
        city: daata
      });
    } else if(data == "designation"){
      let daata = this.formGp.value.assignees.designation;
      daata.splice(index, 1);
      const myForm = this.formGp.get("assignees")
      myForm?.patchValue({
        designation: daata
      });
    } else if(data == "grade"){
      let daata = this.formGp.value.assignees.grade;
      daata.splice(index, 1);
      const myForm = this.formGp.get("assignees")
      myForm?.patchValue({
        grade: daata
      });
    }  else if(data == "branch"){
      let daata = this.formGp.value.assignees.branch;
      daata.splice(index, 1);
      const myForm = this.formGp.get("assignees")
      myForm?.patchValue({
        branch: daata
      });
    }  else if(data == "employee"){
      let daata = this.formGp.value.assignees.employee;
      daata.splice(index, 1);
      const myForm = this.formGp.get("assignees")
      myForm?.patchValue({
        employee: daata
      });
    }
    this.initiateErrorStatus();
  }

  // Form Valid
  checkcommonFormValid(){
    if(this.f.applicable.value == false){
      if(this.f.assignees.get('company')?.valid || this.f.assignees.get('businessunit')?.valid || this.f.assignees.get('department')?.valid || this.f.assignees.get('designation')?.valid || this.f.assignees.get('grade')?.valid || this.f.assignees.get('branch')?.valid ){
        return false;
      } else{
        return true;
      }
    }else{
      if(this.f.assignees.get('employee')?.valid){
        return false
      }else{
        return true
      }
    }
  }

  submitForm(){
    if(this.checkcommonFormValid()){
      return;
    }else{
      this.submitted = true
    }
    this.submitmethod.emit()
  }

  // Select All
   selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  close(){
    this.errorLog = false
    this.router.navigate(['employee-shift'])
  }

  // downloadError(){
  //   this.appService.exportExcel( this.exportData,'Assign Shift Error');
  //   this.errorLog = false;
  //   this.router.navigate(['employee-shift'])
  // }

  changeApplicable(){
    this.assignees.get('company')?.reset([])
    this.assignees.get('businessunit')?.reset([])
    this.assignees.get('department')?.reset([])
    this.assignees.get('designation')?.reset([])
    this.assignees.get('branch')?.reset([])
    this.assignees.get('grade')?.reset([])
    this.assignees.get('employee')?.reset([])
  }

  ngChange(e:any){
    if(this.f.applicable.value == true && this.hasSpecificStatus(e)){
      this.formGp?.get('assignees')?.get('employee')?.setErrors({
        'relievedError' : true
      })
    }
  }

  hasSpecificStatus(employees:any){
    const statuses = ['Relieved','Settled','Terminated','Retired']
    return employees?.some((employee:any)=> statuses.includes(employee.employment_status));
  }

  initiateErrorStatus(){
    if(this.formGp.get('assignees')?.get('employee')?.value != null && this.formGp.get('assignees')?.get('employee')?.value != ''){
      const filteredData = this.employeeDropdown.filter((item:any) => this.formGp.get('assignees')?.get('employee')?.value.includes(item.id))
      setTimeout(()=>{
        if(this.hasSpecificStatus(filteredData)){
          this.formGp.get('assignees')?.get('employee')?.setErrors({
            'relievedError' : true
          })
          this.formGp.get('assignees')?.get('employee')?.markAsTouched();
          this.cd.detectChanges();
        }
      },0)
    }
  }

  isInsideItemIdFive(item: any): boolean {
    const insideItem = this.employeeDropdown.filter((insideitem:any) => insideitem.id === item);
    return this.hasSpecificStatus(insideItem)
  }
}
