<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Routes - {{empData.employee_name}} ({{empData.employee_code}})</h5>
    <button class="btn-reset text-light-600" (click)="closePanel()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar sidebar-collapse  p-0" [ngClass]="{'active': sidebarCollapse }">
    <button class=" sidebar-collapse-btn link-primary1 btn-reset position-absolute end-0  p-16"
      (click)="sidebarCollapse = !sidebarCollapse">
      <i class="icon-chevron-right-circle fs-20" [ngClass]="{'icon-chevron-left-circle': sidebarCollapse }"></i>
    </button>
    <div class="text-uppercase fs-12 fw-500 px-24 pb-8 pt-16">
      LIVE TRACK DETAILS
    </div>
    <div class="position-sticky top-0 bg-white p-16 z-1">
      <div class="slider-input-container position-relative">
        <input (click)="datePicker1.open()" class="form-control w-100 position-absolute"
          placeholder="{{this.messageServ.selectplaceholddisplay('date')}} " matInput [matDatepicker]="datePicker1"
          [(ngModel)]="today" style="visibility : hidden;" (dateInput)="fromDates($event)">
        <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
        <button class="slider-btn-left" (click)="changeWeek('left')"><i class="icon-chevron-left-lg"></i></button>
        <div class="slider-input">
          <div class="align-items-center d-flex justify-content-around" (click)="datePicker1.open()">
            <span class="fs-14 fw-500"> {{today| date:this.appservice.getdateformat()}} </span>
          </div>
        </div>
        <button class="slider-btn-right" (click)="changeWeek('right')"><i class="icon-chevron-right-lg"></i></button>
      </div>
    </div>
    <ul class="timeline-c3">
      <li class="timeline-li-1">
        <i class="icon-clock-fill text-accent2 fs-16"></i>
        <span class="text-nowrap">{{today+'
          '+data.shift_details.shift_start_time|date:this.appservice.gettimeformat()}}</span>
        <span class="text-trim">Shift start time</span>
      </li>
      <ng-container *ngFor="let dir of dirs">
        <!-- <li>{{dir.key}}</li> -->
        <li class="timeline-li-1" *ngIf="dir.isDirection && dir.originVal?.realtime_status == 'Check-in'">
          <i class="icon-arrow-right-circle-fill text20 fs-16"></i>
          <span class="text-nowrap">{{dir.originVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-in</span>
        </li>
        <li class="timeline-li-1" *ngIf="dir.isDirection && dir.DestVal?.realtime_status == 'Check-in'">
          <i class="icon-arrow-right-circle-fill text20 fs-16"></i>
          <span class="text-nowrap">{{dir.originVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-in</span>
        </li>
        <li class="timeline-li-1" *ngIf="!dir.isDirection && dir.markerVal?.realtime_status == 'Check-in'">
          <i class="icon-arrow-right-circle-fill text20 fs-16"></i>
          <span class="text-nowrap">{{dir.markerVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-in</span>
        </li>
        <!-- && (dir.originVal?.location != dir.DestVal?.location || dir.waysVal?.length>0) -->
        <li *ngIf="dir.isDirection " class="timeline-li-2 li-card " [class.active]="dir.key == click"
          (click)="clicklist(dir.key,showbredcrumb)">
          <div class="card-c8 px-8 ">
            <div class="fs-12 hstack gap-12 py-8 fw-600 align-items-start">
              <span class="text-nowrap">{{dir.originVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
              <span class="text-trim-2" title="{{dir.originVal?.location}}">{{dir.originVal?.location}}</span>
            </div>
            <div class="divider-c1">
              <span> <i class="icon-map-route "></i>
                {{( dir.distance )}}
              </span>
            </div>
            <div class="fs-12 hstack gap-12 py-8 fw-600 align-items-start">
              <span class="text-nowrap">{{dir.DestVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
              <span class="text-trim-2" title="{{dir.DestVal?.location}}">{{dir.DestVal?.location}}</span>
            </div>
          </div>
        </li>
        <li *ngIf="!dir.isDirection && dir.markerVal?.location" class="timeline-li-2" [class.active]="dir.key == clickedMarkerKey"
          (click)="clickMarker(dir.key)">
          <div class="card-c8 px-8  ">
            <div class=" hstack fw-600 align-items-stretch fs-12 gap-8 py-2">
              <i class="icon-stopwatch1-fill text-warning fs-16 my-auto"></i>
              <span class="text-trim-3" title="{{dir.markerVal?.location}}">{{dir.markerVal?.location}}</span>
              <ng-container *ngIf="dir.markerVal?.plugin_data.stationary_interval != null"><span class="text-nowrap ms-auto">{{this.timeFormatConvert(dir.markerVal?.plugin_data.stationary_interval)}}
              </span></ng-container>
            </div>
          </div>
        </li>
        <li class="timeline-li-1"
          *ngIf="!dir.isDirection && dir.markerVal?.realtime_status == 'Check-out' &&  timeCompare(dir.markerVal?.timestamp,today+' '+data.shift_details.shfit_endtime)">
          <i class="icon-arrow-left-circle-fill text-danger fs-16"></i>
          <span class="text-nowrap">{{dir.markerVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-Out</span>
        </li>
        <li class="timeline-li-1"
          *ngIf="dir.isDirection && dir.originVal?.realtime_status == 'Check-out' && timeCompare(dir.originVal?.timestamp,today+' '+data.shift_details.shfit_endtime)">
          <i class="icon-arrow-left-circle-fill text-danger fs-16"></i>
          <span class="text-nowrap">{{dir.originVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-Out</span>
        </li>
        <li class="timeline-li-1"
          *ngIf="dir.isDirection && dir.DestVal?.realtime_status == 'Check-out' && timeCompare(dir.DestVal?.timestamp,today+' '+data.shift_details.shfit_endtime)">
          <i class="icon-arrow-left-circle-fill text-danger fs-16"></i>
          <span class="text-nowrap">{{dir.DestVal?.timestamp|date:this.appservice.gettimeformat()}}</span>
          <span class="text-trim">Check-Out</span>
        </li>
      </ng-container>
      <li class="timeline-li-1">
        <i class="icon-clock-fill text-accent2 fs-16"></i>
        <span class="text-nowrap">{{today+'
          '+data.shift_details.shfit_endtime|date:this.appservice.gettimeformat()}}</span>
        <span class="text-trim">Shift end time</span>
      </li>
      <li class="timeline-li-1"
        *ngIf="data.tracking_datas[data.tracking_datas?.length-1]?.realtime_status == 'Check-out' && timeCompare(today+' '+data.shift_details.shfit_endtime,data.tracking_datas[data.tracking_datas?.length-1].timestamp)">
        <i class="icon-arrow-left-circle-fill text-danger fs-16"></i>
        <span
          class="text-nowrap">{{data.tracking_datas[data.tracking_datas?.length-1].timestamp|date:this.appservice.gettimeformat()}}</span>
        <span class="text-trim">Check-Out</span>
      </li>
    </ul>
  </aside>
  <main class=" config-container">
    <div class="container-fluid  p-0 form-container">
      <div class="row" style="height:calc(100vh - 4rem)" *ngIf="dirs.length==0">
        <app-nodata-view class="vstack" [noDataImg]="'bg3.png'" [noDataText]="'Oops, no results found!'"
          [noDataPara]="'It seems no results match your search criteria. Please adjust or apply different filters.'"
          [hasPermission]="false">
        </app-nodata-view>
      </div>
      <ng-container *ngIf="dirs.length>0">
        <div class="card card-c2 radius-8">
          <div #gmapContainer id="map">
            <agm-map [mapTypeControl]='true' [latitude]="centre.latitude" [styles]="styles"
              [longitude]="centre.longitude" [zoom]="zoom" style="height: calc(100vh - 4rem);"
              (mapReady)="mapReady($event)">
              <button id="Settings" class="toggle-button px-16 controls button mt-16 btn-map btn-breadcrumb"
                (click)="clicklist(click,!showbredcrumb)">
                <i class=" me-8 fs-16 {{!showbredcrumb ? 'icon-square':'icon-check-square-fill text-accent1'}}"></i>
                Show Breadcrumbs
              </button>
              <button id="Zoom" class="mt-16 sq-40 radius-6 ms-16 btn-map btn-zoom flex-center fs-20"
                (click)="sidebarCollapse = !sidebarCollapse">
                <i class="icon-min-fullscreen" [ngClass]="{'icon-fullscreen': sidebarCollapse }"></i>
              </button>
              <ng-container *ngIf="click>=0 && showbredcrumb">
                <ng-container *ngFor="let m of ways; let i = index">
                  <agm-overlay [latitude]="m.lat" [longitude]="m.lng" (markerClick)="openWindow(i)"
                    [title]="'map-circle'">
                    <!-- <i class="bi bi-circle-fill text-accent2"></i> -->
                    <img style="transform: translate(2px, 18px);" src="assets/images/helper/map-img/map-dot-18.png">
                    <agm-info-window [isOpen]="isInfoWindowOpen(i)" *ngIf="isInfoWindowOpen(i)">
                      <p class=" hstack mb-0 align-items-start gap-16  me-16 fw-400 overflow-hidden">
                        {{this.appservice.dateFormatDisplay(m.val.timestamp)+"
                        "+this.appservice.formatTimeDate(m.val.timestamp)}}
                      </p>
                    </agm-info-window>
                  </agm-overlay>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="click<0 && showbredcrumb">
                <ng-container *ngFor="let m of fullbreadcrumb; let i = index">
                  <agm-overlay [latitude]="m.lat" [longitude]="m.lng" (markerClick)="openWindow(i)"
                    [title]="'map-circle'">
                    <!-- <i class="bi bi-circle-fill text-accent2"></i> -->
                    <img style="transform: translate(2px, 18px);" src="assets/images/helper/map-img/map-dot-18.png">
                    <agm-info-window [isOpen]="isInfoWindowOpen(i)" *ngIf="isInfoWindowOpen(i)">
                      <p class=" hstack align-items-start gap-16 me-24 mb-0 fw-400 overflow-hidden">
                        {{this.appservice.dateFormatDisplay(m.val.timestamp)+"
                        "+this.appservice.formatTimeDate(m.val.timestamp)}}
                      </p>
                    </agm-info-window>
                  </agm-overlay>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="clickedMarkerKey>=0">
                <agm-overlay [latitude]="clickedMarker.latitude" [longitude]="clickedMarker.longitude"
                  (markerClick)="openMarkerWindow(clickedMarkerKey)" [title]="'map-circle'">
                  <img src="assets/images/helper/map-img/map-pin2-45.png">
                  <agm-info-window [isOpen]="isInfoMWindowOpen(clickedMarkerKey)"
                    *ngIf="isInfoMWindowOpen(clickedMarkerKey)">
                    <div class="card card-c2 border-0" style="max-width: 16rem;">
                      <div class="card-body overflow-hidden hstack gap-8 px-0 fs-12 pt-0 pb-12">
                        <i class="text-accent1 fs-16 icon-geo-alt-fill"></i>
                        <span class=" fw-500 text-trim-3 pe-16"
                          title="{{clickedMarker.location}}">{{clickedMarker.location}}</span>
                      </div>
                      <div class="card-body overflow-hidden vstack gap-8 px-0 fs-12 pt-12 pb-0">
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Mobility Status</span>
                          <span
                            class=" text-end text-uppercase {{this.badges[clickedMarker.mobility_status]}} fw-500">{{clickedMarker.mobility_status}} </span>
                        </div>
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Time</span>
                          <span
                            class=" text-end fw-500">{{this.appservice.formatTimeDate(clickedMarker.timestamp)}} 
                            <ng-container *ngIf="clickedMarker?.plugin_data.stationary_interval != null">
                            ({{timeFormatConvert(clickedMarker?.plugin_data.stationary_interval)}})
                          </ng-container>
                          </span>
                        </div>
                        <div class="hstack gap-16 justify-content-between">
                          <span class="text-light-500 ">Date</span>
                          <span
                            class="text-end fw-500">{{this.appservice.dateFormatDisplay(clickedMarker.timestamp)}}</span>
                        </div>
                      </div>
                    </div>
                  </agm-info-window>
                </agm-overlay>
              </ng-container>
              <!-- [optimizeWaypoints]="true" [travelMode]="DRIVING" [provideRouteAlternatives]="false" -->
              <ng-container *ngIf="!prepareloader">
              <ng-container *ngFor="let dir of dirs">
                <ng-container *ngIf="click>=0">
                  <agm-direction *ngIf="dir.isDirection" [origin]="dir.origin" [destination]="dir.destination"
                    [renderOptions]="dir.renderOptions" [markerOptions]="dir.markerOptions" [waypoints]="dir.waypoints" [optimizeWaypoints]="true" [travelMode]="DRIVING" [provideRouteAlternatives]="false">
                  </agm-direction>
                </ng-container>
                <ng-container *ngIf="click<0 && showbredcrumb">
                  <agm-direction *ngIf="dir.isDirection" [origin]="dir.origin" [destination]="dir.destination"
                    [renderOptions]="ros" [markerOptions]="dir.fbms" [waypoints]="dir.waypoints" [optimizeWaypoints]="true" [travelMode]="DRIVING" [provideRouteAlternatives]="false">
                  </agm-direction>
                </ng-container>
                <ng-container *ngIf="click<0 && !showbredcrumb">
                  <agm-direction *ngIf="dir.isDirection" [origin]="dir.origin" [destination]="dir.destination"
                    [renderOptions]="roswob" [markerOptions]="dir.fbms" [waypoints]="dir.waypoints" [optimizeWaypoints]="true" [travelMode]="DRIVING" [provideRouteAlternatives]="false">
                  </agm-direction>
                </ng-container>
              </ng-container>
              </ng-container>

              <!-- <ng-container *ngFor="let dir of dirs">
                <agm-polyline  [strokeColor]="'#0066DD'" [strokeWeight]="5">
                  <ng-container *ngFor="let i of dir.polypoints">
                  <agm-polyline-point  [latitude]="i.lat" [longitude]="i.lng">
                  </agm-polyline-point>
                </ng-container>
              </agm-polyline>
            </ng-container> -->
              <!-- </ng-container>
                    <agm-direction  [origin]="fulldirs.origin" [destination]="fulldirs.destination"
                      [renderOptions]="fulldirs.renderOptions" [markerOptions]="fulldirs.markerOptions" [waypoints]="fullbreadcrumb">
                    </agm-direction>
                  </ng-container> -->
            </agm-map>
          </div>
        </div>
      </ng-container>
    </div>
  </main>
</div>
