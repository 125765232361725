import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import * as moment from 'moment';
import { NotificationService } from 'src/app/@core/services/notification.service';


@Component({
  selector: 'app-final-settlement-workdays-payable',
  templateUrl: './final-settlement-workdays-payable.component.html',
  styleUrls: ['./final-settlement-workdays-payable.component.scss']
})

export class FinalSettlementWorkdaysPayableComponent implements OnInit {

  @Input() payrollStatus: any;
  @Input() resignationDetails: any;
  @Input() inboxId: any = ""
  @Input() relievingStat:any;

  @Output() submitmethod = new EventEmitter();
  @Output() warningMsg = new EventEmitter();
  @Output() allowSaveWorkDays = new EventEmitter();
  @Output() attendanceProcessed = new EventEmitter()

  @Output() attendanceModuleWorkDaysSaveData = new EventEmitter();
  @Output() lopOrAttendanceModule = new EventEmitter();

  overtimeHours = false;
  totalPaidDays = false;
  workDaysPayableData: any = [];
  lopMonthArray: any = [];
  empId: any = "";
  loader = false;
  submitted = false;
  defaultMonthData: any = [];
  currentMonth = new Date().toLocaleString('en-us', { month: 'long' })
  currentYear = Number(new Date().getFullYear());
  listOfMonth = ["January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"];
  salaryYears: any;

  alertShow: any;
  attendanceMonthArray: any = [];
  attendanceYearArray: any = [];
  errorMsg: any;
  allowSave = true;
  monthName: any;
  months: any = [];
  monthArray: any[] = [];
  disableBtn = true;
  arrearArr : any = []
  saveData : any = [];
  arrearprocessFlag : any;
  attendanceArr : any = []
  errValidation : any;
  errFlag = false;
  overtimeId : any
  alertMsg = '';
  status : any
  statusAlertMsg : any
  empalertMsg = '';
  alertToggle = false;
  statArr : any = []
  attendanceErrMsg = ''
  attendErrFlag = false;
  attendanceProcessFlag:any
  resetMsg = ""
  confirmMsg = false;
  resetClicked = false;
  overtimeErrMsg = ''
  overtimeErrFlag = false;




  constructor(
    public finalSettlementService: FinalSettlementService,
    public router: Router,
    public route: ActivatedRoute,
    public appService: AppService,
    public messageService: MessageService,
    private notificationService: NotificationService,
    public fb: FormBuilder,
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['id'])) {
        this.empId = parseInt(params['id']);
        this.workDaysPayableDetails('onInit');
      }
    });
  }

  @Input() formGp: FormGroup = this.fb.group({
    present_days: [''],
    weekoff_days: [''],
    lop_days: [''],
    total_payable_days: [''],
    fnf_reversal_lop: []
  });

  reversalLOP(): FormArray {
    return this.formGp.get("fnf_reversal_lop") as FormArray
  }

  newresersalLOP(): FormGroup {
    return this.fb.group({
      "pay_year": [null],
      "pay_month": [null],
      "reversal_days": [0, [Validators.required, digitdecimalValidator()]]
    })
  }

  addLOPreserveal(from: any, year: any, month: any) {

    this.reversalLOP().push(this.newresersalLOP());
    if (from == 'add') {
      // let lopMonth = {
      //   'month': this.currentMonth,
      //   'year': this.currentYear
      // };
      // this.lopMonthArray.push(lopMonth);
      (<FormArray>this.formGp.get("fnf_reversal_lop")).at(Number(this.formGp.value.fnf_reversal_lop.length)-1).get('pay_month')?.setValue(this.currentMonth);
      (<FormArray>this.formGp.get("fnf_reversal_lop")).at(Number(this.formGp.value.fnf_reversal_lop.length)-1).get('pay_year')?.setValue(this.currentYear);
      this.monthArrayCreation(this.formGp.value.fnf_reversal_lop?.length);
    }
    else {
      // let lopMonth = {
      //   'month': month,
      //   'year': year
      // };
      // this.lopMonthArray.push(lopMonth);
      (<FormArray>this.formGp.get("fnf_reversal_lop")).at(Number(this.formGp.value.fnf_reversal_lop.length)-1).get('pay_month')?.setValue(month);
      (<FormArray>this.formGp.get("fnf_reversal_lop")).at(Number(this.formGp.value.fnf_reversal_lop.length)-1).get('pay_year')?.setValue(year);
      this.monthArrayCreation(this.formGp.value.fnf_reversal_lop?.length);
    }

  }

  removeLOPreserveal(index: any) {
    this.reversalLOP().removeAt(index);
    // this.lopMonthArray.splice(index, 1);
    this.months.splice(index, 1);
  }

  get f() { return this.formGp.controls; }

  workDaysPayableDetails(from:any) {
    this.loader = true;

    this.disableBtn = false;
    this.arrearprocessFlag = null;
    this.workDaysPayableData = [];
    this.finalSettlementService.getResignationDetails(this.empId, 'workdays_payable',this.resignationDetails?.value?.pay_month,this.resignationDetails?.value?.pay_year,this.inboxId).subscribe((res: any) => {
      this.workDaysPayableData = res?.data;

      this.lopOrAttendanceModule.emit(this.workDaysPayableData?.attendance_lop)

      //onInit validation
      if(from == 'onInit'){
        if(!this.workDaysPayableData?.attendance_lop){
          this.attendanceEmpList('');
          this.allowSaveWorkDays.emit(false);
        }else{
          this.setValidatorsFunction();
          this.allowSaveWorkDays.emit(false);
        }
      }
      // End

      this.loader = false;

      this.salaryYears = this.generateArrayOfYears();

      // The case of attendance process
      if(from == 'process')
        this.fnfattendanceSave();
      // End

        // The case of LOP
      if(this.workDaysPayableData?.attendance_lop){
        this.attendanceProcessed.emit(true);
        // In the case of initial API call
        if(this.formGp.value.fnf_reversal_lop?.length == 0){
          this.formGp.get('present_days')?.setValue(res?.data?.present_days);
          this.formGp.get('weekoff_days')?.setValue(res?.data?.weekoff_days);
          this.formGp.get('lop_days')?.setValue(res?.data?.lop_days);
        }
        Object.entries(res?.data).forEach(([key, value]) => {

           if (key == 'fnf_reversal_lop'){
            let fnf_reversal_lop: any = value
            if (fnf_reversal_lop?.length == 0) {
              this.reversalLOP().removeAt(0);
              // this.addLOPreserveal('edit', this.currentYear, this.currentMonth);
              this.addLOPreserveal('edit', this.resignationDetails?.value?.pay_year, this.resignationDetails?.value?.pay_month);
              this.monthArrayCreation(1);
            }
            else if (fnf_reversal_lop?.length > 0 &&  (this.formGp.value.fnf_reversal_lop.length == 0)) {
              // In the case of initial API call
              for (let i = 0; i < fnf_reversal_lop?.length; i++) {
                this.reversalLOP().removeAt(i);
                this.addLOPreserveal('edit', fnf_reversal_lop[i]?.pay_year, fnf_reversal_lop[i]?.pay_month);
              }
              (<FormArray>this.formGp.get("fnf_reversal_lop")).reset(fnf_reversal_lop);
              this.computeTotalPayableDays('load', this.workDaysPayableData.present_days, this.workDaysPayableData.weekoff_days, '');
              this.monthArrayCreation(fnf_reversal_lop?.length);

            }else{
              // In the case of not initial API call
              this.computeTotalPayableDays('load', this.formGp.value.present_days,this.formGp.value.weekoff_days, '');
              this.monthArrayCreation(this.formGp.value.fnf_reversal_lop.length);
            }
          }
          this.formGp.get('total_payable_days')?.disable();
        })
      }
      if(this.relievingStat == 'Processed'){
        this.formGp.get('present_days')?.disable();
        this.formGp.get('weekoff_days')?.disable();
        this.formGp.get('lop_days')?.disable();
      }
      this.getFnFMonthsDropdown();

    });
  }

  changeCarousel(key: any, field: any, index: any) {

    if (field == 'month') {
      (<FormArray>this.formGp.get("fnf_reversal_lop")).at(index).get('pay_month')?.setValue(key);
    } else {
      if (Number(key) == this.currentYear)
        this.monthNameSetting(index);
      else
        this.months[index] = this.listOfMonth;
        (<FormArray>this.formGp.get("fnf_reversal_lop")).at(index).get('pay_year')?.setValue(key);
    }

    this.getUsedLopReversalDays(index, 'change');
  }

  generateArrayOfYears() {
    var max = new Date().getFullYear();
    let doj = (new Date(this.workDaysPayableData?.employee_doj)).getFullYear();
    doj = max - doj;
    var min = max - doj;
    var years = [];
    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }

  computeTotalPayableDays(e: any, presentDays: any, weekoff: any, cntName: any) {
    let totalPayableDays = this.workDaysPayableData.exclude_weekoff == false ? (presentDays)?Number(presentDays):0 + (weekoff)?Number(weekoff):0 : Number(presentDays);
    this.formGp.get('total_payable_days')?.setValue(Number(totalPayableDays));

    if (cntName == 'present_days') {
      if (e.target?.value == this.workDaysPayableData?.present_days) {
        this.getrestrictData();
      } else {
        this.getFnFAttendanceDetails('keyUp', 'present_days');
      }
    } else if (cntName == 'weekoff_days') {
      if (e.target?.value == this.workDaysPayableData?.weekoff_days) {
        this.getrestrictData();
      } else {
        this.getFnFAttendanceDetails('keyUp', 'weekoff_days');
      }
    }
  }

  keyUpLopDays(e: any, value: any) {
    if (e.target?.value == this.workDaysPayableData?.lop_days) {
      this.getrestrictData();
    } else {
      this.getFnFAttendanceDetails('keyUp', 'lop_days');
    }
  }

  getrestrictData() {
    this.allowSave = true;
    this.allowSaveWorkDays.emit(this.allowSave);
    this.alertShow = 'hide';
  }

  submitWorkDaysPayableDetails() {
    if(this.workDaysPayableData?.attendance_lop == true){
      if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
        this.warningMsg.emit();
        this.submitmethod.emit('lop');
      } else {

        this.submitted = true;
        if (this.formGp.invalid) {
          return;
        }

        this.attendanceProcessed.emit(true);

        this.submitmethod.emit('lop');
      }
    }else{
      if (this.attendanceProcessFlag == true) {
          this.submitmethod.emit('');
      }
    }

  }

  fnfattendanceSave(){

     var  fnf_reversal_lop : any = [];

    this.workDaysPayableData?.fnf_reversal_lop?.forEach((items:any)=>{
      fnf_reversal_lop.push({
        "pay_year" : items.lop_year,
        "pay_month" : items.lop_month,
        "reversal_days" : items.arrear_days
      })

    })

    let workData = {
      "step_name": "workdays_payable",
      "employee": this.empId,
      "pay_year": this.resignationDetails?.value?.pay_year,
      "pay_month": this.resignationDetails?.value?.pay_month,
      "present_days": this.attendanceArr?.prasent_days,
      "weekoff_days": this.attendanceArr?.weekly_offs,
      "lop_days": this.attendanceArr?.absent_unpaid,
      "fnf_reversal_lop": fnf_reversal_lop,

      "holiday_days": this.attendanceArr?.holiday_paid,
      "deduction_paid_days":  this.attendanceArr?.deduction_paid_days,
      "deduction_leave_days": this.attendanceArr?.deduction_leaves,
    }


    this.attendanceModuleWorkDaysSaveData.emit({'data': workData});

  }


  getUsedLopReversalDays(index: any, from: any) {
    let lopMonth = (<FormArray>this.formGp.get("fnf_reversal_lop")).at(index).get('pay_month')?.value;
    let lopYear  = (<FormArray>this.formGp.get("fnf_reversal_lop")).at(index).get('pay_year')?.value;


    if(lopMonth != undefined && lopYear != undefined){
      this.finalSettlementService.getUsedLopReversalDays(this.empId, lopMonth, lopYear).subscribe((res: any) => {
        if (from == 'change')
          (<FormArray>this.formGp.get("fnf_reversal_lop")).at(index).get('reversal_days')?.setValue(res?.data?.reversal_days);
        (<FormArray>this.formGp.get("fnf_reversal_lop")).at(index).get('reversal_days')?.setValidators([Validators.required, digitdecimalValidator(), Validators.max(res?.data?.total_lop)]);
        (<FormArray>this.formGp.get("fnf_reversal_lop")).at(index).get('reversal_days')?.updateValueAndValidity();
      });
    }
  }

  getFnFMonthsDropdown() {
    this.attendanceMonthArray = [];
    this.attendanceYearArray = [];
    this.finalSettlementService.getFnFMonthsDropdown(this.empId).subscribe((res: any) => {
      if (res?.data?.length > 0) {
        for (let i = 0; i < res?.data?.length; i++) {
          this.attendanceMonthArray.push(res?.data[i]?.['pay_month']);
          this.attendanceYearArray.push(res?.data[i]?.['pay_year']);
        }

        // To remove the duplicate entries
        this.attendanceYearArray = this.attendanceYearArray?.filter((item: any, index: any) => {
          return (this.attendanceYearArray.indexOf(item) == index)
        })
        this.loader = false;
        this.disableBtn = true;
      }
    });
  }



  fnfAttendanceDetails: any = [];

  getFnFAttendanceDetails(from: any, cntName: any) {

    this.errorMsg = '';

    if (this.resignationDetails?.value?.pay_month != undefined && this.resignationDetails?.value?.pay_year != undefined) {

      this.finalSettlementService.getFnFAttendanceDetails(this.empId, this.resignationDetails?.value?.pay_month, this.resignationDetails?.value?.pay_year).subscribe((res: any) => {

        this.fnfAttendanceDetails = res?.data;

        if (cntName == 'present_days') {
          this.formGp.get('present_days')?.setValidators([Validators.required, Validators.max(this.fnfAttendanceDetails?.max_payable_days_details?.max_payable_days), digitdecimalValidator()]);
          this.formGp.get('present_days')?.updateValueAndValidity();
        }

        this.allowSave = res?.data?.payroll_status_details?.allow_save;
        if (!this.allowSave)
          this.allowSaveWorkDays.emit(this.allowSave);

        if (from == 'onChange') {
          this.formGp.get('lop_days')?.setValue(res?.data?.lop_days);
          this.formGp.get('present_days')?.setValue(res?.data?.present_days);
          this.formGp.get('weekoff_days')?.setValue(res?.data?.weekoff_days);
        }


        if (res?.data?.payroll_status_details?.error != '' && res?.data?.payroll_status_details?.error != null && res?.data?.payroll_status_details?.error != undefined) {
          this.errorMsg = res?.data?.payroll_status_details?.error;
          this.alertShow = 'show';
        } else {
          this.errorMsg = '';
          this.alertShow = 'hide';
        }
      });
    }
  }

  lopReversalDaysKeyUp(index: any) {
    this.getUsedLopReversalDays(index, 'keyup');
  }

  monthArrayCreation(number: any) {
    for (let i = 0; i < number; i++) {
      this.monthArray[i] = Array();
    }
    this.priorMonthNameSetting();
  }

  priorMonthNameSetting() {
    if (this.formGp?.value?.fnf_reversal_lop?.length > 0) {
      for (let i = 0; i < this.formGp?.value?.fnf_reversal_lop?.length; i++) {
        if (((<FormArray>this.formGp.get("fnf_reversal_lop")).at(i).get('pay_year')?.value) == this.currentYear) {
          this.monthNameSetting(i)
        } else {
          this.months[i] = this.listOfMonth;
        }
      }
    }
  }

  monthNameSetting(index1: any) {
    let index = this.listOfMonth.indexOf(this.workDaysPayableData?.pay_month);
    let monthNumber: any = [];
    for (let i = 0; i <= index; i++) {
      monthNumber.push(this.listOfMonth[i])
    }
    this.months[index1] = monthNumber;
  }



checkall(event:any){
  var newList = this.workDaysPayableData?.fnf_reversal_lop;
  newList.forEach((items:any) => {
    if(event.target.checked == true){
    this.statArr.push(items.status)
    var sameElement = this.statArr.every( (item:any) => item === items.status )
    if(!sameElement){
      this.empalertMsg = "Different status cannot be selected";
      this.alertToggle = true;
      event.target.checked = false;
      newList.forEach((data:any) => {
      data.checked = false;
      this.arrearprocessFlag = null;
      this.statArr = [];
    })
    }else{
      items.checked = true;
      if(items?.status == 'Pending'){
        this.arrearprocessFlag = false;
      }else{
        this.arrearprocessFlag = true;
      }
    }
  }else{
    items.checked = false;
    this.statArr = [];
    this.arrearprocessFlag = null;

  }
  })

}

checkAllSelected(){
  if(this.workDaysPayableData?.fnf_reversal_lop?.length){
    return this.workDaysPayableData?.fnf_reversal_lop.every((item:any) => item.checked)
  } else {
   return false;
  }
}

// appear ignore or process button
viewButton(event:any,status:any,checked:any){
  if(event.target.checked == true){
    this.statArr.push(status)
    var sameElement = this.statArr.every( (item:any) => item === status )
    if( !sameElement){
      checked = false;
      this.empalertMsg = "Different status cannot be selected";
      this.alertToggle = true;
      event.target.checked = false;
      this.statArr.pop(status)
    }else{
      if(status == 'Pending'){
        this.arrearprocessFlag = false;
      }else{
        this.arrearprocessFlag = true;
      }
    }
  }else{
    this.statArr = []
    this.arrearprocessFlag = null;

  }
}

// for arrear process
successDataCount    = 0;
failedData:any      = [];
exportData:any      = []
downloadMsg         = false;
arrearProcessFunction(){
  this.statArr = [];
  this.saveData = []
  for(let i=0;i<this.workDaysPayableData?.fnf_reversal_lop?.length;i++) {
    if(this.workDaysPayableData?.fnf_reversal_lop[i]?.checked == true){
      var dataArr = {
        'id': this.empId,
        'arrear_month': this.workDaysPayableData?.fnf_reversal_lop[i]?.lop_month,
        'arrear_year' : this.workDaysPayableData?.fnf_reversal_lop[i]?.lop_year
      }
      this.saveData.push(dataArr)
    }
    }
  this.finalSettlementService.saveArrearProcess({'month':this.resignationDetails?.value?.pay_month,'year':parseInt(this.resignationDetails?.value?.pay_year),'status':'Completed','fnf':true,'data':this.saveData}).subscribe((res: any) => {

    if(res?.error_list != undefined && res?.error_list?.length != 0)
      this.downloadErrorData(res);

    this.workDaysPayableDetails('')
  })

}

// for arrear unprocess
arrearUnprocessFunction(){

  this.statArr = [];
  this.saveData = []
  for(let i=0;i<this.workDaysPayableData?.fnf_reversal_lop?.length;i++) {
    if(this.workDaysPayableData?.fnf_reversal_lop[i]?.checked == true){
      var dataArr = {
        'id': this.empId,
        'arrear_month': this.workDaysPayableData?.fnf_reversal_lop[i]?.lop_month,
        'arrear_year' : this.workDaysPayableData?.fnf_reversal_lop[i]?.lop_year
      }
      this.saveData.push(dataArr)
    }
    }
  this.finalSettlementService.saveArrearProcess({'month':this.resignationDetails?.value?.pay_month,'year':this.resignationDetails?.value?.pay_year,'status':'Pending','fnf':true,'data':this.saveData}).subscribe((res: any) => {

    if(res?.error_list != undefined && res?.error_list?.length != 0)
      this.downloadErrorData(res);

    this.workDaysPayableDetails('')

  })
}

// for ignore
arrearIgnored(){

  this.saveData = []
  for(let i=0;i<this.workDaysPayableData?.fnf_reversal_lop?.length;i++) {
    if(this.workDaysPayableData?.fnf_reversal_lop[i]?.checked == true){
      var dataArr = {
        'id': this.empId,
        'arrear_month': this.workDaysPayableData?.fnf_reversal_lop[i]?.lop_month,
        'arrear_year' : this.workDaysPayableData?.fnf_reversal_lop[i]?.lop_year
      }
      this.saveData.push(dataArr)
    }
    }
  this.finalSettlementService.saveArrearProcess({'month':this.resignationDetails?.value?.pay_month,'year':parseInt(this.resignationDetails?.value?.pay_year),'status':'Ignored','fnf':true,'data':this.saveData}).subscribe((res: any) => {
    if(res?.error_list != undefined && res?.error_list?.length != 0)
      this.downloadErrorData(res);
    this.workDaysPayableDetails('')

  })

}

// Error download
downloadErrorData(data:any){
  this.exportData    = [];
  this.failedData    = [];
    if(data?.error_list != undefined && data?.error_list?.length != 0){
      data?.error_list?.forEach((i:any, k:any) => {
            this.failedData?.push(i)
        });
    }
    if(this.failedData?.length > 0){
    for (let i = 0; i < this.failedData?.length; i++) {
      ['Employee Code','Employee Name','Salary Month','Salary Year', 'Arrear Month','Arrear Year','Arrear Days','Comment']
      this.exportData.push({'Employee Code':this.failedData[i]['employee_code'],'Arrear Month':this.failedData[i]['lop_month'], 'Arrear Year':this.failedData[i]['lop_year'],'Error description':this.failedData[i]['error']});
    }

    this.errorMsg     = this.failedData?.length+' error data';
    this.downloadMsg  = true;
  }
}

// for arrear process overide
arrearProcessOveride(index:any){
  this.saveData = []
  var dataArr = {
        'id': this.workDaysPayableData?.fnf_reversal_lop[index]?.process_id,
        'arear_days' : parseInt(this.workDaysPayableData?.fnf_reversal_lop[index]?.arrear_days)
      }
      this.saveData.push(dataArr)


  this.finalSettlementService.arrearProcessOveride({'month':this.resignationDetails?.value?.pay_month,'year':parseInt(this.resignationDetails?.value?.pay_year),'data':this.saveData}).subscribe((res: any) => {
    if(res?.error_list?.length == 0){
      this.errFlag = false;
    }else{
      this.errValidation = res?.error_list[0]?.error;
      this.errFlag = true;

    }
  })

}

// for getting attendance list
attendanceEmpList(from:any){
  this.finalSettlementService.attendanceList(this.empId,this.resignationDetails?.value?.pay_month,parseInt(this.resignationDetails?.value?.pay_year)).subscribe((res: any) => {
    this.attendanceArr = res.data;
    this.attendanceProcessFlag = this.attendanceArr?.attendance_processed == false?false:true;
    this.attendanceProcessed.emit(this.attendanceProcessFlag);


    if(this.attendanceArr?.attendance_processed)
      this.fnfattendanceSave();


    if(from == 'process')
      this.workDaysPayableDetails('process');

  })

}

// for attendance process
attendanceProcessFunction(){


  let relieve_month_processed = (this.relievingStat == null || this.relievingStat == '' || this.relievingStat == 'Unprocessed') ? false : true;

  this.saveData = [];
  this.saveData.push(this.attendanceArr?.employee_id)
  this.finalSettlementService.saveAttendanceProcess({'month':this.resignationDetails?.value?.pay_month,'year':parseInt(this.resignationDetails?.value?.pay_year),'status':'Completed','fnf':true,'data':this.saveData,'relieve_month_processed':relieve_month_processed},this.inboxId).subscribe((res: any) => {
    this.attendanceEmpList('process');
    // this.fnfattendanceSave();
    // this.workDaysPayableDetails('process');


    },(error:any) => { // second parameter is to listen for error
   if(error.error.error_list?.length !=0){
    this.alertMsg = error.error.error_list
    this.notificationService.handleErrorNotification(this.alertMsg,"Error");
   }
  });

}

// for attendance Unprocess
attendanceUnProcessFunction(){

  let relieve_month_processed = (this.relievingStat == null || this.relievingStat == '' || this.relievingStat == 'Unprocessed') ? false : true;

  this.saveData = [];
  this.saveData.push(this.attendanceArr?.employee_id)
  this.finalSettlementService.saveAttendanceProcess({'month':this.resignationDetails?.value?.pay_month,'year':parseInt(this.resignationDetails?.value?.pay_year),'status':'Pending','fnf':true,'data':this.saveData,'relieve_month_processed':relieve_month_processed},this.inboxId).subscribe((res: any) => {
    this.workDaysPayableDetails('')
    this.attendanceEmpList('')
  },(error:any) => { // second parameter is to listen for error
    if(error.error.error_list?.length !=0){
     this.alertMsg = error.error.error_list
     this.notificationService.handleErrorNotification(this.alertMsg,"Error");
    }
   })

}

// for edit total payable days
attendanceEditForm: FormGroup = this.fb.group({
  prasent_days: ['',[Validators.required,digitdecimalValidator(), Validators.maxLength(5)]],
  weekly_offs: ['',[Validators.required,digitdecimalValidator(), Validators.maxLength(5)]],
  holiday_paid: ['',[Validators.required,digitdecimalValidator(), Validators.maxLength(5)]],
  leave_paid: ['',[Validators.required,digitdecimalValidator(), Validators.maxLength(5)]],
  absent_unpaid: ['',[Validators.required,digitdecimalValidator(), Validators.maxLength(5)]],
  leave_unpaid: ['',[Validators.required,digitdecimalValidator(), Validators.maxLength(5)]],
  deduction_paid_days : ['',[Validators.required,digitdecimalValidator(), Validators.maxLength(5)]],
})

// for edit overtime hours
overtimeForm: FormGroup = this.fb.group({
  working_day_ot_hours: ['',[Validators.required]],
  week_off_ot_hours: ['',[Validators.required]],
  holiday_ot_hours: ['',[Validators.required]],
  national_holiday_ot_hours: ['',[Validators.required]],
  half_working_day_ot_hours: ['',[Validators.required]],

})
disableDate(){
  return false;
}

//for attendance override
attendanceOveride(){
  this.saveData = []
  var dataArr = {
        'id': this.attendanceArr?.process_id,
        'prasent_days' : Number(this.attendanceEditForm?.value?.prasent_days),
        'weekly_offs' : Number(this.attendanceEditForm?.value?.weekly_offs),
        'leave_paid' : Number(this.attendanceEditForm?.value?.leave_paid),
        'holiday_paid' : Number(this.attendanceEditForm?.value?.holiday_paid),
        'absent_unpaid' : Number(this.attendanceEditForm?.value?.absent_unpaid),
        'leave_unpaid' : Number(this.attendanceEditForm?.value?.leave_unpaid),
        'deduction_paid_days' : Number(this.attendanceEditForm?.value?.deduction_paid_days),
      }
      this.saveData.push(dataArr)
    this.finalSettlementService.attendanceProcessOveride({'month':this.resignationDetails?.value?.pay_month,'year':parseInt(this.resignationDetails?.value?.pay_year),'data':this.saveData}).subscribe((res: any) => {
      if(res?.error_list?.length != 0){
        this.attendErrFlag = true;
        this.attendanceErrMsg = res?.error_list[0]?.error
      }else{
        this.overtimeHours = false;
        this.workDaysPayableDetails('')
        this.attendanceEmpList('')
      }
  })


}

// getting overtime process id
overtimedata(ind:any){
  this.overtimeId = ind;
}

// for overtime override
overtimeOverrride(){
  this.saveData = []

  var workingarr : any = (moment(this.overtimeForm?.value?.working_day_ot_hours).format('HH:mm:ss')).split(':')
  var decWorking : any = (workingarr[0]*1 +parseInt(workingarr[1])/60).toFixed(2);

  var weekarr : any = (moment(this.overtimeForm?.value?.week_off_ot_hours).format('HH:mm:ss')).split(':')
  var decWeekly : any = (weekarr[0]*1 +parseInt(weekarr[1])/60).toFixed(2);

  var holidayarr : any = (moment(this.overtimeForm?.value?.holiday_ot_hours).format('HH:mm:ss')).split(':')
  var decHoliday : any = (holidayarr[0]*1 +parseInt(holidayarr[1])/60).toFixed(2);

  var nationalHolidayarr : any = (moment(this.overtimeForm?.value?.national_holiday_ot_hours).format('HH:mm:ss')).split(':')
  var decNationalHoliday : any = (nationalHolidayarr[0]*1 +parseInt(nationalHolidayarr[1])/60).toFixed(2);

  var halfWorkingarr : any = (moment(this.overtimeForm?.value?.half_working_day_ot_hours).format('HH:mm:ss')).split(':')
  var decHalfWorking : any = (halfWorkingarr[0]*1 +parseInt(halfWorkingarr[1])/60).toFixed(2);

  var dataArr = {
        'id': this.overtimeId,
        'working_day_ot_hours' : decWorking,
        'week_off_ot_hours' : decWeekly,
        'holiday_ot_hours' : decHoliday,
        'national_holiday_ot_hours' : decNationalHoliday,
        'half_working_day_ot_hours' : decHalfWorking
      }
      this.saveData.push(dataArr)
    this.finalSettlementService.overtimeOveride({'month':this.resignationDetails?.value?.pay_month,'year':Number(this.resignationDetails?.value?.pay_year),'data':this.saveData}).subscribe((res: any) => {
      if(res?.error_list?.length != 0){
        this.overtimeErrFlag = true;
        this.overtimeErrMsg = res?.error_list[0]?.error;
      }else{
        this.totalPaidDays = false;
        this.workDaysPayableDetails('')
        this.attendanceEmpList('')
      }
  })
}

lopDetailsResetConfirm(){
  this.confirmMsg = true;
  this.resetMsg = "Resetting will permanently delete all attendance and LOP reversal details for the month. You'll have to add these details again. Are you sure you want to continue?";

}
confirmReset(){
  this.resetClicked = true;
  this.finalSettlementService.LopDataReset(this.empId,this.resignationDetails?.value?.pay_month,this.resignationDetails?.value?.pay_year).subscribe((res: any) => {
    this.confirmMsg = false;
    this.resetClicked = false;
    this.removeAllReversalLOP();
    this.formGp.get('present_days')?.reset(0);
    this.formGp.get('weekoff_days')?.reset(0);
    this.formGp.get('lop_days')?.reset(0);
    this.formGp.get('total_payable_days')?.reset(0);
    this.workDaysPayableDetails('')

  })

}

removeAllReversalLOP(){
  if(this.formGp.value.fnf_reversal_lop?.length > 0){
    while(this.formGp.value.fnf_reversal_lop?.length !== 0){
      this.reversalLOP().removeAt(0);
    }
  }
}

  setValidatorsFunction(){

    this.formGp.get('present_days')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(5)]);
    this.formGp.get('weekoff_days')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(5)]);
    this.formGp.get('lop_days')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(5)]);
    // this.formGp.get('lop_reversal_days')?.setValidators([digitdecimalValidator()]);

    this.formGp.get('present_days')?.updateValueAndValidity();
    this.formGp.get('weekoff_days')?.updateValueAndValidity();
    this.formGp.get('lop_days')?.updateValueAndValidity();
    // this.formGp.get('lop_reversal_days')?.updateValueAndValidity();

  }

}


