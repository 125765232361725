import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InboxRoutingModule } from './inbox-routing.module';
import { InboxComponent } from './inbox.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgSelectModule } from '@ng-select/ng-select';
import { SalaryStructureApprovalComponent } from './salary-structure-approval/salary-structure-approval.component';

import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import * as moment from 'moment';
import { MasterSelectModule } from 'src/app/@shared/master-select.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HrModule } from '../hr/hr.module';

import { IgxTimePickerModule } from 'igniteui-angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { environment } from 'src/environments/environment';
import { TripRequestComponent } from './trip-request/trip-request.component';
import { InboxTitleComponent } from './inbox-title/inbox-title.component';
import { RequestModule } from '../request/request.module';
import { LoanProcessComponent } from './loan-process/loan-process.component';
import { LoanApprovalComponent } from './loan-approval/loan-approval.component';
@NgModule({
  declarations: [
    InboxComponent,
    SalaryStructureApprovalComponent,
    TripRequestComponent,
    InboxTitleComponent,
    LoanProcessComponent,
    LoanApprovalComponent
  ],
  imports: [
    CommonModule,
    InboxRoutingModule,
    DpDatePickerModule,
    NgxChartsModule,
    NgSelectModule,
    AngularMyDatePickerModule,
    MasterSelectModule,
    NgbModule,
    InfiniteScrollModule,
    PdfViewerModule,
    HrModule,
    Ng2SearchPipeModule,
    NgxDaterangepickerMd.forRoot({
      format: 'DD/MMM/YYYY',
      clearLabel: 'Cancel'
    }),
    FormsModule,
    ReactiveFormsModule,
    IgxTimePickerModule,
    MatDatepickerModule,
    AgmCoreModule.forRoot({
      apiKey: environment.locKey,
      libraries: ['places']
    }),
    AgmDirectionModule,
    RequestModule
  ],
  providers: [
    GoogleMapsAPIWrapper
  ]
})
export class InboxModule { }
