<div class="container-fluid p-32">
    <div class="row row-24">
        <div class="col-12 ">
            <div class="card card-c2 p-24">
                <div class="d-flex mb-16 align-items-start">
                    <div>
                        <div class=" fs-24">Reimbursements Claimed </div>
                        <div class="fs-12 text-light-400 text-uppercase">ALL CATEGORY</div>
                    </div>
                    <ng-select class="form-ngselect ms-auto fs-14"
                        placeholder="{{this.messageService.selectplaceholddisplay('Year')}}"
                        (change)="getReimbursementTrendDetails($event)" [(ngModel)]="selectedYearDisp" [clearOnBackspace]="false">
                        <ng-option class="fs-12" *ngFor="let year of yearList" [value]="year.k">{{year.v}}</ng-option>
                    </ng-select>
                    <!-- <ng-select class="form-ng-select ms-auto" placeholder="{{this.messageService.selectplaceholddisplay('Year')}}"
                    [(ngModel)]="selectedYear" (change)= "getReimbursementTrendDetails()">
                        <ng-option class="fs-12" *ngFor="let year of yearList" [value]="year">{{year}}</ng-option>
                    </ng-select> -->
                </div>

                <div class="col-12" *ngIf="!graphLoader">
                    <app-bar-chart [data]="multi" [labels]="xAxisLabel" [yText]="yAxisLabel"></app-bar-chart>
                </div>
                <div class="col-12" *ngIf="graphLoader">
                    <app-graph-loader></app-graph-loader>
                </div>
            </div>
        </div>

    </div>
</div>
