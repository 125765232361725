import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { FormBuilder,Validators ,FormArray,FormGroup} from '@angular/forms';
import * as moment from 'moment';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';


@Component({
  selector: 'app-leave-policy-details',
  templateUrl: './leave-policy-details.component.html',
  styleUrls: ['./leave-policy-details.component.scss']
})
export class LeavePolicyDetailsComponent implements OnInit {
  private subjectKeyUp = new Subject<any>();

  // @Input() checkVisibleToParent : any;
  // @Output() submitmethod = new EventEmitter();
  // @Input() mapLeaveType:any;

  // leaveType : any = [];
  // checkVisible = false;
  // leaveId : any
  // leaveTypeName : any;
  // leaveIdarr : any = []
  // @Input() editData:any;
// @Input() selectData:any



saveBtnClicked     : boolean = false
value              : any;
leaveName          : any =[];
maxValue           : any;
@Input() leaveType:any;
@Input() formGp: any;
@Input() editflag  :any;
@Input() id  :any;
@Output() checkboxEvent = new EventEmitter();
@Output() checkboxCount = new EventEmitter();
@Output() submitNext    = new EventEmitter();
minDate = new Date(2020, 0, 1);




  constructor(public messageService : MessageService,public appService: AppService,public existDataService : ExistDatasService,public fb : FormBuilder,public leavetypeService:leavetypeService) { }

  ngOnInit(): void {

    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
  })

  }

   // Simplified function to use for validation
   existService(){
    if(!this.editflag){
      this.value ={
        "page":"leavepolicy",
        "field":"name",
        "value": this.f.name.value,
        "is_active" : true
      }
    }else{
      this.value ={
        "id":this.id,
        "page":"leavepolicy",
        "field":"name",
        "value": this.f.name.value,
        "is_active" : true
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.leaveName = res.body
      if(this.leaveName.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }

// For validation of keyup(name)
keyUp(){
 this.subjectKeyUp.next(this.f.name.value)
}

 get f() {return  this.formGp.controls; }

 disableDate(){
  return false;
}

policy_valid_from(value:any){
  let from      = this.f.policy_valid_from.value
  this.maxValue = moment(from).add(30, 'days')
}
checkboxAction(index:any){
 this.checkboxEvent.emit(index);

}

CheckSelectedCount(){
  let selectedCount = 0
  let data = this.f.mapleave.controls;

  for(let i=0;i<data.length;i++){

    if(data[i].get('checked')?.value==true)
   selectedCount++
  }
  this.checkboxCount.emit(selectedCount);
  return selectedCount;
}

validateForm(){

  this.saveBtnClicked=true;

  if(this.formGp.invalid) {
    return;
  }
  this.submitNext.emit();
}


}
