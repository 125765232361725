<div class="container-fluid p-24 ">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>My Loan </h3>
      <p class="text-helper mb-md-0">View and track your loan request details here.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class=" " style="width:22.6875rem;">
          <app-searchbar #search [searchplaceholder]="searchkey" [isLoading]="isLoading"
            (searchEmitter)="searchResults($event)" (keywordsearchEmitter)="searchLoan($event)"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle (click)="filterPanel=true">
            <i class="icon-filter-left fs-13"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="height: calc(100vh - 15rem);" *ngIf="noDataFound && !loader; else all_data">
    <app-nodata-view class="vstack" [noDataImg]="'bg18.png'" [noDataText]="'Oops! no loans have been processed yet.'"
      [noDataPara]="'Loan details will be available here once it is processed. Check back later for updates.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>
  <ng-template  #all_data>
  <app-table-loader *ngIf="loader"></app-table-loader>
  <div class="row row-16" *ngIf="!noDataFound && !loader">
    <div class="col-12">
      <div class="card card-c2 py-16 flex-row align-items-center gap-64 px-32">
        <div class="indicater6 indicater-bg12  ps-12 fs-12 hstack gap-12">
          <div class="fs-32">{{outstandAmount}}</div>
          <div class="text-uppercase text-light-500  fw-500 fs-12">Total outstanding amount</div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card card-c2">
        <div class="table-responsive radius-4">
          <table class="table td-white-space td-width-15ch  td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
            <thead>
              <tr>
                <th class="fw-600 text-uppercase" scope="col">LOAN code</th>
                <th class="fw-600 text-uppercase" scope="col">LOAN TYPE</th>
                <th class="fw-600 text-uppercase" scope="col">Loan amount</th>
                <th class="fw-600 text-uppercase" scope="col"> Interest Type</th>
                <th class="fw-600 text-uppercase" scope="col"> Interest rate</th>
                <th class="fw-600 text-uppercase" scope="col"> outstanding amount</th>
                <th class="fw-600 text-uppercase" scope="col">PENDING EMI</th>
                <th class="fw-600 text-uppercase" scope="col">Closed on</th>
                <th class="fw-600 text-uppercase" scope="col">Status</th>
              </tr>
            </thead>
            <tbody class="cell-16 ">
              <tr *ngFor='let data of tableData'>
                <td data-toggle="tooltip" title="{{data?.loan_code}}"><a (click)="loanDetailFn(data?.id)" class="link-primary1">{{data?.loan_code}}</a></td>
                <td data-toggle="tooltip" title="{{data?.loan_type}}">{{data?.loan_type}}</td>
                <td data-toggle="tooltip" title="{{data?.loan_amount}}">{{data?.loan_amount}}</td>
                <td data-toggle="tooltip" title="{{data?.interest_type}}">{{data?.interest_type}}</td>
                <td data-toggle="tooltip" title="{{data?.interest_rate}}">{{data?.interest_rate}}%</td>
                <td data-toggle="tooltip" title="{{data?.to_be_recovered}}">{{data?.to_be_recovered}}</td>
                <td data-toggle="tooltip" title="{{data?.pending_emis}}">{{data?.pending_emis}}</td>
                <td data-toggle="tooltip" title="{{data?.closed_on != null ? this.appServ.dateFormatDisplay(data?.closed_on) : '-'}}">{{data?.closed_on != null ? this.appServ.dateFormatDisplay(data?.closed_on) : '-'}}</td>
                <td><span class="badge py-1 px-3" [ngClass]="{'badge-secondary' : data?.status == 'Closed', 'badge-success' : data?.status == 'Active', 'badge-primary' : data?.status == 'Restructured'}">{{data?.status}}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="lastPageCount > 20 && lastPageCount > tableData?.length">
      <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [collectionSize]="lastPageCount"
        [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="limit"
        (pageChange)="pageChanged($event)"></ngb-pagination>
    </div>
  </div>
  </ng-template>
</div>

<!-- Loan Filter -->
<app-loan-filter *ngIf="filterPanel" [filterPanel]="filterPanel" (filterData)="applyFilter($event)" (close)="closeFilter()" (reset)="resetfilter()" [filterForm]="filterForm" [from]="'loanProfile'"></app-loan-filter>

<!-- Loan Details View -->
<app-view-loan-details *ngIf="viewDetailsPanel" [from]="'myLoan'" [viewDetails]="viewDetailsPanel" [loanData]="viewSpecData" [loader]="viewLoader" (close)="panelClose($event)"></app-view-loan-details>
