import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-lr-leave-summary',
  templateUrl: './lr-leave-summary.component.html',
  styleUrls: ['./lr-leave-summary.component.scss']
})

export class LrLeaveSummaryComponent implements OnInit {

  leaveBalance                    = false;
  modalToggle                     = false;
  currentDocName                  = "";
  currentExtension                = "";
  allowedExtension                = "";
  investalertMsg:any              = '';
  alertToggle                     = false;
  fileName:any;
  employee: number                = 0;
  leaveBalanceDataTile:any        = [];
  leaveTypeBalanceDataTile:any[]  = [];
  leaveBalanceTable:any[]         = [];
  laeveBalanceDate: any           = "";
  loader                          = true;
  leaveTypeName: any              = "";
  currentYear: number             = new Date().getFullYear();
  portions:any                    = [];
  fullDay:any                     = [];
  halfDay:any                     = [];
  saveClicked                     = false;
  leaveRule                       = false;
  leaveRuleData:any               = [];
  leaveClubbedData:any            = [];
  lastDate:any                    = new Date(this.currentYear, 11, 31);

  @Output() submitmethod          = new EventEmitter();
  @Input()formGp:any;
  @Input()validationData:any;
  @Input()totalLeaveDays:any;

  @Input() validationPortionData: any;

  constructor(
    private investmentService:InvestmentDeclerationService,
    public appservice:AppService,
    private authService:AuthService,
    private leavetypeService : leavetypeService,
    public datePipe:DatePipe
  ) { }


  ngOnInit(): void {

    this.employee   = this.authService.isEmployeeId;

    if(this.formGp.value.leave_type != null)
      this.getLeaveType(this.formGp.value.leave_type);

      this.leaveForFunction();
  }

  /********* LEAVE FOR FUNCTION ****************/
  leaveForFunction(){

    this.portions = [];
    if(this.formGp.value.portion?.length == 2 && this.validationData?.leave_dates?.length > 2){
      for(let i=0; i< this.validationData?.leave_dates?.length; i++){

        if(this.appservice.dateFormatConvert(this.formGp.value.from_date) == this.appservice.dateFormatConvert(this.validationData.leave_dates[i])){
          this.portions.push({'date': this.appservice.dateFormatConvert(this.formGp.value.from_date),'portion': this.formGp.value.portion[0]['portion']});
        }
        else if(this.appservice.dateFormatConvert(this.formGp.value.to_date) == this.appservice.dateFormatConvert(this.validationData.leave_dates[i])){
          this.portions.push({'date': this.appservice.dateFormatConvert(this.formGp.value.to_date),'portion': this.formGp.value.portion[1]['portion']});
        }
        else{
          this.portions.push({'date': this.appservice.dateFormatConvert(this.validationData.leave_dates[i]),'portion': "Full Day"});
        }
      }
    }else{
      this.portions = this.formGp.value.portion;
    }

    this.fullDaysFunction();
    this.halfDaysFunction();
  }

   /*********  FULL DAYS FUNCTION ****************/
  fullDaysFunction(){
    this.fullDay = [];
    for(let i=0; i< this.portions.length; i++){
      if(this.portions[i]['portion'] == "Full Day"){
        // this.fullDay.push(this.datePipe.transform(this.portions[i]['date'] , 'd MMM y'));
        this.fullDay.push(this.appservice.dateFormatDisplay(this.portions[i]['date']));
      }
    }
  }

  /*********  HALF DAYS FUNCTION ****************/
  halfDaysFunction(){
    this.halfDay = [];
    for(let i=0; i< this.portions.length; i++){
      if(this.portions[i]['portion'] != "Full Day"){
        // this.halfDay.push(this.datePipe.transform(this.portions[i]['date'] , 'd MMM y'));
        this.halfDay.push(this.appservice.dateFormatDisplay(this.portions[i]['date'])+ '('+this.portions[i]['portion']+')');
      }
    }
  }

  /********* SUBMIT FUNCTION ****************/
  validateLeaveSummary(){
    this.saveClicked  = true;
    this.submitmethod.emit({'portions':this.portions});
  }

  /********* VIEW DOCUMENT FUNCTION ****************/
  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.investalertMsg       = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  /********* GET LEAVE TYPE FUNCTION ****************/
  getLeaveType(id:any){
    this.leavetypeService.getLeaveType(id).subscribe((res: any) => {
     this.leaveTypeName = res.leave_name;
    });
  }

  /********* LEAVE BALANCE COMPONENT FUNCTION ****************/
  leaveBalanceFunction(){
    this.leaveBalance = true;
  }

  /********* LEAVE BALANCE COMPONENT CLOSE FUNCTION ****************/
  leaveBalanceViewClose(data:any){
    this.leaveBalance = data;
  }

  dateFilter(d: any){
    let dateRange = [new Date(new Date().getFullYear() - 5, 0, 1),
      new Date(new Date().getFullYear() + 5, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  disableDate(){
    return false;
  }

  /********* LEAVE BALANCE FUNCTION ****************/
  leaveBalanceFunction1(){
    this.leaveBalance = true;

    this.loader = true;

    this.investmentService.leaveRequestBalance(this.employee,this.formGp.value.leave_type,this.formGp.get('leave_num_days')?.value,this.appservice.dateFormatConvert(this.formGp.value.from_date),this.appservice.dateFormatConvert(this.formGp.value.to_date)).subscribe((res:any)=>{
        this.loader                       = false;
        this.leaveBalanceDataTile         = res?.data;
        this.leaveBalanceTable            = res?.data?.leave_transaction_data;
        this.leaveTypeBalanceDataTile     = res?.data?.leave_data;
    })

  }

  // Modal Close
  close(bool:any){
    this.modalToggle = bool;
  }

   // Modal close for Invalid Extension
   closed(bool:any){
    this.alertToggle = bool
  }

  getLeaveRule(){

    this.leaveClubbedData = [];
    this.leaveRule        = true;
    this.loader           = true;

    this.investmentService.getLeaveRule(this.employee, this.formGp.get('leave_type')?.value).subscribe((res: any) => {
      this.loader         = false;
      this.leaveRuleData  = res?.data?.[0];

      if(this.leaveRuleData?.leave_request_criteria?.leave_clubbing_leave_type?.length > 0){
        for(let i=0;i<this.leaveRuleData?.leave_request_criteria?.leave_clubbing_leave_type?.length;i++){
          this.leaveClubbedData?.push(this.leaveRuleData?.leave_request_criteria?.leave_clubbing_leave_type?.[i]?.leave_name+' ('+this.leaveRuleData?.leave_request_criteria?.leave_clubbing_leave_type?.[i]?.leave_code+')')
        }
      }

    })

  }

}
