<div class=" config-layout" [formGroup]="WFHrequestForm">
  <header class=" config-header">
    <h5 class="m-0 text-trim w-50">Work From Home Request
      <span *ngIf="from=='behalf'" title="{{requestFor}}">
        {{' Of '+ requestFor}}
      </span>
    </h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar ">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step" [class]="{'success':WFHrequestForm['controls'].WFHDetails.valid}">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Work From Home Request</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-work-from-home-request [formGp]="WFHDetialsForm" (submitmethod)="submitOne($event)"
            (submitmethod2)="submitmethod2($event)" (validationError)="validationError($event)"
            (validationResult)="validationResult($event)" [wfhdayss]="wfhdayss" [temparrr]="temparrr"
            (isrestricted)="isrestricted($event)" [employee]="employee" [from]="from"></app-work-from-home-request>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Summary</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-work-from-home-summary [formGp]="WFHDetialsForm"
            (submitmethod)="submitForm($event)"></app-work-from-home-summary>
        </ng-template>
      </li>

    </ul>
  </aside>
  <main class=" config-container">
    <div [ngbNavOutlet]="nav"></div>
  </main>
</div>
