import { Component, OnInit, Input } from '@angular/core';
import { ChallanGenerationPfService } from 'src/app/@core/services/challan-generation-pf.service';
import { AppService } from 'src/app/app.global';
import { FormBuilder } from '@angular/forms';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { NotificationService } from 'src/app/@core/services/notification.service';

import { Subject } from 'rxjs';
import { delay,switchMap  } from 'rxjs/operators';

@Component({
  selector: 'app-challan-generation-provident-fund',
  templateUrl: './challan-generation-provident-fund.component.html',
  styleUrls: ['./challan-generation-provident-fund.component.scss']
})
export class ChallanGenerationProvidentFundComponent implements OnInit {

  activeTab2               = 'challan';
  generateChallan          = false;
  downloadChallan          = false;

  @Input() fyArray:any;
  @Input() fMonth:any;
  @Input() changedYear:any;

  selectedMonth:any        = 'April'
  selectedYear:any         = Number(this.appservice.getFinancialYear());
  loader                   = false;
  challanList:any          = [];
  nodata                   = false;

  buttonLabel:any;
  generateViewData:any;
  groupName:any;
  employeeChallan:any;
  btnDisable                = false;
  loader1                   = false;

  regenerateChallanList:any = [];
  returnPfId                = 0;

  excelDownloadData:any     = [];
  fileType                  = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension             = '.xlsx';

  searchData : Subject<void> = new Subject();


  constructor(
    public challanGenerationPfService: ChallanGenerationPfService,
    public appservice: AppService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.getPfChallanList();

    this.searchData.pipe(delay(100),
        switchMap(() => this.activeTab2 == 'challan' ?  this.challanGenerationPfService.challanGenerationLandingPage(this.selectedMonth,Number(this.selectedYear)) : this.challanGenerationPfService.generatedPfChallan(this.selectedMonth, Number(this.selectedYear)))).subscribe((res:any)=>{
          if(this.activeTab2 == 'challan'){
            this.challanList  = [];
            this.nodata       = res?.data?.length == 0 ? true : false;
            this.challanList  = res?.data;
          }else{
            this.regenerateChallanList  = [];
            this.nodata                 = res?.length == 0 ? true : false;
            this.regenerateChallanList  = res;
          }
          this.loader         = false;
        });
  }

  fileDownloadForm = this.formBuilder.group({
    filetype: true,
  });


  ngOnChanges(){
    if(this.changedYear != undefined){
      this.loader = true;
      if(this.selectedMonth == 'January' || this.selectedMonth == 'February' || this.selectedMonth == 'March')
          this.selectedYear = Number(this.changedYear) + 1;
      else
          this.selectedYear = this.changedYear;

      this.searchData.next();
    }
  }

  challanMonthYear(month:any,year:any){
    this.loader        = true;
    this.fMonth        = month;
    this.selectedMonth = month;
    this.selectedYear  = year;
    this.searchData.next();
  }

  getPfChallanList(){

    if(this.selectedMonth != '' && this.selectedMonth != null && this.selectedYear != '' && this.selectedYear != null){
      this.loader         = true;
      this.challanList    = [];
      this.challanGenerationPfService.challanGenerationLandingPage(this.selectedMonth,Number(this.selectedYear))
      .subscribe((res: any) => {
        this.nodata       = res?.data?.length == 0 ? true : false;
        this.challanList  = res?.data;
        this.loader       = false;

      })
    }
  }

  currency : any
  generateViewChallan(data:any,val:any,name:any,empChallan:any,deleteitem:any,currency:any){

    if(!deleteitem){
      this.notificationService.handleErrorNotification("The challan details for respective group are not created.","Error");
    }else {
      this.loader1          = true;
      this.generateChallan  = true;
      this.buttonLabel      = val == false ? 'Generate' : 'Details';
      this.generateViewData = data;
      this.currency = currency
      this.groupName        = name;
      this.employeeChallan  = empChallan;
      this.loader1          = false;
    }
  }

  generatePfChallan(){
    if(this.selectedMonth != '' && this.selectedMonth != null && this.selectedYear != '' && this.selectedYear != null){

      this.btnDisable = true;
      this.challanGenerationPfService.generatePfChallan({'employee_challan': this.employeeChallan, 'month': this.selectedMonth, 'year': Number(this.selectedYear)})
      .subscribe((res: any) => {
        this.getPfChallanList();
        this.notificationService.handleSuccessNotification("The challan generated successfully","Success");
        this.generateChallan = false;
        this.btnDisable      = false;
      },
      (error) => {
        this.btnDisable = false;
      });
    }
  }

  tabClick(from:any){
    this.activeTab2 = from;
    this.searchData.next();
  }

  generatedPfChallan(){
    if(this.selectedMonth != '' && this.selectedMonth != null && this.selectedYear != '' && this.selectedYear != null){

      this.loader         = true;
      this.regenerateChallanList    = [];
      this.challanGenerationPfService.generatedPfChallan(this.selectedMonth, Number(this.selectedYear))
      .subscribe((res: any) => {
        this.nodata       = res?.length == 0 ? true : false;
        this.regenerateChallanList  = res;
        this.loader       = false;
      },
      (error) => {

      });
    }
  }

  regeneratPfChallanView(id:any,from:any,name:any,deleteitem:any,currency:any){

    if(!deleteitem){
      this.notificationService.handleErrorNotification("The challan details for respective group are not created.","Error");
    }else{
      this.loader1          = true;
      this.buttonLabel      = from;
      this.generateChallan  = true;
      this.groupName        = name;
      this.generateViewData = '';
      this.currency = currency
      this.returnPfId       = id;
      this.challanGenerationPfService.regeneratPfChallanView(Number(id))
      .subscribe((res: any) => {
        this.generateViewData = res;
        this.loader1          = false;
      },
      (error) => {

      });
    }
  }

  regeneratePfChallan(){
    if(this.selectedMonth != '' && this.selectedMonth != null && this.selectedYear != '' && this.selectedYear != null){

      this.btnDisable = true;
      this.challanGenerationPfService.regeneratePfChallan({"employee_challan": this.returnPfId})
      .subscribe((res: any) => {
        this.generatedPfChallan();
        this.notificationService.handleSuccessNotification("The challan regenerated successfully","Success");
        this.generateChallan = false;
        this.btnDisable      = false;
      },
      (error) => {
        this.btnDisable = false;
      });
    }
  }

  downloadTextFile(): void {

    this.challanGenerationPfService.textDownload(Number(this.returnPfId))
    .subscribe((res: any) => {
      var data      = res?.data;
      var fileName  = "PF ECR.txt";
      this.saveData(data, fileName);
    },
    (error) => {

    });
  }

  saveData = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    // a.style = "display: none";
    return function (data:any, fileName:any) {
        var json   = JSON.stringify(data),
        blob       = new Blob([data], {type: 'text/plain'}),
        url        = window.URL.createObjectURL(blob);
        a.href     = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
  }());

  download(){
    this.downloadChallan = true;
  }

  downloadFile(){
    if(this.fileDownloadForm.value.filetype == true){
      this.downloadExcelFile();
    }else{
      this.downloadTextFile();
    }
    this.downloadChallan = false;
  }

  downloadExcelFile(){
    let exportDataDownload:any = [];
    if(this.excelDownloadData?.length != 0){
      for(let i=0;i<this.excelDownloadData?.length;i++){
        exportDataDownload.push({
          'UAN'                             : this.excelDownloadData[i]['UAN'],
          'Name'                            : this.excelDownloadData[i]['name'],
          'Gross wages'                     : this.excelDownloadData[i]['gross_wage'],
          'EPF wages'                       : this.excelDownloadData[i]['epf_wage'],
          'EPS wages'                       : this.excelDownloadData[i]['eps_wage'],
          'EDLI wages'                      : this.excelDownloadData[i]['edli_wages'],
          'EPF contribution(EE share) being remitted'  : this.excelDownloadData[i]['epf_countirbution'],
          'EPS contribution being remitted' : this.excelDownloadData[i]['eps_countirbution'],
          'Diff EPF and EPS contribution(ER share) being remitted' : this.excelDownloadData[i]['epf_eps_diff_remitted'],
          'NCP days'                        : this.excelDownloadData[i]['ncp_days'],
          'Refund of advances'              : this.excelDownloadData[i]['return_of_advance'],
        })
      }

      if(exportDataDownload?.length !=0)
        this.exportExcel(exportDataDownload, 'PF ECR');
    }

  }

  public exportExcel(jsonData: any[], fileName: string): void {
     const ws: XLSX.WorkSheet  = XLSX.utils.json_to_sheet(jsonData);
     const wb: XLSX.WorkBook   = { Sheets: { 'data': ws }, SheetNames: ['data'] };
     const excelBuffer: any    = XLSX.write(wb,{ bookType: 'xlsx', type: 'array'});
     this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

}
