import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { ExpenseAdvnaceProfileService } from 'src/app/@core/services/expense-advance-profile.service';
import { AppService } from 'src/app/app.global';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
@Component({
  selector: 'app-request-panel',
  templateUrl: './request-panel.component.html',
  styleUrls: ['./request-panel.component.scss']
})
export class RequestPanelComponent implements OnInit {
  @Input() showSidePanel :any;
  @Input() from :any;
  searchKeywordRequestType        = "";
  requestSearchData:any           = '';
  loader3                         = false;
  dropdownData : any              = []
  requestsearch:string            = this.messageService.searchdisplay('requests');
  requestTypeSearch:string        = this.messageService.searchdisplay('novalue');
  reqTypeSearchlistdata:any       = [];
  loader2                         = false;
  recentUsedReq:any               = [];
  requestId:any
  requestName:any
  requestitem                     = '';
  alertToggle                     = false;
  alertToggle1                    = false;
  cond                            = true;
  investDetailsData:any           = [];
  investalertMsg:any              = '';
  addadvance = false;
  validationdata:any              = [];
  advancedatalist: any            = [];
  requestViewData:any             = [];
  tax_regima:any                  = [];
  confirmed_cnt:any               = [];
  proposed_cnt:any                = [];
  compOffRequestFlag              = false;
  compOffListData:any             = [];
  currentYear:any                 = new Date().getFullYear();
  regularizationEligibleData: any = [];
  loader_regularization: boolean  = false;
  fbdeclarationFlag               = false;
  fbpRequestView                  = false;
  fbpReqViewData:any              = [];
  fbpStatus:any                   = '';
  ondutysidepanel                 = false;
  holidayFloatData:any            = [];
  holidatSearchData:any           = [];
  holiday_nodata                  = false;
  holiday_loader                  = false;
  holidayRequestView              = false;
  holidayRequestSingleData:any    = [];
  saveClicked                     = false;
  holidayRequestFlag              = false;
  requestFlag = false;
  regularizationData: any;
  regualarizationID: any;
  @Input() emp_Id: any;
  regularizeCancel = false
  eligibileData: any;
  investmentCnt:any
  @Output() closeReqPanel = new EventEmitter();
  @Output() requestAction = new EventEmitter();


  constructor(
    public messageService : MessageService,
    private investmentService:InvestmentDeclerationService,
    public ExpenseAdvnaceProfileService: ExpenseAdvnaceProfileService,
    public appservice:AppService,
    public router: Router,
    private activityService:ManageActivityService,



  ) { }

  ngOnInit(): void {
    this.getBehalfEmployee();
    this.newRequestSidePanelFunction()
    this.OnDutyEligibility();
    this.requestDetails()
    this.regualarizationEligibility();
  }
  requestFor:any
  getBehalfEmployee(){
    this.activityService.employeeBasicData(this.emp_Id).subscribe((res:any)=>{
      this.requestFor = "";
      if(res['first_name'] != null)
      this.requestFor+= res['first_name']+" ";
      if(res['middle_name'] != null)
      this.requestFor+= res['middle_name']+" ";
      if(res['last_name'] != null)
      this.requestFor+= res['last_name'];
      this.requestFor+=" ("+res['employee_code']+")"
    })
  }
  OnDutyEligibility(){
    this.activityService.checkOndutyEligibility(this.emp_Id).subscribe((res:any)=>{

      this.eligibileData = res?.data
    })
  }
  regualarizationEligibility(){
    this.activityService.getCheckRegEligibility(this.emp_Id).subscribe((res:any)=>{
      this.regularizationEligibleData = res?.data
    })
  }
  newRequestSidePanelFunction(){
    this.showSidePanel            = true;
    this.loader3 = true;
    this.showReqDataItems();
    this.recentRequestType();
  }
  newRequestPanelClose(){
    this.showSidePanel            = false;
    this.searchKeywordRequestType = "";
    this.requestSearchData        = "";
    this.closeReqPanel.emit();

  }
  showReqDataItems(){
    this.loader2 = true;
    this.activityService.requestTypeList(this.searchKeywordRequestType,this.emp_Id).subscribe((res: any) => {
      this.reqTypeSearchlistdata = res;
      this.dropdownData = res;
    });

  }
  recentRequestType(){
    this.activityService.recentUsedRequest(this.emp_Id).subscribe((res: any) => {
      this.recentUsedReq  = res;
      this.loader3 = false;
      this.loader2 = false;
    });
  }
  searchtyperesults(data:any){
    this.requestSearchData = data;
    if(data == ""){
      this.searchKeywordRequestType = "";
      this.reqTypeSearchlistdata = [];
       this.showReqDataItems();
       this.recentRequestType();
    }
    else{
      this.investmentService.getSingleReqType(data).subscribe((res: any) => {
        this.reqTypeSearchlistdata = res;
      });
    }
  }

  searchrequestType(searchKeyword:any){

    this.reqTypeSearchlistdata= [];
    this.searchKeywordRequestType = searchKeyword;
    if(searchKeyword != ""){
      this.showReqDataItems();
      this.recentRequestType();
    }
  }
  /********* INVESTMENT RESTRICTIONS ****************/
  getRequest(investType:any){

    this.requestName = investType;

    let currentYear:any     = new Date().getFullYear();
    let currentDate:any     = new Date().getDate();
    let currentMonth:any    = new Date().toLocaleString('default', { month: 'long' });
    let currentMonthNumber  = new Date(Date.parse(currentMonth +" 1, "+currentYear+"")).getMonth()+1;
    let setYear:any;

    if(investType == 'Confirmed Investment Declaration' || investType == 'Proposed Investment Declaration'){
      // this.showSidePanel  = false;
      this.alertToggle    = true;
      this.cond           = true;
      // if(this.investmentCnt == 'Not tax settings configured' && (this.tax_regima == 'AssignSalaryStructure matching query does not exist.' || this.tax_regima == 'Salary structure not assigned')){
      //   this.investalertMsg = 'Tax setting and Salary structure are not configured';
      // }
      if(this.investDetailsData.count_info?.statutory_tax_settings == 'Not tax settings configured'){
        if(investType == 'Confirmed Investment Declaration')
            this.investalertMsg = 'Confirmed investment declaration cannot be raised as tax settings has not been configured yet.'
        if(investType == 'Proposed Investment Declaration')
            this.investalertMsg = 'Proposed investment declaration request cannot be raised as tax settings has not been configured yet.'
      }
      else if(!this.investDetailsData.count_info.is_payroll_configed){
        // this.investalertMsg = 'Payroll configuration is not configured.';
        if(investType == 'Confirmed Investment Declaration')
          this.isConfigured('payrollConfig','Confirmed investment declaration');
        if(investType == 'Proposed Investment Declaration')
          this.isConfigured('payrollConfig','Proposed investment declaration');
      }
      else if(this.tax_regima == 'AssignSalaryStructure matching query does not exist.' || this.tax_regima == 'Salary structure not assigned'){
        if(investType == 'Confirmed Investment Declaration')
          this.isConfigured('salaryStructure','Confirmed investment declaration');
        if(investType == 'Proposed Investment Declaration')
          this.isConfigured('salaryStructure','Proposed investment declaration');
      }
      else if(this.investDetailsData.count_info?.statutory_tax_settings?.is_employee_under_flat_rate){
        if(investType == 'Confirmed Investment Declaration')
          this.isConfigured('flattax','Confirmed investment declaration');
        else if(investType == 'Proposed Investment Declaration')
          this.isConfigured('flattax','Proposed investment declaration');
      }
      else{
        this.cond         = false;
        this.alertToggle  = false;
        if(investType == 'Confirmed Investment Declaration'){

          // Payroll configuration for confirmed start
          if(this.investDetailsData.count_info.hasOwnProperty('payroll_config_data')){
            let fyMonthNumber =  new Date(Date.parse(this.investDetailsData.count_info.payroll_config_data.confirmed_declaration_fy_month +" 1, "+currentYear+"")).getMonth()+1;

            let todate = new Date();

             var ci = [1, 2, 3].indexOf(currentMonthNumber);
             var fi = [1, 2, 3].indexOf(fyMonthNumber);

             if(ci != -1 && fi != -1){
               setYear = currentYear;
               // CD => 9-1-2023 FD => 12-2-2023
              }
              else if(ci == -1 && fi == -1){
              // CD => 9-12-2023 FD => 12-12-2023
               setYear = currentYear;
              }
              else if(ci == -1 && fi != -1){
              // CD => 9-12-2023 FD => 12-2-2024
                setYear = Number(currentYear) + 1;
              }
              else if(ci != -1 && fi == -1){
              // CD => 9-1-2023 FD => 12-12-2022
              setYear = this.appservice.getFinancialYear();
              }
              let fromdate          = (new Date(setYear+"-"+fyMonthNumber+"-"+this.investDetailsData.count_info.payroll_config_data.confirmed_declaration_fy_date));

              if(moment(todate).format('YYYY-MM-DD') > moment(fromdate).format('YYYY-MM-DD')){
                this.alertToggle    =  true;

                this.investalertMsg = 'The last date for submitting confirmed investment declaration for the current financial year has lapsed, please contact your HR.'
              }
              else if(this.investDetailsData.count_info.payroll_config_data?.is_today_in_proposed_cuttoff_range && !(this.investDetailsData.count_info.is_payslip_released) ){

                this.isConfigured('cutOffData','confirmed investment declaration');
              }
              else if(this.investDetailsData.count_info?.is_confirmed_it_declaration_pending){
                this.isConfigured('limitExceed','confirmed investment declaration');
              }

              else{
                if(this.investDetailsData?.count_info?.statutory_tax_settings?.max_con_investment_declare_request >= 0 && (this.investDetailsData?.count_info?.statutory_tax_settings?.max_con_investment_declare_request <= this.confirmed_cnt)){
                  this.cond           = true;
                  this.alertToggle    = true;
                  this.investalertMsg = 'You cannot raise a confirmed investment declaration request as the request limit is exceeded for this month.';
                }else{
                  this.router.navigate(['/confirmed-investment-decleration/'+this.emp_Id]);
                }
              }

            }
        }
          // Payroll configuration for confirmed end

        else if('Proposed Investment Declaration'){

          // Payroll configuration for proposed start

          if(this.investDetailsData.count_info.hasOwnProperty('payroll_config_data')){
            let fyMonthNumber =  new Date(Date.parse(this.investDetailsData.count_info.payroll_config_data.proposed_declaration_fy_month +" 1, "+currentYear+"")).getMonth()+1;

            let todate = new Date();

            var ci = [1, 2, 3].indexOf(currentMonthNumber);
            var fi = [1, 2, 3].indexOf(fyMonthNumber);

            if(ci != -1 && fi != -1){
              setYear = currentYear;
              // CD => 9-1-2023 FD => 12-2-2023
            }
            else if(ci == -1 && fi == -1){
              // CD => 9-12-2023 FD => 12-12-2023
              setYear = currentYear;
            }
            else if(ci == -1 && fi != -1){
              // CD => 9-12-2023 FD => 12-2-2024
              setYear = Number(currentYear) + 1;
            }
            else if(ci != -1 && fi == -1){
              // CD => 9-1-2023 FD => 12-12-2022
              setYear = this.appservice.getFinancialYear();
            }
            let fromdate  = (new Date(setYear+"-"+fyMonthNumber+"-"+this.investDetailsData.count_info.payroll_config_data.proposed_declaration_fy_date));


            if((moment(todate).format('YYYY-MM-DD') > moment(fromdate).format('YYYY-MM-DD')) && (!this.investDetailsData.count_info.payroll_config_data.proposed_allow_submit_aftercuttoff)){

              this.alertToggle    =  true;

              this.investalertMsg = 'The last date for submitting proposed investment declaration for the current financial year has lapsed, please contact your HR.';

            }
              else if(this.investDetailsData.count_info.payroll_config_data?.is_today_in_proposed_cuttoff_range && !(this.investDetailsData.count_info.is_payslip_released) ){

                this.isConfigured('cutOffData','proposed investment declaration');
              }
              else if(this.investDetailsData.count_info?.is_proposed_it_declaration_pending){
                this.isConfigured('limitExceed','proposed investment declaration');
              }
            // Payroll configuration for proposed end
            else{

                if(this.investDetailsData?.count_info?.statutory_tax_settings?.max_pro_investment_declare_request >= 0 && (this.investDetailsData?.count_info?.statutory_tax_settings?.max_pro_investment_declare_request <= this.proposed_cnt)){
                this.cond           = true;
                this.alertToggle    = true;
                this.investalertMsg = 'You cannot raise proposed investment declaration request as the request limit is exceeded the request limit for this month.';
                }else{
                this.router.navigate(['/proposed-investment-decleration/'+this.emp_Id]);}
            }

          }
        }
      }

    }
    else if(investType == 'On Duty'){

      if(!this.eligibileData.on_duty){
        this.alertToggle = true
        this.investalertMsg = 'As per policy,'+this.requestFor+' not eligible for On-duty request';
      }else if(!this.investDetailsData.count_info?.attend_rule_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'On-duty request cannot be raised as attendance rule has not been assigned yet.';
      }else{
        this.ondutyRequest();
      }
    }
    else if(investType == 'Leave'){

      if(!this.investDetailsData.count_info?.leave_configured){
        this.alertToggle = true
        this.investalertMsg = 'Leave request cannot be raised as Leave policy is not configured yet.';
      }else if(!this.investDetailsData.count_info?.attend_rule_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'Leave request cannot be raised as attendance rule has not been assigned yet.';
      }else{
        this.leaveRequest();
      }

    }
    else if(investType == 'Compensatory Off'){

      if(!this.investDetailsData.count_info?.compoff_data?.enable_compoff){
        this.alertToggle = true
        this.investalertMsg = 'Compensatory off request cannot be raised as it has not been configured yet.';
      }else if(!this.investDetailsData.count_info?.leave_configured){
        this.alertToggle = true
        this.investalertMsg = 'Comp-off cannot be availed as the leave policy is not configured yet.';
      }else if(!this.investDetailsData.count_info?.attend_rule_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'Compensatory off request cannot be raised as attendance rule has not been assigned yet.';
      }else{
        this.compOffRequest(this.currentYear);
      }
    }
    else if(investType == 'Holiday'){
      if(!this.investDetailsData.count_info?.attend_rule_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'Holiday request cannot be raised as attendance rule has not been assigned yet.';
      }else{
        this.holidayRequest();
      }
    }
    else if(investType == 'Work From Home'){

        if(!this.investDetailsData.count_info?.is_wfh_allowed){
          this.alertToggle = true;
          this.investalertMsg = 'As per policy,'+this.requestFor+' not eligible for WFH request.';
        }else if(!this.investDetailsData.count_info?.attend_rule_assigned){
          this.alertToggle = true;
          this.investalertMsg = 'WFH request cannot be raised as attendance rule has not been assigned yet.';
        }else{
          this.WFHRequest();
        }
    }
    else if(investType == 'Attendance Regularization'){
      if(!this.regularizationEligibleData.is_attend_regular_allowed){
        this.alertToggle = true
        this.investalertMsg = 'As per policy, '+this.requestFor+' not eligible for Attendance Regularization request.';
      }else if(!this.investDetailsData.count_info?.attend_rule_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'Attendance Regularization request cannot be raised as attendance rule has not been assigned yet.';
      }else{
        this.regularizationRequest();
      }

    }
    else if(investType == 'Short Leave'){
      if(!this.investDetailsData?.count_info.short_leave_configured){
        this.alertToggle = true
        this.investalertMsg = 'Short leave request cannot be raised as short leave policy has not been configured yet.'
      }
      else if(!this.investDetailsData.count_info?.attend_rule_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'Short leave request cannot be raised as attendance rule has not been configured yet.';
      }
      else{
        this.shortLeaveRequest()
      }
    }
    else if(investType == 'Reimbursement'){
      if(this.investDetailsData.count_info?.statutory_tax_settings == 'Not tax settings configured'){
        this.isConfigured('taxSettings','Reimbursement');
      }
      else if(!this.investDetailsData.count_info?.is_payroll_configed){

        this.isConfigured('payrollConfig','Reimbursement');
      }
      else if(this.tax_regima == 'AssignSalaryStructure matching query does not exist.' || this.tax_regima == 'Salary structure not assigned'){
        this.isConfigured('salaryStructure','Reimbursement');
      }
      else if(this.investDetailsData.count_info.hasOwnProperty('payroll_config_data')){

        if(this.investDetailsData.count_info?.payroll_config_data?.is_today_in_reimbursemen_cuttoff_range
          && !(this.investDetailsData.count_info.is_payslip_released)){

          this.isConfigured('cutOffData','reimbursement');
        }
        else
        this.reimbursementRequest();
      }
    }
    else if(investType == 'FBP Declaration'){

      if(this.investDetailsData.count_info?.statutory_tax_settings == 'Not tax settings configured'){

        this.isConfigured('taxSettings','FBP');
      }

      else if(!this.investDetailsData.count_info.is_payroll_configed){
        this.isConfigured('payrollConfig','FBP');

      } else if(this.tax_regima == 'AssignSalaryStructure matching query does not exist.' || this.tax_regima == 'Salary structure not assigned'){
        this.isConfigured('salaryStructure','FBP');
      }
      else if(this.investDetailsData.count_info.hasOwnProperty('payroll_config_data')){

        if(this.investDetailsData.count_info?.is_FBP_restricted){
          this.isConfigured('cutOffData','fbp');
        }
        else
        this.FBPDeclaration();
      }
    }else if(investType == 'Asset'){
      this.assetRequest();
    }else if(investType == 'Expense Report'){

      if(!this.investDetailsData?.count_info?.expense_policy_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'Expense report request cannot be raised as expense policy is not configured yet.';

      }else if(!this.investDetailsData?.count_info?.advance_policy_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'Expense report request cannot be raised as advance policy is not configured yet.';
      }else if(this.investDetailsData.count_info?.expense_settings == null){
        this.isConfigured('expenseConfig','Expense report');
      }else if(!this.investDetailsData.count_info?.expense_settings?.submission_window){
          this.isConfigured('cutOffData1','expense report');
      }
      else{
        this.expenseReport();
      }
  }

    else if(investType == 'Expense Advance'){
      this.expenseAdvanceRequest();
    }
  }
  expenseReport(){
    this.router.navigate(['/request/expense-reports-setup/'+this.emp_Id]);
  }
  expenseAdvanceRequest(){
    this.activityService.getExpenseValidate(this.emp_Id).subscribe((res: any) => {
      this.validationdata = res;
      if(this.validationdata.data?.advance_policy==false) {
        this.alertToggle = true;
        this.investalertMsg = 'Advance request cannot be raised as advance policy is not configured yet.';
      }else if(this.validationdata.data?.pending_advance_requests==true) {
        this.alertToggle = true;
        this.investalertMsg = 'This request cannot be raised, as there is a pending advance request.';
      }else if(this.validationdata.data?.pending_dispense_requests==true){
        this.alertToggle =true;
        this.investalertMsg = 'This request cannot be raised, as there is an pending advance dispense.';
      }else{
      this.addadvance = true;
      let data = {
        'type':'Expense Advance',
        'flag' :true,
        'amountlimitdata':this.validationdata,
        'outstandingamount':this.advancedatalist[1]?.outstanding_amount
      }
      this.requestAction.emit(data)
      }
   });

  }
  isConfigured(type:any,reqType:any){
    // this.showSidePanel  = false;
    this.alertToggle    = true;
    if(type ==  'salaryStructure')
      this.investalertMsg = reqType+' request cannot be raised as salary structure has not been assigned yet.';
    else if(type == 'payrollConfig')
      this.investalertMsg = reqType+' request cannot be raised as payroll settings has not been configured yet.';
    else if(type == 'cutOffData')
      this.investalertMsg = 'The '+reqType+' request window has closed and will reopen on the beginning of next pay cycle.';
    else if(type == 'limitExceed')
      this.investalertMsg = this.messageService.requestRestriction('Employee');
    else if(type == 'taxSettings')
      this.investalertMsg = reqType+' request cannot be raised as tax settings has not been configured yet.';
    else if(type == 'notConfigured')
      this.investalertMsg = 'Cannot raise a '+reqType+ 'of'+this.requestFor+' request, as it is not configured';
    else if(type == 'cutOffData1')
      this.investalertMsg = 'The '+reqType+' submission window has closed and will reopen at the beginning of the next pay cycle.';
    else if(type == 'expenseConfig')
      this.investalertMsg = reqType+' request cannot be raised as expense settings has not been configured yet.';
    else if(type == 'flattax')
      this.investalertMsg = 'The '+reqType+' is restricted for your employment type, please contact HR for more details.';
  }
  ondutyRequest(){
    this.ondutysidepanel=true;
    if(this.from=='behalf')
    this.router.navigate(['/on-duty-setup/'+this.emp_Id]);
    else
    this.router.navigate(['/on-duty-setup']);
  }
  leaveRequest(){
    if(this.from=='behalf')
    this.router.navigate(['/leave-request-setup/'+this.emp_Id]);
    else
    this.router.navigate(['/leave-request-setup']);
  }
  compOffRequest(year:any){
    this.compOffRequestFlag = true;
    this.compOffListData    = [];
    this.holiday_loader     = true;
    this.activityService.getCompOffRequestList(this.emp_Id,Number(year)).subscribe((res: any) => {
      this.holiday_loader  = false;
      this.compOffListData = res;
      if(this.compOffListData?.length == 0){
        this.holiday_nodata = true;
      }
      else{
        this.holiday_nodata = false;
      }
      let data = {
        'type'            :'Compensatory Off',
        'flag'            : true,
        'compOffListData' : this.compOffListData,
        'holiday_nodata'  : this.holiday_nodata,
        'holiday_loader'  : this.holiday_loader
      }
    this.requestAction.emit(data)

    });
  }
  holidayRequest(){
    this.holidayRequestFlag = true;
    let data = {
      'type':'Holiday',
      'flag':true
    }
    this.requestAction.emit(data)
  }
  WFHRequest(){
    if(this.from=='behalf')
    this.router.navigate(['/work-from-home-setup/'+this.emp_Id]);
    else
    this.router.navigate(['/work-from-home-setup']);

  }
  regularizationRequest(){
    if(this.from=='behalf')
    this.router.navigate(['/regularization-setup/'+this.emp_Id]);
    else
    this.router.navigate(['/regularization-setup']);
  }
  reimbursementRequest(){
    this.router.navigate(['/reimbursement-setup/'+this.emp_Id]);
  }
  shortLeaveRequest(){
    this.router.navigate(['/short-leave-setup/'+this.emp_Id]);
  }
  FBPDeclaration(){
    let data = {
      'type' :'FBP Declaration',
      'flag' :true
    }

  this.requestAction.emit(data)
   }
   assetRequest(){
    this.requestFlag = true;
    let data = {
      'type':'Asset',
      'flag' :true
    }

    this.requestAction.emit(data)
  }
  holidayLists(){
    this.saveClicked    = false;
    this.holiday_loader = true;
    this.activityService.holidayFloatList('holiday_date','',this.emp_Id).subscribe((res: any) => {
      this.holiday_loader   = false;
      this.holidayFloatData = res;

      if(this.holidayFloatData?.length == 0){
        this.holiday_nodata = true;
      }
      else{
        this.holiday_nodata = false;
      }
    });
  }
  searchHoliday(){
    this.holiday_loader = true;
    this.activityService.holidayFloatDropDown(this.emp_Id).subscribe((res: any) => {
      for(let i=0; i< res.length; i++){
        if(res[i]['day_name'] != null && res[i]['day_name'] != '')
          res[i]['name'] = res[i]['holiday_name'] +' '+ 'day ' +res[i]['day_name'];
        else
          res[i]['name'] = res[i]['holiday_name'];
      }
      this.holiday_loader     = false;
      this.holidatSearchData  = res;
    });
  }
  closeInfo(bool:any){
    this.alertToggle = false;
  }
  requestDetails(){
    this.activityService.getReqCount(this.emp_Id).subscribe((res:any)=>{
      // if(res.is_employee){
        this.investDetailsData = res;

        if(res.count_info.hasOwnProperty('max_count_limit')){
          this.investmentCnt   = res.count_info.max_count_limit;
        }if(res.count_info.hasOwnProperty('assigned_salary_data')){
          if(res.count_info.assigned_salary_data.hasOwnProperty('tax_regime')){
            this.tax_regima    = res.count_info.assigned_salary_data.tax_regime;
          }else{
            this.tax_regima    = res.count_info.assigned_salary_data.AssignSalaryStructure;
          }
        }
        if(res.count_info.hasOwnProperty('request_count_info')){
          if(res.count_info.request_count_info.hasOwnProperty('Proposed_Investment_Declaration')){
            this.proposed_cnt   = res.count_info.request_count_info.Proposed_Investment_Declaration;
          }if(res.count_info.request_count_info.hasOwnProperty('Confirmed_Investment_Declaration')){
            this.confirmed_cnt  = res.count_info.request_count_info.Confirmed_Investment_Declaration;
          }
        }


    });
  }
}
