import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import * as moment from 'moment';
@Component({
  selector: 'app-final-settlement-reimbursement',
  templateUrl: './final-settlement-reimbursement.component.html',
  styleUrls: ['./final-settlement-reimbursement.component.scss']
})

export class FinalSettlementReimbursementComponent implements OnInit {

  @Input() empId:any;
  @Input() payrollStatus:any;

  @Output() submitmethod      = new EventEmitter();
  @Output() warningMsg        = new EventEmitter();

  requestedCategoryData: any  = [];
  loader                      = false;
  reimbursement:any           = [];
  saveClicked                 = true;
  currentMonth                = new Date().toLocaleString('en-us', { month: 'long' }).toUpperCase();
  currency:any;

  constructor(
    public finalSettlementService: FinalSettlementService,
    public router: Router,
    public route:ActivatedRoute,
    public appService: AppService,
    public messageService: MessageService,
    public fb:FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getReimbursementComponents();
  }

  formGp : FormGroup = this.fb.group({
    fbpArray         : this.fb.array([])
  });

  getReimbursementComponents(){

    this.loader = true;
    this.finalSettlementService.getempReimbursementData({"employee": this.empId,"year": this.appService.getFinancialYear()}).subscribe((res: any) => {

      this.requestedCategoryData  = res?.data[0]?.category_list;
      this.currency               = res?.data[0]?.currency;
      this.getReimbursementData();
    });
  }

   /********* CREATING & REMOVING ARRAY FUNCTION ****************/
   fbpArrays() : FormArray {

    return this.formGp.get("fbpArray") as FormArray
  }

  newFbpArray(): FormGroup {

    return this.fb.group({
      "id"          : [null],
      "name"        : [null],
      "eligible"    : [null],
      "unclaimed"   : [null],
      "percentage"  : [null],
      "bill_submit" : [null],
      "claimed"     : [null],
      "pending"     : [null],
      "approved"    : [null],
      "rejected"    : [null],
      "credit_cycle": [null],
      "fbp"         : [null],
      "bill_amount" : [null]
    })
  }

  addFbpArray() {
    this.fbpArrays().push(this.newFbpArray());
  }

  removeAllFbpArrays(){
    if(this.formGp.value.fbpArray.length > 0){
      while(this.formGp.value.fbpArray.length !== 0){
        this.fbpArrays().removeAt(0);
      }
    }
  }

  getReimbursementData(){
    this.removeAllFbpArrays();
    if(this.requestedCategoryData?.length > 0){
      for(let i=0;i<this.requestedCategoryData?.length;i++){
        this.addFbpArray();
      }
      (<FormArray>this.formGp.get("fbpArray")).reset(this.requestedCategoryData);
    }
    this.loader = false;
  }

  reimbursementSave(index:any){

    this.reimbursement = [];

    if(this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish'){
      this.warningMsg.emit();
    }else{
      if (this.formGp.invalid) {
        return;
      }
      this.saveClicked = true;

      let todate = new Date();

      this.reimbursement.push({
        "bill_date"   : moment(todate).format('YYYY-MM-DD'),
        "bill_no"     : '',
        "bill_amount" :  (<FormArray>this.formGp.get("fbpArray")).at(index).get('bill_amount')?.value,
        "document"    : '',
        "description" : '',
      });

      this.finalSettlementService.createReimbursementRequest({"employee": this.empId,"reimbursement": this.reimbursement,"paycomponent": (<FormArray>this.formGp.get("fbpArray")).at(index).get('id')?.value, "status": "Accepted","fnf" : true}).subscribe((res: any) => {
        this.getReimbursementComponents();
      });
    }

  }

  calculateValidation(index:any){
    this.saveClicked = false;
    let maxValue = Number((<FormArray>this.formGp.get("fbpArray")).at(index).get('unclaimed')?.value);
    (<FormArray>this.formGp.get("fbpArray")).at(index).get('bill_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.max(maxValue)]);
    (<FormArray>this.formGp.get("fbpArray")).at(index).get('bill_amount')?.updateValueAndValidity();
  }

  submitReimbursement(){
    this.submitmethod.emit();
  }

}
