<div class=" config-layout">
  <header class=" config-header">

    <h5 class="m-0 text-trim w-50">Regularization request
      <span *ngIf="from=='behalf'" title="{{requestFor}}">
        {{' Of '+ requestFor}}
      </span>
    </h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar ">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step" [class]="{'success': validateOne()}">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Select Date</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-regularization-select-date #regulariseChild [formGrp]="dateForm" [data]="editDetailsForm"
            (submitmethod)="submitOne($event)" [empId]="empId" [from]="from"></app-regularization-select-date>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step"
          [class]="{'success': ((checkValid() && editDetailsForm.value.regularization_request.length && dateForm.length && !this.invalidDates())|| editDetailsForm['controls'].regularization_request.disabled)}">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Edit Details</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-regularization-edit-details [formGp]="editDetailsForm" [editDetailsData]="dateForm"
            (editDetails)="editDetails($event)" (submitmethod)="submitTwo()"></app-regularization-edit-details>
        </ng-template>
      </li>

    </ul>
  </aside>
  <main class=" config-container">
    <div [ngbNavOutlet]="nav"></div>
  </main>
</div>
