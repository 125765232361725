<div class="container-fluid p-24 " *ngIf="permissions && !viewempmap && !viewDetail">
    <div class="row mb-16">
        <div class="col">
            <h3>{{header}} Location</h3>
            <p class="text-helper mb-md-0">{{subheader}}</p>
        </div>
        <div class="col">
            <div class="d-flex justify-content-end align-items-start  gap-16">
              <mat-form-field class="form-rangepicker" style="width: 17rem;" *ngIf="status=='listView'">
                <mat-date-range-input (click)="picker.open()" (keydown)="false" [rangePicker]="picker" >
                  <input matStartDate matInput placeholder="Start date"  [formControl]="start" />
                  <input matEndDate matInput placeholder="End date"  [formControl]="end" (dateChange)="changeDate()"/>
                </mat-date-range-input>
                <mat-date-range-picker  #picker ></mat-date-range-picker>
                <i class="icon-calendar"  (click)="picker.open()"></i>
              </mat-form-field>
                <div class="form-icon " style="width:16rem;">
                    <app-searchbar #search [searchlist]='searchlistdata' [searchplaceholder]='locsearch'
                        (searchEmitter)="searchresults($event)"
                        (keywordsearchEmitter)="searchlocation($event)" [isLoading]="sLoading"></app-searchbar>
                </div>

                <div ngbDropdown class="d-inline-block">
                    <button class=" btn-square btn btn-secondary" (click)="filterclick()" placement="bottom-left"
                        id="dropdownBasic1" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13"></i>
                    </button>
                    <app-common-employee-filters *ngIf='filter === true' [filterForm]="filterForm" [from]="from" [filter]="filter"
                    (filterClose)="closefn($event)"  (resetCalled)="resetCalled($event)" (filterOutput)="resultfilterfn($event)"></app-common-employee-filters>
                </div>
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" (click)="status='mapView';mapView()" name="btnradio" id="btnradio1"
                        autocomplete="off" [checked]='status=="mapView"'>
                    <label class="btn btn-outline-accent2 text-uppercase fs-10 fw-700" for="btnradio1">MAP view</label>
                    <input type="radio" class="btn-check" (click)="status='listView'" name="btnradio" id="btnradio2" autocomplete="off" [checked]='status=="listView"'>
                    <label class="btn btn-outline-accent2 text-uppercase fs-10 fw-700" for="btnradio2">LIST view</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-16">
        <app-c1-loader *ngIf="loader && status!='listView'"></app-c1-loader>
        <ng-container *ngIf="loader==false">
            <div class="col-12"  *ngIf="status!='listView'">
                <div class="card card-c2 px-24 py-16 card-hover indicater4 indicater-warning"
                    (click)="gpslostlist();viewDetail = true">
                    <div class=" hstack gap-16 align-items-center">
                        <div class="fs-32">{{GPSLostCnt}}</div>
                        <div class="fs-12 text-uppercase fw-500 text-light-500">EMPLOYEES HAVING BAD GPS SETTINGS
                            TODAY
                        </div>
                        <span class="text-accent1  ms-auto ">
                            <i class="icon-chevron-right-lg fs-18 "></i>
                        </span>

                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="status!='listView'">
                <agm-map [mapTypeControl]='true' [fullscreenControl]='true'  style="height: 450px;" [latitude]="18.520430" [longitude]="73.856744" [zoom]="4" [styles]="styles" (mapReady)="onMapReady($event)">
                      <marker-cluster [imagePath]="'/assets/images/helper/map-img/map-cluster'" [imageExtension]="'png'" (clusterClick)="clickOnCluster($event)">
                        <ng-container *ngFor="let m of geolocationlist; let i = index">
                            <agm-overlay [latitude]="m.latitude" [longitude]="m.longitude" [markerDraggable]="false" [title]="m.employee"
                              (mouseOver)=" showInfo(i)" (mouseOut)="hideInfo(i)" (markerClick)="openWindow(i)">
                              <div class="avatar-marker">
                                <div *ngIf="m?.profile_image == '' || m?.profile_image == null"
                                  class="avatar-circle sq-36 fw-500 {{m?.color_code}}">
                                  {{m?.label}}
                                </div>
                                <img *ngIf="(m?.profile_image != null && m?.profile_image!='')" class="avatar-circle sq-36"
                                  src="{{m?.profile_image}}" alt="">
                              </div>
                              <agm-info-window [isOpen]="isInfoWindowOpen(i)">
                                <div class="card card-c2 border-0" style="max-width: 16rem;">
                                  <div class="card-body px-0 pt-0 d-flex gap-8 pb-12">
                                    <div *ngIf="m?.profile_image == '' || m?.profile_image == null"
                                      class="avatar-circle avatar-border fw-500 sq-40 {{m?.color_code}}">
                                      {{m?.label}}
                                    </div>
                                    <img *ngIf="(m?.profile_image != null && m?.profile_image!='')" class="avatar-circle avatar-border sq-40 "
                                      src="{{m?.profile_image}}" alt="">
                                    <div class="overflow-hidden">
                                      <h6 class="mb-1 text-trim fs-14" title="{{m?.employee_name}}">{{m?.employee_name}}</h6>
                                      <div class="fs-10 text-uppercase fw-500 d-flex gap-8 align-items-center">
                                        <span class="text-light-500 text-trim w-100 flex-shrink-0">{{m?.employee_code}}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="card-body overflow-hidden hstack gap-8 px-0 fs-12 py-12">
                                    <i class="text-accent1 fs-16 icon-geo-alt-fill"></i>
                                    <span class="  text-trim-3" title="{{m.location}}">{{m.location}}</span>
                                  </div>
                                  <div class="card-body overflow-hidden vstack gap-8 px-0 fs-12 pt-12 pb-0">
                                    <div class="hstack gap-16 justify-content-between">
                                      <span class="text-light-500 ">Mobility status</span>
                                      <span class="text-nowrap text-end fw-500 text-uppercase {{this.badgetext[m.mobile_status]}}">{{m.mobile_status}}</span>
                                    </div>
                                    <div class="hstack gap-16 justify-content-between">
                                      <span class="text-light-500 ">Last updated</span>
                                      <span class=" text-end fw-500 text-nowrap">{{m?.last_updated !=
                                        ''?appService.datetimeconvert(m?.last_updated):'--'}}</span>
                                    </div>
                                    <div class="hstack gap-16 justify-content-between">
                                      <span class="text-light-500 ">Km from 1st checkin</span>
                                      <span class=" text-end fw-500">{{m?.mileage}}</span>
                                    </div>
                                    <div class="hstack gap-16 justify-content-between">
                                      <span class="text-light-500 ">Routes</span>
                                      <span class="link-primary1 text-end fw-500" (click)="viewemployee(m,m.date)">View Routes</span>
                                    </div>
                                  </div>
                                </div>
                              </agm-info-window>
                            </agm-overlay>
                      </ng-container>
                    </marker-cluster>
                </agm-map>
            </div>
        </ng-container>
        <ng-container *ngIf="status!='mapView'">
            <app-table-loader *ngIf="loader"></app-table-loader>
            <div class="col-12" *ngIf="!loader && nodata ">
                <div class="row" style="height: calc(100vh - 18rem);">
                    <app-nodata-view class="vstack" [noDataImg]="'bg37.png'" [noDataText]="'Real-Time Locations at Your Fingertips'"
                        [noDataPara]="'To view live locations, ensure that employees have enabled location tracking in their mobile app settings. Once active, their locations will appear here.'"
                        [hasPermission]="false"> </app-nodata-view>
                </div>
            </div>
            <div class="col-12" *ngIf="!loader && !nodata ">
                <div class="card card-c2">
                    <div class="table-responsive radius-4" >
                        <table
                            class="table sticky-header td-10 sticky-first-col th-12 td-fs-14 tr-fs-14 sticky-last-col table-sm form-table td-white-space">
                            <thead>
                                <tr>
                                    <th class="fw-600 text-uppercase" scope="col">Employee NAME</th>
                                    <!-- <th class="fw-600 text-uppercase" scope="col">EMPLOYEE CODE</th> -->
                                    <th class="fw-600 text-uppercase" scope="col">DATE</th>
                                    <th class="fw-600 text-uppercase" scope="col">CHECK IN</th>
                                    <th class="fw-600 text-uppercase" scope="col">CHECK OUT</th>
                                    <th class="fw-600 text-uppercase" scope="col">EMPLOYEE LOCATION</th>
                                    <th class="fw-600 text-uppercase" scope="col">MOBILITY STATUS</th>
                                    <th class="fw-600 text-uppercase" scope="col">last updated date & time</th>
                                    <th class="fw-600 text-uppercase" scope="col">MILEAGE</th>
                                    <th class="fw-600 text-uppercase" scope="col">ROUTE</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="nodata">
                                <tr>
                                    <td colspan="9">

                                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                                            <span class="fw-500 fs-14">No data found</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="cell-16 " *ngIf="!nodata ">

                                <tr
                                    *ngFor='let locationdata of $any(geolocationlist| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize),let i = index'>
                                    <td>
                                      <div class="d-flex align-items-center ">
                                        <img *ngIf="(locationdata?.profile_image!=null && locationdata?.profile_image!='')" class="avatar-circle sq-32"
                                          src="{{locationdata?.profile_image}}" alt="">
                                        <div *ngIf="(locationdata?.profile_image ==  null || locationdata?.profile_image == '')"
                                          class="avatar-circle fw-500 sq-32 {{locationdata?.color_code}}">
                                          {{this.appService.getFirstChar(locationdata?.employee_name,1)}}</div>
                                        <div class="overflow-hidden ms-16 d-flex flex-column">
                                          <p class="fs-14 fw-600 w-15ch text-trim mb-0" ngbPopover="{{locationdata?.employee_name}}"
                                            popoverClass="popover-input popover-info" container="body" triggers="hover">{{locationdata?.employee_name}}</p>
                                          <p class="fs-10 mb-0 text-trim lh-base">{{locationdata?.employee_code}}</p>
                                        </div>
                                      </div>
                                    </td>
                                    <!-- <td class="text-trim w-30ch" title="{{locationdata?.employee_name}}">{{locationdata?.employee_name}}</td>
                                    <td>{{locationdata?.employee_code}}</td> -->
                                    <td>{{locationdata?.date| date:this.appService.getdateformat()}}</td>
                                    <td>{{(locationdata?.check_in !=  '' && locationdata?.date !=  '' && appService.isValidDateTime(locationdata?.date+" "+locationdata.check_in ))?(locationdata.date+" "+locationdata.check_in | date:this.appService.gettimeformat()):'--'}}</td>
                                    <td>{{(locationdata?.check_out !=  '' && locationdata?.date !=  '' && appService.isValidDateTime(locationdata?.date+" "+locationdata.check_out ))?(locationdata.date+" "+locationdata.check_out | date:this.appService.gettimeformat()):'--'}}</td>
                                    <td class="text-trim w-30ch" title="{{locationdata?.location != ''?locationdata?.location:'--'}}">{{locationdata?.location != ''?locationdata?.location:'--'}}</td>
                                    <td><span class="badge px-16 fs-14 {{badges[locationdata?.mobile_status ]}} py-1">{{locationdata?.mobile_status  != '' && locationdata?.mobile_status  != null?locationdata?.mobile_status :'--'}}</span></td>
                                    <td>{{locationdata?.last_updated != ''  && appService.isValidDateTime(locationdata?.last_updated)?appService.datetimeconvert(locationdata?.last_updated):'--'}}</td>
                                    <td>{{locationdata?.mileage!= '' && locationdata?.mileage != null?locationdata?.mileage:'--'}}</td>
                                    <td (click)="viewemployee(locationdata,locationdata.date)"><i
                                            class="icon-eye fs-18 link-primary "></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="this.appService.filteredcount(geolocationlist,searchKeyword)>pageSize">
                <ngb-pagination class="d-flex justify-content-end"
                    [collectionSize]="this.appService.filteredcount(geolocationlist,searchKeyword)" [(page)]="page"
                    [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                    [pageSize]="pageSize"></ngb-pagination>
            </div>
        </ng-container>
    </div>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:27rem;"
    [class.side-pane-active]='viewEmployees === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Employees in location</h5>
            <a class="toggle-panel" (click)="viewEmployees = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">

                <div class="row row-24 ">
                    <!-- <div class="col-12 d-flex">
                        <span class="title">Employees mapped</span>
                    </div>
                    <div class="col-12">
                        <div class="form-icon icon-left w-100">
                            <i class="icon-search"></i>
                            <input type="text" class="form-control fs-14" name="" placeholder="Search "
                                [(ngModel)]="searchString" [ngModelOptions]="{standalone: true}" id="">
                        </div>
                    </div> -->
                    <div class=" col-12" *ngFor="let m of this.viewEmployeesList | filter:searchString">
                        <div class="card card-c2 border-0 p-16"  >
                            <div class="card-body px-0 pt-0 d-flex gap-8 pb-12">
                              <div *ngIf="m?.profile_image == '' || m?.profile_image == null"
                                class="avatar-circle avatar-border fw-500 sq-40 {{m?.color_code}}">
                                {{m?.label}}
                              </div>
                              <img *ngIf="(m?.profile_image != null && m?.profile_image!='')" class="avatar-circle avatar-border sq-40 "
                                src="{{m?.profile_image}}" alt="">
                              <div class="overflow-hidden">
                                <h6 class="mb-1 text-trim fs-14" title="{{m?.employee_name}}">{{m?.employee_name}}</h6>
                                <div class="fs-10 text-uppercase fw-500 d-flex gap-8 align-items-center">
                                  <span class="text-light-500 text-trim w-100 flex-shrink-0">{{m?.employee_code}}</span>
                                </div>
                              </div>
                            </div>
                            <div class="card-body overflow-hidden hstack gap-8 px-0 fs-12 py-12">
                              <i class="text-accent1 fs-16 icon-geo-alt-fill"></i>
                              <span class="  text-trim-3" title="{{m.location}}">{{m.location}}</span>
                            </div>
                            <div class="card-body overflow-hidden vstack gap-8 px-0 fs-12 pt-12 pb-0">
                              <div class="hstack gap-16 justify-content-between">
                                <span class="text-light-500 ">Mobility status</span>
                                <span class="text-nowrap text-end fw-500  text-uppercase {{this.badgetext[m.mobile_status]}}">{{m.mobile_status}}</span>
                              </div>
                              <div class="hstack gap-16 justify-content-between">
                                <span class="text-light-500 ">Last updated</span>
                                <span class="text-nowrap text-end fw-500">{{m?.last_updated !=
                                  ''?appService.datetimeconvert(m?.last_updated):'--'}}</span>
                              </div>
                              <div class="hstack gap-16 justify-content-between">
                                <span class="text-light-500 ">Km from 1st checkin</span>
                                <span class=" text-end fw-500">{{m?.mileage}}</span>
                              </div>
                              <div class="hstack gap-16 justify-content-between">
                                <span class="text-light-500 ">Routes</span>
                                <span class="link-primary1 text-end fw-500" (click)="viewemployee(m,m.date)">View Routes</span>
                              </div>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-gps-lost-table *ngIf="viewDetail" [gpsdata]="gpsdata" [loadergps]="loadergps" (closepanel)="viewDetail =false"></app-gps-lost-table>
<app-employee-map-view *ngIf="viewempmap" [data]="viewdata" (closepanel)="viewempmap=false" [today]="clickedDate" [empData]="clickedEmployee" (emitDateSlider)="clickDateSlider($event)" [childloader]="childloader" ></app-employee-map-view>
