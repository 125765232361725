import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { digitdecimalValidator, noDecimalValidator } from '../../validators/2digitdecimal.validators';
import { MessageService } from 'src/app/message.global';
import { LoanProcessService } from 'src/app/@core/services/loan-process.service';
import * as moment from 'moment';
import { distinct } from 'rxjs/operators';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
@Component({
  selector: 'app-view-loan-details',
  templateUrl: './view-loan-details.component.html',
  styleUrls: ['./view-loan-details.component.scss']
})
export class ViewLoanDetailsComponent implements OnInit {

  // Inputs
  @Input() viewDetails : any
  @Input() loanData    : any
  @Input() from        : any = ''
  @Input() loader      : any
  @Input() navData     : any
  @Input() fullData    : any

  @Output() close  = new EventEmitter()
  // VAR initialisations
  editAdjust           : boolean = false;
  status                         = "Reduce";
  viewAdjust           : boolean = false;
  submitted            : boolean = false
  activeToggle         : any     = ''
  currency                       = 'INR'
  page            = 1
  pageSize        = 20
  alertToggle          : boolean = false
  alertMsg        = ''
  adjustLoanData       : any
  newLoanAmt      = 0
  loanTypeDetails      : any
  adjustData           : any

  listOfMonth         = [ {id:'01',value:"January"},
    {id:'02',value:"February"},
    {id:'03',value:"March"},
    {id:'04',value:"April"},
    {id:'05',value:"May"},
    {id:'06',value:"June"},
    {id:'07',value:"July"},
    {id:'08',value:"August"},
    {id:'09',value:"September"},
    {id:'10',value:"October"},
    {id:'11',value:"November"},
    {id:'12',value:"December"},
  ];
  currentYear= new Date().getFullYear();
  yearList : any = [this.currentYear,this.currentYear+1,this.currentYear+2];

  reduceForm: FormGroup = this.fb.group({
    "request_status":"Reduce",
    "emi_id":[],
    "emi_reduce_type":false,
    "reducing_emi_amount":['', [Validators.required,Validators.min(1), Validators.pattern('^[0-9]*$')]],
    "comment"          : [null,[Validators.required,Validators.maxLength(500)]],
  });

  SettledForm: FormGroup = this.fb.group({
    "request_status":"Settled",
    "loan_id":[],
    "outstanding_amount":[],
    "comment"          : [null,[Validators.required,Validators.maxLength(500)]],
  });

  RestructureForm: FormGroup = this.fb.group({
    "request_status":"Restructure",
    "loan_id":[],
    "amount_out_system":[null, [Validators.required,Validators.min(1), Validators.pattern('^[0-9]*$')]],
    "start_date":"",
    "comment": [null,[Validators.required,Validators.maxLength(500)]],
    "remaing_number_emis":null,
    "new_number_emis":  [null, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]],
    "deduction_month" : [null,[Validators.required]],
    "deduction_year": [null,[Validators.required]]
  });

  constructor(
    public appServ : AppService,
    public fb : FormBuilder,
    public messageService : MessageService,
    private LPS : LoanProcessService,
    private notificationService : NotificationService,
    private loanServ         : InvestmentDeclerationService
  ) {
    const amountFormControl = this.RestructureForm.get('amount_out_system');
    const emiFormControl = this.RestructureForm.get('new_number_emis');

    amountFormControl?.valueChanges .pipe(distinct()) .subscribe(value => amountFormControl.setValue(+value  || null));
    emiFormControl?.valueChanges .pipe(distinct()) .subscribe(value => emiFormControl.setValue(+value  || null));

  }

  ngOnInit(): void {
  }

  ngOnChanges(changes : SimpleChanges){
    if(changes['loanData'] && changes['loanData'].currentValue){
      this.activeToggle = this.loanData?.loan_code
    }
  }

  get r() {return  this.reduceForm.controls; }
  get s() {return  this.SettledForm.controls; }
  get f() {return  this.RestructureForm.controls; }

  setForms(loan:any){
    this.adjustLoanData = loan;
    this.yearList = [this.adjustLoanData.pay_year,this.adjustLoanData.pay_year+1,this.adjustLoanData.pay_year+2];
    this.editAdjust = true;
    this.setReduceForm();
  }

  setReduceForm(){
    this.submitted=false;
    this.status = "Reduce";
    this.reduceForm.reset(
      {
        "request_status":"Reduce",
        "emi_id" : this.adjustLoanData?.id,
        "emi_reduce_type": this.adjustLoanData?.emi_reduce_type == null ? true : this.adjustLoanData?.emi_reduce_type,
        "reducing_emi_amount": this.adjustLoanData?.amount_to_be_paid,
        "comment": this.adjustLoanData?.reduced_comment
      }
    )
    this.reduceForm?.get('reducing_emi_amount')?.setValidators([Validators.required,Validators.min(1),Validators.max(this.adjustLoanData?.emi_amount), Validators.pattern('^[0-9]*$')])
  }

  setsettleForm(){
    this.submitted=false;
    this.status = "Settled";
    this.SettledForm.reset({
      "request_status":"Settled",
      "loan_id": this.loanData?.id,
      "outstanding_amount":[],
      "comment"          : null
    })
    this.SettledForm.controls['outstanding_amount'].setValue(this.loanData?.to_be_recovered);
  }

  setrestructureForm(){
    this.RestructureForm.reset({
      'request_status' : 'Restructure',
      'loan_id' : this.loanData?.id,
      'amount_out_system' : null,
      "start_date": null,
      "comment" : null,
      "remaing_number_emis" : null,
      "new_number_emis" : null,
      "deduction_year" : null,
      "deduction_month" : null
    })
    this.LPS.getLoanTypeDetails(this.loanData?.loan_type_id).subscribe((res:any)=>{
      this.loanTypeDetails = res?.data
      this.RestructureForm.get('amount_out_system')?.setValidators([Validators.required, Validators.min(1),Validators.pattern('^[0-9]*$'),Validators.max(this.loanData?.to_be_recovered)])
      this.RestructureForm.get('new_number_emis')?.setValidators([Validators.max(this.loanTypeDetails?.max_number_emis),Validators.required, Validators.min(1),Validators.pattern('^[0-9]*$')])
    })
    this.submitted=false;
    this.status = "Restructure";
  }

  reduce(){
    let body = this.reduceForm.value
    body['reducing_emi_amount'] = Number(body['reducing_emi_amount'])
    this.LPS.adjust(body).subscribe((res:any)=>{
     this.close.emit(false)
     this.editAdjust  = false
     this.viewAdjust  = false
     this.notificationService.handleSuccessNotification("Updated Successfully","Success");
    }, error => {
      this.close.emit(false)
      this.editAdjust  = false
      this.viewAdjust  = false
    });
  }

  settle(){
    let body = this.SettledForm.value
    body['outstanding_amount'] = Number(body['outstanding_amount'])
    this.LPS.adjust(body).subscribe((res:any)=>{
      this.close.emit(false)
      this.editAdjust  = false
      this.viewAdjust  = false
      this.notificationService.handleSuccessNotification("Updated Successfully","Success");
     }, error => {
      this.close.emit(false)
      this.editAdjust  = false
      this.viewAdjust  = false
    });
  }

  restructure(){
    if(this.alertMsg != ''){
      this.alertToggle = true
      return
    }
    let body = this.RestructureForm.value
    body['amount_out_system'] = Number(body['amount_out_system'])
    body['new_number_emis'] = Number(body['new_number_emis'])
    body['remaing_number_emis'] = Number(body['remaing_number_emis'])
    this.LPS.adjust(body).subscribe((res:any)=>{
      this.close.emit(false)
      this.editAdjust  = false
      this.viewAdjust  = false
     this.notificationService.handleSuccessNotification("Updated Successfully","Success");
     }, error => {
      this.close.emit(false)
      this.editAdjust  = false
      this.viewAdjust  = false
    });
  }

  calcNewLoan(){
    this.newLoanAmt = this.loanData?.to_be_recovered-this.RestructureForm.value.amount_out_system
    if(this.RestructureForm.get('amount_out_system')?.value != null && Number(this.newLoanAmt == 0)){
      this.setMsg("Loan can't be restructured with new loan amount as 0.",true)
    }else{
      this.setMsg('',false)
      this.checkEMI()
    }
  }

  checkEMI(){
    if(this.alertMsg != ''){
      return
    }
    if(this.RestructureForm.get('amount_out_system')?.value != null && this.RestructureForm.get('amount_out_system')?.value != '' && this.RestructureForm.get('new_number_emis')?.value != null && this.RestructureForm.get('new_number_emis')?.value != '' && this.RestructureForm.get('amount_out_system')?.valid && this.RestructureForm.get('new_number_emis')?.valid){
      this.loanServ.calculateEMI({'loan_type_id' : this.loanTypeDetails?.id, 'interest_type': this.loanTypeDetails?.interest_type, 'loan_amount':Number(this.newLoanAmt),'no_emis':Number(this.RestructureForm.get('new_number_emis')?.value)}).subscribe((res:any)=>{
         if(res?.data?.monthly_emi < 1){
          this.setMsg(`Monthly EMI can't be less than 1 ${this.currency}.`,true)
        }else{
          this.setMsg('',false)
        }
      })
    }
  }

  setMsg(msg:any,bool:any){
    this.alertToggle = bool
    this.alertMsg = msg
  }

  closed(bool: any) {
    this.alertToggle = bool;
  }

  adjustLoader = false
  viewAdjustFn(loan:any){
    this.adjustLoanData = loan;
    this.adjustLoader = true
    this.viewAdjust = true
    this.LPS.adjustDetails(loan.id).subscribe((res:any)=>{
      this.adjustData = res.body.data
      this.currency = this.adjustData?.currency
      this.adjustLoader = false
    });
  }

  monthConversion(month:any){
    return moment().month(month).format("MM")
  }

  selectItemList(id:any){
    this.loader = true
    this.activeToggle = id
    const newData = this.fullData.find((item:any)=>{
      return item.loan_code == id
    })
    this.loanData = newData?.loan_details
    setTimeout(()=>{
      this.loader = false
    },1000)
  }

  editAdjusted(){
    this.viewAdjust = false;
    this.setForms(this.adjustLoanData)
  }

  updatedate(){
    const deductionYear = this.RestructureForm.get('deduction_year')?.value;
    const deductionMonth = this.RestructureForm.get('deduction_month')?.value;
    const monthObject = this.listOfMonth.find(month => month.value === deductionMonth);
    const formattedMonth = monthObject ? monthObject.id : '01';
    const sDate = `${deductionYear}-${formattedMonth}-01`;
    this.RestructureForm.controls['start_date'].setValue(sDate);

    if(this.adjustLoanData.pay_year == this.RestructureForm.get('deduction_year')?.value && parseInt(this.monthConversion(this.adjustLoanData.pay_month)) >= parseInt(formattedMonth)){
      this.RestructureForm.controls['start_date'].setErrors({'invalidDate': true});
      this.RestructureForm.controls['deduction_month'].setErrors({'invalidDate': true});
    } else {
      this.RestructureForm.controls['deduction_month'].setErrors(null)
    }
  }

}
