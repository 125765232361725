import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators  } from '@angular/forms';
import { holidaytypeService } from 'src/app/@core/services/holiday-type.service';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { AppService } from 'src/app/app.global';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { CustomDateValidators } from 'src/app/@shared/validators/customDate.validators';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime, delay, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-holiday-type',
  templateUrl: './holiday-type.component.html',
  styleUrls: ['./holiday-type.component.scss'],
})

export class HolidayTypeComponent implements OnInit {
  viewDetail = false;
  editDetail = false;
  statusBtn:any = "Active";
  viewholidaytypedata:any=[];
  searchlistdata:any = [];
  holidayTypeList:any = [];
  holidaysearch : string = this.messageService.searchdisplay('Holiday');
  deleteClass =false;
  header = '';
  addEditBtn = '';
  is_active : boolean = true;
  deleteToggle= false;

  public holiday_list_flag: boolean = true;
  submitted = false;
  status_filter :string = 'true';
  disabled = false;
  loader = true;
  loader1 = false;
  isDisabled = false;
  floating = false;
  national = false;
  deleteClicked = false
  yearList : any;
  selectedYear : any;
  /********INFINITY SCROLL VARIABLES  ****/
  offset                     = 0;
  listlength              = 0;
  direction               = '';
  defaultlimit:any        = 20;
  holidaytypDetailsList:any       = [];
  infinityloader          = false;
  permissions:any = [];
  searchKeyword="";
  id:any
  holidayUnit : any =[]
  editflag :boolean = false
  value:any;
  year: any;
  maxValue          : any
  maxFromValue          : any
  minFromValue          : any

  currentDate: any = new Date();
  futureAlert      = false;
  originalFromDate:any;
  originalToDate:any;
  isLoading = false
  searchData : Subject<void> = new Subject();
  private subjectKeyUp = new Subject<any>();
  @ViewChild(SearchbarComponent) child:any;

 constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    private holidaytypeService : holidaytypeService,
    private formBuilder: FormBuilder,
    public breakpointObserver: BreakpointObserver,
    public appService: AppService,
    public messageService : MessageService,
    public router: Router,
    public existDataService :  ExistDatasService,
  ) { }

  holidaytypeForm=this.formBuilder.group({
    id: 0,
    holiday_name: ['', [Validators.required,UnamepatternValidator(), Validators.maxLength(100)]],
    from_date: ['', Validators.required],
    to_date: ['', Validators.required],
    floating: [false, Validators.required],
    national_holiday: [false, Validators.required]
  },
  { validators: CustomDateValidators.fromToDate('from_date', 'to_date') });


  ngOnInit(): void {
    this.getDateFormat();
    this.yearListCreate()
    let maxdate = moment(new Date()).add(1, 'year').format('YYYY-MM-DD')
    this.maxFromValue = moment(maxdate).endOf('year').format('YYYY-MM-DD')
    this.minFromValue = moment(new Date()).startOf('year').format('YYYY-MM-DD')

    this.getPermission();
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })

    this.selectedYear = new Date().getFullYear();
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.holidayTypeListfn();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.holidaytypeService.getholidaytypeList(this.status_filter,this.defaultlimit,this.offset,this.isDisabled,this.searchKeyword,this.selectedYear)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }

   // Date Format
   getDateFormat() {
    this.matDateFormat.display.dateInput = this.appService.getdatepickerformat();
    if (this.matDateFormat.display.dateInput == '') {
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }


  toggleType(event:any){
    if(event == 'floating'){
      if(!this.holidaytypeForm.controls['floating'].value)
        this.holidaytypeForm.setControl('national_holiday', new FormControl(false));
    }else if(event == 'national'){
      if(!this.holidaytypeForm.controls['national_holiday'].value){
        this.holidaytypeForm.setControl('floating', new FormControl(false));
      }
    }
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  yearListCreate(){
    this.year = new Date().getFullYear();
    this.yearList=[];
    var i:number;
    for(i = this.year+1; i >= (this.year) ; i--){
      this.yearList.push(String(i))
    }
    for(i = this.year-1; i >= this.year-1 ; i--){
      this.yearList.push(String(i))
    }

  }

  yearChange(value:any){
    this.selectedYear    = Number(value);
    this.offset          = 0;
    this.holidayTypeList = [];
    this.holidayTypeListfn();
  }

 // add form
  addholidayForm(){
    this.editDetail = true
    this.header = "Add"
    this.addEditBtn = "Add"
    this.deleteClass = false
    this.disabled = false;

    this.holidaytypeForm.controls.floating.enable();
    this.holidaytypeForm.controls.national_holiday.enable();

    this.holidaytypeForm.reset({id: 0 ,floating: false, national_holiday: false});
    // this.holidaytypeForm.enable();
    this.submitted= false;
  }

  // edit form
  editholidayForm(id:any){
    this.id = id
    this.editflag = true;
    this.viewDetail = false;
    this.loader1 = true;
    this.editDetail = true
    this.addEditBtn = "Save"

    this.holidaytypeService.getholidayType(id).subscribe((res: any) => {
      this.deleteClass = true;
      this.loader1 = false;
      this.header = res.is_active==true?"Edit":"View";
      if(res.is_active==false){
        this.holidaytypeForm.disable();
        this.disabled = true;
      }
      else{
        this.holidaytypeForm.enable();
        this.disabled = false;
      }
      this.holidaytypeForm.controls.floating.disable();
      this.holidaytypeForm.controls.national_holiday.disable();
      this.holidaytypeForm.reset({
        id: res.id,
        holiday_name: res.holiday_name,
        from_date: res.from_date,
        to_date: res.to_date,
        floating: res.floating,
        national_holiday: res.national_holiday});

        this.originalFromDate = res?.from_date;
        this.originalToDate   = res?.to_date;

    });
  }

  /********* STATUS FILTER **************/
  selectItemList(value:any){
    this.offset                 = 0;
    this.holidayTypeList      = [];
    this.status_filter          = value
    this.holidayTypeListfn();
  }

  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.holidayTypeListfn();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.holidayTypeList.push(this.holidaytypDetailsList[i]);
    }

  }
  holidayTypeListfn(){
    this.loader = true;
    this.holidaytypeService.getholidaytypeList(this.status_filter,this.defaultlimit,this.offset,this.isDisabled,this.searchKeyword,this.selectedYear).subscribe((res: any)=> {
      this.arrangeApiData(res)
    })
  }

  arrangeApiData(res:any){
    this.isLoading = false
    if (res.results.length == 0) {
      this.holiday_list_flag = (this.offset == 0) ? false :true;
      this.listlength  = 0;
    } else {
      this.holiday_list_flag = true;
      this.listlength = res.results.length;
      if(this.offset ==0){
        this.holidayTypeList = [];
      }
      this.holidaytypDetailsList = res.results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
  }

  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.holidayTypeList = [];
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  holidaytypFormSubmit(){
    this.submitted = true;
    // return if validation fails
    if(this.holidaytypeForm.invalid){
      return
    }
    this.disabled = true

    if(this.addEditBtn === "Add"){
      // add api
      var dataArray = {
        "holiday_name": this.holidaytypeForm.controls.holiday_name.value,
        "from_date": this.appService.dateFormatConvert(this.holidaytypeForm.controls.from_date.value),
        "to_date": this.appService.dateFormatConvert(this.holidaytypeForm.controls.to_date.value),
        "floating": this.holidaytypeForm.controls.floating.value,
        "national_holiday": this.holidaytypeForm.controls.national_holiday.value,
      }

      this.holidaytypeService.createholidaytype(dataArray).subscribe((res: any) => {
        this.editDetail = false,
        this.offset = 0;
        this.holidayTypeList = [];

        this.holidayTypeListfn()
      },
      (err: any) => {
        this.disabled = false
      });

    }else{
      let today  = moment(this.currentDate).format('YYYY-MM-DD');
      if(((this.originalFromDate > today) && (this.originalToDate > today)) && (this.holidaytypeForm.controls.from_date.value != this.originalFromDate ||  this.holidaytypeForm.controls.to_date.value != this.originalToDate)){
        this.futureAlert = true;
      }else{
        this.updateHolidayType();
      }
    }
  }

  updateHolidayType(){
    var editdataArray = {
      "id": this.holidaytypeForm.controls.id.value,
      "holiday_name": this.holidaytypeForm.controls.holiday_name.value,
      "from_date": this.appService.dateFormatConvert(this.holidaytypeForm.controls.from_date.value),
      "to_date": this.appService.dateFormatConvert(this.holidaytypeForm.controls.to_date.value),
      "floating": this.holidaytypeForm.controls.floating.value,
      "national_holiday": this.holidaytypeForm.controls.national_holiday.value,
    }
    //  API for update Holiday Type
    // this.holidaytypeForm.patchValue({
    //   from_date:  this.appService.dateFormatConvert(this.holidaytypeForm.controls.from_date.value),
    //   to_date:  this.appService.dateFormatConvert(this.holidaytypeForm.controls.to_date.value),
    // })
    this.holidaytypeService.updateholidayType( this.holidaytypeForm.value.id,editdataArray).subscribe((res: any) => {
      this.editDetail = false,
      this.offset = 0;
      this.holidayTypeList = [];
      this.holidayTypeListfn()
    },
    (err: any) => {
      this.disabled = false;
    });
  }

  // active status filter
  listFilter(value:any){
    this.offset            = 0;
    this.holidayTypeList = [];
    this.status_filter = value
    this.child.clearBar();
  }

  deleteholidaytype(id:any){
    this.holidaytypeForm.value.id = id;
    this.deleteToggle = true;
  }

  confirmDelete(){
    this.deleteClicked = true
    this.holidaytypeService.deleteholidayType(this.holidaytypeForm.value.id, {'is_active': false}).subscribe((res: any) => {
      this.deleteToggle = false,
      this.editDetail = false,
      this.offset = 0;
      this.holidayTypeList = [];
      this.deleteClicked = false
      this.holidayTypeListfn()
    },
    (err: any) => {
      this.deleteClicked = false
      this.deleteToggle = false
      this.editDetail = false
    });
  }

  get f() {
    return this.holidaytypeForm.controls;
  }

  viewholidaytyp(id:any){
    this.viewholidaytypedata = [];
    if(this.permissions.v){
      this.viewDetail = true;
      this.loader1 = true;
      this.holidaytypeService.getholidayType(id).subscribe((res: any) => {
      this.viewholidaytypedata =res;
        this.loader1 = false;
      })
    }
  }

  disableDate(){
    return false;
  }

  searchholiday(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset           = 0;
    this.holidayTypeList= [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
    }
  }

  // Simplified function to use for validation
  existService(){
    if(!this.editflag){
      this.value ={
        "page":"holiday",
        "field":"holiday_name",
        "value": this.f.holiday_name.value,
        "year" : moment(this.f.from_date.value).year(),
        "is_active" : true
      }
    }else{
       this.value ={
        "id":this.id,
        "page":"holiday",
        "field":"holiday_name",
        "value": this.f.holiday_name.value,
        "year" : moment(this.f.from_date.value).year(),
        "is_active" : true
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.holidayUnit = res.body
      this.loader = false
      if(this.holidayUnit.data ==='invalid'){
        this.f.holiday_name.setErrors({
          'exist' : true
        })
      }else{
        if (this.f.holiday_name.hasError('exist')) {
          const errors : any = this.f.holiday_name.errors;
          delete errors['exist'];
          if (Object.keys(errors).length === 0) {
            this.f.holiday_name.setErrors(null);
          } else {
            this.f.holiday_name.setErrors(errors);
          }
        }
      }
    })
  }

 // For validation of keyup(name)
  keyUp(){
    if(this.f.from_date.value != null && this.f.from_date.value != ''){
      this.subjectKeyUp.next(this.f.holiday_name.value)
    }
  }

  // From Date
  fromDate(value:any){
    let from = this.f.from_date.value
    // this.maxValue = moment(from).add(1, 'year')
    this.holidaytypeForm.patchValue({
      to : null
    })
  }
}










