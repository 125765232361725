import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Injectable({
  providedIn: 'root'
})
export class AttendanceDashboardService {
  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };
  FILTERDATA:any = `employment_status=${JSON.stringify(['Probation','Confirmed','Resigned'])}&ordering=user__first_name`
  SEARCHFILTER:any = `employment_status=${JSON.stringify(['Probation','Confirmed','Resigned'])}&ordering=user__first_name`;
  SETDATE = this.appService.convertToDate('from',new Date());
  SEARCHKEY = '';
  
constructor(
  private http: HttpClient,
  public router: Router,
  public appService : AppService
) {this.router.events.subscribe(event => {
  if (event instanceof NavigationEnd) {
    if(!event.url.includes('employee-attendance')){
      this.setFilterData(`employment_status=${JSON.stringify(['Probation','Confirmed','Resigned'])}&ordering=user__first_name`)
      this.setSearchFilterData(`employment_status=${JSON.stringify(['Probation','Confirmed','Resigned'])}&ordering=user__first_name`)
      this.SETDATE = this.appService.convertToDate('from',new Date());
    }
  }
}) }

getAllAttendanceData(from:any,to:any,limit:any,offset:any,search:any,filter:any,id:number){
  if(id == 0)
    return this.http.get(`${this.API_PATH}attendance/api/v1/hr-attendance-dashboard/?fromdate=${from}&todate=${to}&limit=${limit}&offset=${offset}&search=${search}&${filter}`, this.httpOption)
  else 
    return this.http.get(`${this.API_PATH}attendance/api/v1/hr-attendance-dashboard/?fromdate=${from}&todate=${to}&limit=1&offset=1&emp_id=${id}`,this.httpOption)
}

savePunchData(data:any,from:any){
  if(from=='hr'){
     return this.http.post(`${this.API_PATH}attendance/api/v1/hr-employee-punch-add/`, data, this.httpOption)
  }else{
    return this.http.post(`${this.API_PATH}attendance/api/v1/team-employee-punch-add/`, data, this.httpOption)
  }
}
deletePunch(data:any,from:any){
  if(from=='hr' || from=='HR'){
  return this.http.post(`${this.API_PATH}attendance/api/v1/hr-employee-punch-delete/`, data,this.httpOption)
  }else{
  return this.http.post(`${this.API_PATH}attendance/api/v1/team-employee-punch-delete/`, data,this.httpOption)
  }
}
uploadPunches(data: any,from:any){
  if(from=='hr'){
      return this.http.post(`${this.API_PATH}attendance/api/v1/employee-punch-upload/`, data, this.httpOption)
  }else{
      return this.http.post(`${this.API_PATH}attendance/api/v1/team-employee-punch-upload/`, data, this.httpOption)
  }
}
setFilterData(val:any){
  this.FILTERDATA = val
}
setSearchFilterData(val:any){
  this.SEARCHFILTER = val
}
setDates(setdate:any){
  this.SETDATE = setdate

}
saveoverrideData(data:any, from:any){
  if(from=='hr'){
     return this.http.post(`${this.API_PATH}attendance/api/v1/hr-employee-attendance-status-override/`, data, this.httpOption)
  }else{
    return this.http.post(`${this.API_PATH}attendance/api/v1/team-employee-attendance-status-override/`, data, this.httpOption)
  }
}
generatedates(body:any){
  return this.http.get(`${this.API_PATH}attendance/api/v1/shift-date-range/${body}`,this.httpOption)
}
uploadoverride(data: any, from:any){
  if(from=='hr'){
     return this.http.post(`${this.API_PATH}attendance/api/v1/attendance-status-overrride-upload/`, data, this.httpOption)
  }else{
    return this.http.post(`${this.API_PATH}attendance/api/v1/attendance-status-overrride-upload-team/`, data, this.httpOption)
  }
}
setSEARCHKEY(val:any){
  this.SEARCHKEY = val
}
}
