<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">Details</h5>
        <button class="btn-reset text-light-600" routerLink="/leave-year-end">
            <i class="icon-close-lg fs-16"></i></button>
    </header>
    <main class=" config-container">
        <div class="container-fluid p-32 form-container">
          <app-table-loader *ngIf="loader"></app-table-loader>
            <div class="row mb-24" *ngIf="!loader">
                <div class="col-md-4">
                    <p class="text-uppercase fw-500 mb-0">Leave year end details</p>
                </div>
                <div class="col-md-8">
                    <div class="d-flex justify-content-end align-items-center flex-wrap gap-16">
                        <button class="btn-square btn btn-secondary" (click)="filterPanel = true">
                        <i class="icon-filter-left fs-13"></i>
                        </button>
                        <button class="btn btn-outline-primary fs-14 radius-2 text-uppercase" (click)="export()">Export</button>
                    </div>
                </div>
            </div>
            <app-salary-dashboard-filter [filter]="filterPanel" [isCompanyNeeded]='true' (applymethod)="submitForm($event)" [formData]="formData"
            (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>
            <div class="row row-24" *ngIf="!loader">
                <div class="col-12">
                    <div class="card card-c2 overflow-hidden">
                        <div class="table-responsive" style="max-height: calc(100vh - 17rem);">
                            <table class="table table-striped table-sm form-table sticky-header">
                                <thead>
                                    <tr>
                                        <th scope="col" class="form-cell">
                                            <input class="form-check-input m-0" type="checkbox" value="" (click)="checkAll($event)" [checked]="pageChange()" *ngIf="permissions?.a">
                                        </th>
                                        <th class="fw-600" scope="col">EMP CODE</th>
                                        <th class="fw-600" scope="col">Emp name</th>
                                        <th class="fw-600" scope="col">company</th>
                                        <th class="fw-600" scope="col">Business unit</th>
                                        <th class="fw-600" scope="col">LEAVE CODE</th>
                                        <th class="fw-600" scope="col">LEAVE balance available</th>
                                        <th class="fw-600" scope="col">LEAVE encashment limit</th>
                                        <th class="fw-600" scope="col">LEAVE encashed</th>
                                        <th class="fw-600" scope="col">carry forward limit</th>
                                        <th class="fw-600" scope="col">Carry forward leave balance</th>
                                        <th class="fw-600" scope="col">LAPSED LEAVE BALANCE</th>
                                        <th class="fw-600" scope="col">YEAR END STATUS</th>
                                    </tr>
                                </thead>
                                <tbody class="cell-16">
                                  <tr *ngIf="this.appServ.filterIt(viewData,'').length<=0">
                                    <td colspan="13">
                                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                                            <span class="fw-500 fs-14">No data found</span>
                                          </div>
                                    </td>
                                  </tr>
                                   <ng-container *ngFor="let item of $any(viewData | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize) ; let i = index;">
                                    <tr>
                                        <td class="form-cell">
                                            <input class="form-check-input m-0" type="checkbox" [(ngModel)]="item.checked" *ngIf="permissions?.a">
                                        </td>
                                        <td data-toggle="tooltip" title="{{item?.employee_code?.data}}">{{item?.employee_code?.data}}</td>
                                        <td data-toggle="tooltip" title="{{item?.employee_name?.data}}">{{item?.employee_name?.data}}</td>
                                        <td data-toggle="tooltip" title="{{item?.company_name?.data}}">{{item?.company_name?.data}}</td>
                                        <td data-toggle="tooltip" title="{{item?.bu_name?.data}}">{{item?.bu_name?.data}}</td>
                                        <td data-toggle="tooltip" title="{{item?.leave_code?.data}}">{{item?.leave_code?.data}}</td>
                                        <td data-toggle="tooltip" title="{{item?.balance?.data}}">{{item?.balance?.data}}</td>
                                        <td data-toggle="tooltip" title="{{item?.encashment_leave_limit?.data}}">{{item?.encashment_leave_limit?.data}}</td>
                                        <td>
                                          <div class="form-icon " [ngClass]="{'icon-right': !(item.encashment_leave_balance.is_valid)}">
                                            <input type="text"
                                              class="hasError form-control {{ (item.encashment_leave_balance.is_valid == false) ? 'is-invalid' : ''}}"
                                              [(ngModel)]="item.encashment_leave_balance.data"
                                              placeholder="{{this.messageService.placeholderdisp('Encashment Leave Balance')}}"
                                              (keyup)="validateData(i,'encashment_leave_balance')"
                                              [disabled]="!permissions?.e || item?.status?.data == 'Completed' || item?.encashment_leave_limit?.data == 0">
                                            <app-table-input-popover [errorCheck]="!(item.encashment_leave_balance.is_valid)"
                                              [errorData]="item.encashment_leave_balance.error"></app-table-input-popover>
                                          </div>
                                          <!-- <div *ngIf="!(item.encashment_leave_balance.is_valid)" class="invalid-feedback">
                                            <div>{{item.encashment_leave_balance.error}}</div>
                                          </div> -->
                                        </td>
                                        <td data-toggle="tooltip" title="{{item?.carry_forward_limit?.data}}">{{item?.carry_forward_limit?.data}}</td>
                                        <td>
                                          <div class="form-icon " [ngClass]="{'icon-right': !(item.carry_forward_leave_balance.is_valid)}">
                                            <input type="text"
                                              class="form-control hasError {{(item.carry_forward_leave_balance.is_valid == false) ? 'is-invalid' : '' }}"
                                              [(ngModel)]="item.carry_forward_leave_balance.data"
                                              placeholder="{{this.messageService.placeholderdisp('Carry Forward Leave Balance')}}"
                                              (keyup)="validateData(i,'carry_forward_leave_balance')"
                                              [disabled]="!permissions?.e || item?.status?.data == 'Completed' || item?.carry_forward_limit?.data == 0">
                                            <app-table-input-popover [errorCheck]="!(item.carry_forward_leave_balance.is_valid)"
                                              [errorData]="item.carry_forward_leave_balance.error"></app-table-input-popover>
                                          </div>
                                          <!-- <div *ngIf="!(item.carry_forward_leave_balance.is_valid)" class="invalid-feedback">
                                            <div>{{item.carry_forward_leave_balance.error}}</div>
                                          </div> -->
                                        </td>
                                        <td data-toggle="tooltip" title="{{item?.lapsed_leave_balance?.data}}">{{item?.lapsed_leave_balance?.data}}</td>
                                        <td data-toggle="tooltip" title="{{item?.status?.data}}">
                                            <span class="badge px-16 {{item?.status?.data == 'Pending' ? 'badge-warning' : item?.status?.data=='Rejected' ? 'badge-danger' : 'badge-success'}} fs-14  py-1">{{item?.status?.data}}</span>
                                        </td>
                                    </tr>
                                   </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-4" *ngIf="this.appServ.filteredcount(viewData,searchString)>pageSize">
              <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [collectionSize]="this.appServ.filteredcount(viewData,searchString)"
                  [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize" ></ngb-pagination>
            </div>
        </div>
        <footer class="side-panel-footer radius-0 mt-auto position-sticky bottom-0" *ngIf="permissions?.a">
          <button class="btn btn-primary ms-auto btn-sm text-uppercase" [disabled]="getWholeData().length==0 || countInvalidElements() > 0" (click)="saveSelected()">SAVE</button>
        </footer>
    </main>
</div>

