import { Component, OnInit,ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SalaryDashboardFilterComponent } from 'src/app/@shared/components/salary-dashboard-filter/salary-dashboard-filter.component';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import { Validators } from '@angular/forms';
import { AttendanceProcessService } from 'src/app/@core/services/attendance-process.service';
@Component({
  selector: 'app-attendance-process',
  templateUrl: './attendance-process.component.html',
  styleUrls: ['./attendance-process.component.scss']
})
export class AttendanceProcessComponent implements OnInit {
  status = "Pending"
  fileName: string    = '';
  fileSize: string    = '';
  searchString:any    = "";
  data:any            = [];
  // inputData:any       = [];
  preparedData:any    = [];
  successData:any     = [];
  permissions:any     = [];
  ws:any              = null;
  invalidFormat       = false;
  page                = 1;
  pageSize            = 20;
  showSidePanel       = false;
  submiited           = false;
  processList:any   = [];
  confirmMsg          = false;
  confirmMsgText      = "";
  saveComplete        = false;
  alertToggle         = false;
  historyData:any     = [];
  year:any;
  month:any;
  company:any
  loader = false
  empalertMsg = "The selected employee(s) payroll has already been Processed/Freezed/Held/Published. Kindly undo the respective action to add/remove LOP reversal"
  keyArray:any = [];
  deleteToggle = false;
  deleteMsg = "";
  uploadArray:any = [];
  saveData:any = []
  statusFlag = false
  chunkLen = 10
  percentage:any = 0;
  iscompleted = false;
  isDataUpload = 0;
  isProgressStart = false
  failedData:any =[];
  successDataCount=0;
  body = '';
  downloadMsg = false;
  exportData:any      = [];
  errorMsg = ''
  successMsg = ''
  filter  = false;
  term = "process"
  keyuped = false
  saveUpdatedData : any = []
  editconfirmMsg : boolean = false;
  editconfirmMsgText = ''
  editflag : boolean = false;
  purpose = ''
  isClosed = false;
  popupHead = ''
  constructor(
    public sds:SalaryDashboardService,
    private notificationService: NotificationService,
    public route:ActivatedRoute,
    public appService: AppService,
    public messageService : MessageService,
    public aps:AttendanceProcessService,
    public router: Router,
    public previousRouteService :PreviousRouteService
    ) { }
    @ViewChild(SalaryDashboardFilterComponent) filtercomp!:SalaryDashboardFilterComponent;
  ngOnInit(): void {

    this.getPermission();
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['month'])){
        this.month = params['month'];
      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
    })
    this.getProcessList();
  }
  getPermission(){
    if(this.previousRouteService.getPreviousUrl() == '/attendance-dashboard'){
      this.permissions = this.appService.getPermissions('/attendance-dashboard');
    }else{
      this.permissions = this.appService.getPermissions('/run-payroll');
    }
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].type.split('/');
    if(extension[1] =='xlsx' || extension[1] == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: false,cellDates:true });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        this.ws = wb.Sheets[wsname];

        var ws = wb.Sheets[wsname];
        this.fileName = target.files[0].name;
        this.fileSize = this.appService.formatSizeUnits(target.files[0].size)
        this.data = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: false,rawNumbers: false, dateNF:'MMMM YYYY',blankrows:false}));

        this.processData();
      };
      reader.readAsBinaryString(target.files[0]);
    }
    else{
      this.invalidFormat = true;
    }
  }

  deleteFile(){
    this.fileName = '';
    this.data = [];
    this.fileSize = '';
    this.successData = [];
    this.keyArray = [];
    this.preparedData = []
    this.page = 1
  }

  getProcessList(){
    this.loader = true;
    this.page = 1
    this.searchString = "";
    this.sds.attendanceProcessList(this.company,this.year,this.month,"Pending",this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList).subscribe((res: any) => {
      this.processList = res.body.data
      this.loader = false
    })
  }
  historyList(){
    this.loader = true;
    this.page = 1
    this.searchString = "";
    this.sds.attendanceProcessList(this.company,this.year,this.month,"Completed",this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList).subscribe((res: any) => {
      this.historyData = res.body.data
      this.loader = false
    })
  }
  processData(){
    if(this.data.length <=1){
      this.deleteToggle = true;
      this.deleteMsg = "No data found in the uploaded file.";
      this.deleteFile();
    } else {
      var headerRow = this.data[0];
      let Heading =['Employee Code', 'Full name', 'Attendance month','Attendance year', 'Present days(paid)', 'Weekly offs', 'Leave(paid)', 'Holiday(paid)','Absent',	'Unpaid leave',
        	'Paid days deduction']
      let success = Heading.every(function(val:any) {

        return headerRow.indexOf(val) !== -1;
      });
      success =  JSON.stringify(Heading)==JSON.stringify(headerRow)
      if(success == false){
        this.deleteToggle = true;
        this.deleteMsg = this.messageService.invalidExcelFormat();
        this.deleteFile();
      }
      else
      this.validateData()
    }
  }

  validateData(){
    const validateDat:any = [];
    this.uploadArray = [];
    this.data.forEach((item:any, key:any) => {
      if(key != 0 && item[2]!= undefined){
        if(this.month == item[2] && this.year == item[3]){
          this.uploadArray.push({
            "employee": item[0]?item[0]:"",
            "emp_name": item[1]?item[1]:"",
            "attend_month": item[2]?item[2]:"",
            "attend_year": item[3]?item[3]:"",
            // "total_days": item[4]?item[4]:0,
            "prasent_days": Number(item[4]?item[4]:0),
            "weekly_offs": Number(item[5]?item[5]:0),
            "leave_paid": Number(item[6]?item[6]:0),
            "holiday_paid": Number(item[7]?item[7]:0),

            "absent_unpaid":Number(item[8]?item[8]:0),
            "leave_unpaid":Number(item[9]?item[9]:0),
            "deduction_paid_days":Number(item[10]?item[10]:0)
          })
        }
      }
    });
    if(this.uploadArray.length >0){

    } else {
      this.deleteToggle = true;
      this.deleteMsg = "The uploaded file does not contain any data for "+this.month+" "+this.year;
      this.deleteFile();
    }
  }
  processUploadData(k:any,p:any){
    var l = this.uploadArray.length;
    var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    var saveDataArray:any = [];
    this.percentage +=(p/4);
    for(var i= k*this.chunkLen; i<x; i++){
      saveDataArray.push(this.uploadArray[i])
    }

    this.sds.uploadAttendenceprocess({ "company_id" : this.company,"data":saveDataArray}).subscribe((res: any) => {
      this.body = x+' out of '+l+" data uploaded successfully"
        this.confirmMsg = false;
        if(res.body.failed_data != undefined && res.body.failed_data.length != 0){
            res.body.failed_data.forEach((i:any, k:any) => {
                this.failedData.push(i)
            });
        }

        this.successDataCount += res.body.succesd_data_count;
        this.percentage +=((p/4)*3);
        if(x<l)
            this.processUploadData(k+1,p);
        else{
          this.iscompleted = true
              this.isProgressStart = false
            this.successDataCount = this.uploadArray.length- this.failedData.length;
            if(this.failedData.length == 0){
                this.notificationService.handleSuccessNotification(this.successDataCount+" attendance data uploaded successfully.","Success");
            this.clearData()

            } else {
                this.downloadMsg = true;
                this.exportData    = [];
                for (let i = 0; i < this.failedData.length; i++) {
                  this.exportData.push({"Employee Code":this.failedData[i]['data'].employee,
                        "Full name":this.failedData[i]['data'].emp_name,
                        "Attendance month":this.failedData[i]['data'].attend_month,
                        "Attendance year": this.failedData[i]['data'].attend_year,
                        // "Paid days":this.failedData[i]['data'].total_days,
                        "Presen't days(paid)":this.failedData[i]['data'].prasent_days,
                        "Weekly offs (paid)":this.failedData[i]['data'].weekly_offs,
                        "Leave(paid)":this.failedData[i]['data'].leave_paid,
                        "Holiday(paid)":this.failedData[i]['data'].holiday_paid,
                        "Absent":this.failedData[i]['data'].absent_unpaid,
                        "Unpaid leave":this.failedData[i]['data'].leave_unpaid,
                        "Paid days deduction":this.failedData[i]['data'].deduction_paid_days,
                        'Error description':this.failedData[i].error});
                }
                this.successMsg = this.successDataCount+" out of "+l+" data uploadeded successfully "
                this.errorMsg = this.exportData.length+' employee(s) have error data'
            }
        }
      });
  }

  uploadData(){
    this.isDataUpload = 1;
    this.iscompleted = false
    this.popupHead = 'Uploading'
    this.saveComplete = true;
    this.percentage   = 0;

    if(this.uploadArray.length>0){
      this.isProgressStart = true
      this.failedData = []
      var l = this.uploadArray.length;
      var cLoop = Math.ceil(l/this.chunkLen)
      var p = 100/cLoop
      this.body = '0 out of '+l+" data uploaded successfully"
      this.successDataCount = 0
      this.processUploadData(0,p);
    }

  }

  checkSuccessData(){
    if(this.successData.length==0)
      this.clearData()
  }


  filterIt() {
    if(this.status=='Pending'){
      return this.processList?.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey = obj[key]?.toString().toLowerCase();
            const tempSearch = this.searchString.toLowerCase();
            return tempKey.includes(tempSearch);
          }
        });
      });
    } else {
      return this.historyData.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey = obj[key]?.toString().toLowerCase();
            const tempSearch = this.searchString.toLowerCase();
            return tempKey.includes(tempSearch);
          }
        });
      });
    }
  }
  filteredcount(){
    return this.filterIt()?.length;
  }

  clearData(){
   if(this.status == 'Pending'){
    this.getProcessList()
   }
   else{
    this.historyList()
   }

  }

  checkall(event:any){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }

  checkallSelected(){
    var newList = this.filterIt();
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList.slice(begin,end);
    if(subArray.length){
      return subArray.every((item: { checked: any; }) => {
        return item.checked;
      });
    } else {
      return false;
    }
  }

  countSelected(){
    var delArray:any = [];
    if(this.status == 'Pending'){
      this.processList.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(value)
      });
    } else {
      this.historyData.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(this.historyData[value].id)
      });
    }
    return delArray.length
  }
  selectAll(){
    if(this.status == 'Pending'){
    this.processList = this.appService.selectAll(this.processList)
    }else{
      this.historyData = this.appService.selectAll(this.historyData)
    }
  }
  unselectAll(){
    if(this.status == 'Pending'){
    this.processList = this.appService.unselectAll(this.processList)
    }else{
      this.historyData = this.appService.unselectAll(this.historyData)

    }
  }
  confirmBox(){

    if(this.saveData.length>0){
      if(this.status=='Pending')
        this.confirmMsgText ="Click the Save button, If you want to process Attendance for the selected employees.";
      else
        this.confirmMsgText ="Click the Save button, If you want to unprocess Attendance for the selected employees.";
      this.confirmMsg     = true;
    }
    if(this.statusFlag==true)
      this.alertToggle = true;
  }

  confirmation(){
    this.saveData     = [];
    this.saveComplete = false;
    this.purpose = "processing"
    if(this.status=='Pending'){
      this.aps.getAttendanceProcessData().subscribe((res:any) => {
        if(res.data?.percentage>0 && res.data?.percentage<100){
          this.notificationService.handleErrorNotification("Attendance processing cannot be done until the existing Attendance process is completed.","Attendance process");
        } else {
          for(let i=0;i<this.processList.length;i++) {
            if(this.processList[i]?.checked == true && (this.processList[i]?.status=='Pending' || this.processList[i]?.status=='' || this.processList[i]?.status==null)){
              this.saveData.push(this.processList[i]?.employee_id)
            }
          }

          this.confirmBox()

        }
      })


    } else {
      for(let i=0;i<this.historyData.length;i++) {
        if(this.historyData[i]?.checked == true && (this.historyData[i]?.status=='Completed' || this.historyData[i]?.status=='' || this.historyData[i]?.status==null)){
          this.saveData.push(this.historyData[i]?.employee_id)
        }
      }
    }

    this.confirmBox()

  }
  saveAttendanceData(){
    if(this.status=='Pending'){
      var nStatus = "Completed"
      this.term = "process"
      this.aps.setProcessData(this.saveData.length,this.router.url,"Process")
      this.startProgress = true;this.showProgress = false
      this.iscompletedA = false
      this.sds.saveAttendanceProcess({'month':this.month, 'year':parseInt(this.year), 'status': nStatus,"data":this.saveData, "company" : this.company}).subscribe((res: any) => {
        this.downloadMsg  = false;
        this.saveComplete = false;
      });
      this.startProgressBar()

    } else {
      this.popupHead = 'Unprocessing'
      this.isDataUpload = 1;
      this.iscompleted = false

      this.saveComplete = true;
      this.percentage   = 0;

      if(this.saveData.length>0){
          this.isProgressStart = true
          this.failedData = []
          var l = this.saveData.length;
          var cLoop = Math.ceil(l/this.chunkLen)
          var p = 100/cLoop
          this.body = '0 out of '+l+" data unprocessed successfully"
          this.successDataCount = 0
          this.processUpload(0,p);
      } else
      this.loader       = false
      this.alertToggle  = false;
    }
  }

  showProgress = false
  progPercentage:any = 0
  isProgressStartA =false
  timeLeft:any = ''
  iscompletedA = false
  startProgressBar(){
    let cnt = 0;
    this.timeLeft = "0 min 0 sec"
    this.aps.setProgress(false);
    this.aps.processPercentage.subscribe(result =>{
      this.showProgress = true
      this.isProgressStartA = true
      this.progPercentage = result.event.percentage
      this.body =  result.event.uploaded_count+" out of "+result.event.employee_count+" employee(s) attendance processed"
      if(result.event.percentage == 100 && cnt == 0){

        this.timeLeft = ""
        this.isProgressStartA = false
        this.iscompletedA =true
        cnt++
        this.checkProgress()

      }
      else{
        this.convertMinSecs(result.event.time_left)
      }
    },
    (error:any)=>{
      this.closeProgress();
      this.notificationService.handleErrorNotification('Something went wrong','Error')

    })
  }

  errorList:any = []
  progress=0
  startProgress = false
  closeProgress(){
    this.aps.setProgress(true);
    this.isProgressStartA=false;
    this.startProgress = false;
  }
  checkProgress(){
    if(this.startProgress){
      this.aps.getAttendanceProcessData().subscribe((res:any) => {

        this.errorList =res.data;
        this.progress = res.data.percentage;
        if(this.aps.PROCESSURL == this.router.url && res.data.percentage==100){
          this.startProgress = false;
          this.errorPopup();

        }
      });
    }
  }
  errorLog = false
  successMsgA = ''
  errorMsgA = ''
  errorPopup(){
    this.clearData()
    this.exportData = []
    if(this.errorList?.error_list.length>0){
      for (let i = 0; i < this.errorList.error_list.length; i++) {
        this.exportData.push({'Employee code':this.errorList.error_list[i]['employee_code'], 'Employee name':this.errorList.error_list[i]['employee_name'], 'Error Description':this.errorList.error_list[i]['error_list'][0]['description']});
      }
      this.errorLog = true;
      this.successMsgA = this.aps.PROCESSCOUNT-this.errorList?.error_list.length+" out of "+this.aps.PROCESSCOUNT+" employee(s) attendance processed successfully"
      this.errorMsgA =this.errorList?.error_list.length+' employee(s) have error data.'
    }
  }

  convertMinSecs(min:any){
    let time = min*60;
    var minutes = Math.floor(time / 60);
    var seconds:any = time - minutes * 60;
    seconds = seconds.toFixed(2)
    this.timeLeft = minutes+" min "+seconds+" sec "
  }
  processUpload(k:any,p:any){
    var l = this.saveData.length;
    var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    var saveDataArray:any = [];
    this.percentage +=(p/4);
    for(var i= k*this.chunkLen; i<x; i++){
      saveDataArray.push(this.saveData[i])
    }
    var nStatus = "Pending"
    this.term = "unprocess"
    // if(this.status=='Pending'){
    //   nStatus = "Completed"
    //   this.term = "Process"
    // } else {
    //   nStatus = "Pending"
    //   this.term = "Unprocess"
    // }
    this.sds.saveAttendanceProcess({'month':this.month, 'year':parseInt(this.year), 'status': nStatus,"data":saveDataArray}).subscribe((res: any) => {
      this.body = x+' out of '+l+" data uploaded successfully"
        this.confirmMsg = false;
        if(res.body.error_list != undefined && res.body.error_list.length != 0){
            res.body.error_list.forEach((i:any, k:any) => {
                this.failedData.push(i)
            });
        }

        this.percentage +=((p/4)*3);
        if(x<l)
            this.processUpload(k+1,p);
        else{
            this.iscompleted = true
            this.isProgressStart = false
            this.successDataCount = this.saveData.length- this.failedData.length;
            if(this.failedData.length == 0){
                this.notificationService.handleSuccessNotification(this.successDataCount+" attendance data "+this.term+"ed successfully.","Success");
            this.clearData()

            } else {
                this.downloadMsg = true;
                this.exportData    = [];
                for (let i = 0; i < this.failedData.length; i++) {
                  this.exportData.push({'Employee code':this.failedData[i]['employee_code'], 'Employee Name':this.failedData[i]['name'],'Error description':this.failedData[i]['error']});
                }
                this.successMsg = this.successDataCount+" out of "+l+" data "+this.term+"ed successfully "
                this.errorMsg = this.failedData.length+' employee(s) have error data'
            }
        }
      },
      (error:any)=>{
        this.isProgressStart = false
        this.notificationService.handleErrorNotification('Something went wrong','Error')
      }
      );

  }
  closeInfo(bool:any){
    this.alertToggle = bool;
    this.loader      = false;

  }
  buList:any = [];
  DepList:any = [];
  DesignationList:any = [];
  BranchList:any = [];
  cityList:any = [];
  gradeList:any = [];
  resetFilter(){
    this.buList = []
    this.DepList = []
    this.DesignationList = []
    this.gradeList = []
    this.BranchList = []
    if(this.status=='Pending')
    this.getProcessList();
    else
    this.historyList()
    this.filter  = false
  }
  submitForm(data:any){
    this.filter  = false
    this.filtercomp.getBUList(this.company);
    this.buList = data.buList;
    this.DepList = data.DepList;
    this.DesignationList = data.DesignationList;
    this.gradeList = data.gradeList;
    this.BranchList = data.BranchList;
    if(this.status=='Pending')
    this.getProcessList();
    else
    this.historyList()
  }
  closeFilter(){
    this.filter  = false
  }


  keyup(field:any,item:any){
      // let index = this.pageSize*(this.page-1)+ i;
      // Added below code to get exact index otherwise on search the index shows the first element on normal table
      const ind = this.historyData.findIndex((data:any) => data?.employee_id === item);
      this.validateText(field,ind)
      this.keyuped = true
  }

    editConfirmation(){
      this.saveUpdatedData = []
      this.editflag = true
      this.saveComplete = false
      this.purpose = "override"

      for(let i=0;i<this.historyData.length;i++) {
        if(this.historyData[i]?.checked == true && (this.historyData[i]?.status=='Completed' || this.historyData[i]?.status=='' || this.historyData[i]?.status==null)){
          this.saveUpdatedData.push({
            "id":this.historyData[i]?.process_id,
            "prasent_days":Number(this.historyData[i]?.prasent_days),
            "weekly_offs":Number(this.historyData[i]?.weekly_offs),
            "leave_paid":Number(this.historyData[i]?.leave_paid),
            "holiday_paid":Number(this.historyData[i]?.holiday_paid),

            "absent_unpaid":Number(this.historyData[i]?.absent_unpaid),
            "leave_unpaid":Number(this.historyData[i]?.leave_unpaid),
            "deduction_paid_days":Number(this.historyData[i]?.deduction_paid_days)
          })
        }
      }
      if(this.saveUpdatedData.length>0){
        this.confirmMsgText = "Click the Save button, If you want to override the attendance details for the selected employees.";
        this.confirmMsg     = true;
      }
      if(this.statusFlag==true)
        this.alertToggle = true;
      }


  editCompletedData(){
    this.isDataUpload = 1;
    this.iscompleted = false
    this.popupHead = 'Processing'
    this.saveComplete = true;
    this.percentage   = 0;
    if(this.saveUpdatedData.length>0){
        this.isProgressStart = true
        this.failedData = []
        var l = this.saveUpdatedData.length;
        var cLoop = Math.ceil(l/this.chunkLen)
        var p = 100/cLoop
        this.body = '0 out of '+l+" data processed successfully"
        this.successDataCount = 0
        this.editprocessUpload(0,p);
    } else
    this.loader       = false
    this.alertToggle  = false;

  }

  editprocessUpload(k:any,p:any){
    var l = this.saveUpdatedData.length;
    var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    var saveDataArray:any = [];
    this.percentage +=(p/4);
    for(var i= k*this.chunkLen; i<x; i++){
      saveDataArray.push(this.saveUpdatedData[i])
    }
    this.sds.updateAttendenceprocess({'month':this.month, 'year':parseInt(this.year),'data':saveDataArray}).subscribe((res: any) => {
      this.body = x+' out of '+l+" data uploaded successfully"
        this.confirmMsg = false;
        if(res.body.error_list != undefined && res.body.error_list.length != 0){
            res.body.error_list.forEach((i:any, k:any) => {
                this.failedData.push(i)
            });
        }
        this.percentage +=((p/4)*3);
        if(x<l)
            this.editprocessUpload(k+1,p);
        else{
          this.iscompleted = true
              this.isProgressStart = false
            this.successDataCount = this.saveUpdatedData.length- this.failedData.length;
            if(this.failedData.length == 0){
                this.notificationService.handleSuccessNotification("The selected employee(s) attendance data has been processed successfully","Success");
                this.clearData()
            } else {
                this.downloadMsg = true;
                this.exportData    = [];
                for (let i = 0; i < this.failedData.length; i++) {
                  this.exportData.push({'Employee code':this.failedData[i]['employee_code'], 'Employee Name':this.failedData[i]['name'],'Error description':this.failedData[i]['error']});
                }
                this.successMsg = this.successDataCount+" out of "+l+" data "+this.term+"ed successfully "
                this.errorMsg = this.failedData.length+' employee(s) have error data'
            }
          }
        this.editconfirmMsg = false;

    });
  }


  validateText(field:any,index:any){
    let msg='Enter a valid data.';
    let keys=['prasent_days','weekly_offs','leave_paid','holiday_paid','absent_unpaid','leave_unpaid','deduction_paid_days']
    let days = this.historyData[index][field]
    // const digitdecimalpattern = /^([0-9]|[1-9][0-9]*)$/.test(days);
    // const digitdecimalpattern = /^[0-9]\d*(\.\d{1,2})?$/.test(days);
    let  digitdecimalpattern:any ='';
    if(field=='holiday_paid')
      digitdecimalpattern =  /^([0-9]|[1-9][0-9]*)$/.test(days);
    else
      digitdecimalpattern = /^[0-9]\d*(\.[5])?$/.test(days);
    if(digitdecimalpattern){
      if(this.historyData[index][field+'_msg']=='Enter a valid data.'){
        this.historyData[index][field+'_msg'] = '';
        this.historyData[index][field+'_invalid'] = false;
      }      
      if(days > Number(this.historyData[index]['total_days'])){
        this.historyData[index][field+'_invalid']       = true;
        this.historyData[index][field+'_msg']           = 'Value entered cannot be greater than calendar days';
      } else{
        if(this.historyData[index]['weekoff_paid'] == true){
          let adddays = Number(this.historyData[index]['prasent_days'])
                      + Number(this.historyData[index]['weekly_offs'])
                      +  Number(this.historyData[index]['leave_paid'] )
                      + Number(this.historyData[index]['holiday_paid'])
                      + Number(this.historyData[index]['absent_unpaid'])
                      + Number(this.historyData[index]['leave_unpaid'])
                      // + Number(this.historyData[index]['deduction_paid_days'])
          if(adddays > Number(this.historyData[index]['total_days'])){
            this.historyData[index][field+'_invalid']       = true;
            this.historyData[index][field+'_msg']           = 'Paid Days cannot be greater than calendar days';
          }
          else{
            for (let i = 0; i < keys.length; i++) {      
               if(this.historyData[index][keys[i]+'_msg'] !=msg){
                  this.historyData[index][keys[i]+'_invalid'] =  false;
                  this.historyData[index][keys[i]+'_msg']     = '';
                }
            }
            
          }
        }
        else{
          let adddays = Number(this.historyData[index]['prasent_days'])
                      + Number(this.historyData[index]['leave_paid'] )
                      + Number(this.historyData[index]['holiday_paid'])
                      + Number(this.historyData[index]['absent_unpaid'])
                      + Number(this.historyData[index]['leave_unpaid'])
                      // + Number(this.historyData[index]['deduction_paid_days'])
          if(adddays > Number(this.historyData[index]['total_days'])){
            this.historyData[index][field+'_invalid'] = true;
            this.historyData[index][field+'_msg']     = 'Paid Days cannot be greater than calendar days';
          }
          else{  
            for (let i = 0; i < keys.length; i++) {             
               if(this.historyData[index][keys[i]+'_msg'] !=msg){
                  this.historyData[index][keys[i]+'_invalid'] =  false;
                  this.historyData[index][keys[i]+'_msg']     = '';
                }
            }     
           
          }
        }
      }

    } 
    else if(!digitdecimalpattern){
        this.historyData[index][field+'_invalid'] = true;
        this.historyData[index][field+'_msg']     = this.messageService.validationDisplay('pattern')
    }
    if(this.historyData[index][field+'_invalid']==false){
      let validArr : any = []
        validArr.push({
          "employee":this.historyData[index]?.employee_code,
          "emp_name":this.historyData[index]?.employee_name,
          "attend_month":this.historyData[index]?.attend_month,
          "attend_year":this.historyData[index]?.attend_year,
          "prasent_days":parseFloat(this.historyData[index]?.prasent_days),
          "weekly_offs":parseFloat(this.historyData[index]?.weekly_offs),
          "leave_paid":parseFloat(this.historyData[index]?.leave_paid),
          "holiday_paid":parseFloat(this.historyData[index]?.holiday_paid),

          "absent_unpaid":parseFloat(this.historyData[index]?.absent_unpaid),
          "leave_unpaid":parseFloat(this.historyData[index]?.leave_unpaid),
          "deduction_paid_days":parseFloat(this.historyData[index]?.deduction_paid_days)
        })
      this.sds.validateAttendanceProcess({'company_id':this.company,'data':validArr}).subscribe((res: any) => {
        this.historyData[index].is_valid = res.body.error_info[0].employee.is_valid;
        this.historyData[index].error = res.body.error_info[0].employee.error;
      })

    }


  }
  checkValidity(){
    let count = 0
    let index = 0;
    this.historyData.forEach((item:any, value:any) => {
      if(item?.checked == true){
        if(this.historyData[index]['prasent_days_invalid'] || this.historyData[index]['weekly_offs_invalid'] || this.historyData[index]['leave_paid_invalid'] || this.historyData[index]['holiday_paid_invalid']){
          count ++
        }

      }
      index++
    })
    return count;
  }

  clearfilter(){
    this.buList = []
    this.DepList = []
    this.DesignationList = []
    this.gradeList = []
    this.BranchList = []
  }

}


