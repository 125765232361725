import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExpenseReceiptService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  getReportDropdown() {
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-report-dropdown/?status_filter=${JSON.stringify(['Draft','Rejected'])}&is_active=true`, this.httpOption)
  }
  categoryListData(type:any,id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-category-dropdown/?expense_type=${type}&ordering=name&is_active=true&employee=${id}`, this.httpOption)
  }
  LocatonListData(id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/city-list/?category=${id}`, this.httpOption)

  }
  getSingleCategoryData(id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/profile-expense-category-view/${id}/`, this.httpOption)
  }
  saveOrUpdateReceipt(id: any, body:any){
    if(id=='')
    return this.http.post(`${this.API_PATH}expense/api/v1/expense-receipt/`, body, this.httpOption)
    else
    return this.http.put(`${this.API_PATH}expense/api/v1/expense-receipt/${id}/`, body, this.httpOption)


  }
  receiptLandingdata(status:any,search:any,filter:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-receipt/?status=${status}&search=${search}&is_active=true&${filter}`, this.httpOption)

  }
  receiptDropdown(status:any,filter:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-receipt-dropdown/?status=${status}&ordering=number&is_active=true&${filter}`, this.httpOption)

  }
  receiptDelete(id: number,body:any) {
    return this.http.patch(`${this.API_PATH}expense/api/v1/expense-receipt/${id}/`,body, this.httpOption)
  }
  getSingleReceipt(id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-receipt/${id}/`, this.httpOption)
  }
  saveBulkReceipt(body:any){
    return this.http.post(`${this.API_PATH}expense/api/v1/expense-receipt-bulkcreate/`, body, this.httpOption)
  }
  getCategoryConfig(id:any,date:any,receipt_id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/validate-expense-category/${id}/?receipt_date=${date}&receipt_id=${receipt_id}`, this.httpOption)
  }
  bulkexpensevalidate(body:any){
    return this.http.post(`${this.API_PATH}expense/api/v1/validate-bulk-expense-receipts/`, body, this.httpOption)
  }

	getCategoryvalidate(id:any,date:any,location:any,receipt_id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/validate-expense-category/${id}/?receipt_date=${date}&location=${location}&receipt_id=${receipt_id}`, this.httpOption)
  }
}
