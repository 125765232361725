import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AssetReportServiceService } from 'src/app/@core/services/asset-report-service.service';
import { AssetSettingsService } from 'src/app/@core/services/asset-settings.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { AssetHistoryReportComponent } from '../../asset-report-configure/asset-history-report/asset-history-report.component';
import { AssetRequestReportComponent } from '../../asset-report-configure/asset-request-report/asset-request-report.component';
import { HardwareInventoryReportComponent } from '../../asset-report-configure/hardware-inventory-report/hardware-inventory-report.component';
import * as moment from 'moment';
import { MatSort } from '@angular/material/sort';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-asset-report-configuration',
  templateUrl: './asset-report-configuration.component.html',
  styleUrls: ['./asset-report-configuration.component.scss']
})
export class AssetReportConfigurationComponent implements OnInit {

  // Variables Initialization
  reportId          : number = 0
  reportName        : string = ''
  formData          : string = ''
  closeControl      : any = ''
  resetFrom         : any = ''
  isLoading         : boolean = false
  noData            : boolean = false
  addColumnFilter   : boolean = false
  viewDetail        : boolean = false
  alertShow         : boolean = true
  reportDownload    : boolean = false
  applyFilterPop    : boolean = false
  resetFilterPop    : boolean = false
  saveFilterModalClicked : boolean = false
  resetFilterModalClicked: boolean = false
  apiData           : any = []
  apiExcelData      : any = []
  displayedColumns  : any = []
  multiData         : any = [];
  applicableData    : any = [];
  yearsList         : any = [];
  calcHeaders       : any = []
  hint_dict         : any = {}
  resDataFilter     : any
  resHeaderFilter   : any
  selectedYear      : any
  applicableCount         = 0
  initialLoaded    : boolean = false
  colRearranged     : boolean = false

  // Store initial state of columns
  initialFormState: any;

  // Sort
  ordering          : String = 'Employee Code'
  direction         : string = 'asc'
  sortProperty      : string = 'Employee Code'
  isSorted          : boolean = false

  // Page
  limit             : number = 20
  offset            : number = 0
  lastPageCount     : number = 0
  page              : number = 1
  pageNumber        : number = 1
  initialFormData   : any = {}

  //Tag Filter Variables
  appliedFilter: any = {};
  tagMultiData: any = [];
  tagMultiCnt: any = 0;

  // Filter Memorize
  filterSubmitMem   : boolean = false
  addedColumns      : boolean = false

  // View Child
  @ViewChild(AssetRequestReportComponent, { static: false }) appLive !: AssetRequestReportComponent;
  @ViewChild(HardwareInventoryReportComponent, { static: false }) appInventory !: HardwareInventoryReportComponent;
  @ViewChild(AssetHistoryReportComponent, { static: false }) history !: AssetHistoryReportComponent;

  // Mat Table
  @ViewChild(MatSort) sort!: MatSort;

  // Form Initialization
  // Side Panel Form
  sidePanelForm     !: FormGroup

  // Filter Save Form
  filterSaveForm = this.fb.group({
    filter: true,
  });

  // Common OU Form
  OuFilterForm = this.fb.group({
    company: [''],
    bu: [''],
    branch: [''],
    designation: [''],
    department: [''],
    employee_list: [''],
    grade: [''],
    employee_type: [''],
    employment_status: [''],
  })

  // Live Location
  liveLocationForm = this.fb.group({
    request_month   : ['this_month',[Validators.required]],
    specific_month  : null,
    specific_year   : null,
    request_status  : ['',[Validators.required]],
    request_type    : ['',[Validators.required]],
    ouFilterForm    : this.OuFilterForm
  })

  // Hardware Inventory
  inventoryForm = this.fb.group({
    asset_category      : [],
    asset_sub_category  : [],
    status              : [],
    assigned_to         : [],
    assigned_by         : [],
    applicable          : [],
    assignees           : [],
    assigned_on         : [],
    specific_month      : null,
    specific_year       : null,
  })

  // History
  historyForm = this.fb.group({
    asset_category      : [null],
    asset_sub_category  : [null],
    // status              : [null],
    assigned_to         : [null],
    assigned_by         : [],
    applicable          : [null],
    assignees           : [null],
    assigned_on         : ['this_month'],
    specific_month      : null,
    specific_year       : null,
    requested_on        : ['this_month'],
    specific_month_req  : null,
    specific_year_req   : null,
    approved_on         : [null],
    specific_month_app  : null,
    specific_year_app   : null,
  })

  constructor(
    private fb       : FormBuilder,
    public route     : ActivatedRoute,
    public appService: AppService,
    private rprtServ : ReportsService,
    private cd       : ChangeDetectorRef,
    public router    : Router,
    private notify   : NotificationService,
    public assetReprt: AssetReportServiceService,
    private assetSetting : AssetSettingsService,
  ) { }

  ngOnInit(): void {
    // ID & Report Name from Params
    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['id'])) {
        this.reportId = params['id'];
      }
      if(params['name']){
        let report = params['name']?.match(/[A-Z][a-z]*|[A-Z]/g);
        this.reportName = report.join(' ');
        if(this.reportName == 'Asset Request'){
          this.sortProperty = 'Employee Code'
          this.ordering = 'Employee Code'
        }else if(this.reportName == 'Asset History'){
          this.sortProperty = 'Employee code'
          this.ordering = 'Employee code'
        }
        this.initialLoaded = true
        this.isLoading = true
        this.getDateFormat().then(() => {
          this.reportInitialSetups();
        });
      }
    })
  }

  ngAfterViewInit(){
    this.yearListFunction()
  }

  // Fn to setup initials for all reports
  reportInitialSetups(){
    this.isLoading = true
    this.apiExcelData = []
    this.appliedFilter = {};
    this.assetReprt.reportAPI(this.urlGenerator()?.url,this.limit,this.offset,this.ordering,this.formData).subscribe((res:any)=>{
      if(res?.data?.length != 0){
        this.noData = false;
        // Formatting dates and fields if needed
        for(let i = 0; i < res?.data?.length; i++){
          res.data[i]['Date of joining']    = this.appService.dateFormatDisplay(res.data[i]['Date of joining']);
          res.data[i]['Request on']         = this.appService.dateFormatDisplay(res.data[i]['Request on']);
          res.data[i]['Approved on']        = res.data[i]['Approved on']!="None"? this.appService.dateFormatDisplay(res.data[i]['Approved on']):'';
          res.data[i]['Assigned on']        = res.data[i]['Assigned on']!="None"?this.appService.dateFormatDisplay(res.data[i]['Assigned on']):'';
          res.data[i]['End of life']        = res.data[i]['End of life']!="None"?this.appService.dateFormatDisplay(res.data[i]['End of life']):'';
          res.data[i]['Last audit date']    = res.data[i]['Last audit date']!="None"?this.appService.dateFormatDisplay(res.data[i]['Last audit date']):'';
          res.data[i]['Manufacturing date'] = res.data[i]['Manufacturing date']!="None"?this.appService.dateFormatDisplay(res.data[i]['Manufacturing date']):'';
          res.data[i]['Return date']        = res.data[i]['Return date']!="None"? this.appService.dateFormatDisplay(res.data[i]['Return date']):'';
          res.data[i]['Invoice date']       = res.data[i]['Invoice date']!="None"? this.appService.dateFormatDisplay(res.data[i]['Invoice date']):'';
          res.data[i]['Warranty expiry']    = res.data[i]['Warranty expiry']!="None"? this.appService.dateFormatDisplay(res.data[i]['Warranty expiry']):'';
          res.data[i]['Asset Code']         = res.data[i]['Asset code'];
          res.data[i]['Asset Name']         = res.data[i]['Asset name'];
          res.data[i]['Asset Category']     = res.data[i]['Asset category'];
          res.data[i]['Requested on']       = res.data[i]['Requested on']!="None"? this.appService.dateFormatDisplay(res.data[i]['Requested on']):'';
        }
        this.apiExcelData = res?.data
        const limitedData = res?.data.slice(0, 20);
        this.limit = 20
        this.apiData = new MatTableDataSource(limitedData);
        this.setDataSourceAttributes();
      }else{
        this.noData = true
      }
      this.settingFormVal(res?.applied_filter)
      this.lastPageCount = res?.count;
      this.resDataFilter = res?.data_filter;
      this.resHeaderFilter = res?.header_filter
      this.appliedFilter = res?.applied_filter;
      if(this.initialLoaded){
        this.assetReprt.setDataFilter(this.resDataFilter)
        this.assetReprt.setHeaderFilter(this.resHeaderFilter)
        this.initialLoaded = false;
      }
      // Columns setup by considering page & sort
      if(!this.isSorted){
        this.setColumnsPanel(res?.check_list,res?.header_list);
      }
      // Saved data setup for filter
      if(Object.keys(this.resDataFilter)?.length != 0){
        this.urlGenerator()?.form.reset(this.resDataFilter)
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.emp_status?.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type?.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
        if (this.appliedFilter?.managed_by) {
          let data = this.appliedFilter?.managed_by
          this.urlGenerator()?.form.get('applicable')?.setValue(data["applicable"])
          if(this.reportName == 'Asset History'){
            this.history.changeApplicable(0)
          }else{
            this.appInventory.changeApplicable(0)
          }
          this.urlGenerator()?.form.get('assignees')?.setValue(data["ids"])
        }
        this.settingFormVal(this.resDataFilter)
        if(this.urlGenerator()?.form.get('assigned_by')?.value == ''){
          this.urlGenerator()?.form.get('assigned_by')?.setValue(null)
        }else if(this.urlGenerator()?.form.get('assigned_to')?.value == ''){
          this.urlGenerator()?.form.get('assigned_to')?.setValue(null)
        }
      }
      // Download Report
      if(this.reportDownload){
        this.commonLogic(this.apiExcelData)
        this.reportDownload = false
      }
      this.initialFormData = this.urlGenerator()?.form.getRawValue();
      this.isLoading = false
    })
  }

  // URL & Form collection
  urlGenerator(){
    let urlForm;
    if(this.reportName == 'Asset Request'){
      urlForm = { url : 'asset_request_report', form : this.liveLocationForm}
    }else if(this.reportName == 'Hardware Inventory Report'){
      urlForm = { url : 'hardware_inventory_report', form : this.inventoryForm}
    }else{
      urlForm = { url : 'asset_history_report', form : this.historyForm}
    }
    return urlForm
  }

  // Side Panel Date & Status
  settingFormVal(data:any){
    const reportConfig = {
      'Asset Request': { controlName: 'request_month', from: 'specific_month', to: 'specific_year', isMandatory : true },
      'Asset History' : [
        { controlName: 'assigned_on', from: 'specific_month', to: 'specific_year', isMandatory : true },
        { controlName: 'approved_on', from: 'specific_month_app', to: 'specific_year_app', isMandatory : false },
        { controlName: 'requested_on', from: 'specific_month_req', to: 'specific_year_req', isMandatory : false },
      ],
      'Hardware Inventory Report' : { controlName: 'assigned_on', from: 'specific_month', to: 'specific_year', isMandatory : false }
    }
    const defaultConfig = { controlName: 'assigned_on', from: 'specific_month', to: 'specific_year', isMandatory : false };
    const config = reportConfig[this.reportName as keyof typeof reportConfig] || defaultConfig;
    if (Array.isArray(config)) {
      config.forEach(cfg => {
        this.updateFormControl(data, this.urlGenerator()?.form, cfg.controlName, cfg.from, cfg.to, cfg.isMandatory);
      });
    } else {
      this.updateFormControl(data, this.urlGenerator()?.form, config.controlName, config.from, config.to, config.isMandatory);
    }
  }

  // For Specific date setup
  updateFormControl(filter: any, formGroup: FormGroup, controlName: string, fromDateControl: string, toDateControl: string, isMandatory: boolean) {
    const dateRange = filter?.[controlName];
    let dateArray = dateRange?.split(',');
    let isHyphen = dateRange?.split('-');
    if (dateRange && dateArray.length === 2) {
      formGroup.patchValue({
        [controlName]: true,
        [fromDateControl]: new Date(dateArray[0]),
        [toDateControl]: new Date(dateArray[1])
      });
    } else if (isHyphen?.length === 2) {
      const atten_month = moment(isHyphen[1], 'M').format('MMMM');
      formGroup.patchValue({
        [controlName]: true,
        [fromDateControl]: atten_month,
        [toDateControl]: isHyphen[0]
      });
    } else {
      formGroup.get(controlName)?.reset(dateRange);
    }
    if (dateRange === undefined && isMandatory) {
      formGroup.get(controlName)?.reset('this_month');
    }
  }

  // Columns Side Panel
  // Reset Columns Button
  resetColumns(){
    const formArray = this.sidePanelForm.get('columnsFormArr') as FormArray;
    formArray.clear();
    if(this.assetReprt.header_filter?.length > 0){
      this.resetFilterPop = true
      this.resetFrom = 'columnReset'
    }else{
      // Rebuild the FormArray with the initial state
      this.initialFormState.forEach((item:any) => {
        formArray.push(this.fb.group({
          name: [item.name],
          checkbox: [item.checkbox]
        }));
      });
      this.headersApply();
    }
  }

  // Apply Columns Button
  headersApply(){
    const checkedItems = this.columnsFormArrForm().controls
    .filter(control => control.get('checkbox')?.value)
    .map(control => control.get('name')?.value);
    const sortedCheckedItems = checkedItems.sort((a, b) => {
      const indexA = this.calcHeaders.indexOf(a);
      const indexB = this.calcHeaders.indexOf(b);
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });
    // Table Columns setup
    this.displayedColumns = sortedCheckedItems
    this.addedColumns = !this.columnsFormArrForm().pristine
  }

  // Set Columns Panel from API
  setColumnsPanel(check_list: any, header_list: any) {
    this.calcHeaders = this.assetReprt.header_filter?.length > 0 ? this.assetReprt.header_filter : header_list
    // Preserving initial values for Reset
    this.initialFormState = check_list.map((item:any) => ({
      name: item,
      checkbox: header_list.includes(item)
    }));
    // Setting form array for columns
    this.sidePanelForm = this.fb.group({
      columnsFormArr: this.fb.array(
        check_list.map((item: any) => this.fb.group({
          name: [item],
          checkbox: [this.calcHeaders.includes(item)]
        }))
      )
    });
    this.headersApply()
  }

  // Get Columns Form Panel (Formarray)
  columnsFormArrForm(): FormArray {
    return this.sidePanelForm.get('columnsFormArr') as FormArray;
  }

  // Atleast One column should be checked
  onCheckboxChange(index: number) {
    const formArray = this.columnsFormArrForm();
    const checkboxControl = formArray.at(index).get('checkbox');
    const checkedItems = formArray.controls.filter(ctrl => ctrl.get('checkbox')?.value);
    if (checkedItems.length === 0) {
      // If it's the last item being unchecked, recheck it
      checkboxControl?.setValue(true, { emitEvent: false });
    }
  }

  // Filter Setup
  // Filter Panel ON and OFF
  panel(value: any) {
    this.viewDetail = value
    this.urlGenerator()?.form.reset(this.initialFormData);
  }

  // Reset Filter Filter
  resetFilterFn(val:any){
    this.page = 1;
    this.pageNumber = 0
    this.offset = 0
    this.resetFilterPop = val
    if (typeof val !== 'boolean') {
      this.reportInitialSetups()
    }else{
      this.resetFrom = 'filterReset'
    }
  }

  // Reset Filter Normal
  reportFnCall(reprt:any){
    this.formData = ''
    this.page = 1;
    this.pageNumber = 0
    this.offset = 0
    if(typeof this.resetFilterPop !== 'boolean' && Object.keys(this.assetReprt.data_filter)?.length == 0){
      this.reportInitialSetups()
      this.filterSubmitMem = false
    }else{
      if(Object.keys(this.assetReprt.data_filter)?.length > 0){
        this.resetFilterPop = true
        this.resetFrom = 'filterReset'
      }
    }
  }

  // Each report filter output
  FilterApiConfig(res:any){
    this.limit = res?.limit;
    this.offset = res?.offset;
    this.formData = res?.filterForm
    this.filterSubmitMem = !this.formData?.split('&').every((data:any)=>{
      const [key, value] = data?.split('=')
      if(key === 'ordering'){
        return true;
      }
      return (value === '[]' || value === '')
    })
    this.resetFilterFn(res)
    this.isSorted = true
    this.viewDetail = false
  }

  // Filter SAVE
  filterMemorization(curr:any){
    let isColumnVal = this.addedColumns || this.assetReprt.header_filter?.length > 0
    let isFilterVal = this.filterSubmitMem || Object.keys(this.assetReprt.data_filter)?.length > 0
    const checkedItems = this.columnsFormArrForm().controls
    .filter(control => control.get('checkbox')?.value)
    .map(control => control.get('name')?.value);
    const sortedCheckedItems = checkedItems.sort((a, b) =>{
      return this.displayedColumns.indexOf(a) - this.displayedColumns.indexOf(b)
    })
    let dataFilter = this.reportDataFunctions[this.reportName]?.() ?? "";
    //dataFilter is not null, undefined, or empty setup
    dataFilter = dataFilter || JSON.stringify({});
    let apiDataFilter;
    if (this.resetFrom === 'filterReset') {
      // If resetFrom is 'filterReset', set to empty object
      apiDataFilter = JSON.stringify({});
    } else if (this.resetFrom === 'columnReset') {
        // If resetFrom is 'columnReset', check data_filter length
        if (Object.keys(this.assetReprt.data_filter)?.length > 0) {
            apiDataFilter = dataFilter;
        } else {
            // If data_filter is empty, set to empty object
            apiDataFilter = JSON.stringify({});
        }
    } else if (isFilterVal === false) {
        // If isFilterVal is false, set to empty object
        apiDataFilter = JSON.stringify({});
    } else {
        // Default case: set to dataFilter
        apiDataFilter = dataFilter;
    }
    let apiHeaderFilter;
    if (this.resetFrom === 'filterReset') {
      // If resetFrom is 'filterreset' and header_filter has items, set to data
      if (this.assetReprt.header_filter?.length > 0) {
        apiHeaderFilter = JSON.stringify(sortedCheckedItems);
      } else {
        // Otherwise, set to empty array
        apiHeaderFilter = JSON.stringify([]);
      }
    } else if (isColumnVal || this.colRearranged) {
      // If not resetting, check if addedColumns or header_filter has items
      apiHeaderFilter = JSON.stringify(sortedCheckedItems);
    } else {
      // Default case: empty array
      apiHeaderFilter = JSON.stringify([]);
    }
    this.rprtServ.filterMemorize({ 'data_filter': apiDataFilter, 'header_filter': apiHeaderFilter, 'is_active': true, 'report': Number(this.reportId) }).subscribe((res: any) => {
      if(curr == 'tag'){
        this.notify.handleSuccessNotification("Updated Successfully","Success")
      }else{
        this.notify.handleSuccessNotification("Created Successfully","Success")
      }
      if(curr == 'reset'){
        if(this.resetFrom == 'filterReset'){
          this.assetReprt.setDataFilter({})
          this.filterSubmitMem = false
          this.isSorted = true
          this.initialLoaded = true
        }else{
          this.assetReprt.setHeaderFilter([])
          this.addedColumns = false
          this.isSorted = false
        }
      }else{
        this.filterSubmitMem = false
        this.addedColumns = false
      }
      this.applyFilterPop = false
      this.colRearranged = false
      this.saveFilterModalClicked = false
      this.resetFrom = ''
      this.page = 1
      this.pageNumber = 0
      this.offset = 0
      this.limit = 20
      this.reportInitialSetups()
      if(curr == 'save'){
        this.router.navigate(['/asset-report']);
      }
    })
  }

  // Save data format from child
  reportDataFunctions: { [key: string]: () => any } = {
    'Asset Request': () => this.appLive.getData(),
    'Hardware Inventory Report': () => this.appInventory.getData(),
    'Asset History': () => this.history.getData(),
  };

   // Popups
  // Filter Mem button
  saveFilterModal(){
    this.saveFilterModalClicked = true;
    if(this.filterSaveForm.get('filter')?.value == true){
      this.filterMemorization('save')
    }else{
      this.filterSubmitMem = false
      this.addedColumns = false
      this.colRearranged = false
      setTimeout(()=>{
        this.router.navigate(['/asset-report']);
        this.cd.detectChanges()
      },0)
    }
  }

  // Reset Saved Filter button
  resetFilterApply(){
    this.viewDetail = false
    this.filterMemorization('reset')
    // Filter reset
    if(this.resetFrom == 'filterReset'){
      this.resetFilterControllers()
    }
    this.resetFilterPop = false
  }

  // Reset from Parent
  resetFilterControllers(): void {
    type ReportAction = () => void;
    const reportActions : { [key: string]: ReportAction }= {
      'Asset Request': () => this.appLive.resetFilterControllers('reset'),
      'Hardware Inventory Report': () => this.appInventory.resetFilterControllers('reset'),
      'Asset History': () => this.history.resetFilterControllers('reset'),
    };
    if (this.reportName in reportActions) {
      reportActions[this.reportName]();
    }
  }

  // Close Button
  confirm(): boolean {
    if(this.filterSubmitMem == true || this.addedColumns == true || this.colRearranged == true){
      this.applyFilterPop = true
      return false
    }else{
      return true
    }
  }

  // Download Excel
  export(){
    this.limit = this.lastPageCount
    this.offset = 0
    this.isSorted = true
    this.reportDownload = true
    this.reportInitialSetups()
  }

  // Download arrangements
  commonLogic(data: any) {
    let column = this.displayedColumns;
    const newArray = [];
    for (const obj of data) {
      const newObject: any = {};
      for (const key of column) {
        if (obj.hasOwnProperty(key)) {
          newObject[key] = obj[key];
        }
      }
      newArray.push(newObject);
    }
    if (data != undefined)
      this.exportExcel(newArray, this.reportName);
    this.cd.detectChanges()
  }

  public exportExcel(jsonData: any[], fileName: string): void {
    let excelHeaders = []
    let templateToExcel :any = []
    if (!this.noData) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
      const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      this.saveExcelFile(excelBuffer, fileName);
    }
    else {
      for (let i = 0; i < this.displayedColumns.length; i++) {
        excelHeaders.push(this.displayedColumns[i])
        templateToExcel = [excelHeaders, []];
      }
      const wss: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(templateToExcel);
      const wbb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wbb, wss, 'Sheet1');
      XLSX.writeFile(wbb, fileName + '.xlsx')
    }
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(data, fileName + '.xlsx');
  }

  // Table COlumn Drop
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    if(this.displayedColumns?.length > 1){
      this.colRearranged = true
      this.calcHeaders = this.displayedColumns
    }
  }

  // Sort
  onSort(val: any) {
    this.direction = (this.sortProperty === val && this.direction === 'desc') ? 'asc' : 'desc';
    this.sortProperty = (this.sortProperty === val) ? this.sortProperty : val;
    this.ordering = `${this.direction === 'desc' ? '-' : ''}${val}`;
    this.formData = this.formData?.replace(/ordering=.*?(?=&|$)/, `ordering=${this.ordering}`);
    this.isSorted = true
    this.reportInitialSetups()
  }

  // Api call with updated Limit
  pageChanged(val: any) {
    this.pageNumber = val - 1
    this.offset = this.rprtServ.calculateOffset(val - 1)
    this.isSorted = true
    this.reportInitialSetups()
  }

  // Tags
  // Show/Hide close icon
  isFilterVisible(reportName: string, filterKey: any): boolean {
    const reportFilters : { [key: string]: string[] } = {
      'Asset Request': ['request_month','request_type','request_status'],
      'Hardware Inventory Report': ['managed_by'],
      'Asset History': ['assigned_on','managed_by'],
    };
    return reportName in reportFilters && !reportFilters[reportName].includes(filterKey);
  }

  // Call child fn for tag close
  handleTagClose(reportName: string, filterKey: any): void {
    const controlMap: { [key: string]: string } = {
      'emp_type': 'employee_type',
      'emp_status': 'employment_status'
    };

    this.closeControl = controlMap[filterKey] || filterKey;
    this.cd.detectChanges()
    type ReportAction = () => void;
    const reportActions : { [key: string]: ReportAction }= {
      'Asset Request': () => this.appLive.applyTagClose1(),
      'Hardware Inventory Report': () => this.appInventory.applyTagClose(),
      'Asset History': () => this.history.applyTagClose(),
    };
    if (reportName in reportActions) {
      reportActions[reportName]();
    }
    if(Object.keys(this.assetReprt.data_filter)?.length > 0){
      this.filterMemorization('tag')
    }
  }

  // Utilities
  // Select ALL option for Multiselect
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  // Sort attribute for response
  setDataSourceAttributes() {
    if (this.apiData != undefined && this.apiData != null) {
      this.apiData.sort = this.sort;
    }
  }

  // More than 1 item in array
  tagMultiDataFunction(data: any) {
    this.tagMultiData = [];
    this.tagMultiCnt = 0;
    this.tagMultiData.push(data[0]);
    if (data?.length == 1) {
      return this.tagMultiData;
    } else {
      this.tagMultiCnt = '+' + JSON.stringify(data?.length - 1);
      return this.tagMultiData;
    }
  }

  // Tage Underscore
  removeUnderscoreAddUpperCase(str: any) {
    var i, frags = str.split('_');
    for (i = 0; i < frags?.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  // Excess data on click of tags
  onPopoverClick(event: Event): void {
    event.stopPropagation();
  }

  // MatDatePicker
  salFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  // DOJ based filter date
  dojFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date()]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  // HTML for date split
  getDateRangeToDisplay(dateSetup: any, key: any): string {
    const dates = dateSetup?.[key];
    return dates
      ? `${this.appService.dateFormatDisplay(dates[0])} - ${this.appService.dateFormatDisplay(dates[1])}`
      : '';
  }

  populateMultiData(key:any){
    this.multiData = this.appliedFilter[key]
    return this.multiData;
  }

  populateApplicableData(){
    this.applicableData = [];
    this.applicableCount  = 0;
    this.applicableData = this.appliedFilter['managed_by']['objects']
    if(this.appliedFilter['managed_by']['ids'].length == 1){
      return this.tagMultiData;
    }else{
      this.applicableCount = this.appliedFilter['managed_by']['ids'].length-1
      return this.applicableData;
    }
  }

  yearListFunction(){
    this.selectedYear = new Date().getFullYear();
    this.assetReprt.yearListDropdown().subscribe((res: any) => {
     if(res?.created_year == this.selectedYear){
        this.yearsList.push(res?.created_year)
      }
      else{
        for(let i=this.selectedYear;i>=res?.created_year;i--){
          this.yearsList.push(i)
        }
      }
    })
  }


  // Date Format
  getDateFormat(): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const dateFormat = this.appService.getdatepickerformat();
        if (dateFormat !== '') {
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  }
}
