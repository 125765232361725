<div class="side-panel" style="--sidepanel-width:43.75rem;" [class.side-pane-active]='holidayRequestFlag === true'>
  <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">

          <h5 class="m-0 text-trim w-50">Holiday Request
            <span *ngIf="from=='behalf'" title="{{requestFor}}">
              {{' Of '+ requestFor}}
            </span>
          </h5>
          <a class="toggle-panel" (click)="closeFunction()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
              <div class="d-flex mb-16">
                  <div class="me-auto">
                      <p class="text-uppercase fw-500 mb-0">FLOATING HOLIDAYS</p>
                      <span class="fs-12">Employees can choose from any of the configured floating holidays that are mentioned below depending on their applicability.</span>
                  </div>
                  <div class="d-flex justify-content-end  gap-16">
                      <div class=" " style="width:14.8125rem;">
                          <div class="icon-left ">
                            <app-searchbar [searchlist]='holidatSearchData' [searchplaceholder]='holidayPlaceHoldaer' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchrequest($event)"></app-searchbar>
                        </div>
                      </div>
                      <div ngbDropdown class="d-inline-block">
                        <a class="btn-square btn btn-secondary" href="javascript:void(0)" placement="bottom-left"
                            ngbDropdownToggle>
                            <i class="icon-filter-left fs-13"></i>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu-end">
                            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }" (click)="statusBtn='All';selectSorting('')">All</button>
                            <button ngbDropdownItem [class]="{'active':statusBtn=='Month (Ascending)' }" (click)="statusBtn='Month (Ascending)';selectSorting('holiday_date')">Month (Ascending)</button>
                            <button ngbDropdownItem [class]="{'active':statusBtn=='Month (Descending)' }" (click)="statusBtn='Month (Descending)';selectSorting('-holiday_date')">Month (Descending)</button>
                        </div>
                    </div>

                  </div>
              </div>
              <!--No data found-->
              <div class="row pt-5 flex-center text-center" *ngIf="((nodata || holidayFloatData?.length == 0) && !loader);else all_data">
                <div class="col-12  ">
                  <img class="img-fluid mx-auto" src="/assets/images/bg/15 Startup launch.png" style="width: 438px" />
                  <p class="text-uppercase text-light-400 fw-700 fs-14">
                      NO FLOATING HOLIDAY ADDED YET
                  </p>
                </div>
              </div>
              <!--End-->
              <app-c1-loader *ngIf="loader"></app-c1-loader>
              <ng-template #all_data>
              <div class="row row-16" *ngIf="!loader">
                <div class="col-12" *ngIf="alertShow">
                <div  role="alert" class="alert alert-warning show">
                  <p class="mb-0 fs-12
                  ">You can apply maximum {{max_limit}} floating holiday requests</p>
                  <div class="btn-close pointer" (click)="alertShow=false"></div>
                </div>
              </div>
                  <div class="col-12" *ngFor="let holiday of holidayFloatData,let i = index">
                    <div class="card card-c5  text-light-500">
                        <!-- <div class="card-body bg{{i%5+15}} flex-center flex-column sq-72 p-0"> -->
                          <div class="card-body {{holiday?.color_code}} flex-center flex-column sq-72 p-0">
                            <div class="fw-500 lh-28 fs-24">{{holiday?.holiday_date | date : "d"}}</div>
                            <div class="fs-14 text-uppercase">{{holiday?.holiday_date | date : " MMM"}}</div>
                        </div>
                        <div class="card-body  align-items-center d-flex py-0">
                            <div class="fs-14 fw-500  me-auto text-trim w-30ch ">{{holiday?.holiday_name}} {{holiday?.day_name != null ? 'day '+ +holiday?.day_name : ''}} </div>
                            <!-- <div class="fs-14 fw-500  me-auto ">{{holiday?.name}}</div> -->

                            <ng-container *ngIf="holiday?.is_applied">
                               <div class="fw-500 fs-14 flex-center ms-8 btn-sm text-success">Request sent</div>
                            </ng-container>
                            <ng-container *ngIf="!holiday?.is_applied">
                              <button class="ms-8 btn btn-primary btn-sm text-uppercase" [disabled]="saveClicked" (click)="onSubmit(holiday?.id,holiday?.day_name,holiday?.holiday_date)">Request</button>
                            </ng-container>
                        </div>
                    </div>
                </div>
              </div>
            </ng-template>
          </div>
      </div>
  </div>
</div>

<!-- Alert for investment declaration start -->
<ng-container *ngIf="alertToggle == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="investalertMsg"></app-info-popup>
</ng-container>
<!--End-->
