import { Component, OnInit } from '@angular/core';
import { LivelocationService } from 'src/app/@core/services/livelocation.service';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { AppService } from 'src/app/app.global';
import { AuthService } from 'src/app/@core/auth/auth.service';
@Component({
  selector: 'app-live-location',
  templateUrl: './live-location.component.html',
  styleUrls: ['./live-location.component.scss']
})
export class LiveLocationComponent implements OnInit {
  loader = true;
  dateRange!: moment.Moment;
  disabled = false;
  geolocationlist: any = [];
  start: FormControl = new FormControl(new Date());
  end: FormControl = new FormControl(new Date());
  nodata = false;
  status = "mapView";
  styles: any = [{
    "elementType": "labels.icon",
      "stylers": [
      {
          "visibility": "off"
      }
      ]
  },
  {
      "elementType": "labels.text.fill",
      "stylers": [
      {
          "color": "#0c0c0c"
      }
      ]
  }]
  openedWindow:any
  clickedDate:any
  clickedEmployee:any
  viewdata: any = [];
  viewempmap = false;
  childloader = false;
  badges:any = {"Stationary" :'badge-warning',
    "Mobile" :'badge-success',
    "Check-out":'badge-danger',
    "Check-in":'badge-primary'
  }
  page = 1;
  pageSize: any = 20;
  constructor(private livelocationService: LivelocationService,private mapsAPILoader: MapsAPILoader,public appService: AppService,private authService:AuthService) { }

  ngOnInit(): void {
    this.showDataItems();
  }
  showDataItems() {
    this.loader = true;
    this.livelocationService.getMylocationList( moment(this.start.value).format('YYYY-MM-DD'),moment(this.end.value).format('YYYY-MM-DD')).subscribe((res: any) => {
    this.listdata(res);
    })  ​
  }    
  listdata(res: any) {
    this.geolocationlist = [];

    if (res.body.length == 0) {
      this.nodata = true;
    } else {
      this.nodata = false;
    }
    for (let i = 0; i < res.body.length; ++i) {
        if (res.body[i]['location'] != '') {
            this.mapsAPILoader.load().then(() => {
                res.body[i]['longitude'] = parseFloat(res.body[i]['longitude'])
                res.body[i]['latitude'] = parseFloat(res.body[i]['latitude'])
                res.body[i]['label'] = this.appService.getFirstChar(res.body[i]['employee_name'], 2);
                this.geolocationlist.push(res.body[i]);
            });
        }
    }
    this.loader = false;
  }
  changeDate(){
    if(this.start.value && this.end.value)
      this.showDataItems()
  }
  mapView(){
    this.start = new FormControl(new Date());
    this.end = new FormControl(new Date());
    this.changeDate()
  }
  
  showInfo(p: any) {
    this.openedWindow = p;
  }
​
  hideInfo(p: any) {
    this.openedWindow = -1;
  }

​  openWindow(id: any) {
    if (this.isInfoWindowOpen(id))
      this.openedWindow = -1;
    else
      this.openedWindow = id; // alternative: push to array of numbers
  }
  
  isInfoWindowOpen(id: any) {
    return this.openedWindow == id; // alternative: check if id is in array
  }
  
  viewemployee(locData: any,time:any) {
    this.livelocationService.mybreadcrumbData(moment(time).format('YYYY-MM-DD'),this.authService.isEmployeeId).subscribe((res: any) => {
      if (res.body?.data?.tracking_datas) {
        this.openedWindow = -1;
        this.viewdata = res.body.data;
        let lastkey = res.body?.data?.tracking_datas?.length - 1
        ​this.clickedDate = moment(time).format('YYYY-MM-DD')
        this.viewempmap = true;
        this.clickedEmployee = locData
        this.childloader = false;
      }
    })
  }
  clickDateSlider(data:any){
    this.childloader = true;
    this.viewemployee(data.locData,data.time)
  }
}
