import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EmployeeDirectoryService {

  constructor(
    private http: HttpClient,
    public router: Router) { }

    API_PATH: string = environment.apiPath;
    httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    directoryPermissionlist(){
      return this.http.get(`${this.API_PATH}general_settings/api/v1/directory-permision/?section=snapshot`, this.httpOption)
    }

    directoryPermissionUpdate(id: number,body:any){
      return this.http.put(`${this.API_PATH}general_settings/api/v1/directory-permision-update/${id}/`,body, this.httpOption)

    }

    employeeDirectorylist(limit:any,offset:any){
      return this.http.get(`${this.API_PATH}general_settings/api/v1/directory-employee-list/?is_active=true&limit=${limit}&offset=${offset}&ordering=user__first_name&employment_status__in=Probation,Confirmed,Resigned`, this.httpOption)

    }

    employeeDirectoryview(id: number){
      return this.http.get(`${this.API_PATH}general_settings/api/v1/directory-employee-detail/${id}`, this.httpOption)
    }

    employeeDirectorysearch(id:number){
      return this.http.get(`${this.API_PATH}general_settings/api/v1/directory-employee-list/?emp_id=${id}`, this.httpOption)
    }

    employeegetfilterdata(limit:any,offset:any,search:any,filterparam:any) {
      return this.http.get(`${this.API_PATH}general_settings/api/v1/directory-employee-list/?limit=${limit}&is_active=true&offset=${offset}&search=${search}&${filterparam}`, this.httpOption)
  }

    employeeDirectoryfilter(data:any){
      return  this.http.get(`${this.API_PATH}general_settings/api/v1/directory-employee-list/?${data}`, this.httpOption);
    }
    getTabsPermissionList(){
      return this.http.get(`${this.API_PATH}general_settings/api/v1/employeetable-permision`, this.httpOption)
    }
    updateTabsPermission(id:any,data:any){
      return this.http.put(`${this.API_PATH}general_settings/api/v1/employeetable-permision-update/${id}/`,data, this.httpOption)

    }
    getFieldsPermissionList(section:any){
      return this.http.get(`${this.API_PATH}general_settings/api/v1/employeefield-permision/?section=${section}`, this.httpOption)
    }
    updateFieldsPermission(id:any,data:any){
      return this.http.put(`${this.API_PATH}general_settings/api/v1/employeefield-permision-update/${id}/`,data, this.httpOption)

    }





}
