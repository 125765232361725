<div class=" config-layout">
  <header class=" config-header">

    <h5 class="m-0 text-trim w-50">On duty request
      <span *ngIf="from=='behalf'" title="{{requestFor}}">
        {{' Of '+ requestFor}}
      </span>
    </h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class=" config-sidebar ">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="steps gap-24" (navChange)="onNavChange($event)">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step" [class]="{'success': validateOne()}">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Select Date</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-od-select-date #odChild [formGrp]="datesForm" [data]="assignForm"
            (submitmethod)="submitOne()" [empId]="empId"></app-od-select-date>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step"
          [class]="{'success': ((checkValid() && assignForm.value.dutyRequest.length && datesForm.length && !this.invalidDates())|| assignForm['controls'].dutyRequest.disabled)}">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Edit Details</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-od-edit-details [formGrp]="assignForm" [data]="datesForm" (editDetails)="editDetails($event)"
            (submitmethod)="submitFinal()"></app-od-edit-details>
        </ng-template>
      </li>

    </ul>
  </aside>
  <main class=" config-container">
    <div [ngbNavOutlet]="nav"></div>
  </main>
</div>
