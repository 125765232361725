<div class=" config-layout">
    <header class=" config-header">
        <h5 class="m-0">View map </h5>
        <button class="btn-reset text-light-600" (click)="closePanel()"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <aside class=" config-sidebar sidebar-2 p-0">
        <div class="text-uppercase fs-12 fw-500 px-24 py-16">
            LIVE TRACK DETAILS
        </div>
        <ul class="steps-2">
            <ng-container *ngFor="let item of data,let ind = index">
                <li class="nav-item">
                    <a class="vstack gap-4 nav-link active" (click)="openWindow()">
                        <p class="mb-0 lh-base fs-14 text-uppercase text-light-600">
                            {{item.created_at_formatted|date:this.appservice.gettimeformat()}}</p>
                        <p class="mb-0 lh-base fs-10 text-light-500">{{item.latitude}}, {{item.longitude}}</p>
                        <!-- <p class="mb-0 lh-base fs-10 text-light-500">{{item.location_name}}</p> -->
                    </a>
                </li>
            </ng-container>
        </ul>
    </aside>
    <main class=" config-container">
        <!-- <div [ngbNavOutlet]="nav"></div> -->
        <div class="container-fluid p-32 form-container">
            <div class="row row-16">
                <div class="col-12 ">
                    <p class="text-uppercase fw-500 mb-0">LOCATION MAP</p>
                </div>
                <div class="col-12">
                    <div class="card card-c2 radius-8">
                        <div #gmapContainer id="map" class="img-fluid w-100" style="height: 544px;">

                            <agm-map [latitude]="data[0].latitude" [styles]="styles"
                                [longitude]="data[0].longitude" [zoom]="zoom" style="height: 544px;">
                                <ng-container *ngFor="let m of  data; let i = index">
                                    <agm-overlay [latitude]="m.latitude" [longitude]="m.longitude"
                                        (markerClick)="openWindow()" [title]="'map-circle'">
                                        <div class="avatar-marker">
                                            <div *ngIf="(data?.empData.profile_image == null ||data?.empData.profile_image=='')"
                                                class="avatar-circle sq-40 {{data?.empData.color_code}}">
                                                {{this.appservice.getFirstChar(data?.empData.first_name +
                                                " " +
                                                data?.empData.last_name,2)}}
                                            </div>
                                            <img *ngIf="(data?.empData.profile_image != null && data?.empData.profile_image!='')"
                                                class="avatar-circle sq-40" src="{{data?.empData.profile_image}}"
                                                alt="">
                                        </div>
                                        <agm-info-window [isOpen]="openedWindow" *ngIf="openedWindow">
                                            <div class=" hstack align-items-start gap-16 overflow-hidden">
                                                <div *ngIf="(data?.empData.profile_image == null ||data?.empData.profile_image=='')"
                                                    class="avatar-circle sq-64  fw-400 fs-18 {{data?.empData.color_code}}">
                                                    {{this.appservice.getFirstChar(data?.empData.first_name +
                                                    " " +
                                                    data?.empData.last_name,2)}}
                                                </div>
                                                <img *ngIf="(data?.empData.profile_image != null && data?.empData.profile_image!='')"
                                                    class="avatar-circle sq-64"
                                                    src="{{data?.empData.profile_image}}" alt="">
                                                <div class="vstack gap-5 " style="max-width: 20rem;">

                                                    <p
                                                        class="fs-12 fw-500 text-light-500 d-flex lh-base mb-0 align-items-baseline">
                                                        <i class="icon-person1 text-accent1 me-8"></i><span
                                                            class="text-trim">{{data?.empData.first_name}}
                                                            {{data?.empData.last_name}}</span>
                                                        <span class="px-1">|</span>
                                                        <span
                                                            class="text-trim">{{data?.empData.employee_code}}</span>
                                                    </p>
                                                    <p
                                                        class="fs-12 fw-500 text-light-500 d-flex lh-base txt-trim-4 mb-0 align-items-baseline">
                                                        <i class="bi bi-geo-alt text-accent1 me-8"></i>{{m.location_name != null && m.location_name != ""?m.location_name:m.latitude+", "+m.longitude}}
                                                    </p>
                                                    
                                                    <p
                                                        class="fs-12 fw-500 text-light-500 d-flex lh-base mb-0 align-items-baseline">
                                                        <i
                                                            class="icon-calendar text-accent1 me-8"></i>{{m.created_at_formatted|date:this.appservice.gettimeformat()}}
                                                    </p>
                                                </div>
                                            </div>
                                        </agm-info-window>
                                    </agm-overlay>
                                </ng-container>                       

                            </agm-map>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="submit-footer">
            <button class="btn btn-primary btn-sm ms-auto" (click)="closePanel()">OK</button>
        </footer>
    </main>
</div>