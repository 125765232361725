import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnDutyService {

  constructor(
    private http: HttpClient
  ) { }

  API_PATH: string = environment.apiPath;
  public httpOption : any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  observe: 'response'
  }

  // Step 1 Data
  getDateInfo(id:number,month:number,year:number){
    return this.http.get(`${this.API_PATH}request/api/v1/onduty-calender/${id}/?year=${year}&month=${month}`,this.httpOption)
  }

  checkEligibility(id:number){
    return this.http.get(`${this.API_PATH}request/api/v1/onduty-check-eligibility/${id}/`,this.httpOption)
  }

  // Step 2 Create
  sendRequest(body:any){
    return this.http.post(`${this.API_PATH}request/api/v1/onduty-request-create/`,body,this.httpOption)
  }

  // View Status
  viewStatusData(empId:number,reqst_id:number){
    return this.http.get(`${this.API_PATH}request/api/v1/request-view-status/?emp_req_id=${empId}&request_section=On Duty&specific_id=${reqst_id}`,this.httpOption)
  }
}
