import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormBuilder, Validators } from '@angular/forms';
import { RegularizationRequestService } from 'src/app/@core/services/regularization-request.service';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
@Component({
  selector: 'app-attendance-regularization-inbox',
  templateUrl: './attendance-regularization-inbox.component.html',
  styleUrls: ['./attendance-regularization-inbox.component.scss']
})
export class AttendanceRegularizationInboxComponent implements OnInit {
  showTrend = false;
  viewDetails = false;
  viewData:any = [];
  pageSize = 20;
  page = 1
  cancelReq = false
  cancelId:any
  sendReminder:any
  @Input() requestitem: any;
  @Input() from: any;
  @Input()cancelStatus:any
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();
  @Output() cancelEmitter: EventEmitter<boolean> =  new EventEmitter();

  constructor(public messageService : MessageService,public appService: AppService ,private investmentService:InvestmentDeclerationService,private inboxservice: InboxService,private fb: FormBuilder, private regularizationServ : RegularizationRequestService,public datePipe:DatePipe,private notificationService:NotificationService,
    private activityService:ManageActivityService
  ) { }

  xAxisLabel:any = [];
  yAxisLabel: string = 'Days';
  graphLoader             = false;
  year:any=false;
  month:any=false;
  alertShow = 'show';
  today =  this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()

ngOnChanges(): void{
  this.page=1
  this.loader_regularization = true
  if(this.requestitem?.request_data?.is_cancel)
    this.viewAttendanceRegularization(this.requestitem?.request_data?.id)
  else
    this.loader_regularization = false
  this.yearListCreate();

}
  ngOnInit(): void {
    this.sendReminder = this.from=='behalf'?true:false
  }
  billLoader                      = false;
  viewstatusPanel                 = false;
  viewStatusData:any              = [];
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;


  // For year dropdown in regularization request trend

  currentYear =  new Date().getFullYear();

  selectedYearDisp = String(this.currentYear)
  selectedYear = this.currentYear
  yearList: any = [];
  empId : any;
  multi: any = [];


  /********* Attendance Regularization View ****************/
  regularizationData         : any
  loader_regularization      : boolean = false
  regualarizationID          : any
  viewAttendanceRegularization(req_id:any){

    this.loader_regularization = true
    if(this.from=='behalf'){

      this.regularizationData = this.requestitem['regularizationData']
      this.regualarizationID  = req_id
      this.loader_regularization = false
    }
    else{

      this.inboxservice.getRequestDetails('Attendance Regularization',req_id).subscribe((res:any)=>{
        this.setData(res,req_id)
      })
    }
  }
  setData(res:any,req_id:any){
    this.regularizationData = res.data.requests
    this.regualarizationID  = req_id
    this.loader_regularization = false
  }
  requestViewStatus(){

    this.viewstatusPanel  = true
    this.billLoader       = true;

     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.billLoader         = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
  }

  checkall(event:any){
    var newList = this.requestitem?.requests
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList?.slice(begin,end);
    subArray?.forEach((item:any, key:any) => {
      if(item.status != 'Cancelled' && item?.status != 'Invalid' )
        item.checked = event.target.checked
    })
  }

  checkAllSelected(){
    var newList = this.requestitem?.requests
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList?.slice(begin,end);
    if(subArray?.length){
      return subArray.every((item: { checked: any; }) => {
        return item.checked;
      });
    } else {
      return false;
    }
  }
  countSelected(){
    var delArray:any = [];
    this.requestitem?.requests?.forEach((item:any, value:any) => {
      if(item?.checked == true && item?.status == 'Pending')
        delArray.push(value)
    });
    return delArray.length
  }

  approveReject(val:any){
    if(this.countSelected() == 0)
      return;
    this.requestitem?.requests?.forEach((item:any, value:any) => {
      if(item?.checked == true && item?.status == 'Pending'){
        this.inboxservice.updateOndutyItem(item?.id,`{"status":"${val}"}`).subscribe((res:any)=>{
          this.requestitem.requests[value].status = val;
        });
      }

    });
  }
  refreshInbox(event: boolean){
    if(event){
      this.successEmitter.emit(event);
    }
  }

  form = this.fb.group({
    read_status: [true,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })

  get f() {return  this.form.controls; }



  yearListCreate(){
    var i:number;
    for(i = this.currentYear; i >= 2020 ; i--){
      this.yearList.push({k:String(i)})
    }
  }
  getRegularizationTrendDetails(event:any){

    this.selectedYearDisp = String(event)
    this.empId =  this.from=='behalf'?this.requestitem?.request_data.employee:this.requestitem?.from_person?.id

    this.graphLoader = true;
    if(this.empId != null && this.empId != '' && this.empId != undefined){
    this.inboxservice.getRegularizationTrend(event,this.empId).subscribe((res:any)=>{
        if(res){
          this.prepareTrendData(res.data)
        }
       })
      }
    }
    prepareTrendData(data:any){

      let approved:any         = [];
      let pending:any          = [];
      let rejected:any         = [];
      let cancelled:any        = [];
      this.xAxisLabel = [];

      this.multi           = [];
      data.forEach((item:any) => {
        this.xAxisLabel?.push(item?.name)
        item?.series.forEach((item1:any) => {
            if(item1?.name == "Approved"){
              approved.push(item1?.value)
            }else if(item1?.name == "Pending"){
              pending.push(item1?.value)
            }else if(item1?.name == "Cancelled"){
              cancelled.push(item1?.value)
            }else{
              rejected.push(item1?.value)
            }
        });
      });
      this.multi.push({label: "Accepted", data: approved, backgroundColor: '#009722', barThickness: 10 },{label: "Pending", data: pending, backgroundColor: '#FFC107', barThickness: 10 },{label: "Rejected", data: rejected, backgroundColor: '#DC3545', barThickness: 10},{label: "Cancelled", data: cancelled, backgroundColor: '#7d92a1', barThickness: 10 })
      this.graphLoader = false;

    }

    performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
      let status_value;
      let dataArray: any = [];

      if (action == 3) {
        if (type == 1) {
          status_value = "Approved"
        } else {
          status_value = "Rejected"
        }
        dataArray = {
          "approved_status": status_value,
          "comment": comment_value,

        };
      } else if (action == 1) {
        if (read_status == false) {
          read_status = true;
          dataArray = {
            "read_status": read_status,
          };
        }
      } else if (action == 2) {
        if (star == false) {
          star = true;
        } else {
          star = false;
        }
        dataArray = {
          "star": star,
        };
      }
      if (dataArray.length != 0) {
        this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
        })
      }
    }

    confirmCancel(status:any){
      var status: any;

      if(status=='Approved'){
        this.form.controls['approved_status'].setValue('Approved');
      }else{
        this.form.controls['approved_status'].setValue('Rejected');
      }

      this.form.controls['approved_status'].setValue(status);
      if(this.from=='behalf'){
        let data                = this.form.value
        data['request_id']      = this.requestitem.request_data['id']
        this.successEmitter.emit(data);
        this.form.get('comment')?.reset('')

      }
      else{

        this.inboxservice.updateInboxItem(this.requestitem?.id,this.form.value).subscribe((res)=>{
          if(res){
            this.successEmitter.emit(true);
          }
        })
      }



    }

  cancelRequest(cancelStatus:any,ot:any,comp:any,arrear:any){

    if(cancelStatus){
      this.notificationService.handleErrorNotification("Your previous cancellation request is already in progress.","Error");
    }else if(!arrear){
      this.notificationService.handleErrorNotification("Attendance regularization request cannot be cancelled prior to the arrear month as per the policy.","Error");
    }else if(ot){
      this.notificationService.handleErrorNotification("OT request is already approved.","Error");
    }else if(comp){
      this.notificationService.handleErrorNotification("Comp off request is already approved.","Error");
    }else{

      this.cancelReq = true;

    }
  }
  cancelFinalRequest(data:any){
    this.cancelReq    = false
    this.viewDetails  = false
    this.cancelEmitter.emit(data);

  }
  close(data:any){
    this.cancelReq = false
  }
}

