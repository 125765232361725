import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators} from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';

@Component({
  selector: 'app-advance-dispense-inbox',
  templateUrl: './advance-dispense-inbox.component.html',
  styleUrls: ['./advance-dispense-inbox.component.scss']
})
export class AdvanceDispenseInboxComponent implements OnInit {

  editAmount = false;
  @Input() from:any;
  @Input() requestitem:any;
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();

  billLoader                      = false;
  viewstatusPanel                 = false;
  viewStatusData:any              = [];
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;
  sendReminder:any
  constructor( private inboxservice: InboxService,private investmentService:InvestmentDeclerationService,private fb: FormBuilder,public messageService : MessageService,public appService: AppService) { }

  ngOnInit(): void {
    this.sendReminder = this.from=='behalf'?true:false
  }
  performAction(action:any,type:any=0,id:any,comment_value:any='',read_status:any=true,star:any=true){
    let dataArray:any = [];
    if(action == 1){
      if(read_status == false){
        read_status = true;
        dataArray = {
          "read_status": read_status,
       };
      }
    }else if(action == 2){
      if(star == false){
        star = true;
      }else{
        star = false;
      }
      dataArray = {
        "star": star,
     };
    }
    if(dataArray.length != 0){
      this.inboxservice.updateInboxItem(id,dataArray).subscribe((res: any) =>{
        if(res){
          Object.entries(dataArray).forEach(([key, value]) => {
              if(this.requestitem['id'] == id){
                this.requestitem[key] = value;
              }
          })
        }
      })
    }
  }
  /******** REQUEST VIEW STATUS  ****/
  requestViewStatus(){

    this.viewstatusPanel  = true
    this.billLoader       = true;

     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.billLoader         = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
  }
  form = this.fb.group({
    read_status: [true,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })

  get f() {return  this.form.controls; }
  confirm(status:any){
    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }
    if(this.from =='behalf'){

      let data                = this.form.value
      data['request_id']      = this.requestitem?.request_data?.id
      this.successEmitter.emit(data);
      this.form.get('comment')?.reset('')

    }
    else{

      this.inboxservice.updateInboxItem(this.requestitem.id,this.form.value).subscribe((res)=>{
        if(res){
          this.successEmitter.emit(true);
        }
      })
    }
  }
  modalToggle                     = false;
  currentDocName                  = "";
  currentExtension                = "";
  allowedExtension                = "";
  fileName:any;
  alertMsg:any              = '';
  alertToggle                     = false;
  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appService.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.alertMsg       = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }
  dispenseform = this.fb.group({
    inbox_dispansed_amount: ['',[Validators.required,NumberpatternValidator(), Validators.maxLength(6)]],
  })

  get df() {return  this.dispenseform.controls; }
  saveDispenseAmt(){

    this.inboxservice.dispenseAmtUpdate(this.dispenseform.value,this.requestitem?.requests?.id).subscribe((res)=>{
      this.requestitem.requests.inbox_dispansed_amount = this.dispenseform.controls.inbox_dispansed_amount.value
      this.editAmount = false;
    })
  }
  resetForm(){
    this.editAmount = true;
    if(this.requestitem?.requests?.inbox_dispansed_amount>0)
      this.dispenseform.reset({inbox_dispansed_amount:this.requestitem?.requests?.inbox_dispansed_amount});
    else
      this.dispenseform.reset({inbox_dispansed_amount:this.requestitem?.requests?.expense_advance?.dispansed_amount});
    this.dispenseform.get('inbox_dispansed_amount')?.setValidators([Validators.required,NumberpatternValidator(), Validators.maxLength(6),Validators.max(this.requestitem?.requests?.expense_advance?.dispansed_amount)])
  }

}
