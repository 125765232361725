import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export function PhonenoValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const Phonenopattern = /^((\\+[0-9]{0}-?)|0)?[0-9]{10}$/.test(value);

      return !Phonenopattern ? {pattern:true}: null;
  }
}

export function phoneNumberValidator(phnCode: string, phnNum: string): ValidatorFn {
  return (group: AbstractControl): { [key: string]: any } | null => {
    const phoneCode = group.get(phnCode)?.value;
    const phoneNum = group.get(phnNum)?.value;
    if (phoneNum && phoneCode) {
      const combinedVal = phoneCode + phoneNum;
      if (phoneCode === '1' || phoneCode === '968') {
        if (combinedVal.length !== 11) {
          group.get(phnNum)?.setErrors({ pattern: true });
        }
      } else if (phoneCode === '964') {
        if (combinedVal.length !== 13) {
          group.get(phnNum)?.setErrors({ pattern: true });
        }
      } else {
        if (combinedVal.length !== 12) {
          group.get(phnNum)?.setErrors({ pattern: true });
        }
      }
    }
    return null;
  };
}


