import { Component, OnInit, Inject,ViewChild,Input, ElementRef,ChangeDetectionStrategy } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { EmppShiftMapService } from 'src/app/@core/services/empp-shift-map.service';
import { MessageService } from 'src/app/message.global';
import { RoleService } from 'src/app/@core/services/role.service';
import * as moment from 'moment';
import { ShiftService } from 'src/app/@core/services/shift.service';
import { Subject } from 'rxjs';
import {  delay, switchMap } from 'rxjs/operators';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { DatePipe } from '@angular/common';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-shift-mapping',
  templateUrl: './shift-mapping.component.html',
  styleUrls: ['./shift-mapping.component.scss'],

})
export class ShiftMappingComponent implements OnInit {
  sLoading = false
  limit                = 20
  offset               = 0
  page                 = 1
  viewDetail : boolean = false;
  loader     : boolean = false;
  loaderTable: boolean = false
  filterpanel: boolean = false;
  viewloader : boolean = false;
  init       : boolean = false;
  copyData   : boolean = false;
  noData     : boolean = false;
  resetFil   : boolean = false
  landData   : any     = []
  landDataList: any    = []
  searchListData:any   = []
  shiftDropdown:any    = []
  shiftDayBetween:any  = []
  successCount:any
  displayedColumns:any = ['Employee']
  fromDate   : any
  toDate     : any
  lastPage   : any
  collectionSize:any
  empId      : number  = 0
  permissions: any
  assignControls:any
  fromDay   : any
  toDay     : any
  maxValue  : any
  errorLog  : boolean  = false
  exportData: any      = []
  failedData: any      = []
  datas     : any      = []
  errorMsg  : any      = ''
  successMsg: any      = ''
  filterData : any     = `employment_status=${JSON.stringify(['Probation','Confirmed','Resigned'])}&ordering=user__first_name`
  filterdata:any       = `employment_status=${JSON.stringify(['Probation','Confirmed','Resigned'])}&ordering=user__first_name`
  from                 = 'EmployeeShift';
  searchString:any
  searchData           = new Subject<any>();
  searchHolder         = this.messageServ.searchdisplay('employee')
  datePickDay: any     = moment().format('MMM YYYY');
  pickDate : any       = this.convertToDate('from',new Date());
  weekRange : any
  showSidePanel: boolean = false
  alertDiv  : boolean  = true
  selectedName : any  = 'Employees not mapped'
  selectedList : any  = []
  minDate : any
  currentYr = new Date().getFullYear()+1
  maxDate = new Date(this.currentYr,0,1)
  submitted : boolean = false
  format = 'Array'
  searchDataCalled : boolean = false

  filterForm  = this.fb.group({
    company       : '',
    bu            : '',
    branch        : '',
    department    : '',
    designation   : '',
    grade         : '',
    emp_status    : '',
    emp_type    : '',
    repomanager    : '',
    funmanager    : '',
    sortby    : 'All',
  })

  isMemorized : boolean = false

  shift_from_to_date = this.fb.group({
    from            : [null,[Validators.required]],
    to              : [null,[Validators.required]],
    assign_shift_date : this.fb.array([])
  })

  weekoffDropdown   : any = [
    {name:"Full Day",    value:"Full Day"},
    {name:"First Half",  value:"First Half"},
    {name:"Second Half", value:"Second Half"},
    {name:"None",        value: null },
  ];
  currentDay:any  =  this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()
  defaultClass:any      = {
    'Present'   : '',
    'Absent'    : '',
    'Leave'     : '',
    'Weekoff'   : 'weekoff',
    'Holiday'   : 'holiday'

  }
  @Input() fromfile: boolean = false;
  @Input() reporting_manager_status: boolean = false;
  @ViewChild(SearchbarComponent) child:any;

  get f() { return this.shift_from_to_date.controls }

  controls() : FormArray {
    return this.shift_from_to_date.get('assign_shift_date') as FormArray
  }

  constructor(
    public emppService : EmppShiftMapService,
    public fb          : FormBuilder,
    public appService  : AppService,
    public messageServ : MessageService,
    public roleServ    : RoleService,
    public shiftService: ShiftService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public notificationService : NotificationService,
    public empServ     : EmployeeService,
    private datePipe: DatePipe,
    private router:Router
  ) { }

  ngOnInit(): void {
    let inThreeYears = new Date();
    inThreeYears.setFullYear (inThreeYears.getFullYear() + -3 )
    this.minDate = new Date(inThreeYears.getFullYear(), 0, 1);
    this.fromDate = moment(this.pickDate).startOf('week').format('YYYY-MM-DD')
    this.toDate = moment(this.pickDate).endOf('week').format('YYYY-MM-DD')
    let arrFilter: any = []
    let arrList = []
    let arrData = []
    if(this.emppService.FILTERDATA != undefined && this.emppService.FILTERDROP != undefined){
      this.filterData = this.emppService.FILTERDATA
      this.isMemorized = true
      arrData = this.filterData.split("&")
      arrData.forEach((element: any) => {
        arrList = element.split('=')
        arrFilter[arrList[0]] =arrList[1]
      })
      let ordering:any = this.appService.memOrdering()
      this.filterdata = this.emppService.FILTERDROP
      this.filterForm.reset(this.appService.memFilterArray3(arrFilter,ordering));
    }
    if(this.emppService.FILTERFROMDATE !=undefined && this.emppService.FILTERTODATE != undefined){
      this.isMemorized = true
      this.fromDate = this.emppService.FILTERFROMDATE
      this.toDate = this.emppService.FILTERTODATE
      this.datePickDay = moment(this.emppService.FILTERTODATE).format('MMM YYYY')
    }
    this.getLandingScreenData()
     // Formarray intialise
     this.assignControls = <FormArray>this.shift_from_to_date.controls.assign_shift_date
    //  Shift Dropdown
    this.getShiftDropdownAPI()
    this.checkedData()
    // Search & Cancel old api calls to get latest serach results
    this.searchData.pipe(delay(1000),
    switchMap(() => this.emppService.getAllShiftDatas(this.fromDate,this.toDate,this.limit,this.offset,this.searchString,this.reporting_manager_status,this.filterData,this.empId))).subscribe((res:any) =>{
      // Used this var for not overriding the main api call with search data
      this.searchDataCalled = true;
      this.arrangeApiData(res.body)
      this.dateSetup()
    })
    this.getPermission();
  // Date Format
    this.getDateFormat();
  }

  // Date Format
  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appService.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  // Today Date
  isCurrentDate(date:any): boolean {
    const currentDate = moment().format('YYYY-MM-DD');
    return moment(currentDate).isSame(date)
  }

  // Landing Screen
  getLandingScreenData(){
    if(this.searchString == undefined){
      this.searchString = ''
    }
    this.loader =  true

    this.emppService.getAllShiftDatas(this.fromDate,this.toDate,this.limit,this.offset,this.searchString,this.reporting_manager_status,this.filterData,this.empId).subscribe((res:any)=>{
      if(!this.searchDataCalled){
        this.arrangeApiData(res.body)
      }
    })
    this.dateSetup();
  }

  dateSetup(){
    let data = this.getDaysBetweenDates(moment(this.fromDate),moment(this.toDate))
    this.displayedColumns = []
    this.displayedColumns = ['Employee']
    data.forEach((item:any,index:any)=>{
      this.displayedColumns.push((item?.assigndate+' - '+this.getDayMonth(item?.assignday)))
    })
    let length = this.displayedColumns.length
    this.weekRange = `( ${this.displayedColumns[1].slice(6)} - ${this.displayedColumns[length-1].slice(6)} )`
  }

  getShiftDropdownAPI(){
    this.shiftService.shiftDropDownData(true).subscribe((res:any)=>{
      this.shiftDropdown = res
    })
  }

  arrangeApiData(res:any){
    if(res.data.length!=0){
      this.landData = res?.data
      this.lastPage = res?.list_length
      this.selectedList = res?.not_assigned_employees
      for(let i =0;i<this.landData?.length;i++){
        let doj = this.landData[i]['date_of_joined']
        for(let j=0;j<this.landData[i].shift_detail.length;j++){
          let atendanceData = this.landData[i].shift_detail[j];
          if(atendanceData?.status_firstHalf!=null && atendanceData?.status_secondHalf!=null){
            if(atendanceData?.status_firstHalf == atendanceData?.status_secondHalf){
              this.landData[i].shift_detail[j]['attendance_class'] = "status-"+this.defaultClass[atendanceData?.status_firstHalf];
            }
            else{
              this.landData[i].shift_detail[j]['attendance_class'] = "status-"+this.defaultClass[atendanceData?.status_firstHalf]+"-"+this.defaultClass[atendanceData?.status_secondHalf];
            }
          }
          else{
            if(doj>atendanceData?.assignday)
            this.landData[i].shift_detail[j]['attendance_class'] = '';
            else if(atendanceData?.shift?.from_time == 0)
            this.landData[i].shift_detail[j]['attendance_class']="status-shift-unassigned"
            else if(atendanceData?.status_firstHalf == null)
            this.landData[i].shift_detail[j]['attendance_class'] = "status-unknown-"+this.defaultClass[atendanceData?.status_secondHalf];
            else if(atendanceData?.status_secondHalf ==null)
            this.landData[i].shift_detail[j]['attendance_class'] = "status-"+this.defaultClass[atendanceData?.status_firstHalf]+"-unknown";
            else if(atendanceData?.assignday<this.currentDay)
            this.landData[i].shift_detail[j]['attendance_class']="status-absent"
            else
            this.landData[i].shift_detail[j]['attendance_class'] = '';
          }
        }
      }
    }else{
      this.selectedList = []
    }
    this.noData = res.data?.length == 0 ? true : false
    this.loader = false
    this.sLoading = false
  }

  // Individual
  individualCheck(e:any,value:any){
    this.assignControls.controls.filter((item:any,index:any)=> {
      if(e.target.checked == true){
        if(index == value){
          item.get('checkedValue').setValue(true)
        }
      }
      else{
        if(index == value){
          item.get('checkedValue').setValue(false)
        }
      }
    })
    this.checkedData()
  }

  checkedData(){
    let data = this.assignControls.value
    data.forEach((item:any,index:any)=>{
      if(item.shift == null && item.checkedValue == true){
        this.controls().controls[index].get('shift')?.setValidators([Validators.required])
      }else{
        this.controls().controls[index].get('shift')?.clearValidators();
      }
      this.controls().controls[index].get('shift')?.updateValueAndValidity();
    })

    let items = this.getCheckedData()

    if(items.length == 0){
      this.init = true
    }else{
      this.init = false
    }
  }

  // selectedFirst
  selectedFirst(index:any){
    let tbody = document.getElementById('tBody')
    if(tbody!=null){
      let tr = tbody.getElementsByTagName('tr')
      let formVal = this.assignControls.value
      if(formVal.length > 1){
        if(tr[index].id == 'row1'){
          this.copyData = true
        }
      }
    }
  }

  // Copy Data
  copyIt(){
    let formVal = this.assignControls.value
    let firstVal  = formVal[0].shift
    this.copyData = false
    this.controls().controls.forEach((item:any,index:any)=>{
      this.assignControls.controls.at(index).patchValue({
        shift   : firstVal
      })
    })
  }

  // From Date Edit
  fromDateVal(value:any){
    let from  = this.f.from.value
    this.maxValue = moment(from).add(30,'days')
    if(this.f.to.value !== null && moment(this.f.from.value).isSameOrBefore(moment(this.f.to.value)) && moment(this.f.to.value).isSameOrBefore(this.maxValue)){
      this.toDateVal()
    }else{
      this.shift_from_to_date.patchValue({
        to : null
      })
      this.shift_from_to_date?.get('to')?.markAsTouched()
    }
  }

  // To Date Edit
  toDateVal(){
    this.loaderTable = true
    let from = moment(this.convertToDate('from',this.f.from.value)).format('YYYY-MM-DD')
    let to = moment(this.f.to.value).format('YYYY-MM-DD')
    this.shiftDayBetween = []
    this.controls().clear();
    this.emppService.getSearchedData(from,to,this.empId).subscribe((res:any)=>{
      this.shiftDayBetween = res.body.data[0].shift_detail
      this.checkedData()
      this.createArray(this.shiftDayBetween)
      this.loaderTable = false
    })
  }

  // FormArray
  createArray(value:any){
    value.forEach((item:any,index:any)=>{
      this.shiftDayBetween[index]['dateDisplayFormat'] = this.appService.dateFormatDisplay(item?.assignday ? item?.assignday : item?.date)+" ("+(item?.assigndate ? item?.assigndate : item?.day)+")"
      this.controls().push(
        this.fb.group({
          'checkedValue' : [false],
          'assignday'  : [item?.assignday],
          'shift' : [item?.shift?.id ? item.shift.id : item.shift],
          'weekoff_day' : [item?.weekoff_day ? item?.weekoff_day : null]
        })
      )
    })
    this.viewloader = false
  }
  // getChecked Data
  getCheckedData(){
    return this.assignControls.value.filter((item:any)=> item.checkedValue == true)
  }

  // Edit
  editForm(val:any,day:any,id:any){
    this.shiftDayBetween = []
    this.viewloader = true
    this.viewDetail = true
    this.fromDay = this.toDay = day
    this.empId = id
    this.controls().clear()
    val == undefined? '' : val
    this.emppService.getEmpData(val,this.fromfile).subscribe((res:any)=>{
      this.shiftDayBetween.push(res.body)
      this.createArray(this.shiftDayBetween)
    })
    this.shift_from_to_date.reset({
      from : day,
      to   : day,
    })
    this.maxValue = moment(day).add(30,'days')
    // this.fromDateVal('day')
  }

  // Check All
  checkAll(e:any){
    if(e.target.checked){
        this.assignControls.controls.map((value:any) => value.get('checkedValue').setValue(true));
    }else{
        this.assignControls.controls.map((value:any) => value.get('checkedValue').setValue(false));
    }
    this.checkedData()
  }

  // Checked
  allCheck(){
    let list = this.appService.filterIt(this.shift_from_to_date.get('assign_shift_date')?.value,'');
    let slicedArray = list.slice(0,this.shift_from_to_date.get('assign_shift_date')?.value.length)
    if(slicedArray.length){
      return slicedArray.every((item:any)=>{
        return item.checkedValue
      })
    }else{
      return false;
    }
  }

  // Save Button
  saveData(){
    this.emppService.setProcessData(this.router.url,"Update")
    this.submitted = true
    let checkedShift = this.shift_from_to_date.get('assign_shift_date')?.value
    var shiftArr:any[]=[];
    var shiftArrs:any[]=[];
    checkedShift.filter((item:any)=>{
      if(item.checkedValue == true){
        shiftArr.push(item)
      }
    })
      shiftArrs = shiftArr.map((item:any)=>{
      delete item.checkedValue
      item['employee_id'] = this.empId
      item['shift'] = item?.shift
      item['assignday'] = item['assignday']
      return item
    })
    let dataArray = {
      data : shiftArrs
    }
    this.emppService.updateShiftData(dataArray,this.fromfile).subscribe((res:any)=>{
      if(res.status == 200 && res.body.status == 1){
        this.viewDetail = false
        this.offset = 0
        this.empId = 0
        this.resetCheckedValues()
        this.searchDataCalled = false
        this.getLandingScreenData()
        this.checkedData()

      }else{
        this.viewDetail = false
        this.exportData = []
        this.empId = 0
        this.searchDataCalled = false
        this.datas = []
        this.successCount = 0
        this.errorMsg = 'Something went wrong!'
      }
      this.init = true
      this.submitted = false
    })
  }

  resetCheckedValues() {
    this.assignControls.controls.forEach((control: any) => {
      control.patchValue({ checkedValue: false });
    });
  }


  close(){
    this.errorLog = false
    this.offset = 0
    this.getLandingScreenData()
  }
  // Date Picker
  disableDate(){
    return false;
  }

  // Search Results
  searchResults(data:any){
    this.loader = true
    if(data == ''){
      this.searchString = ""
       this.offset = this.empId = 0
       this.page = 1
      this.landDataList = this.landData = []
      this.searchData.next()
    }else{
      this.empId = data
       this.offset = 0
       this.page = 1
       this.searchData.next()
    }
  }

  // Search Emp
  searchEmp(keyWord:any){
    this.sLoading = true
    this.loader = true
    this.offset = this.empId = 0
    this.landDataList = this.landData = []
    this.searchString = keyWord
    if(this.searchString!=""){
      this.page = 1
      this.offset = 0
      this.searchData.next()
    }else{
      this.page = 1
      this.offset = 0
    }
  }

  // Page
  getPageFromService(val:any){
    let oldOffset = (val - 1) * this.limit
    this.offset = oldOffset
    this.searchDataCalled = false
    this.getLandingScreenData()
  }

  // From Date
  fromDates(val:any){
    this.loader = true
    this.fromDate = moment(val.value)?.startOf('week').format('YYYY-MM-DD')
    this.toDate = moment(val.value)?.endOf('week').format('YYYY-MM-DD')
    this.emppService.setFilterDate(this.fromDate,this.toDate)
    let data = this.getDaysBetweenDates(moment(val.value)?.startOf('week'),moment(val.value)?.endOf('week'))
    this.displayedColumns = []
    this.displayedColumns = ['Employee']
    data.forEach((item:any,index:any)=>{
      this.displayedColumns.push((item?.asssigndate?.slice(0,3)+'-'+this.getDayMonth(item?.assignday)))
    })
    this.searchDataCalled = false
    this.searchData.next()
    this.datePickDay = moment(val.value).format('MMM YYYY')
  }

  getDaysBetweenDates = (startDate:any, endDate:any)=> {
    var now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push({
          assigndate : moment(now).format('ddd'),
          assignday :  now.format('YYYY-MM-DD')
        });
        now.add(1, 'days');
    }
    return dates;
  };

  // Get day and Month
  getDayMonth(day:any){
    return new Date(day).toLocaleString('en-us',{month:'short', day:'numeric'})
  }

  // Format
  convertToDate(val:any,value:any){
    var date = new Date(value);
    var d;
    val == 'to' ?  d = date.getDate()+6 :  d = date.getDate()
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    var dateString = (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;
    dateString = dateString.split("-").reverse().join("-")
    return dateString
  }

  // Filter
  resultfilterfn(data:any){
    this.searchDataCalled = false
    this.filterData = data
    this.searchListData = []
    this.emppService.setFilterData(data)
    this.child.clearBar();
  }

  searchDrop(val:any){
    this.filterdata = val
    this.emppService.setFilterDrop(val)
    this.offset = 0
    this.page = 1
    this.child.clearBar()
  }

  resetCalled(e:any){
    this.resetFil = e
    this.filterpanel = false
    this.offset = 0
    this.filterData = ''
  }

  // Close Panel
  closefn(daata:any){
    this.filterpanel = daata;
  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/employee-shift');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  changeWeek(val:any){
    if(val=='left'){
      this.pickDate = new Date(moment(this.pickDate).subtract(7, 'days').format('YYYY-MM-DD'))
    }else{
      this.pickDate = new Date(moment(this.pickDate).add(7, 'days').format('YYYY-MM-DD'))
    }
    this.fromDate = moment(this.pickDate).startOf('week').format('YYYY-MM-DD')
    this.toDate = moment(this.pickDate).endOf('week').format('YYYY-MM-DD')
    this.loader = true
    this.searchDataCalled = false
    this.searchData.next()
    this.emppService.setFilterDate(this.fromDate,this.toDate)
    this.datePickDay = moment(this.pickDate).format('MMM YYYY')
  }

  closePanel(val:any){
    this.showSidePanel = val
  }
}
