import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MyReimbursementService } from 'src/app/@core/services/my-reimbursement.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { NguCarouselConfig } from '@ngu/carousel';
import { RequestService } from 'src/app/@core/services/request.service';
@Component({
  selector: 'app-my-reimbursement',
  templateUrl: './my-reimbursement.component.html',
  styleUrls: ['./my-reimbursement.component.scss']
})

export class MyReimbursementComponent implements OnInit {
  viewDetails = false;

  // options

  year: any = false;
  month: any = false;
  nodata = false;
  // colorScheme: any        = {
  //                             domain: ['#0078CE', '#D8DBF2','#F9F9F9']
  //                           };
  // colorScheme: any = ['#0078CE', '#D8DBF2', '#F9F9F9'];

  colorScheme: any = ['#D8DBF2','#0078CE', '#F9F9F9'];

  onSelect(event: any) {
  }

  permissions: any = [];

  empId = 0;
  tableData: any = [];
  page = 1;
  pageSize = 20;
  searchString: any = "";
  barChartData: any = [];
  daughnutChartData: any = [];
  requestedCategoryData: any = [];
  dropDownData: any = [];
  loader = false;
  empReimburseView = false;
  loader1 = false;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().toLocaleString('en-us', { month: 'long' }).toUpperCase();
  reimburseYear: any
  reimbursementData: any;
  reimbursementID: any;
  selectedCategory: any;
  selectedYear: any;
  currency: any;
  currency1: any;
  chartData: any = [];
  taxLabels: any = [];
  barchart: any = [];
  barChartXAxisLabels: any = [];
  barChartYAxisLabel: any;
  detailsView = false;
  categoryData:any = [];

  public reimbursementTiles: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 2, lg: 3, all: 0 },
    slide: 3,
    speed: 250,
    point: {
      visible: true
    },
    load: 2,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  constructor(
    private myReimbursementService: MyReimbursementService,
    private authService: AuthService,
    public messageService: MessageService,
    private requestService: RequestService,
    public appService: AppService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.getPermission();
    this.empId = this.authService.isEmployeeId;
    this.reimburseYear = new Date().getFullYear();
    this.selectedYear = this.appService.getFinancialYear();
    this.getMyReimbursementData();
    this.getCategoryDropDownData();
    this.getEmployeeCurrency();
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  getPermission() {
    this.permissions = this.appService.getPermissions('');
    if (this.permissions == undefined) {
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  getMyReimbursementData() {

    this.loader = true;

    this.myReimbursementService.getMyReimbursementData({
      "employee": this.empId, "year": Number(this.selectedYear), "category": (this.selectedCategory == 'All categories' ? '' : this.selectedCategory)
    }).subscribe((res: any) => {

      this.daughnutChartData = [];

      if (res.data?.length == 0 || res.data[0]?.category_list?.length == 0) {
        this.nodata = true;
        this.loader = false;
        this.barChartData = res.data[0]?.multi;
        this.daughnutChartData = res.data[0]?.single;
        this.requestedCategoryData = res.data[0]?.category_list;
        this.tableData = [];
      }
      else {
        this.nodata = false;
        this.barChartData = res.data[0]?.multi;
        this.barChartDataConstruction(this.barChartData);
        this.daughnutChartData = res.data[0]?.single;
        this.requestedCategoryData = res.data[0]?.category_list;
        this.getMyReimbursementTableData();
      }

      // Remove INR from api

      this.chartData = [];
      this.taxLabels = ['Claimed', 'Unclaimed', 'Eligible']

      this.daughnutChartData.forEach((item: any) => {
        // if (item.name.slice(0, -8) != "Eligible ") {
          this.chartData.push(item.value)
        // }
      });


      this.changeTotalContent();
      setTimeout(() => {
        this.changeTotalContent();
      }, 1800)

    });
    this.cd.detectChanges();
  }

  public changeTotalContent() {
    if (document.getElementById('pieChart')?.getElementsByClassName('total-label')) {
      var totalLabel = document.getElementById('pieChart')?.getElementsByClassName('total-label') as HTMLCollectionOf<HTMLElement>;
      totalLabel[0].innerHTML = "Eligible"
    }
  }

  addCurrency() {
    if (this.daughnutChartData != null && this.daughnutChartData != undefined) {
      for (let i = 0; i < this.daughnutChartData.length; i++) {
        this.daughnutChartData[i].value = this.daughnutChartData[i].value + ' (' + this.currency + ')';
      }
    }
  }

  getMyReimbursementTableData() {

    this.myReimbursementService.getMyReimbursementTableData({
      "employee": this.empId, "year": Number(this.selectedYear), "category": (this.selectedCategory == 'All categories' ? '' : this.selectedCategory), "search": ''
    }).subscribe((res: any) => {

      this.tableData = res.data;
      this.loader = false;
    });

  }

  getCategoryDropDownData() {

    this.myReimbursementService.reimbursementCategoryDropDown(this.empId, Number(this.selectedYear)).subscribe((res: any) => {
      if (res?.length > 0)
        this.dropDownData = res;

      this.dropDownData.push({
        "name": "All categories",
        "pay_component_id": "All categories"
      })

      this.selectedCategory = this.dropDownData[this.dropDownData?.length - 1]?.name;
    });
  }

  viewrReimbursement(req_obj: any) {
    this.loader1 = true;
    this.empReimburseView = true;
    this.requestService.viewRequest('Reimbursement',req_obj).subscribe((res: any = []) => {
      this.reimbursementData = res.data;
      this.reimbursementID = req_obj;
      this.loader1 = false;
    });
  }

  categoryChange(val: any) {
    this.selectedCategory = val;
    this.getMyReimbursementData();
  }

  yearChange(val: any) {
    this.reimburseYear = this.selectedYear = Number(val);
    if (this.currentMonth == 'JANUARY' || this.currentMonth == 'FEBRUARY' || this.currentMonth == 'MARCH') {
      ++this.reimburseYear
    }
    this.getMyReimbursementData();
  }

  /********* EMPLOYEE CURRENCY FUNCTION ****************/
  getEmployeeCurrency() {
    let userData = this.appService.getEmpData();
    this.currency = userData.currency;
    this.currency1 = userData.currency;
    // this.yAxisLabel1 = 'Amount'+' ('+this.currency+')';
  }

  // numberWithCommas(x:any) {
  //   if(x != null && x!= '' && x != undefined)
  //     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }

  format = (e: any) => {
    let CURRENCY = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: this.currency,
    })
      .format(e)

    return CURRENCY
  }

  // Bar chart
  barChartDataConstruction(data: any) {
    let approved: any = [];
    let pending: any  = [];
    let rejected: any = [];
    let cancelled:any = [];
    this.barChartXAxisLabels = [];
    this.barChartYAxisLabel = 'Amount (' + this.currency1 + ')';
    this.barchart = [];
    data.forEach((item: any) => {
      this.barChartXAxisLabels?.push(item?.name)
      item?.series.forEach((item1: any) => {
        if (item1?.name == "Approved") {
          approved.push(item1?.value)
        }else if(item1?.name == "Pending") {
          pending.push(item1?.value)
        }else if(item1?.name == "Cancelled"){
          cancelled.push(item1?.value)
        }else{
          rejected.push(item1?.value)
        }
      });
    });

    this.barchart.push({ borderSkipped: true, label: "Approved", data: approved, backgroundColor: '#009722', barThickness: 10 }, { borderSkipped: true, label: "Pending", data: pending, backgroundColor: '#FFC107', barThickness: 10 }, { borderSkipped: true, label: "Rejected", data: rejected, backgroundColor: '#DC3545', barThickness: 10 }, {label: "Cancelled", data: cancelled, backgroundColor: '#7d92a1', barThickness: 10 })
  }
  // End

  // Category details view
  vieCategoryDetails(data:any){
    this.categoryData = data;
  }
  // End
}
