import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { MessageService } from 'src/app/message.global';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { AppService } from 'src/app/app.global';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';

@Component({
  selector: 'app-reimbursement-inbox-details',
  templateUrl: './reimbursement-details.component.html',
  styleUrls: ['./reimbursement-details.component.scss']
})
export class ReimbursementInboxDetailsComponent implements OnInit {

  constructor(
    public messageService : MessageService,
    private fb: FormBuilder,
    private inboxservice: InboxService,
    private notificationService: NotificationService,
    public finalSettlementService: FinalSettlementService,
    public appService:AppService,
    private activityervice:ManageActivityService

  ) { }
  @Input() iscancel:any;
  @Input() btndisable:any;
  @Input() reimbursement:any;
  @Input() currency : any
  @Input() archieved_status:any;
  @Input() empId:any;
  @Input() from:any;
  @Input() reqId:any;
  @Input() requestId:any;
  @Input() reqStatus:any;
  @Input() error:any;
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();
  @Output() cancelEmitter: EventEmitter<boolean> =  new EventEmitter();

  loader = true;
  showTrend = false;
  viewDetails = false;
  counter = 0;
  formArray = this.fb.array([]);
  isMasterSel: boolean = false;
  billDetails:any;
  approveComment = ""
  rejectModal = false
  approveModal = false
  page = 1;
  pageSize = 20;
  billCancelStatus:any
  form = this.fb.group({
    read_status: [true,Validators.required],
    star: [false,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })



  ngOnInit(): void {
    setTimeout(()=>{
      if(this.reimbursement != undefined)
        this.loader = false;
    },1000)

  }

  ngOnChanges(){
    this.loader = true;
    setTimeout(()=>{
      if(this.reimbursement != undefined)
        this.loader = false;
    },1000)
  }

  getDay(date: any) {
    const d = moment(date, 'YYYY/MM/DD');
    var day = d.format('DD');
    var month = d.format('MMMM');
    var date:any = day+' '+month;
    return date;
  }


  checkUncheckAll(){
    this.btndisable = false;
      if(this.formArray.length != 0){
        for(let i = 0;i < this.formArray.length;i++){
          this.formArray.removeAt(i);
        }
      }
      if(this.isMasterSel == true){
        this.counter = 0;
        for(let i = 0;i<this.reimbursement.length;i++){
          if(this.reimbursement[i].status != 'Cancelled'){
            this.reimbursement[i]['isSelected'] = true;
            this.formArray.push(new FormControl(this.reimbursement[i]));
            this.counter++;
          }
        }
        this.isMasterSel = true;
      }else{
        for(let i = 0;i < this.reimbursement.length;i++){
          if(this.reimbursement[i].status != 'Cancelled'){
            this.reimbursement[i]['isSelected'] = false;
            this.formArray.removeAt(i);
            // this.counter--;
          }
        }
        this.counter = 0;
        this.isMasterSel = false;
      }
  }

  viewBillDetails(id:any,status:any){
    this.inboxservice.getBillDetails(id).subscribe((res:any)=>{
      if(res){
        this.billDetails = res;
        this.viewDetails = true;
        this.billCancelStatus = status
      }
    })
  }

  onCheckChange(event: any,item:any) {
    this.btndisable = false;
    if(event.target.checked){
      this.formArray.push(new FormControl(item));
      this.counter++;
    }
    else{
      let i: number = 0;
      this.formArray.controls.forEach((ctrl) => {
        if(ctrl.value == item) {
          this.formArray.removeAt(i);
          this.counter--;
          return;
        }
        i++;
      });
    }
  }

  approveReject(val:any){
    this.btndisable = true;
    if(this.formArray.length == 0)
      return;
    if(val == 'Accept'){
      this.action('Approved');
    }else{
      this.action('Rejected');
    }
  }

action(value:any){
  if(this.formArray.length == 0){
    this.notificationService.handleErrorNotification("Please select bills to accept or reject","Select Bills");
    return;
  }

  this.formArray.controls.forEach((ctrl) => {
      if(value == 'Approved'){
        this.updateReimbursement(ctrl.value.id,'Approved');
      }else{
        this.updateReimbursement(ctrl.value.id,'Rejected');
      }
  });
  this.notificationService.handleSuccessNotification("Updated Successfully","Success");

}

updateReimbursement(id: any,val:any){
  if(this.from == 'inbox'){
    this.inboxservice.updateReimbursementItem(id,`{"status":"${val}"}`).subscribe((res:any)=>{
      if(res){
        for(let i = 0;i<this.reimbursement?.length;i++){
          if(this.reimbursement[i].id == id){
              this.reimbursement[i].status = val;
          }
        }
        this.updateCheckBoxes();
      }
    });
  }else if(this.from == 'fnf'){
    this.finalSettlementService.updateFnFReimbursementItem(id,`{"status":"${val}"}`).subscribe((res:any)=>{
      if(res){
        for(let i = 0;i<this.reimbursement.length;i++){
          if(this.reimbursement[i].id == id){
              this.reimbursement[i].status = val;
          }
        }
        this.updateCheckBoxes();
      }
    });
  }
  else if(this.from=='behalf'){
    this.activityervice.updateReimbursementItem(id,`{"status":"${val}"}`).subscribe((res:any)=>{
      if(res){
        for(let i = 0;i<this.reimbursement?.length;i++){
          if(this.reimbursement[i].id == id){
              this.reimbursement[i].status = val;
          }
        }
        this.updateCheckBoxes();
      }
    });
  }

}

  updateCheckBoxes(){
    this.counter = 0;

    this.reimbursement.forEach((ctrl: any) => {
      delete ctrl.isSelected;
      this.formArray.removeAt(0);
    });

    this.isMasterSel = false;
  }

  get f() {return  this.form.controls; }

  confirm(){
    var status: any;
    var flag = true;
    for(let i = 0;i<this.reimbursement.length;i++){
      if(this.reimbursement[i].status == 'Pending'){
        this.notificationService.handleErrorNotification("Bills cannot be submited in pending state.","Pending Bills");
        return;
      }
      if(flag == true && this.reimbursement[i].status == 'Approved'){
        status = 'Approved';
        flag = false;
      }
      if(flag == true && this.reimbursement[i].status == 'Rejected'){
        status = 'Rejected';
      }
    }
    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }

    if(this.form['controls'].comment.valid){
      if(this.from == 'inbox'){
        if(this.iscancel){
          this.inboxservice.updateInboxItem(this.reqId,this.form.value).subscribe((res)=>{
            if(res){
              this.successEmitter.emit(true);
            }
          })
        } else {
          this.inboxservice.updateInboxItem(this.reimbursement[0].inbox,this.form.value).subscribe((res)=>{
            if(res){
              this.successEmitter.emit(true);
            }
          })
        }
      }else if(this.from == 'fnf'){
        this.finalSettlementService.updateInboxItem(this.reqId,this.form.value).subscribe((res)=>{
          if(res){
            this.successEmitter.emit(false);
          }
        })
      }
      else if(this.from=='behalf'){

          let data                = this.form.value
          data['request_id']      = this.requestId
          this.successEmitter.emit(data);


      }

  }
  }



  checkifallCompleted(){
    var returnVal = false;
    for(let i = 0;i<this.reimbursement?.length;i++){
      if(this.reimbursement[i].status == 'Pending'){
        returnVal =  true
      }
    }
    return returnVal;
  }

  confirmCancel(status:any){
    var status: any;
    var flag = true;

    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }

    this.form.controls['approved_status'].setValue(status);
    if(this.from=='behalf'){
      let data                = this.form.value
      data['request_id']      = this.requestId
      this.successEmitter.emit(data);

    }
    else if(this.from == 'inbox'){
      this.inboxservice.updateInboxItem(this.reqId,this.form.value).subscribe((res)=>{
        if(res){
          this.successEmitter.emit(true);
        }
      })
    } else {
      this.finalSettlementService.updateInboxItem(this.reqId,this.form.value).subscribe((res)=>{
        if(res){
          this.successEmitter.emit(true);
        }
      })
    }


  }
  CancelBill(data:any){
    this.viewDetails = false
    this.cancelEmitter.emit(data);

  }
}
