import { Component, Input, OnInit, Output,  EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
import { NotificationService } from 'src/app/@core/services/notification.service';

@Component({
  selector: 'app-view-status',
  templateUrl: './view-status.component.html',
  styleUrls: ['./view-status.component.scss']
})
export class ViewStatusComponent implements OnInit {

  @Input() loader:any;
  @Input() approvalRequired:any;
  @Input() viewStatusData:any;
  @Input() viewStatusLevelCnt:any;
  @Input() viewStatusmaxLevel:any;
  @Input() from:any;
  @Input() requestId:any;
  @Input() sendReminder:any = false
  @Output() closePanel        = new EventEmitter();

  reminderClick = false


  constructor(
    public appservice:AppService,
    private activityService:ManageActivityService,
    private notificationService:NotificationService,

  ) { }

  ngOnInit(): void {
  }

  sendReminderApprover(){

    this.reminderClick = true;
    this.activityService.sendReminder({'request_id':this.requestId}).subscribe((res:any)=>{
      this.notificationService.handleSuccessNotification(res.data,"Success");
      this.closePanel.emit()
      this.reminderClick = false
    })
  }


}
