import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinalSettlementService {

  FILTERDATA:any;
  constructor(
    private http: HttpClient,
    public router: Router,
    public route:ActivatedRoute
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if( !event.url.includes('final-settlement')){
          this.setFilterData(null);
        }
      }
    })

   }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  finalSettlementList(status:any,limit:any,offset:any,search:any,filterparam:any) {
    return this.http.get(`${this.API_PATH}organization/api/v1/resigned-employee-list/?status=${status}&employee_status=[]&limit=${limit}&offset=${offset}&search=${search}&${filterparam}`, this.httpOption)
  }

  getsingleEmployee(id:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/resigned-employee-list/?employee_status=[]&emp_id=${id}`, this.httpOption);
  }

  searchDropDown(){
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-dropdown-list/?is_active=true&ordering=user__first_name&fnf=true`, this.httpOption);
  }
  searchList(status:any,filterparam:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/resigned-employee-dropdown-list/?is_active=true&fnf=true&${filterparam}&status=${status}`, this.httpOption);
  }

  getResignationDetails(emp_id:any,step_name:any,month:any,year:any,inboxId:any){

    if(inboxId != ""){
      return  this.http.get(`${this.API_PATH}payroll/api/v1/inbox-final-settlement/${emp_id}/?step_name=${step_name}&month=${month}&year=${year}`, this.httpOption);
    } else{
      return  this.http.get(`${this.API_PATH}payroll/api/v1/final-settlement/${emp_id}/?step_name=${step_name}&month=${month}&year=${year}`, this.httpOption);
    }
  }

  requestList(limit:any, offset:any, emp_id:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-list/?status=Pending&limit=${limit}&offset=${offset}&employee_id=${emp_id}&show_fnf=true`, this.httpOption)
  }

  createReimbursementRequest(data:any){
    return this.http.post(`${this.API_PATH}request/api/v1/hr-reimbursement-create/`, data, this.httpOption)
  }

  getNoticePay(emp_id:any,notice_recovery:any,month:any,year:any,inboxId:any){
    if(inboxId != ""){
      return  this.http.get(`${this.API_PATH}payroll/api/v1/inbox-final-settlement/${emp_id}/?step_name=notice_pay_and_clearance&notice_paytype=${notice_recovery}&month=${month}&year=${year}`, this.httpOption);
    } else{
      return  this.http.get(`${this.API_PATH}payroll/api/v1/final-settlement/${emp_id}/?step_name=notice_pay_and_clearance&notice_paytype=${notice_recovery}&month=${month}&year=${year}`, this.httpOption);
    }
    
  }

  finalSettlementSave(data:any,inboxId:any){
    if(inboxId != "")
      return this.http.post(`${this.API_PATH}payroll/api/v1/inbox-final-settlement-save/`, data, this.httpOption)
    else
      return this.http.post(`${this.API_PATH}payroll/api/v1/final-settlement-save/`, data, this.httpOption)
  }

  componentsDropdown(emp_id:any){
    return  this.http.get(`${this.API_PATH}general_settings/api/v1/employee-components/?employee=${emp_id}&nature_of_payment=false`, this.httpOption);
  }

  getComponentData(emp_id:any,component_id:any){
    return  this.http.get(`${this.API_PATH}general_settings/api/v1/employee-components/?employee=${emp_id}&component=${component_id}`, this.httpOption);
  }
  getEmpFinalSettelement(emp_id:any,from:any){
    if(from == 'inbox')
      return  this.http.get(`${this.API_PATH}payroll/api/v1/inbox-fnf-settlement-details/${emp_id}`, this.httpOption);
    else
      return  this.http.get(`${this.API_PATH}payroll/api/v1/fnf-settlement-details/${emp_id}`, this.httpOption);

  }

  getInboxData(request_type:any='',starred:any='',archeived_status:any='',search:any='',created_date:any='',offset:any,limit:any){
    const url=`${this.API_PATH}inbox/api/v1/inbox-list/?search=${search}&achieved_status=${archeived_status}&request_data__request_type__name=${request_type}&star=${starred}&created_date__range=${created_date}&offset=${offset}&limit=${limit}`;
    return this.http.get(url, this.httpOption)
  }

  getEmployeeCompany(emp_id:any){
    return  this.http.get(`${this.API_PATH}organization/api/v1/employee-basic/${emp_id}/`, this.httpOption);
  }
  processSave(data:any,inboxId:any){
    if(inboxId != "")
      return this.http.post(`${this.API_PATH}hr/api/v1/inbox-fnf-process-save/`, data, this.httpOption)
    else
      return this.http.post(`${this.API_PATH}hr/api/v1/fnf-process-save/`, data, this.httpOption)
  }

  getProcessDetails(emp_id:any){
    return  this.http.get(`${this.API_PATH}hr/api/v1/fnf-process-details/?employee=${emp_id}`, this.httpOption);
  }
  geInboxtProcessDetails(emp_id:any){
    return  this.http.get(`${this.API_PATH}hr/api/v1/inbox-fnf-process-details/?employee=${emp_id}`, this.httpOption);
  }
  
  sendPayslip(data:any){
    return this.http.post(`${this.API_PATH}hr/api/v1/send-fnf-payslip/`, data, this.httpOption)
  }

  getFnFItDeclarationList(emp_id:any,year:any){
    return  this.http.get(`${this.API_PATH}request/api/v1/fnf-investment_declaration-list/?employee=${emp_id}&year=${year}`, this.httpOption);
  }
  finishProcess(data:any,emp_id:any){
    return this.http.post(`${this.API_PATH}payroll/api/v1/fnf-request-save/${emp_id}/`, data, this.httpOption)
  }

  updateInboxItem(id:number,data:any){
    return this.http.put(`${this.API_PATH}request/api/v1/fnf-employee-requests-update/${id}/`,data, this.httpOption)
  }

  getSingleInboxData(req_type:any,id:any){
    const url=`${this.API_PATH}request/api/v1/hr-request-detail/?request_type=${req_type}&request_id=${id}`;
    return this.http.get(url, this.httpOption)
  }

  updateFnFReimbursementItem(id:number,data:any){
    return this.http.put(`${this.API_PATH}request/api/v1/fnf-reimbursebill-update/${id}/`,data, this.httpOption)
  }

  getUsedLopReversalDays(emp_id:any,lop_reversal_month:any,lop_reversal_year:any){
    return  this.http.get(`${this.API_PATH}attendance/api/v1/lop-reversal-days-used/${emp_id}/?lop_reversal_month=${lop_reversal_month}&lop_reversal_year=${lop_reversal_year}`, this.httpOption);
  }

  getFnFMonthsDropdown(emp_id:any){
    return  this.http.get(`${this.API_PATH}payroll/api/v1/fnf-months-dropdown/${emp_id}/`, this.httpOption);
  }

  getFnFAttendanceDetails(emp_id:any, attendance_month:any, attendance_year:any){
    return  this.http.get(`${this.API_PATH}payroll/api/v1/fnf-attendance-details/${emp_id}/?attendance_month=${attendance_month}&attendance_year=${attendance_year}`, this.httpOption);
  }


  updateFnFRegularizeItem(id:number,data:any){
    return this.http.put(`${this.API_PATH}request/api/v1/fnf-regularizarion-update/${id}/`,data, this.httpOption)
  }

  updateFnFOndutyItem(id:number,data:any){
    return this.http.put(`${this.API_PATH}request/api/v1/fnf-onduty-update/${id}/`,data, this.httpOption)
  }

  saveArrearProcess(body: any) {
    return this.http.post(`${this.API_PATH}attendance/api/v1/arrear-process-unprocess/`, body, this.httpOption)
  }

  arrearProcessOveride(body: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/arrear-process-overide/`, body, this.httpOption)

  }

  saveAttendanceProcess(body: any, inboxId:any){
    if(inboxId != "")
      return this.http.post(`${this.API_PATH}attendance/api/v1/inbox-attendance-process-unprocess/`, body, this.httpOption)
    else
      return this.http.post(`${this.API_PATH}attendance/api/v1/attendance-process-unprocess/`, body, this.httpOption)

  }

  attendanceProcessOveride(body: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/attendance-process-overide/`, body, this.httpOption)

  }

  attendanceList(emp_id:any, attendance_month:any, attendance_year:any){
    return  this.http.get(`${this.API_PATH}attendance/api/v1/attendance-employee-get-fnf/${emp_id}/?month=${attendance_month}&year=${attendance_year}`, this.httpOption);

  }

  overtimeOveride(body: any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/overtime-process-overide/`, body, this.httpOption)

  }

  salaryRevisionArrears(body: any){
    return this.http.post(`${this.API_PATH}general_settings/api/v1/salary-arrears/`, body, this.httpOption)

  }

  LopDataReset(id:number,attendance_month:any, attendance_year:any){
    return this.http.get(`${this.API_PATH}payroll/api/v1/final-settlement-reset/${id}/?month=${attendance_month}&year=${attendance_year}`,this.httpOption)

  }

  //  test start
  assetProductList(empId:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/asset-product-list/?show_fnf=true&employee_list=${empId}`,this.httpOption)
  }

  assetProductUpdate(body: any){
    return this.http.post(`${this.API_PATH}asset/api/v1/asset-product-update/`, body, this.httpOption)
  }
  // test start
  getFnfExpenseReimbursementData(status:any,emplist:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-final-settlement-report-list/?is_automatic_or_manual=true&status=${status}&employee_list=${emplist}`,this.httpOption)
  }

  updateFnFCheckInItem(id:number,data:any){
    return this.http.put(`${this.API_PATH}request/api/v1/fnf-checkin-update/${id}/`,data, this.httpOption)
  }
  // test end
  updateFnfExpenseReimbursementStatus(body: any){
    return this.http.post(`${this.API_PATH}expense/api/v1/expense-hr-reimbursement-status-update/`, body, this.httpOption)
  }

  getFnfExpenseManualReimbursementData(status:any,emplist:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-final-settlement-report-list/?status=${status}&employee_list=${emplist}`,this.httpOption)
  }

  getExpenseReportDetail(id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-report/${id}/`,this.httpOption)
  }


  getDateRangeSlider(company:any,month:any,year:any){
    return this.http.get(`${this.API_PATH}hr/api/v1/date-range-slider-fnf/?company=${company}&month=${month}&year=${year}`,this.httpOption)
  }
  // test end


  setFilterData(val:any){
    this.FILTERDATA = val;
  }

  getempReimbursementData(body: any) {
    return this.http.post(`${this.API_PATH}request/api/v1/hr-reimbursement/`, body, this.httpOption)
  }

}
