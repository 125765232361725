<app-view-loader *ngIf="loader"></app-view-loader>
<div class="row row-16" *ngIf="!loader && requestitem">
  <!-- Snapshot -->
  <div class="col-12 fs-12 fw-600 text-uppercase" *ngIf="from!=='inbox'">EMPLOYEE PROFILE REQUEST</div>
  <ng-container *ngIf="requestitem.category =='profile_image'">
    <div class="col-12">
      <div class="{{from!='inbox'?'card card-c2 p-16':''}}">
        <div class="row row-12">
          <div class="col-12 d-flex align-items-start">
            <div class="fs-12 fw-600 text-uppercase">snapshot {{from!='inbox'?'details':''}} </div>
            <span
              class="badge px-16 ms-auto fs-14 {{currentStaus=='Pending'?'badge-warning':currentStaus=='Accepted'?'badge-success':'badge-danger'}} py-1"
              *ngIf="from!=='inbox'">{{currentStaus}}</span>
          </div>
          <div *ngIf="options[0].value[0][0][1] == null" class="col-12 fs-12 fw-600  mt-16 {{from =='inbox'?'text-accent2 ':'text-uppercase'}}">
            Newly Added Information
          </div>
          <div *ngIf="options[0].value[0][0][1] != null && from !='inbox'" class="col-12 fs-12 fw-600  mt-16 text-uppercase">
            Updated Information
          </div>
          <div class="col-12">
            <div class="card card-c2 p-16 details-card">
              <div class="row row-16">
                <div class="col-12">
                  <span class="title">
                    Profile Picture
                  </span>
                </div>
                <ng-container *ngFor="let option of options">
                  <ng-container *ngFor="let list of option.value">
                    <ng-container *ngFor="let l of list;let j=index;let last = last">
                      <div class="col flex-grow-0" *ngIf="options[0].value[0][0][1] != null">
                        <div class="detail-title">Old Profile </div>
                        <div class="detail-desc"><img class="sq-80 radius-8 object-fit-contain" [src]="l[1]"></div>
                      </div>
                      <div class="col-2 flex-center fs-12 text-light-500"  *ngIf="options[0].value[0][0][1] != null">to</div>
                      <div class="col ">
                        <div class="detail-title">New Profile </div>
                        <div class="detail-desc"><img class="sq-80 radius-8 object-fit-contain" [src]="l[2]"></div>
                      </div>
                      <div class="col-12" *ngIf="!last">
                        <hr />
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="requestitem.category =='snapshot'">
    <div class="col-12">
      <div class="card card-c2 p-16">
        <div class="row row-12">
          <div class="col-12 d-flex align-items-start">
            <div class="title">
              snapshot
            </div>
            <span
              class="badge px-16 ms-auto fs-14 {{currentStaus=='Pending'?'badge-warning':currentStaus=='Accepted'?'badge-success':'badge-danger'}} py-1"
              *ngIf="from!=='inbox'">{{currentStaus}}</span>
          </div>
          <div class="col-12 fs-12 fw-600  mt-16 {{from=='inbox'?'text-accent2 ':'text-uppercase'}}">
            Updated Information
          </div>
          <div class="col-12">
            <div class="card card-c2 p-16 details-card">
              <div class="row row-16">
                <div class="col-12">
                  <span class="title">
                    Basic Information
                  </span>
                </div>
                <ng-container *ngFor="let option of options">
                  <ng-container *ngFor="let list of option.value">
                    <ng-container *ngFor="let l of list;let j=index;let last = last">
                      <div class="col">
                        <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                        <div class="detail-desc">{{(l[1] == null || l[1] == '') ? '-' : l[1]}}</div>
                      </div>
                      <div class="col-1 flex-center fs-12 text-light-500">to</div>
                      <div class="col">
                        <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                        <div class="detail-desc">{{(l[2] == null || l[2] == '') ? '-' : l[2]}}</div>
                      </div>
                      <div class="col-12" *ngIf="!last">
                        <hr />
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Professionals -->
  <ng-container *ngIf="requestitem.category=='professional'">
    <div class="col-12">
      <div class="card card-c2 p-16">
        <div class="row row-12">
          <div class="col-12 d-flex align-items-start">
            <div class="title">
              PROFESSIONALS
            </div>
            <span
              class="badge px-16 ms-auto fs-14 {{currentStaus=='Pending'?'badge-warning':currentStaus=='Accepted'?'badge-success':'badge-danger'}} py-1"
              *ngIf="from!=='inbox'">{{currentStaus}}</span>
          </div>
          <!-- Add new professional-->
          <ng-container *ngIf="requestitem.new_data?.professional">
            <div class="col-12 fs-12 fw-600  mt-16 {{from =='inbox'?'text-accent2 ':'text-uppercase'}}">
              Newly Added Information
            </div>
            <div class="col-12" *ngFor="let item of addedData;let i=index">
              <div class="card card-c2 p-16 details-card">
                <div class="row row-16">
                  <div class="col-12">
                    <span class="title">{{item.previous_company}} (
                      {{formatDate('from_date',item.from_date)}} - {{formatDate('to_date',item.to_date)}} ) </span>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Previous Company</div>
                    <div class="detail-desc">{{item.previous_company}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Time Period</div>
                    <div class="detail-desc">{{formatDate('',item.from_date)}} - {{formatDate('',item.to_date)}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Previous designation</div>
                    <div class="detail-desc">{{item.previous_designation}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Job description</div>
                    <div class="detail-desc">{{item.job_description!=""?item.job_description:'-'}}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- Edit professional-->
          <ng-container *ngIf="requestitem.edit_data?.professional">
            <div class="col-12 fs-12 fw-600  mt-16 {{from=='inbox'?'text-accent2 ':'text-uppercase'}}">
              Updated Information
            </div>
            <div class="col-12">
              <div class="card details-card p-16">
                <div class="row row-16">
                  <ng-container *ngFor="let option of options">
                    <div class="col-12">
                      <span class="title">{{option.key}}</span>
                    </div>
                    <ng-container *ngFor="let list of option.value;let i=index">
                      <ng-container *ngFor="let l of list;let j=index;let last = last">
                        <ng-container *ngIf="l[0]!='job_description'">
                          <div class="col">
                            <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                            <div class="detail-desc">{{formatDate(l[0],l[1])}}</div>
                          </div>
                          <div class="col-1 flex-center fs-12 text-light-500">to</div>
                          <div class="col">
                            <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                            <div class="detail-desc">{{formatDate(l[0],l[2])}}</div>
                          </div>
                          <div class="col-12" *ngIf="!last">
                            <hr />
                          </div>
                        </ng-container>
                        <ng-container *ngIf="l[0] =='job_description'">
                          <div class="col-12">
                            <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                            <div class="detail-desc">
                              {{l[1]}}
                            </div>
                          </div>
                          <div class="col-12 flex-center fs-12 text-light-500">to</div>
                          <div class="col-12">
                            <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                            <div class="detail-desc">
                              {{l[2]}}
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- <ng-container *ngIf="requestitem.category=='professional'">
      <div class="col-12 fs-12 fw-600 text-uppercase">PROFESSIONALS</div>
      <ng-container *ngIf="requestitem.new_data?.professional">
        <div class="col-12" >
        <div class="fs-12 fw-600 text-accent2  ">Newly Added Information</div>
      </div>
      <div class="col-12">
        <div class="card details-card p-16">
            <div class="row row-16">
              <div class="col-12" *ngFor="let item of addedData;let i=index">
                <div class="card details-card p-16">
                  <div class="row row-16">
                  <div class="col-12 "><span class="title">{{item.previous_company}} (
                      {{formatDate('from_date',item.from_date)}} - {{formatDate('to_date',item.to_date)}} ) </span>
                  </div>
                    <div class="col-4">
                      <div class="detail-title">Previous Company</div>
                      <div class="detail-desc">{{item.previous_company}}</div>
                    </div>
                    <div class="col-4">
                      <div class="detail-title">Time Period</div>
                      <div class="detail-desc">{{formatDate('',item.from_date)}} - {{formatDate('',item.to_date)}}</div>
                    </div>
                    <div class="col-4">
                      <div class="detail-title">Previous designation</div>
                      <div class="detail-desc">{{item.previous_designation}}</div>
                    </div>
                    <div class="col-4">
                      <div class="detail-title">Job description</div>
                      <div class="detail-desc">{{item.job_description!=""?item.job_description:'-'}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </div>
      </ng-container>
    <ng-container *ngIf="requestitem.edit_data?.professional">
        <div class="fw-600 fs-12 text-accent2">Updated Information</div>
        <div class="col-12">
          <div class="card details-card p-16">
            <div class="row row-16">
              <ng-container *ngFor="let option of options;let last = last">
                <div class="col-12">
                  <span class="title">{{last}} {{option.key}}</span>
                </div>
                <ng-container *ngFor="let list of option.value;let i=index">
                  <ng-container *ngFor="let l of list;let j=index">
                    <ng-container *ngIf="l[0]!='job_description'">
                      <div class="col">
                        <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                        <div class="detail-desc">{{formatDate(l[0],l[1])}}</div>
                      </div>
                      <div class="col-1 flex-center fs-12 text-light-500">to</div>
                      <div class="col">
                        <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                        <div class="detail-desc">{{formatDate(l[0],l[2])}}</div>
                      </div>
                      <div class="col-12" *ngIf="!last">
                        <hr />
                      </div>
                    </ng-container>
                    <ng-container *ngIf="l[0] =='job_description'">
                      <div class="col-12">
                        <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                        <div class="detail-desc">
                          {{l[1]}}
                        </div>
                      </div>
                      <div class="col-12 flex-center fs-12 text-light-500">to</div>
                      <div class="col-12">
                        <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                        <div class="detail-desc">
                          {{l[2]}}
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
    </ng-container>
  </ng-container> -->
  <!-- Education -->
  <ng-container *ngIf="requestitem.category =='education'">
    <div class="col-12">
      <div class="card card-c2 p-16">
        <div class="row row-12">
          <div class="col-12 d-flex align-items-start">
            <div class="title">
              EDUCATION
            </div>
            <span
              class="badge px-16 ms-auto fs-14 {{currentStaus=='Pending'?'badge-warning':currentStaus=='Accepted'?'badge-success':'badge-danger'}} py-1"
              *ngIf="from!=='inbox'">{{currentStaus}}</span>
          </div>
          <!--Add Education-->
          <ng-container *ngIf="requestitem.new_data?.education">
            <div class="col-12 fs-12 fw-600  mt-16 {{from =='inbox'?'text-accent2 ':'text-uppercase'}}">
              Newly Added Information
            </div>
            <div class="col-12" *ngFor="let edu of addedData;let i=index">
              <div class="card card-c2 p-16 details-card">
                <div class="row row-16">
                  <div class="col-12 "><span class="title">{{edu.qualification}}</span></div>
                  <div class="col-4">
                    <div class="detail-title">Qualification</div>
                    <div class="detail-desc">{{edu.qualification!=null?edu.qualification:'-'}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">College/School</div>
                    <div class="detail-desc">{{(edu.college_school!=null && edu.college_school!='')
                      ?edu.college_school:'-'}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Year of passing</div>
                    <div class="detail-desc">{{edu.year_of_passing!=null && edu.year_of_passing!=''?
                      this.appService.dateFormatDisplay(edu.year_of_passing):'-'}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Specialization</div>
                    <div class="detail-desc">{{edu.specialization!=null &&
                      edu.specialization!=''?edu.specialization:'-'}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Grade/CGPA</div>
                    <div class="detail-desc">{{edu.grade_cgpa!="" && edu.grade_cgpa!=null?edu.grade_cgpa:'-'}}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!--Edit Education-->
          <ng-container *ngIf="requestitem.edit_data?.education">
            <div class="col-12 fs-12 fw-600  mt-16 {{from=='inbox'?'text-accent2 ':'text-uppercase'}}">
              Updated Information
            </div>
            <div class="col-12">
              <div class="card details-card p-16">
                <div class="row row-16">
                  <ng-container *ngFor="let option of options;">
                    <div class="col-12 lh-1"><span class="title">{{option.key}}</span></div>
                    <ng-container *ngFor="let list of option.value">
                      <ng-container *ngFor="let l of list;let last = last">
                        <div class="col">
                          <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                          <div class="detail-desc">{{formatDate(l[0],l[1])}}</div>
                        </div>
                        <div class="col-1 flex-center fs-12 text-light-500">to</div>
                        <div class="col">
                          <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                          <div class="detail-desc">{{formatDate(l[0],l[2])}}</div>
                        </div>

                        <div class="col-12" *ngIf="!last">
                          <hr />
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- <ng-container *ngIf="requestitem.category =='education'">
    <div class="col-12 fs-12 fw-600 text-uppercase">EDUCATION</div>
    <ng-container *ngIf="requestitem.new_data?.education">
      <div class="col-12">
        <div class="fs-12 fw-600 text-accent2 ">Newly Added Information</div>
      </div>
      <div class="col-12">
        <div class="card details-card p-16">
          <div class="row row-16">
              <div class="col-12" *ngFor="let edu of addedData;let i=index">
                <div class="card details-card p-16">
                  <div class="row row-16">
                    <div class="col-12 "><span class="title">{{edu.qualification}}</span></div>
                    <div class="col-4">
                      <div class="detail-title">Qualification</div>
                      <div class="detail-desc">{{edu.qualification!=null?edu.qualification:'-'}}</div>
                    </div>
                    <div class="col-4">
                      <div class="detail-title">College/School</div>
                    <div class="detail-desc">{{(edu.college_school!=null && edu.college_school!='')
                      ?edu.college_school:'-'}}</div>
                    </div>
                    <div class="col-4">
                      <div class="detail-title">Year of passing</div>
                    <div class="detail-desc">{{edu.year_of_passing!=null && edu.year_of_passing!=''?
                      this.appService.dateFormatDisplay(edu.year_of_passing):'-'}}</div>
                    </div>
                    <div class="col-4">
                      <div class="detail-title">Specialization</div>
                    <div class="detail-desc">{{edu.specialization!=null &&
                      edu.specialization!=''?edu.specialization:'-'}}</div>
                    </div>
                    <div class="col-4">
                      <div class="detail-title">Grade/CGPA</div>
                      <div class="detail-desc">{{edu.grade_cgpa!="" && edu.grade_cgpa!=null?edu.grade_cgpa:'-'}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      </ng-container>
      <div *ngIf="requestitem.edit_data?.education">
        <div class="fs-12 fw-600 text-accent2 pb-16">Updated Information</div>
        <div class="card details-card indicater1 shadow-0 px-24 py-16">
          <div class="row row-16">
            <ng-container *ngFor="let option of options;let last = last">
              <div class="col-12 lh-1"><span class="title">{{option.key}}</span></div>
              <ng-container *ngFor="let list of option.value">
                <ng-container *ngFor="let l of list">
                  <div class="col">
                    <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                    <div class="detail-desc">{{formatDate(l[0],l[1])}}</div>
                  </div>
                  <div class="col-1 flex-center fs-12 text-light-500">to</div>
                  <div class="col">
                    <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                    <div class="detail-desc">{{formatDate(l[0],l[2])}}</div>
                  </div>
                  <div class="col-12" *ngIf="!last">
                    <hr />
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
  </ng-container> -->
  <!-- Personal -->
  <ng-container *ngIf="requestitem.category =='personal'">
    <div class="col-12">
      <div class="card card-c2 p-16">
        <div class="row row-12">
          <div class="col-12 d-flex align-items-start">
            <div class="title">
              PERSONAL
            </div>
            <span
              class="badge px-16 ms-auto fs-14 {{currentStaus=='Pending'?'badge-warning':currentStaus=='Accepted'?'badge-success':'badge-danger'}} py-1"
              *ngIf="from!=='inbox'">{{currentStaus}}</span>
          </div>
          <!-- Add Personal-->
          <ng-container *ngIf="requestitem.new_data?.personal">
            <div class="col-12 fs-12 fw-600  mt-16 {{from =='inbox'?'text-accent2 ':'text-uppercase'}}">
              Newly Added Information
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 p-16">
                <div class="row row-16 ">
                  <div class="col-12 "><span class="title">Identification Details</span></div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">PAN no.</div>
                    <div class="detail-desc">{{options[0]['pan_number']}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Aadhar No.</div>
                    <div class="detail-desc">{{options[0]['aadhar_number']}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Passport No.</div>
                    <div class="detail-desc">{{options[0]['passport_number'] != null?options[0]['passport_number']:'-'}}
                    </div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Passport Expiry Date</div>
                    <div class="detail-desc">{{options[0]['passport_expiry_date'] !=
                      null?formatDate('',options[0]['passport_expiry_date']):'-'}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Personal Email</div>
                    <div class="detail-desc">{{options[0]['personal_email'] != null ? options[0]['personal_email'] : '-'}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Emergency Contact Name</div>
                    <div class="detail-desc">{{options[0]['emergency_contact_name'] !=
                      null?options[0]['emergency_contact_name']:'-'}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Emergency Contact Number</div>
                    <div class="detail-desc">{{options[0]['phone_code'] == null ? '' : options[0]['phone_code'] + ' '
                      }}{{options[0]['emergency_contact_number'] !=
                      null?options[0]['emergency_contact_number']:'-'}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Relation</div>
                    <div class="detail-desc">{{options[0]['relation'] != null?options[0]['relation']:'-'}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 p-16">
                <div class="row row-24 ">
                  <div class="col-12"><span class="title">Address Details</span></div>
                  <div class="col-12">
                    <div class="detail-title">Current Address</div>
                    <div class="detail-desc">{{(options[0]['current_address'] != null && options[0]['current_address'] != '') ? options[0]['current_address'] : '-'}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Permanent Address</div>
                    <div class="detail-desc">{{(options[0]['permanent_address'] != null && options[0]['permanent_address'] != '') ? options[0]['permanent_address'] : '-'}}</div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Current Country</div>
                    <div class="detail-desc">{{(options[0]['Current_country'] != null && options[0]['Current_country'] != '') ? options[0]['Current_country'] : '-'}}</div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Current State</div>
                    <div class="detail-desc">{{(options[0]['Current_state'] != null && options[0]['Current_state'] != '') ? options[0]['Current_state'] : '-'}}</div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Current City</div>
                    <div class="detail-desc">{{(options[0]['Current_city'] != null && options[0]['Current_city'] != '') ? options[0]['Current_city'] : '-'}}</div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Current Pin</div>
                    <div class="detail-desc">{{(options[0]['current_pin'] != null && options[0]['current_pin'] != '') ? options[0]['current_pin'] : '-'}}</div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Permanent Country</div>
                    <div class="detail-desc">{{(options[0]['Permanent_country'] != null && options[0]['Permanent_country'] != '') ? options[0]['Permanent_country'] : '-'}}</div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Permanent State</div>
                    <div class="detail-desc">{{(options[0]['Permanent_state'] != null && options[0]['Permanent_state'] != '') ? options[0]['Permanent_state'] : '-'}}</div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Permanent City</div>
                    <div class="detail-desc">{{(options[0]['Permanent_city'] != null && options[0]['Permanent_city'] != '') ? options[0]['Permanent_city'] : '-'}}</div>
                  </div>
                  <div class="col-lg-3  col-sm-6 col-12">
                    <div class="detail-title">Permanent Pin</div>
                    <div class="detail-desc">{{(options[0]['permanent_pin'] != null && options[0]['permanent_pin'] != '') ? options[0]['permanent_pin'] : '-'}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- Edit Personal-->
          <ng-container *ngIf="requestitem.edit_data?.personal">
            <div class="col-12 fs-12 fw-600  mt-16 {{from=='inbox'?'text-accent2 ':'text-uppercase'}}">
              Updated Information
            </div>
            <ng-container *ngFor="let option of options">
              <ng-container *ngFor="let list of option.value">
                <ng-container *ngFor="let l of list">
                  <ng-container *ngIf="l[0]!='current_address'  && l[0]!='permanent_address'">
                    <div class="col-12">
                      <div class="card details-card shadow-0 p-16">
                        <div class="row row-16">
                          <div class="col">
                            <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                            <div class="detail-desc">{{formatDate(l[0],l[1])}}</div>
                          </div>
                          <div class="col-1 flex-center fs-12 text-light-500">to</div>
                          <div class="col">
                            <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                            <div class="detail-desc">{{formatDate(l[0],l[2])}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="l[0] =='current_address'  || l[0] =='permanent_address'">
                    <div class="col-12">
                      <div class="card details-card shadow-0 p-16">
                        <div class="row row-16">
                          <div class="col-12">
                            <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                            <div class="detail-desc">
                              {{l[1]}}
                            </div>
                          </div>
                          <div class="col-12 fs-12 text-light-500">to</div>
                          <div class="col-12">
                            <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                            <div class="detail-desc">
                              {{l[2]}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Health Insurance -->
  <ng-container *ngIf="requestitem.category =='health'">
    <div class="col-12">
      <div class="card card-c2 p-16">
        <div class="row row-12">
          <div class="col-12 d-flex align-items-start">
            <div class="title">
              HEALTH INSURANCE
            </div>
            <span
              class="badge px-16 ms-auto fs-14 {{currentStaus=='Pending'?'badge-warning':currentStaus=='Accepted'?'badge-success':'badge-danger'}} py-1"
              *ngIf="from!=='inbox'">{{currentStaus}}</span>
          </div>
          <!-- Add Health Insurance -->
          <ng-container *ngIf="requestitem.new_data?.health">
            <div class="col-12 fs-12 fw-600  mt-16 {{from =='inbox'?'text-accent2 ':'text-uppercase'}}">
              Newly Added Information
            </div>
            <div class="col-12" *ngFor="let item of addedData;let i=index">
              <div class="card details-card p-16">
                <div class="row row-16">
                  <div class="col-12 "><span class="title">INSURANCE {{i+1}}</span></div>
                  <div class="col-4">
                    <div class="detail-title">Insuree Name</div>
                    <div class="detail-desc">{{item.insuree_name}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Insuree DOB</div>
                    <div class="detail-desc">{{item['insuree_of_birth'] !=
                      null?formatDate('',item['insuree_of_birth']):'-'}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Gender</div>
                    <div class="detail-desc">{{item['insuree_gender'] != null?item['insuree_gender']:'-'}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Insurance Code</div>
                    <div class="detail-desc">{{item['insurance_code'] != null?item['insurance_code']:'-'}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Assured Sum</div>
                    <div class="detail-desc">{{item['assured_sum'] != null?item['assured_sum']:'-'}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Insurance Company</div>
                    <div class="detail-desc">{{item['insurance_company'] != null?item['insurance_company']:'-'}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Company Code</div>
                    <div class="detail-desc">{{item['company_code'] != null?item['company_code']:'-'}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Issue Date</div>
                    <div class="detail-desc">{{item['issue_date'] != null?formatDate('',item['issue_date']):'-'}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Valid Upto</div>
                    <div class="detail-desc">{{item['valid_upto'] != null?formatDate('',item['valid_upto']):'-'}}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- Edit Health Insurance -->
          <ng-container *ngIf="requestitem.edit_data?.health">
            <div class="col-12 fs-12 fw-600  mt-16 {{from=='inbox'?'text-accent2 ':'text-uppercase'}}">
              Updated Information
            </div>
            <ng-container *ngFor="let option of options">
              <div class="col-12">
                <div class="card details-card  shadow-0 p-16">
                  <div class="row row-16">
                    <div class="col-12 lh-1"><span class="title">{{option.key}}</span></div>
                    <ng-container *ngFor="let list of option.value">
                      <ng-container *ngFor="let l of list;let last = last">
                        <div class="col">
                          <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                          <div class="detail-desc">{{formatDate(l[0],l[1])}}</div>
                        </div>
                        <div class="col-1 flex-center fs-12 text-light-500">to</div>
                        <div class="col">
                          <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                          <div class="detail-desc">{{formatDate(l[0],l[2])}}</div>
                        </div>
                        <div class="col-12" *ngIf="!last">
                          <hr />
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Previous Employer -->
  <ng-container *ngIf="requestitem.category=='prev_employer'">
    <div class="col-12">
      <div class="card card-c2 p-16">
        <div class="row row-12">
          <div class="col-12 d-flex align-items-start">
            <div class="title">
              PREVIOUS EMPLOYER
            </div>
            <span
              class="badge px-16 ms-auto fs-14 {{currentStaus=='Pending'?'badge-warning':currentStaus=='Accepted'?'badge-success':'badge-danger'}} py-1"
              *ngIf="from!=='inbox'">{{currentStaus}}</span>
          </div>
          <!-- Add PREVIOUS EMPLOYER -->
          <ng-container *ngIf="requestitem.new_data?.prev_employer">
            <div class="col-12 fs-12 fw-600  mt-16 {{from =='inbox'?'text-accent2 ':'text-uppercase'}}">
              Newly Added Information
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 p-16">
                <div class="row row-16 ">
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Income after exemption   </div>
                    <div class="detail-desc">{{options[0]['income_after_exemption'] | currency : requestitem.currency}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Exempted gratuity amount</div>
                    <div class="detail-desc">{{options[0]['exempted_gratuity'] | currency : requestitem.currency}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Exempted leave encashment amount</div>
                    <div class="detail-desc">{{options[0]['exempted_encashed_leave'] | currency : requestitem.currency}}
                    </div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">PF amount deducted</div>
                    <div class="detail-desc">{{options[0]['pf_amount'] | currency : requestitem.currency}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Professional tax amount deducted</div>
                    <div class="detail-desc">{{options[0]['pt_amount'] | currency : requestitem.currency}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Employer NPS deduction 80CCD(2)</div>
                    <div class="detail-desc">{{options[0]['nps_deduction'] | currency : requestitem.currency}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 p-16">
                <div class="row row-16 ">
                  <div class="col-12 ">
                    <p class="title mb-0">Income tax DETAILS</p>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Income tax</div>
                    <div class="detail-desc">{{options[0]['income_tax'] | currency : requestitem.currency}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Surcharge</div>
                    <div class="detail-desc">{{options[0]['surcharge'] | currency : requestitem.currency}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Cess</div>
                    <div class="detail-desc">{{options[0]['cess'] | currency : requestitem.currency}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Total TDS deducted by previous employer</div>
                    <div class="detail-desc">{{options[0]['total_tds'] | currency : requestitem.currency}}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- Edit PREVIOUS EMPLOYER -->
          <ng-container *ngIf="requestitem.edit_data?.prev_employer">
            <div class="col-12 fs-12 fw-600  mt-16 {{from=='inbox'?'text-accent2 ':'text-uppercase'}}">
              Updated Information
            </div>
            <ng-container *ngFor="let option of options">
              <ng-container *ngFor="let list of option.value">
                <ng-container *ngFor="let lm of list">
                  <div class="col-12">
                    <div class="card details-card  shadow-0 p-16">
                      <div class="row row-16">
                        <div class="col">
                          <div class="detail-title">{{replace(lm[0]) | titlecase}} </div>
                          <div class="detail-desc">{{lm[1] | currency : requestitem.currency}}</div>
                        </div>
                        <div class="col-1 flex-center fs-12 text-light-500">to</div>
                        <div class="col">
                          <div class="detail-title">{{replace(lm[0]) | titlecase}}</div>
                          <div class="detail-desc">{{lm[2] | currency : requestitem.currency}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Bank Details -->
  <ng-container *ngIf="requestitem.category=='bank'">
    <div class="col-12">
      <div class="card card-c2 p-16">
        <div class="row row-12">
          <div class="col-12 d-flex align-items-start">
            <div class="title">
              Bank Details
            </div>
            <span
              class="badge px-16 ms-auto fs-14 {{currentStaus=='Pending'?'badge-warning':currentStaus=='Accepted'?'badge-success':'badge-danger'}} py-1"
              *ngIf="from!=='inbox'">{{currentStaus}}</span>
          </div>
          <!-- Add Bank Details -->
          <ng-container *ngIf="requestitem.new_data?.bank">
            <div class="col-12 fs-12 fw-600  mt-16 {{from =='inbox'?'text-accent2 ':'text-uppercase'}}">
              Newly Added Information
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 p-16">
                <div class="row row-16 ">
                  <div class="col-lg-4  col-sm-6 col-12">
                    <div class="detail-title">Salary Pay Mode</div>
                    <div class="detail-desc">{{options[0]['salary_pay_mode']}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12" *ngIf="options[0]['salary_pay_mode'] == 'Online Transfer'">
                    <div class="detail-title">Account holder name</div>
                    <div class="detail-desc">{{options[0]['account_holdername']}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12" *ngIf="options[0]['salary_pay_mode'] == 'Online Transfer'">
                    <div class="detail-title">Account number</div>
                    <div class="detail-desc">{{options[0]['account_number']}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12" *ngIf="options[0]['salary_pay_mode'] == 'Online Transfer'">
                    <div class="detail-title">IFSC Code</div>
                    <div class="detail-desc">{{options[0]['ifsc_code']}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12" *ngIf="options[0]['salary_pay_mode'] == 'Online Transfer'">
                    <div class="detail-title">Branch name</div>
                    <div class="detail-desc">{{options[0]['branch_name']}}</div>
                  </div>
                  <div class="col-lg-4  col-sm-6 col-12" *ngIf="options[0]['salary_pay_mode'] == 'Online Transfer'">
                    <div class="detail-title">Branch address</div>
                    <div class="detail-desc">{{options[0]['branch_address']}}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- Edit Bank Details -->
          <ng-container *ngIf="requestitem.edit_data?.bank">
            <div class="col-12 fs-12 fw-600  mt-16 {{from=='inbox'?'text-accent2 ':'text-uppercase'}}">
              Updated Information
            </div>
            <ng-container *ngFor="let option of options">
              <ng-container *ngFor="let list of option.value">
                <ng-container *ngFor="let l of list">
                  <div class="col-12">
                    <div class="card details-card shadow-0 p-16">
                      <div class="row row-16">
                        <div class="col">
                          <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                          <div class="detail-desc">{{l[1]}}</div>
                        </div>
                        <div class="col-1 flex-center fs-12 text-light-500">to</div>
                        <div class="col">
                          <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                          <div class="detail-desc">{{l[2]}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Document -->
  <ng-container *ngIf="requestitem.category==='document'">
    <div class="col-12 fs-12 fw-600 text-uppercase">DOCUMENT</div>
    <ng-container *ngFor="let option of options;let i=index">

      <div class="col-12" *ngIf="this.requestitem.edit_data?.document">
        <div class="card details-card  shadow-0 ">
          <div class="card-body ">
            <div class="row row-8">
              <!-- <ng-container *ngFor="let option of options;let i=index"> -->
              <div class="col-12 mb-12 d-flex align-items-start">
                <span class="title">{{option.key[0]}}</span>
                <div class="d-flex ms-auto align-items-center gap-16">
                  <span class="badge px-16 fs-14 badge-warning py-1"
                    *ngIf="oldDocId[i]?.status == 'Pending'">Pending</span>
                  <span class="badge px-16 fs-14 badge-success py-1"
                    *ngIf="oldDocId[i]?.status == 'Approved'|| oldDocId[i]?.status == 'Accepted'">Accepted</span>
                  <span class="badge px-16 fs-14 badge-danger py-1"
                    *ngIf="oldDocId[i]?.status == 'Rejected'">Rejected</span>
                  <span *ngIf="from =='request'" class="text-light-400">|</span>
                  <span *ngIf="from =='request'" class="link-primary fs-12 fw-500 text-nowrap"
                    (click)="docViewStatus(oldDocId[i]?.temp_doc_id)">View Status</span>
                </div>
              </div>
              <ng-container *ngFor="let list of option.value;let ki = index;">
                <ng-container *ngIf="ki == 0">
                  <ng-container *ngFor="let l of list;let last=last">
                    <ng-container *ngIf="l[1]!=null">
                      <div class="col">
                        <div class="card card-c2">
                          <div class="card-body px-10 py-12  bg-secondary">
                            <div class="d-flex align-items-center mb-1 gap-8">
                              <i class="bi bi-file-earmark-text-fill fs-28 text-accent2"></i>
                              <div class="d-flex flex-column gap-4">
                                <h6 class="mb-0 text-trim">{{this.appService.getDocName(l[1])}}</h6>
                                <span class="fs-10 fw-500 text-uppercase text-light-500">old document</span>
                              </div>
                            </div>
                            <div class="d-flex gap-24 fs-12 fw-500">
                              <div class="link-primary" (click)="this.appService.downloadFile(l[1],this.appService.getDocName(l[1]))"><i
                                  class="bi bi-download me-8"></i>Download </div>
                              <div class="link-primary" (click)="viewDocument(l[1])"><i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-1 flex-center fs-12 text-light-500">to</div>
                    </ng-container>
                    <div class="col">
                      <div class="card card-c2 ">
                        <div class="card-body px-10 py-12 bg-secondary">
                          <div class="d-flex align-items-start mb-1 gap-8">
                            <i class="bi bi-file-earmark-text-fill fs-28 text-accent2"></i>
                            <div class="d-flex flex-column gap-4">
                              <h6 class="mb-0 text-trim">{{this.appService.getDocName(l[2])}}</h6>
                              <span class="fs-10 fw-500 text-uppercase text-light-500 text-nowrap">New document</span>
                            </div>

                          </div>
                          <div class="d-flex gap-24 fs-12 fw-500">
                            <div class="link-primary" (click)="this.appService.downloadFile(l[2],this.appService.getDocName(l[2]))"><i
                                class="bi bi-download me-8"></i>Download </div>
                            <div class="link-primary" (click)="viewDocument(l[2])"><i class="bi bi-eye me-8"></i>View
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <!-- <div class="col-12" *ngIf="i+1!=options.length">
                      <hr />
                    </div> -->
                  </ng-container>
                </ng-container>
              </ng-container>
              <!-- </ng-container> -->
            </div>
          </div>

          <div class="card-body py-12" *ngIf="from=='inbox' && oldDocId?.length!=0 && oldDocId[i]?.status=='Pending'">
            <div class="col-12 d-flex gap-16 justify-content-end">
              <span class="fs-12 fw-500 link-danger" (click)="confirm('Rejected',oldDocId[i]?.id,oldDocId[i])">
                Reject
              </span>
              <span class="fs-12 fw-500 link-primary" (click)="confirm('Approved',oldDocId[i]?.id,oldDocId[i])">
                Approve
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="this.requestitem.new_data?.document">
      <ng-container *ngFor="let option of addedData;let i=index">
        <ng-container *ngFor="let list of option.value;let ki = index;">
          <ng-container *ngIf="ki == 0">
            <ng-container *ngFor="let l of list">
              <div class="col-12">
                <div class="card details-card  shadow-0  ">
                  <div class="card-body">
                    <div class="row row-8">
                      <div class="col-12 mb-12 d-flex align-items-start">
                        <span class="title">{{option.key[0]}}</span>
                        <div class="d-flex ms-auto align-items-center gap-16">
                          <span class="badge px-16 fs-14 badge-warning py-1"
                            *ngIf="newDocId[i]?.status == 'Pending'">Pending</span>
                          <span class="badge px-16 fs-14 badge-success py-1"
                            *ngIf="newDocId[i]?.status == 'Approved'|| newDocId[i]?.status == 'Accepted'">Accepted</span>
                          <span class="badge px-16 fs-14 badge-danger py-1"
                            *ngIf="newDocId[i]?.status == 'Rejected'">Rejected</span>
                          <span *ngIf="from =='request'" class="text-light-400">|</span>
                          <span *ngIf="from =='request'" class="link-primary fs-12 fw-500 text-nowrap"
                            (click)="docViewStatus(newDocId[i]?.temp_doc_id)">View Status</span>
                        </div>

                      </div>
                      <div class="col-lg-6">
                        <div class="card card-c2 ">
                          <div class="card-body px-10 py-12 bg-secondary">
                            <div class="d-flex align-items-start mb-1 gap-8">
                              <i class="bi bi-file-earmark-text-fill fs-28 text-accent2"></i>
                              <div class="d-flex flex-column gap-4">
                                <h6 class="mb-0">{{this.appService.getDocName(l[2])}}</h6>
                                <span class="fs-10 fw-500 text-uppercase text-light-500 text-nowrap">New document</span>
                              </div>

                            </div>
                            <div class="d-flex gap-24 fs-12 fw-500">
                              <div class="link-primary" (click)="this.appService.downloadFile(l[2],this.appService.getDocName(l[2]))"><i
                                  class="bi bi-download me-8"></i>Download </div>
                              <div class="link-primary" (click)="viewDocument(l[2])"><i class="bi bi-eye me-8"></i>View
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-body  py-12"
                    *ngIf="from=='inbox' || from=='behalf' && newDocId?.length!=0 && newDocId[i]?.status=='Pending'">
                    <div class="col-12 d-flex gap-16 justify-content-end">
                      <span class="fs-12 fw-500 link-danger" (click)="confirm('Rejected',newDocId[i]?.id,newDocId[i])">
                        Reject
                      </span>
                      <span class="fs-12 fw-500 link-primary" (click)="confirm('Approved',newDocId[i]?.id,newDocId[i])">
                        Approve
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>


  </ng-container>
  <!-- New family data -->
  <ng-container *ngIf="requestitem.category =='family_data'">
    <div class="col-12">
      <div class="card card-c2 p-16">
        <div class="row row-12">
          <div class="col-12 d-flex align-items-start">
            <div class="title">
              FAMILY details
            </div>
            <span
              class="badge px-16 ms-auto fs-14 {{currentStaus=='Pending'?'badge-warning':currentStaus=='Accepted'?'badge-success':'badge-danger'}} py-1"
              *ngIf="from!=='inbox'">{{currentStaus}}</span>
          </div>
          <!-- Add family data -->
          <ng-container *ngIf="familyData.length!=0 || childData.length!=0 || depData.length!=0">
            <div class="col-12 fs-12 fw-600  mt-16 {{from =='inbox'?'text-accent2 ':'text-uppercase'}}">
              Newly Added Information
            </div>
            <div class="col-12" *ngIf="familyData.length!=0">
              <div class="card details-card p-16">
                <div class="row row-16">
                  <div class="col-12 "><span class="title">PARENTAL</span></div>
                  <div class="col-4">
                    <div class="detail-title">Father’s Name</div>
                    <div class="detail-desc">{{familyData.father_name}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Father’s DOB</div>
                    <div class="detail-desc">{{familyData?.father_date_of_birth!=null &&
                      familyData?.father_date_of_birth!='' && familyData?.father_date_of_birth!='None' &&
                      familyData?.father_date_of_birth!='none'
                      ?this.appService.dateFormatDisplay(familyData?.father_date_of_birth):'-'}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Mother’s Name</div>
                    <div class="detail-desc">{{(familyData?.mother_name!=null &&
                      familyData?.mother_name!="")?familyData?.mother_name:'-'}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Mother’s DOB</div>
                    <div class="detail-desc">{{familyData?.mother_date_of_birth!=null &&
                      familyData?.mother_date_of_birth!='' && familyData?.mother_date_of_birth!='None' &&
                      familyData?.mother_date_of_birth!='none'
                      ?this.appService.dateFormatDisplay(familyData?.mother_date_of_birth):'-'}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="(familyData.length!=0 && familyData.marital_status!=null)">
              <div class="card details-card p-16">
                <div class="row row-16">
                  <div class="col-12 "><span class="title">MARRIAGE</span></div>
                  <div class="col-4">
                    <div class="detail-title">Status</div>
                    <div class="detail-desc">{{familyData?.marital_status}}</div>
                  </div>
                  <ng-container *ngIf="familyData?.marital_status!='Single'">

                  <div class="col-4">
                    <div class="detail-title">Date of Marriage</div>
                    <div class="detail-desc">{{familyData?.date_of_marriage!=null && familyData?.date_of_marriage!='' &&
                      familyData?.date_of_marriage!='None' && familyData?.date_of_marriage!='none'
                      ?this.appService.dateFormatDisplay(familyData?.date_of_marriage):'-'}}</div>
                  </div>
                  <div class="col-4">
                      <div class="detail-title">Spouse Name</div>
                    <div class="detail-desc">{{familyData?.spouse_name!=null?familyData?.spouse_name:'-'}}</div>
                  </div>
                  <div class="col-4">
                    <div class="detail-title">Spouse DOB</div>
                    <div class="detail-desc">{{familyData?.spouse_date_of_birth!=null &&
                      familyData?.spouse_date_of_birth!='' && familyData?.spouse_date_of_birth!='None' &&
                      familyData?.spouse_date_of_birth!='none'
                      ?this.appService.dateFormatDisplay(familyData?.spouse_date_of_birth):'-'}}</div>
                  </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="childData.length!=0">
              <div class="card details-card p-16">
                <div class="row row-16">
                  <div class="col-12 ">
                    <p class="title mb-0">cHILDREN DETAILS</p>
                  </div>
                  <div class="col-12" *ngFor="let child of childData;let i=index">
                    <div class="card details-card p-16">
                      <div class="row row-16">
                        <div class="col-12 "><span class="title">CHILD {{i+1}}</span></div>
                        <div class="col-4">
                          <div class="detail-title">Children Name</div>
                          <div class="detail-desc">{{child.child_name!=null?child.child_name:'-'}}</div>
                        </div>
                        <div class="col-4">
                          <div class="detail-title">Children DOB</div>
                          <div class="detail-desc">{{this.child.child_date_of_birth!=null &&
                            this.child.child_date_of_birth!='' && this.child.child_date_of_birth!='None' &&
                            this.child.child_date_of_birth!='none'
                            ?this.appService.dateFormatDisplay(this.child.child_date_of_birth):'-'}}</div>
                        </div>
                        <div class="col-4">
                          <div class="detail-title">Gender</div>
                          <div class="detail-desc">{{child.child_gender!=null?child.child_gender:'-'}}</div>
                        </div>
                        <div class="col-4" *ngIf="child.is_dependent">
                          <div class="detail-title">Dependency</div>
                          <div class="detail-desc">Dependent</div>
                        </div>
                        <div class="col-4" *ngIf="child.is_going_to_school">
                          <div class="detail-title">Schooling</div>
                          <div class="detail-desc">Going to school</div>
                        </div>
                        <div class="col-4" *ngIf="child.is_going_to_hostel">
                          <div class="detail-title">Hostel</div>
                          <div class="detail-desc">Hostel</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="depData.length!=0">
              <div class="card details-card p-16">
                <div class="row row-16">
                  <div class="col-12 ">
                    <p class="title mb-0">DEPENDANT DETAILS</p>
                  </div>
                  <div class="col-12" *ngFor="let depend of depData;let i= index">
                    <div class="card details-card p-16">
                      <div class="row row-16">
                        <div class="col-12 "><span class="title">DEPENDANT {{i+1}}</span></div>
                        <div class="col-4">
                          <div class="detail-title">Dependent Name</div>
                          <div class="detail-desc">{{depend.dependent_name!=null?depend.dependent_name:'-'}}</div>
                        </div>
                        <div class="col-4">
                          <div class="detail-title">Dependent DOB</div>
                          <div class="detail-desc">{{depend.dependent_DOB!=null && depend.dependent_DOB!='' &&
                            depend.dependent_DOB!='None' && depend.dependent_DOB!='none'
                            ?this.appService.dateFormatDisplay(depend.dependent_DOB):'-'}}</div>
                        </div>
                        <div class="col-4">
                          <div class="detail-title">Gender</div>
                          <div class="detail-desc">{{depend.dependent_gender!=null?depend.dependent_gender:'-'}}</div>
                        </div>
                        <div class="col-4">
                          <div class="detail-title">Relation</div>
                          <div class="detail-desc">{{depend.dependent_relation!=null?depend.dependent_relation:'-'}}
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="detail-title">Remarks</div>
                          <div class="detail-desc">{{depend.dependent_remark!=null &&
                            depend.dependent_remark!=''?depend.dependent_remark:'-'}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- Edit family data -->
          <ng-container
            *ngIf="requestitem.edit_data?.family || requestitem.edit_data?.child || requestitem.edit_data?.dependant">
            <div class="col-12 fs-12 fw-600  mt-16 {{from=='inbox'?'text-accent2 ':'text-uppercase'}}">
              Updated Information
            </div>
            <ng-container *ngIf="requestitem.edit_data?.family">
              <ng-container *ngFor="let option of familyUpdated">
                <ng-container *ngFor="let list of option.value">
                  <ng-container *ngFor="let l of list;let last = last">
                    <div class="col-12">
                      <div class="card details-card p-16">
                        <div class="row row-16">
                          <!-- <div class="col-12 "><span class="title">CHILD 1</span></div> -->
                          <div class="col">
                            <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                            <div class="detail-desc">{{formatDate(l[0],l[1])}}</div>
                          </div>
                          <div class="col-1 flex-center fs-12 text-light-500">to</div>
                          <div class="col">
                            <div class="detail-title"> {{replace(l[0]) | titlecase}}</div>
                            <div class="detail-desc">{{formatDate(l[0],l[2])}} </div>
                          </div>
                          <!-- <div class="col-12" *ngIf="!last">
                            <hr />
                          </div> -->
                          </div>
                        </div>
                      </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="requestitem.edit_data?.child">
              <div class="col-12">
                <div class="card details-card p-16">
                  <div class="row row-16">
                    <ng-container *ngFor="let option of childUpdated;let j=index">
                      <div class="col-12 "><span class="title">Child {{j+1}}</span></div>
                      <ng-container *ngFor="let list of option.value">
                        <ng-container *ngFor="let l of list;let last = last">
                          <div class="col">
                            <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                            <div class="detail-desc">{{formatDate(l[0],l[1])}}</div>
                          </div>
                          <div class="col-1 flex-center fs-12 text-light-500">to</div>
                          <div class="col">
                            <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                            <div class="detail-desc">{{formatDate(l[0],l[2])}}</div>
                          </div>
                          <div class="col-12" *ngIf="!last">
                            <hr />
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="requestitem.edit_data?.dependant">
              <div class="col-12">
                <div class="card details-card p-16">
                  <div class="row row-16">
                    <ng-container *ngFor="let option of dependUpdated;let j=index">
                      <div class="col-12 "><span class="title">DEPENDANT {{j+1}}</span></div>
                      <ng-container *ngFor="let list of option.value">
                        <ng-container *ngFor="let l of list;let last = last">
                          <div class="col">
                            <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                            <div class="detail-desc">{{formatDate(l[0],l[1])}}</div>
                          </div>
                          <div class="col-1 flex-center fs-12 text-light-500">to</div>
                          <div class="col">
                            <div class="detail-title">{{replace(l[0]) | titlecase}}</div>
                            <div class="detail-desc">{{formatDate(l[0],l[2])}}</div>
                          </div>
                          <div class="col-12" *ngIf="!last">
                            <hr />
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<!-- un supported view format alert -->
<ng-container *ngIf="alertToggle==true">
  <app-alert-toggle (modals)="closed($event)" [alertToggle]="alertToggle" [alertMsg]="empalertMsg"></app-alert-toggle>
</ng-container>
<!-- Viewing the File -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close1($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName"
    [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>
<!-- Document view status start -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <app-view-status [loader]="loader2" [approvalRequired]="true" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"></app-view-status>
    </form>
  </div>
</ng-container>
<!-- Document view status end -->
