import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { AppService } from 'src/app/app.global';

@Injectable({
  providedIn: 'root'
})
export class InboxService {
  inbox_item: EventEmitter<any> = new EventEmitter();
  cancellation_item: EventEmitter<any> = new EventEmitter();
  constructor(
    private http: HttpClient,
    private websocket: WebSocket,
    private multipleItemsocket: WebSocket,
    public router: Router,
    private authService: AuthService,
    public appservice:AppService

  ) { }
  round: any = true;
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  WEBSOCKET_URL: string =environment.webSocketEndPoint;

  public InboxSocket(){
    this.websocket = new WebSocket(`${this.WEBSOCKET_URL}inbox/${this.appservice.getSubDomain()}_inbox_${this.authService.isUserId}/`);

    this.websocket.onopen = (event:any) => {
      // console.log('Open Inbox live update socket: ', event);
    }

    this.websocket.onmessage = (event:any) => {
      // console.log('Inbox live update Item: ', event);
      // console.log('Inbox live update data', JSON.parse(event.data));
      let data = JSON.parse(event.data);
      this.inbox_item.emit(data);
    }

    this.websocket.onclose = (event:any) => {
      // console.log('Close Inbox live update: ', event);
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          // Hide loading indicator
          if(event.url !== '/inbox'){
           this.closeSocket();
          }
      }
    });

    if (this.websocket.readyState === WebSocket.OPEN) {
        // console.log('connected to Inbox live update websocket');
    }

  }

  public closeSocket(){
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close();
      // this.websocket.onclose = (event:any) => {
      //   // console.log('close inbox socket: ', event);
      // }
      this.websocket.addEventListener('close', (event) => {
        // console.log('The connection has been closed successfully.');
      });
    }
  }


  getInboxMenuCount(status: any,search:any=''){
    const url=`${this.API_PATH}inbox/api/v1/inbox-sidemenu/?achieved_status=${status}&search=${search}`;
    return this.http.get(url, this.httpOption)
  }
  getInboxData(request_type:any='',starred:any='',archeived_status:any='',search:any='',created_date:any='',offset:any,limit:any){
    const url=`${this.API_PATH}inbox/api/v1/inbox-list/?search=${search}&achieved_status=${archeived_status}&request_data__request_type__name=${request_type}&star=${starred}&created_date__range=${created_date}&offset=${offset}&limit=${limit}`;
    return this.http.get(url, this.httpOption)
  }
  getSingleInboxData(id:any=''){
    const url=`${this.API_PATH}inbox/api/v1/inbox-detail/${id}/`;
    return this.http.get(url, this.httpOption)
  }
  getRequestDetails(request_type:any='', id:any=''){
    const url=`${this.API_PATH}request/api/v1/inbox-request-detail/?request_type=${request_type}&request_id=${id}`;
    return this.http.get(url, this.httpOption)
  }
  getBillDetails(id:any){
    const url=`${this.API_PATH}inbox/api/v1/reimbursement-bill-detail/${id}/`;
    return this.http.get(url, this.httpOption)
  }
  getReimbursementTrend(body:any){
    return this.http.post(`${this.API_PATH}request/api/v1/reimbursement-trend/`, body, this.httpOption)

  }
  getEmployeeDetails(id:any='',from:any){
    if(from == 'HR'){
      return this.http.get(`${this.API_PATH}organization/api/v1/hr-employee-detail-view/${id}/`, this.httpOption)
    }else if(from == 'Reporting Manager'){
      return this.http.get(`${this.API_PATH}organization/api/v1/team-employee-detail-view/${id}/`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}organization/api/v1/profile-employee-detail-view/${id}/`, this.httpOption)
    }
  }

  updateInboxItem(id:number,data:any){
    return this.http.put(`${this.API_PATH}inbox/api/v1/inbox-update/${id}/`,data, this.httpOption)
  }
  updateReimbursementItem(id:number,data:any){
    return this.http.put(`${this.API_PATH}inbox/api/v1/inbox-reimbursebill-update/${id}/`,data, this.httpOption)
  }
  updateInboxDoc(id:number,data:any){
    return this.http.put(`${this.API_PATH}inbox/api/v1/inbox-profile-doc-update/${id}/`,data, this.httpOption)
  }
  updateOndutyItem(id:number,data:any){
    return this.http.put(`${this.API_PATH}inbox/api/v1/inbox-onduty-request-date-update/${id}/`,data, this.httpOption)
  }
  updateRegularizationItem(id:number,data:any){
    return this.http.put(`${this.API_PATH}inbox/api/v1/inbox-attendance-regularization-request-date-update/${id}/`,data, this.httpOption)
  }

  updateCheckIn(id:any,data:any){
    return this.http.put(`${this.API_PATH}inbox/api/v1/inbox-checkin-update/${id}/`,data, this.httpOption)
  }

  getRegularizationTrend(year:any,empId:any){
    return this.http.get(`${this.API_PATH}request/api/v1/attendance-regularization-trend/?year=${year}&employee_id=${empId}`, this.httpOption)

  }

  getOndutyTrend(year:any,empId:any){
    return this.http.get(`${this.API_PATH}request/api/v1/onduty-trend/?year=${year}&employee_id=${empId}`, this.httpOption)

  }
  getOvertimeTrend(year:any,empId:any){
    return this.http.get(`${this.API_PATH}request/api/v1/overtime-request-trend/?year=${year}&employee_id=${empId}`, this.httpOption)
  }

  editFinalOvertimeHours(id:number,body: any){
      return this.http.put(`${this.API_PATH}request/api/v1/overtime-request/${id}/`, body, this.httpOption)
  }
  getwfhTrend(year:any,empId:any){
    return this.http.get(`${this.API_PATH}request/api/v1/work-from-home-trend/?year=${year}&employee_id=${empId}`, this.httpOption)
  }
  getCompOffTrend(year:any,empId:any){
    return this.http.get(`${this.API_PATH}request/api/v1/compoff-request-trend/?year=${year}&employee_id=${empId}`, this.httpOption)
  }

  updateAssetItem(id:number,data:any){
    return this.http.put(`${this.API_PATH}inbox/api/v1/inbox-asset-request-product-update/${id}/`,data, this.httpOption)
  }

  // test start
  getAsset(empId:any,category:any,sub:any,search:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/get-assets/${empId}/?category=${category}&sub_category=${sub}&search=${search}`, this.httpOption);
  }

  assignAsset(id:any,body: any){
    return this.http.post(`${this.API_PATH}inbox/api/v1/inbox-assign-asset/${id}/`, body, this.httpOption);
  }

  getInventoryDetails(id:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/inventory-detail/${id}/`, this.httpOption);
  }

  assetStatusUpdate(id:number,body: any){
    return this.http.put(`${this.API_PATH}inbox/api/v1/inbox-asset-status-update/${id}/`, body, this.httpOption);
  }

  assetSearchDropDown(empId:any,category:any,sub:any){
    return this.http.get(`${this.API_PATH}asset/api/v1/get-assets/${empId}/?category=${category}&sub_category=${sub}&ordering=name`, this.httpOption);
  }

  // test end

  public InboxCancellationSocket(){
    this.multipleItemsocket = new WebSocket(`${this.WEBSOCKET_URL}inboxcancellation/${this.appservice.getSubDomain()}_inbox_cancellation_${this.authService.isUserId}/`);

    this.multipleItemsocket.onopen = (event:any) => {

    }

    this.multipleItemsocket.onmessage = (event:any) => {

      let data = JSON.parse(event.data);
      this.cancellation_item.emit(data);
    }

    this.multipleItemsocket.onclose = (event:any) => {
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          if(event.url !== '/inbox'){
           this.closeSocket();
          }
      }
    });

    if (this.multipleItemsocket.readyState === WebSocket.OPEN) {
    }

  }

  public closeCancellationSocket(){
    if (this.multipleItemsocket.readyState === WebSocket.OPEN) {
      this.multipleItemsocket.close();
    }
  }

  dispenseAmtUpdate(data:any,id:any){
    return this.http.put(`${this.API_PATH}inbox/api/v1/inbox-dispense-amount-update/${id}/`,data, this.httpOption)
  }

  approverhistory(id:any){
    return this.http.get(`${this.API_PATH}inbox/api/v1/inbox-approval-status-history/${id}/`, this.httpOption);
  }
  getleavetypeList(id: number,inboxid:number){
    return this.http.get(`${this.API_PATH}inbox/api/v1/inbox-leave-type-employee-dropdown/?id=${id}&inbox=${inboxid}&ordering=leave_name`, this.httpOption);
  }

  // Existing Loan Details
  getExistingLoan(body:any){
    return this.http.post(`${this.API_PATH}loan/api/v1/employee-loan-details/`,body, this.httpOption)
  }

  // Edit Modal updates
  editModalUpdates(id:any,body:any){
    return this.http.post(`${this.API_PATH}inbox/api/v1/inbox-loan-update/${id}/`, body, this.httpOption);
  }

  // Loan Process
  loanProcess(id:any,body:any){
    return this.http.post(`${this.API_PATH}inbox/api/v1/inbox-loan-process/${id}/`, body, this.httpOption);
  }
}
