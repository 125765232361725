import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, Input, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { LoanProcessService } from 'src/app/@core/services/loan-process.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-loan-details-import',
  templateUrl: './loan-details-import.component.html',
  styleUrls: ['./loan-details-import.component.scss']
})
export class LoanDetailsImportComponent implements OnInit {

  @ViewChild('inputFile') myInputVariable: ElementRef | any;
  @Input()  viewSidePanel : any
  @Output() closePanel = new EventEmitter()
  @Output() closeSuccess = new EventEmitter()

  fileSize       : string    = '';
  fileName       : string    = '';
  invalidFormat              = false;
  deleteToggle               = false;
  deleteMsg                  = "";
  iscompleted                = false;
  isDataUpload               = 0;
  percentage     :any        = 0;
  isProgressStart            = false;
  successDataCount           = 0;
  downloadMsg                = false;
  invalid                    = false
  confirmMsg                 = false;
  exportData     :any        = [];
  excelData      :any        = [];
  importData     :any        = [];
  failedData     :any        = [];
  importTemp     :any        = [];
  errorMsg                   = '';
  successMsg                 = '';
  body                       = '';
  ws             :any        = null;
  saveDataArray  :any        = []
  chunkLen                   = 10;

  constructor(
    public appService         : AppService,
    private changeDetectorRef : ChangeDetectorRef,
    public messageServ        : MessageService,
    private notificationService: NotificationService,
    private LPS:LoanProcessService,
    private datePipe:DatePipe
  ) { }

  ngOnInit(): void {
  }

  // File Change
  onFileChange(evt: any) {
    this.excelData  = [];
    this.importData = []
    this.iscompleted = false
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){
      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string      = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: true,cellDates: true, });
        const wsname: string = wb.SheetNames[0];
        this.ws   = wb.Sheets[wsname];
        var ws    = wb.Sheets[wsname];
        this.fileName   = target.files[0].name;
        this.fileSize   = this.appService.formatSizeUnits(target.files[0].size)
        this.excelData  = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: false,rawNumbers: false, dateNF: 'YYYY-MM-DD',blankrows:false}));
        this.processData();
        this.changeDetectorRef.detectChanges();
      };
      reader.readAsBinaryString(target.files[0]);
    }else{
      this.invalidFormat = true;
    }
  }

  // Processing Data
  processData(){
   
    if(this.excelData.length <=1){
      this.deleteToggle = true;
      this.deleteMsg = "No data found in the uploaded file.";
      this.clearData()
    } else {
      var headerRow = this.excelData[0];
      let Heading = ['Employee Code', 'Loan Type', 'Loan Amount', 'Number of EMIs', 'Interest rate', 'Interest Penalty on Payment shortfall rate', 'Loan Required date', 'Number of Remaining EMIs', 'Loan (Principal) Amount already paid', 'Interest already paid (This field is required only in case of  reducible interest type)', 'EMI deduction Start Month', 'Loan Request Date', 'Loan Dispense Date']
      let success = Heading.every(function(val:any) {
        return headerRow.indexOf(val) !== -1;
      });
      this.invalid = headerRow.every((element:any) => Heading.includes(element));
      const hasDuplicates = new Set(headerRow).size !== headerRow.length;
      
      if(success == false || !this.invalid || hasDuplicates){
        this.deleteToggle = true;
        this.deleteMsg = this.messageServ.invalidExcelFormat();
        this.clearData();
      }
      else
       this.prepareUploadData()
    }
  }

  prepareUploadData(){
    this.excelData.forEach((item:any, key:any) => {
      if(key != 0){
        this.importData.push({
          'employee_code': item[0]?item[0]:"",
          'loan_type': item[1]?item[1]:"",
          'loan_amount': isNaN(parseFloat(item[2]?item[2]:0)) ? item[2] : parseFloat(item[2]?item[2]:0),
          'number_of_emis': isNaN(parseFloat(item[3]?item[3]:0)) ? item[3] : parseFloat(item[3]?item[3]:0),
          'interest_rate':isNaN(parseFloat(item[4]?item[4]:0)) ? item[4] : parseFloat(item[4]?item[4]:0),
          'short_fall_rate':isNaN(parseFloat(item[5]?item[5]:0)) ? item[5] : parseFloat(item[5]?item[5]:0),
          'loan_required_date':item[6]?this.dateFormatConvert(item[6]):"",
          'remaining_emis': isNaN(parseFloat(item[7]?item[7]:0)) ? item[7] : parseFloat(item[7]?item[7]:0),
          'paid_loan_amount':isNaN(parseFloat(item[8]?item[8]:0)) ? item[8] : parseFloat(item[8]?item[8]:0),
          'paid_interest': isNaN(parseFloat(item[9]?item[9]:0)) ? item[9] : parseFloat(item[9]?item[9]:0),
          'deduction_start_month':item[10]?this.dateFormatConvert(item[10]):"",
          'loan_request_date': item[11]?this.dateFormatConvert(item[11]):"",
          'loan_dispense_date': item[12]?this.dateFormatConvert(item[12]):"",
        })
      }
    });
  }

  uploadLoanData(){
    this.isDataUpload = 1;
    this.iscompleted  = false
    this.percentage   = 0;

    if(this.importData.length>0){
      this.isProgressStart    = true
      this.failedData         = []
      var l                   = this.importData.length;
      var cLoop               = Math.ceil(l/this.chunkLen)
      var p                   = 100/cLoop
      this.successDataCount   = 0
      this.processUploadData(0,p);
    }
  }

  processUploadData(k:any,p:any){
    var l                 = this.importData.length;
    var x                 = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    var saveDataArray:any = [];
    this.percentage       +=(p/4);
    for(var i= k*this.chunkLen; i<x; i++){
      saveDataArray.push(this.importData[i])
    }
    this.LPS.importLoan({"bulk_loans":saveDataArray}).subscribe((res:any)=>{
      this.body       = x+' out of '+l+" data uploaded successfully"
      this.confirmMsg = false;

      if(res.body.error_data != undefined && res.body.error_data.length != 0){
          res.body.error_data.forEach((i:any, k:any) => {
              this.failedData.push(i)
          });
      }

      this.successDataCount += (this.chunkLen-res.body.error_data.length);
      this.percentage +=((p/4)*3);
      if(x<l)
          this.processUploadData(k+1,p);
      else{
        this.iscompleted      = true
        this.isProgressStart  = false
        this.successDataCount = this.importData.length- this.failedData.length;
          if(this.failedData.length == 0){
              this.notificationService.handleSuccessNotification(this.successDataCount+" loan data uploaded successfully.","Success");
              this.clearData()
              this.closeSuccess.emit()
          } else {
              this.downloadMsg  = true;
              this.exportData   = [];
              for (let i = 0; i < this.failedData.length; i++) {
                
                this.exportData.push({
                  'Employee Code':this.failedData[i]['data']['employee_code'],
                  'Loan Type':this.failedData[i]['data']['loan_type'],
                  'Loan Amount':this.failedData[i]['data']['loan_amount'],
                  'Number of EMIs':this.failedData[i]['data']['number_of_emis'],
                  'Interest rate':this.failedData[i]['data']['interest_rate'],
                  'Interest Penalty on Payment shortfall rate':this.failedData[i]['data']['short_fall_rate'],
                  'Loan Required date':this.failedData[i]['data']['loan_required_date'],
                  'Number of Remaining EMIs':this.failedData[i]['data']['remaining_emis'],
                  'Loan (Principal) Amount already paid':this.failedData[i]['data']['paid_loan_amount'],
                  'Interest already paid(This field is required only in case of reducible interest type)':this.failedData[i]['data']['paid_interest'],
                  'EMI deduction Start Month':this.failedData[i]['data']['deduction_start_month'],
                  'Loan Request Date':this.failedData[i]['data']['loan_request_date'],
                  'Loan Dispense Date':this.failedData[i]['data']['loan_dispense_date'],
                  'Error description'     : this.failedData[i].error
              });
              }
              this.successMsg = this.successDataCount+" out of "+l+" data uploaded successfully "
              this.errorMsg   = this.exportData.length+' item have error data'
          }
      }
    },
    (error:any)=>{
      this.clearData()
      this.isProgressStart = false
      this.notificationService.handleErrorNotification('Something went wrong','Error')
    }
    )
  }

  closeSidePanel(){
    this.viewSidePanel = false
    this.closePanel.emit(this.viewSidePanel)
  }

  clearData(){
    this.myInputVariable.nativeElement.value = '';
    this.fileName       = '';
    this.fileSize       = '';
  }

  dateFormatConvert(dateData:any){
    if(dateData != null){
      if(dateData instanceof moment ){
        return this.convertDate(dateData)
      } else if(dateData instanceof Date){
        moment(dateData).add(1, 'minute');
        return this.convertDate(dateData)
      } else
        return dateData;
    } else return null;
  }
  convertDate(date:any){
    return this.datePipe.transform(date, 'dd-MM-yyyy')
  }
}
