<div class="config-layout" [formGroup]="shortForm">
  <header class="config-header">

    <h5 class="m-0 text-trim w-50">Short Leave Request
      <span *ngIf="from=='behalf'" title="{{requestFor}}">
        {{' Of '+ requestFor}}
      </span>
    </h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <aside class="config-sidebar">
    <ul ngbNav #nav="ngbNav" class="steps gap-24" [(activeId)]="activeTab" (navChange)="onNavChange($event)">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="step" [class]="{'success': shortForm['controls'].policyRequest.valid}">
          <div class="count">1</div>
          <div class="content">
            <span>Step 1</span>
            <h6>Short Leave Request</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-short-leave-request [formGp]="policyRequestForm" [dateMsg]="dateMsg" (submitMethod)="submitOne($event)"
            (validationError)="validationError($event)" (validationResult)="validationResult($event)"
            (validationPortionResult)="validationPortionResult($event)" [employee]="empId" [from]="from"></app-short-leave-request>
        </ng-template>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="step">
          <div class="count">2</div>
          <div class="content">
            <span>Step 2</span>
            <h6>Summary</h6>
          </div>
        </a>
        <ng-template ngbNavContent>
          <app-short-leave-summary [formGp]="policyRequestForm" [totalDuration]="totalDuration"
            [validationData]="validationData" [validationPortionData]="validationPortionData"
            (submitMethod)="submitForm($event)"></app-short-leave-summary>
        </ng-template>
      </li>

    </ul>
  </aside>
  <main class="config-container">
    <div [ngbNavOutlet]="nav"></div>
    <!-- <pre>Form Values: {{policyRequestForm.value | json}}</pre> -->
  </main>
</div>

<app-alert-toggle *ngIf="invalidPass" (modals)="invalidPass=false" [alertToggle]="invalidPass"
  [alertMsg]="errorPass"></app-alert-toggle>
