import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AuthService } from './@core/auth/auth.service';

@Injectable()

export class AppService{
    DATEFORMAT;
    TIMEFORMAT;
    ACCESSPERMISSIONS:any;
    fileType          = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    fileExtension     = '.xlsx';
    searchString:any  = "";
    inputData:any =[];
    stringValue :any
    version = 'Version V1.4.1'
    selectedYear =  new Date().getFullYear();
    fiscalyear : any

    isSidebarVisible: boolean = false;
    sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();
    constructor(public router: Router , public datePipe:DatePipe,private authservice:AuthService){
      this.DATEFORMAT = "";
      this.TIMEFORMAT = "";
      this.ACCESSPERMISSIONS = [];
      this.sidebarVisibilityChange.subscribe((value) => {
        this.isSidebarVisible = value
     });
    }
    getEmpData(){
      return JSON.parse(this.authservice.getUserData() || '{}');
    }
    toggleSidebarVisibility(val:any) {
      this.sidebarVisibilityChange.next(val);
    }
    setdateformat(val: string){
      this.DATEFORMAT = val;
    }

    getdateformat(){
      return this.DATEFORMAT;
    }

    getdatepickerformat(){
      return this.datePickerDateformat(this.DATEFORMAT);
    }

    settimeformat(val: string){
      this.TIMEFORMAT = val;
    }

    gettimeformat(){
      return this.TIMEFORMAT;
    }
    resetPermissions(){
      this.ACCESSPERMISSIONS = []
    }

    setPermissions(val: any){
       this.createpermissionsArray(val);
    }
    createpermissionsArray(val: any){
        val.forEach((element: any) => {
            if(element.children.length == 0 && element.url != ""){
                this.ACCESSPERMISSIONS[element.url] = element;
            } else if(element.children.length > 0){
              this.ACCESSPERMISSIONS[element.url] = element;
              this.createpermissionsArray(element.children)
            }
        });
    }

    getPermissions(url:any){
      if(url == ""){
        const urlArray = this.router.url.split(';');
        return this.ACCESSPERMISSIONS[urlArray[0]];
      }else
      return this.ACCESSPERMISSIONS[url];
    }

    datePickerDateformat(datestring:any){
      if(datestring == 'yyyy-MMM-dd'){
        datestring = 'YYYY-MMM-DD'
      } else if(datestring == 'MMM-dd-yyyy'){
        datestring = 'MMM-DD-YYYY'
      }
      else if(datestring == 'dd MMMM,yyyy'){
        datestring = 'DD MMM,YYYY'
      }
      else if(datestring == 'dd-MM-yyyy'){
        datestring = 'DD-MM-YYYY'
      }
      else if(datestring == 'dd.MM.yyyy'){
        datestring = 'DD.MM.YYYY'
      }
      else if(datestring == 'yyyy-MM-dd'){
        datestring = 'YYYY-MM-DD'
      }
      else if(datestring == 'yyyy/MM/dd'){
        datestring = 'YYYY/MM/DD'
      }
      else if(datestring == 'MM/dd/yyyy'){
        datestring = 'MM/DD/YYYY'
      }
      return datestring;
    }


  dateFormatConvert(date:any){
    if(date != null){
        let year    =  Number(this.datePipe.transform(date, 'yyyy'));
        let month   =  Number(this.datePipe.transform(date, 'MM'));
        let day     =  Number(this.datePipe.transform(date, 'dd'));
        let finalDate = year + "-" + month + "-" + day;
        return finalDate;
    }else return null;
  }

  timeFormatConvert(time: any){
    if(time != null){
      let hour   =  Number(this.datePipe.transform(time, 'hh'));
      let minute   =  Number(this.datePipe.transform(time, 'mm'));
      let seconds   =  Number(this.datePipe.transform(time, 'ss'));
      let finalTime = hour + ":" + minute + ":" + seconds;
      return finalTime;
  }else return null;
  }
  // Function for convert date format for display in edit section
  dateFormatDisplay(date:any){
    return this.datePipe.transform(date, this.getdateformat())?.toString()
  }

  dateFormatDisplayYear(date:any){
    return this.datePipe.transform(date, 'yyyy')?.toString()
  }
  // Function for convert time format for display in edit section
  timeFormatDisplay(time:any){
    if(time != null){
      var timearray = time.split(":");
      var today = new Date();
      var myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), timearray[0], timearray[1], timearray[2]);
      return this.datePipe.transform(myToday, this.gettimeformat())?.toString()
    }else return null;

  }
  timeFormatAttConvert(time: any){
    if(time != null && time instanceof Date){
      let hour   =  Number(this.datePipe.transform(time, 'HH'));
      let minute   =  Number(this.datePipe.transform(time, 'mm'));
      let seconds   =  Number(this.datePipe.transform(time, 'ss'));
      let finalTime = hour + ":" + minute + ":" + seconds;
      return finalTime;
  }else return time;
  }

  // Export as excel file
  exportExcel(data: any[], fileName: string): void {

    const ws: XLSX.WorkSheet  = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook   = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any    = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }
  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  /************ Two Letter Tile Case *****************/
  // getFirstChar(name:any,num:number){
  //   if(name != undefined &&  name != null && name != ''){
  //     if(name.split(' ')?.length == 1){
  //      return name.substring(0, num);
  //     }
  //     else{
  //     return JSON.stringify(name.match(/\b(\w)/g)).replace(/[^A-Za-z]/g, '').substring(0, num);
  //     }
  //   }else{
  //   return;
  //  }
  // };

  // Naming conventions for screens
  // First name first letter and last name first letter
  rtrim = function (s:any) {
    return s.replace(/((\s*\S+)*)\s*/, "$1");
  }

  getFirstChar(str:any,num:any){
    let result = '';
    if(str!== undefined && str!== null && str!== ''){
      // Remove the HTML tag from search results
      let isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
      if(isHTML(str)){
        str = str.replace(/<\/?[^>]+(>|$)/g, "");
      }
      let index;
      let newStr;
      if(str.indexOf('(') >=0){
        index  = str.indexOf('(')
        newStr = str.substring(0, index);
      }
       else{
        newStr = str
       }


      let lastWords;

      this.stringValue = this.rtrim(newStr).split(' ');
      let firstName = this.stringValue[0].charAt(0);
      if(this.stringValue.length > 1){
        let lastWord = this.stringValue.length - 1
        lastWords = this.stringValue[lastWord].charAt(0)
      }

       result  = (firstName+""+(lastWords===undefined ? "":lastWords)).toUpperCase()

      return result;
    }else{
      return result;
    }

  }


  filterIt(items:any,searchString:any="") {
    if(items != undefined ){
      return items.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey = obj[key]?.toString().toLowerCase();
            const tempSearch = searchString.toLowerCase();
            return tempKey?.includes(tempSearch);
          }
        });
      });
    }
  }

  filteredcount(items:any,searchString:any=""){
    return this.filterIt(items,searchString).length;
  }

  roundUp(value: any){
    return value.toFixed(2);
  }

  getDocName(name:string){
    if(name !='' && name != undefined && name != null){
      // var docname : any  = name.split('_').pop();
      // docname            = decodeURIComponent(docname);
      // return docname;
      const segments = name.split('/'); // Split by '/'
      const encodedName = segments[segments.length - 1]; // Get the last segment (filename + unique ID)
      const decodedName = decodeURIComponent(encodedName);
      const filenameSegments = decodedName.split('_'); // Split by underscore to get segments
      return filenameSegments.slice(1).join(' ');
    }else{
      return ''
    }
  }

  downloadFile(filename:any,docname:any){
    let cleanedFilename = filename.includes('?') ? filename.split('?')[0] : filename;
    let currentExtension  = cleanedFilename?.split('.').pop();
    let FileSaver         = require('file-saver');
    FileSaver.saveAs(filename,docname+'.'+currentExtension);
  }

  formatSizeUnits(bytes:any){
    if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
    else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
    else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
    else if (bytes > 1)           { bytes = bytes + " bytes"; }
    else if (bytes == 1)          { bytes = bytes + " byte"; }
    else                          { bytes = "0 bytes"; }
    return bytes;

  }

  selectAll(inputdata:any){
    inputdata.forEach((item:any, key:any) => {
      item.checked =true

    })

    return inputdata;
  }


  unselectAll(inputdata:any){

    inputdata.forEach((item:any, key:any) => {
      item.checked =false
    })
    return inputdata;
  }
  subEmp(num:number){
    return JSON.stringify(num-3);
  }

  // To get the subdomain
  getSubDomain(){
    if(!window.location.origin .includes("localhost")){
      return window.location.origin.split('//')[1].split('.')[0];
    }else{
      return 'remya';
    }
  }

  getFinancialYearRange(year:any){
    var today       = new Date();
    var fiscalyearrange  = '';
    if(year == today.getFullYear()){
      if ((today.getMonth() + 1) <= 3) {
        fiscalyearrange = (Number(year) - 1) + "-" + Number(year);
      } else {
        fiscalyearrange = Number(year) + "-" + (Number(year) + 1);
      }
    }else{
      fiscalyearrange = Number(year) + "-" + (Number(year) + 1);
    }

    return fiscalyearrange;
  }
  getFinacialYearByMonth(month:any,year:any){
   var financialYear;
   if(Number(month)<=3)
   financialYear = Number(year)-1;
   else
   financialYear = year;
   return financialYear
  }

  getFinancialYear(){
    var today       = new Date();
    var fiscalyear;

      if ((today.getMonth() + 1) <= 3) {
        fiscalyear = (Number(today.getFullYear()) - 1) ;
      } else {
        fiscalyear = Number(today.getFullYear()) ;
      }
    return fiscalyear;
  }
  // Function for populating Financial Year in FY dropdown. Add new FY from March other than April.
  getEarlyFinancialYear(){
    var today       = new Date();
    var fiscalyear;

      if ((today.getMonth() + 1) < 3) {
        fiscalyear = (Number(today.getFullYear()) - 1) ;
      } else {
        fiscalyear = Number(today.getFullYear()) ;
      }
    return fiscalyear;
  }

  formatTime(timeString:any) {
    const [hourString, minute] = timeString.split(":");
    return (hourString) + " Hour " + minute + " Min";
  }
  //function to display time as 8.5 hrs
  timeStringToFloat(time:any) {
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return  (hours + minutes / 60).toFixed(2);
  }
// convert date from dd-mm-yyyy.Used in excel upload.Coloumn always in dd-mm-yyyy structure other than system provided format
  DateConversion(dateData:any){
    if(dateData != null){
      if(dateData instanceof moment ){

        return this.convertDate(dateData)
      } else if(dateData instanceof Date){
        moment(dateData).add(1, 'minute');
        return this.convertDate(dateData)
      } else
        return dateData;
    } else return null;
  }
  convertDate(date:any){
    return this.datePipe.transform(date, 'dd-MM-yyyy')
  }

  hhmmTimeFormat(time:any){
      if(time != null){
        let a = time.split(":");
        a[0] = a[0]?.length == 1 ? '0'+a[0] : a[0];
        a[1] = a[1]?.length == 1 ? a[1]+'0' : a[1];
        let finalTime  = a[0]+':'+a[1];
        return finalTime;
      }else return time;
  }

  // Get day and Month
  getDayMonth(day:any){
    return new Date(day).toLocaleString('en-us',{month:'short', day:'numeric'})
  }
 //Get last date of the week from a specified date
  convertToDate(val:any,value:any){
    var date = new Date(value);
    var d;
    val == 'to' ?  d = date.getDate()+6 :  d = date.getDate()
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    var dateString = (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;
    dateString = dateString.split("-").reverse().join("-")
    return dateString
  }
  ordinal_suffix_of(i:any) {
    var j = i % 10,
        k = i % 100;

    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    if (i == "Last day of month"){
      return i;
    }
    return i + "th";
 }

  // Financial Year Dropdown
  getFinancialYearDropdown(){
    const thisYear = this.getEarlyFinancialYear() + 1
    const yearArray = [0, 1, 2, 3].map((count) => `${(thisYear - count - 1)} - ${thisYear- count}`);
    return yearArray;
  }

  // Previous Year Dropdown
  generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 3
    var years = []

    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }

   // To get array of days in a month
  getDaysArray(year:any, month:any) {
    var monthIndex = new Date(Date.parse(month +" 1, 2012")).getMonth()
    var names = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ];
    var date = new Date(year, monthIndex, 1);
    // console.log(date)
    var result = [];
    while (date.getMonth() == monthIndex) {
      result.push(names[date.getDay()]);
      date.setDate(date.getDate() + 1);
    }
    return result;
  }
  isValidDateTime(datetime:any){
    return !isNaN(new Date(datetime).getDate());
  }
   getMonthNumberFromName(monthName:any) {
    const year = new Date().getFullYear();
    return new Date(`${monthName} 1, ${year}`).getMonth() ;
  }
  // time difference function
  Timedifference(val1:any,val2:any){
    const pad = (num:any) => ("0" + num).slice(-2);
    const diff = (s:any, e:any) => {
      const [shh, smm] = s.split(":");
      const [ehh, emm] = e.split(":");
      const smin = +smm + (60 * shh);
      const emin = +emm + (60 * ehh);
      let mDiff = s > e ? Math.abs(smin - (24 * 60) - emin) : emin - smin;
      let hours = Math.floor(mDiff / 60);
      let minutes = mDiff % 60;
      return pad(hours) + ":" + pad(minutes) + ":" + '00'
  }
  return diff(val1,val2);
  }

  //date time function
  datetimeconvert(input:any){
    var datetimeMoment = moment(input, 'YYYY-MM-DD HH:mm:ss');
    var date = this.dateFormatDisplay(datetimeMoment.format('YYYY-MM-DD'));
    var time = this.timeFormatDisplay(datetimeMoment.format('HH:mm:ss'));
    return date + ' '  + time
  }

  formatTimeDate(timeDate:any){
    return moment(timeDate).format(this.gettimeformat())
  }

getPayTaxName(name:any,type:any){
  if(name !='' && name != undefined && name != null){
    const segments = name.split('/');
    const encodedName = segments[segments.length - 1];
    const decodedName = decodeURIComponent(encodedName);
    const filenameSegments = decodedName.split('_');
    const arrangement = `${type}_${filenameSegments.slice(1).join('_').slice(0,-4)}`
    return arrangement;
  }else{
    return ''
  }
}

memFilterArray(arrFilter:any,ordering:any){
  return {
    company               : arrFilter['company__in'] && arrFilter['company__in']!='' ? arrFilter['company__in']?.split(',') : '',
    bu                    : arrFilter['businessunit__in'] && arrFilter['businessunit__in']!='' ? arrFilter['businessunit__in']?.split(',') : '',
    department            : arrFilter['department__in'] && arrFilter['department__in']!='' ? arrFilter['department__in']?.split(',') : '',
    designation           : arrFilter['designation__in'] && arrFilter['designation__in']!='' ? arrFilter['designation__in']?.split(',') : '',
    grade                 : arrFilter['grade__in'] && arrFilter['grade__in']!='' ? arrFilter['grade__in']?.split(',') : '',
    emp_status            : arrFilter['employment_status__in'] && arrFilter['employment_status__in']!='' ? arrFilter['employment_status__in']?.split(',') : '',
    emp_type              : arrFilter['employment_type__in'] && arrFilter['employment_type__in']!='' ? arrFilter['employment_type__in']?.split(',') : '',
    branch                : arrFilter['branch__in'] && arrFilter['branch__in']!='' ? arrFilter['branch__in']?.split(',') : '',
    repomanager           : arrFilter['reporting_manager__in'] && arrFilter['reporting_manager__in']!='' ? arrFilter['reporting_manager__in']?.split(',') : '',
    funmanager            : arrFilter['functional_manager__in'] && arrFilter['functional_manager__in']!='' ? arrFilter['functional_manager__in']?.split(',') : '',
    sortby                : arrFilter['ordering'] && arrFilter['ordering']!='' ? ordering[arrFilter['ordering']] : '',
  }
}

memOrdering(){
  let ordering:any = []

  ordering['user__first_name']='Alphabetically (A-Z)'
  ordering['-user__first_name'] = 'Alphabetically (Z-A)'
  ordering['-created_at'] = 'Latest'
  ordering['-modified_at'] = 'All'
  ordering['is_active'] = 'Active First'
  ordering['-is_active'] = 'Inactive First'
  return ordering
}

memFilterArray2(arrFilter:any,ordering:any){
  return {
    company               : arrFilter['employee__company__in']!='' ? arrFilter['employee__company__in']?.split(',') : '',
    bu                    : arrFilter['employee__businessunit__in']!='' ? arrFilter['employee__businessunit__in']?.split(',') : '',
    department            : arrFilter['employee__department__in']!='' ? arrFilter['employee__department__in']?.split(',') : '',
    designation           : arrFilter['employee__designation__in']!='' ? arrFilter['employee__designation__in']?.split(',') : '',
    grade                 : arrFilter['employee__grade__in']!='' ? arrFilter['employee__grade__in']?.split(',') : '',
    emp_status            : arrFilter['employee__employment_status__in']!='' ? arrFilter['employee__employment_status__in']?.split(',') : '',
    emp_type              : arrFilter['employee__employment_type__in']!='' ? arrFilter['employee__employment_type__in']?.split(',') : '',
    branch                : arrFilter['employee__branch__in']!='' ? arrFilter['employee__branch__in']?.split(',') : '',
    repomanager           : arrFilter['employee__reporting_manager__in']!='' ? arrFilter['employee__reporting_manager__in']?.split(',') : '',
    funmanager            : arrFilter['employee__functional_manager__in']!='' ? arrFilter['employee__functional_manager__in']?.split(',') : '',
    sortby                : arrFilter['ordering']!='' ? ordering[arrFilter['ordering']] : '',
  }
}

memFilterArray3(arrFilter:any,ordering:any){
  return {
    company               : arrFilter['company'] && arrFilter['company']!='' ? JSON.parse(arrFilter['company']):'',
    bu                    : arrFilter['businessunit'] && arrFilter['businessunit']!='' ? JSON.parse(arrFilter['businessunit']):'',
    department            : arrFilter['department'] && arrFilter['department']!='' ? JSON.parse(arrFilter['department']):'',
    designation           : arrFilter['designation'] && arrFilter['designation']!='' ? JSON.parse(arrFilter['designation']):'',
    grade                 : arrFilter['grade'] && arrFilter['grade']!='' ? JSON.parse(arrFilter['grade']):'',
    emp_status            : arrFilter['employment_status'] && arrFilter['employment_status']!='' ? JSON.parse(arrFilter['employment_status']):'',
    emp_type              : arrFilter['employment_type'] && arrFilter['employment_type']!='' ? JSON.parse(arrFilter['employment_type']):'',
    branch                : arrFilter['branch'] && arrFilter['branch']!='' ? JSON.parse(arrFilter['branch']):'',
    repomanager           : arrFilter['reporting_manager'] && arrFilter['reporting_manager']!='' ? JSON.parse(arrFilter['reporting_manager']):'',
    funmanager            : arrFilter['functional_manager'] && arrFilter['functional_manager']!='' ? JSON.parse(arrFilter['functional_manager']):'',
    sortby                : arrFilter['ordering'] && arrFilter['ordering']!='' ? ordering[arrFilter['ordering']]:''
  }
}


memOrdering1(){
  let ordering:any = []
  ordering['-modified_at'] = 'All'
  ordering['-created_at'] = 'Latest'
  ordering['employee__user__first_name'] = 'Alphabetically (A-Z)'
  ordering['-employee__user__first_name'] = 'Alphabetically (Z-A)'
  return ordering;
}

memFilterArray4(arrFilter:any,ordering:any){

  return {
    company               : arrFilter['employee__company__in']!='' ? arrFilter['employee__company__in']?.split(',') : '',
    bu                    : arrFilter['employee__businessunit__in']!='' ? arrFilter['employee__businessunit__in']?.split(',') : '',
    department            : arrFilter['employee__department__in']!='' ? arrFilter['employee__department__in']?.split(',') : '',
    designation           : arrFilter['employee__designation__in']!='' ? arrFilter['employee__designation__in']?.split(',') : '',
    grade                 : arrFilter['employee__grade__in']!='' ? arrFilter['employee__grade__in']?.split(',') : '',
    emp_status            : arrFilter['employee__employment_status__in']!='' ? arrFilter['employee__employment_status__in']?.split(',') : '',
    emp_type              : arrFilter['employee__employment_type__in']!='' ? arrFilter['employee__employment_type__in']?.split(',') : '',
    branch                : arrFilter['employee__branch__in']!='' ? arrFilter['employee__branch__in']?.split(',') : '',
    repomanager           : arrFilter['employee__reporting_manager__in']!='' ? arrFilter['employee__reporting_manager__in']?.split(',') : '',
    funmanager            : arrFilter['employee__functional_manager__in']!='' ? arrFilter['employee__functional_manager__in']?.split(',') : '',
    exit_date_type        : arrFilter['exit_date_type']!='' ? arrFilter['exit_date_type'] : '',
    from_date             : arrFilter['from_date']!='' ? new Date(arrFilter['from_date']) : '',
    to_date               : arrFilter['to_date']!='' ?  new Date(arrFilter['to_date']) : '',
    sortby                : arrFilter['ordering']!='' ? ordering[arrFilter['ordering']] : '',
  }
}

memFilterArray5(arrFilter:any){

  return {
    BranchList            : arrFilter['employee_leave__employee__branch__in']!='' ? arrFilter['employee_leave__employee__branch__in']?.split(',') : '',
    buList                : arrFilter['employee_leave__employee__businessunit__in']!='' ? arrFilter['employee_leave__employee__businessunit__in']?.split(',') : '',
    companyList           : arrFilter['employee_leave__employee__company__in']!='' ? arrFilter['employee_leave__employee__company__in']?.split(',') : '',
    DepList               : arrFilter['employee_leave__employee__department__in']!='' ? arrFilter['employee_leave__employee__department__in']?.split(',') : '',
    DesignationList       : arrFilter['employee_leave__employee__designation__in']!='' ? arrFilter['employee_leave__employee__designation__in']?.split(',') : '',
    gradeList            : arrFilter['employee_leave__employee__grade__in']!='' ? arrFilter['employee_leave__employee__grade__in']?.split(',') : '',
  }
}

gotoLastVisited(){
  const lastVisitedUrl: any = localStorage.getItem('last_visited_url'+this.authservice.isUserId);
  localStorage.removeItem('last_visited_url'+this.authservice.isUserId);
  if (lastVisitedUrl && this.getPermissions(lastVisitedUrl)) {
      this.router.navigateByUrl(lastVisitedUrl);
  } else {
    this.router.navigate(['home/']);
  }
}


// Currency Conversion
  formatCurrency(currencyCode: string, amount: number): string {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 2,
    }).format(amount);
  }

}