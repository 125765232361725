import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExpensePolicyService {
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
    })
  }

  constructor( private http: HttpClient,public router : Router) { }

  createcategory(data:any){
    return this.http.post(`${this.API_PATH}expense/api/v1/expense-category/`, data, this.httpOption)
  }
  getcategoryData(status:any,limit:any,offset:any,search:any,order:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-category/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}&ordering=${order}`, this.httpOption);
  }
  getcategorySearchList(status:any,search:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-category-dropdown/?is_active=${status}&ordering=name&search=${search}`, this.httpOption);
  }
  getSinglecategoryData(id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/profile-expense-category-view/${id}`, this.httpOption);
  }
  deletecategory(id:any,data:any) {
    return this.http.patch(`${this.API_PATH}expense/api/v1/expense-category/${id}/`,data, this.httpOption)
  }
  updatecategory(id:number,data:any){
    return this.http.put(`${this.API_PATH}expense/api/v1/expense-category/${id}/`,data, this.httpOption)
  }

  createpolicy(data:any){
    return this.http.post(`${this.API_PATH}expense/api/v1/expense-policy/`, data, this.httpOption)
  }
  getpolicyData(status:any,limit:any,offset:any,search:any,order:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-policy/?is_active=${status}&limit=${limit}&offset=${offset}&search=${search}&ordering=${order}`, this.httpOption);
  }
  getpolicySearchList(status:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-policy-dropdown/?is_active=${status}&ordering=name`, this.httpOption);
  }
  getSinglepolicyData(id:any){
    return this.http.get(`${this.API_PATH}expense/api/v1/expense-policy/${id}`, this.httpOption);
  }
  deletepolicy(id:any,data:any) {
    return this.http.patch(`${this.API_PATH}expense/api/v1/expense-policy/${id}/`,data, this.httpOption)
  }
  updatepolicy(id:number,data:any){
    return this.http.put(`${this.API_PATH}expense/api/v1/expense-policy/${id}/`,data, this.httpOption)
  }

}
