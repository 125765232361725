import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, HostListener, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { AppService } from 'src/app/app.global';
import * as moment from 'moment';
import { MessageService } from 'src/app/message.global';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-employee-map-view',
  templateUrl: './app-employee-map-view.component.html',
  styleUrls: ['./app-employee-map-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeMapViewComponent implements OnInit,OnChanges {
  activeTab = 1;
  sidebarCollapse=true;
  DRIVING: google.maps.TravelMode |undefined
  constructor(
    private cd: ChangeDetectorRef,
    public messageServ : MessageService,
    public appservice: AppService,
    public datePipe:DatePipe) {

  }

  @Input() today: any
  @Input()childloader = false;
  @Input() data: any;
  @Input() empData: any;
  @Output() closepanel = new EventEmitter();
  @Output() emitDateSlider = new EventEmitter();

  zoom: number = 18;
  infoToggle:any
  dirs: Array<any> = []
  styles: any = [{
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  ]
  ways:any = []
  map: any;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.sidebarCollapse = true
  }
  ngOnInit(): void {
    // this.prepareMapList()
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.dirs = []
    this.clickedMarkerKey = -1
    this.click = -1
    this.firstdirKey = -1
    this.prepareMapList()
  }
  closePanel() {
    this.closepanel.emit();
  }

  click = -1
  showbredcrumb = false
  openedWindow: number = -1; // alternative: array of numbers
  openedMWindow: number = -1; // alternative: array of numbers
  fullbreadcrumb:any = []
  fulldirs:any = []
  lastdirKey = 0
  firstdirKey = -1
  ros = { polylineOptions: { strokeColor: "#111B22",strokeWeight: 5,zIndex:99 },suppressMarkers: true }
  roswob = { polylineOptions: { strokeColor: '#0066DD',strokeWeight: 5,zIndex:99 } ,suppressMarkers: true}

  centre:any
  icentre:any
  centre2:any
  icentre2:any
  clickedMarker:any
  clickedMarkerKey:any =-1

  prepareloader = true
  badges:any = {"Stationary" :'text-warning',
    "Mobile" :'text-success',
    "Check-out":'text-danger',
    "Check-in":'badge-primary',
    "End of Tracking":"cbg-7"
  }
  openWindow(id: any) {
    if (this.isInfoWindowOpen(id))
      this.openedWindow = -1;
    else
      this.openedWindow = id; // alternative: push to array of numbers
  }

  isInfoWindowOpen(id: any) {
    return this.openedWindow == id; // alternative: check if id is in array
  }

  mapReady(event: any) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('Settings'));
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('Zoom'));
  }

  // async calcRoute(origin: any, destination: any,ways:any) {
  //   for(var i = ways.length-23; i>=0; i--){
  //     let x1 = Math.floor(Math.random()*ways.length)
  //     ways.splice(x1, 1);
  //   }
  //   var directionsService = new google.maps.DirectionsService();
  //   var request = {
  //     origin: origin,
  //     destination: destination,
  //     waypoints: ways,
  //     optimizeWaypoints: true,
  //     travelMode: google.maps.TravelMode.DRIVING
  //   };
  //   var res:any = null
  //   await directionsService.route(request, function(response, status) {
  //     if (status == google.maps.DirectionsStatus.OK) {
  //         res= response.routes[0].legs[0].distance.text;
  //     }
  //   });
  //  return res
  // }
  prepareWays(origin: any, destination: any,ways:any){
    for(var i = ways.length-23; i>=0; i--){
      let x1 = Math.floor(Math.random()*ways.length)
      ways.splice(x1, 1);
    }
    var directionsService = new google.maps.DirectionsService();
      var request = {
        origin: origin,
        destination: destination,
        waypoints: ways,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING
      };
      var newWays:any = []
      var polyline:any
      directionsService.route(request, function(response, status) {
        if (status == google.maps.DirectionsStatus.OK) {
          var route = response.routes[0];
          var routePath = route.overview_path;
          polyline = new google.maps.Polyline({
            path: routePath,
          });

          ways.forEach(async(val: any, key: any) => {
            if (google.maps.geometry.poly.isLocationOnEdge(val.location, polyline, 0.0001)) {
              newWays.push(val)
            }
          });
          for(var i = newWays.length-23; i>=0; i--){
            let x1 = Math.floor(Math.random()*newWays.length)
            newWays.splice(x1, 1);
          }
        }
      });
      return newWays
  }
   isLocationInPath(origin: any, destination: any,ways:any){
    return this. prepareWays(origin, destination,ways)
  }
  async prepareMapList(){
    this.prepareloader = true
    let origin = false
    let originPts:any
    let originVal:any
    let originKey:any
    let DestPts:any
    let DestVal:any
    let DestKey:any
    let ways:any = []
    let waysVal:any = []
    let k=0
    let rs:any,ms:any,fbms:any
    let dirCount = 0
    rs =   { polylineOptions: { strokeColor: '#0066DD',strokeWeight: 5,zIndex:1  } ,suppressMarkers: true},
    ms =  {}
    fbms = {}
    this.fullbreadcrumb = []
   this.data.tracking_datas.forEach(async (val: any, key: any) => {
    await new Promise((resolve, reject) => {
      setTimeout(() =>{
        if(this.firstdirKey!=k){
          fbms = {
            origin: {visible:false},
            destination:  {visible:false},
          }
        }
        if(key == 0)
          this.centre =  val

          if(this.dirs.length>0 || val.realtime_status == 'Check-in'|| origin == true){
            this.fullbreadcrumb.push({ lat: parseFloat(val.latitude.toFixed(6)), lng: parseFloat(val.longitude.toFixed(6)),val:val })
            if(origin == false && val.mobility_status == "Mobile"){
              if(this.firstdirKey==-1){
                this.centre =   val
                this.icentre =   val
                this.firstdirKey = k
                fbms = {
                  origin: { "icon":"assets/images/helper/map-img/map-pin1-45.png" ,  infoWindow: this.infoWindow(val) },
                  destination:  {visible:false},
                }
              }
              originPts =  new google.maps.LatLng({ lat: Number(val.latitude.toFixed(6)), lng: Number(val.longitude.toFixed(6)) })
              originVal = val
              originKey = key
              origin = true
              ways = []
              waysVal = []
              if(this.data.tracking_datas[k+1]==undefined){
                this.dirs.push({
                  isDirection : false,
                  key:k,
                  marker: new google.maps.LatLng({ lat: Number(val.latitude.toFixed(6)), lng: Number(val.longitude.toFixed(6)) }),
                  markerVal:val,
                  markerKey:key,
                })
              }
            } else if(origin == false && val.mobility_status == "Stationary" ){
              this.dirs.push({
                isDirection : false,
                key:k,
                marker: new google.maps.LatLng({ lat: Number(val.latitude.toFixed(6)), lng: Number(val.longitude.toFixed(6)) }),
                markerVal:val,
                markerKey:key,
              })
              k++
            } else if(origin == true && (val.mobility_status=="Stationary"|| key == this.data.tracking_datas.length-1 || val.realtime_status == "Check-out")){
              this.lastdirKey = k
              origin = false
              DestPts =  new google.maps.LatLng({ lat: Number(val.latitude.toFixed(6)), lng: Number(val.longitude.toFixed(6)) })
              DestVal = val
              DestKey = key
              // let dis = this.calcRoute(res,DestPts,ways)
              let dis = this.getTotalDistance(originKey,DestKey)
              let newways =  this.isLocationInPath(originPts,DestPts,ways)

              if(key==this.data.tracking_datas.length-1) {
                this.centre2 =   val
                this.icentre2 =   val
                fbms =  dirCount == 0?{
                   origin: { "icon":"assets/images/helper/map-img/map-pin1-45.png" ,  infoWindow: this.infoWindow(originVal) },
                  destination:  { "icon":"assets/images/helper/map-img/map-pin1-45.png",  infoWindow: this.infoWindow(val)},
                }:{
                  origin: {visible:false},
                  destination:  { "icon":"assets/images/helper/map-img/map-pin1-45.png",  infoWindow: this.infoWindow(val)},
                };
              }
              dirCount++
              this.dirs.push({
                isDirection : true,
                key:k,
                origin: originPts,
                destination: DestPts,
                renderOptions: rs,
                markerOptions : ms,
                waypoints :newways,
                waysVal: waysVal,
                originVal:originVal,
                DestVal:DestVal,
                originKey:originKey,
                DestKey:DestKey,
                distance:dis,
                fbms:fbms,
                polypoints:waysVal
              })
              k++

              if(val.mobility_status=="Stationary" && val.realtime_status != "Check-out"){
                this.dirs.push({
                  isDirection : false,
                  key:k,
                  marker: new google.maps.LatLng({ lat: Number(val.latitude.toFixed(6)), lng: Number(val.longitude.toFixed(6)) }),
                  markerVal:val,
                  markerKey:key,
                })
                k++
              }

            } else {
              ways.push({ location:  new google.maps.LatLng({ lat: parseFloat(val.latitude.toFixed(6)), lng: parseFloat(val.longitude.toFixed(6)) }),stopover: false, })
              waysVal.push({ lat: parseFloat(val.latitude.toFixed(6)), lng: parseFloat(val.longitude.toFixed(6)),val:val })
            }
          }
          this.cd.detectChanges()
          resolve("successful");
        }, 10);
      });

    })

    setTimeout(()=>{
      this.prepareloader = false;this.cd.detectChanges()
      this.centreMap()
    },1000);
    }
  infoWindow(val:any){
    return `<div class="card card-c2 border-0" style="max-width: 16rem;">
            <div class="card-body overflow-hidden hstack gap-8 px-0 fs-12 pt-0 pb-12">
                    <i class="text-accent1 fs-16 icon-geo-alt-fill"></i>
                    <span class=" fw-500 text-trim-3" title="`+val.location+`">`+val.location+`</span>
            </div>
            <div class="card-body overflow-hidden vstack gap-8 px-0 fs-12 pt-12 pb-0">
                <div class="hstack gap-16 justify-content-between">
                    <span class="text-light-500 ">Mobility Status</span>
                    <span class=" text-end text-uppercase `+this.badges[val.mobility_status]+` fw-500">`+val.mobility_status+`</span>
                </div>
                <div class="hstack gap-16 justify-content-between">
                    <span class="text-light-500 ">Time</span>
                    <span class=" text-end fw-500">`+this.appservice.formatTimeDate(val.timestamp)+this.timeConvert(val.plugin_data.stationary_interval)+`</span>
                </div>
                <div class="hstack gap-16 justify-content-between">
                    <span class="text-light-500 ">Date</span>
                    <span class="text-end fw-500"  >`+this.appservice.dateFormatDisplay(val.timestamp)+`</span>
                </div>
            </div>
        </div>`
  }
  timeConvert(stationaryInterval: any){
    if(stationaryInterval!=null)
      return " ("+this.timeFormatConvert(stationaryInterval)+")"
    else return ""
  }
  timeFormatConvert(stationaryInterval: any){
    let time = this.today+" "+stationaryInterval
    if(time != null&& stationaryInterval!=null){
      // let hour   =  Number(this.datePipe.transform(time, 'HH'));
      // let minute   =  Number(this.datePipe.transform(time, 'mm'));
      // let finalTime = hour + ":" + minute ;
      const [hourString, minute] = stationaryInterval.split(":");
      return (hourString.replace(/^0/, '')) + "h " + minute.replace(/^0/, '') + "m";
    }else return "";
  }

  centreMap() {
    const bonds: any = new google.maps.LatLngBounds();
    if(this.centre?.latitude && this.centre?.longitude)
    bonds.extend(new google.maps.LatLng(this.centre?.latitude, this.centre?.longitude));
    if(this.centre?.location !=this.centre2?.location && this.centre2?.latitude && this.centre2?.longitude)
      bonds.extend(new google.maps.LatLng(this.centre2?.latitude, this.centre2?.longitude));
    this.map.fitBounds(bonds);
  }

  clicklist(click:any,showBreadcrumb:boolean=false){
    this.openedMWindow = -1;
    if(click == this.click && showBreadcrumb ==this.showbredcrumb)
      this.click = click = -1;this.centre = this.icentre;this.centre2 = this.icentre2
    this.showbredcrumb = showBreadcrumb
    this.clickedMarkerKey = -1
    if(click != -1){
    this.click = click
    let newdirs:any = []
      this.dirs.forEach((val: any, key: any) => {
        if(key==click && val.isDirection){
          this.centre =   val.originVal
          this.centre2 =   val.DestVal
          newdirs.push({
            isDirection : true,
            key:val.key,
            origin: val.origin,
            destination: val.destination,
            renderOptions: { polylineOptions: { strokeColor: "#111B22",strokeWeight: 5,zIndex:99 },suppressMarkers: true },
            markerOptions: {
              origin: { "icon":"assets/images/helper/map-img/map-pin2-45.png" ,  infoWindow: this.infoWindow(val.originVal)},
              destination:  { "icon":"assets/images/helper/map-img/map-pin2-45.png",  infoWindow: this.infoWindow(val.DestVal)},
            },
            waypoints :val.waypoints,
            waysVal :val.waysVal,
            originVal:val.originVal,
            DestVal:val.DestVal,
            originKey:val.originKey,
            DestKey:val.DestKey,
            distance:val.distance,
            fbms:val.fbms,
            polypoints:val.polypoints,
            strokeColor: "#111B22"
          })
          if(showBreadcrumb)
            this.ways = val.waysVal
          else
            this.ways = []
        } else if(val.isDirection){
          newdirs.push({
            isDirection : true,
            key:val.key,
            origin: val.origin,
            destination: val.destination,
            renderOptions: { polylineOptions: { strokeColor: "#0066DD",strokeWeight: 5,zIndex:1 } ,suppressMarkers: true},
            markerOptions: {},
            waypoints :val.waypoints,
            waysVal :val.waysVal,
            originVal:val.originVal,
            DestVal:val.DestVal,
            originKey:val.originKey,
            DestKey:val.DestKey,
            distance:val.distance,
            fbms:val.fbms,
            polypoints:val.polypoints,strokeColor: "#0066DD"
          })
        } else {
          newdirs.push({
            isDirection : false,
            key:val.key,
            marker: val.marker,
            markerVal:val.markerVal,
            markerKey:val.markerKey,
        })
      }
      })

      this.dirs = newdirs
      setTimeout(() => {
        this.centreMap()
      }, 300);
    }
  }
  timeCompare(datetimeStart:any,datetimeEnd:any){
    if(Date.parse(datetimeStart) < Date.parse(datetimeEnd))
      return true
    else
      return false
  }
  clickMarker(key:any){
    this.openedMWindow = -1;
    if(this.clickedMarkerKey != key){
      this.click= -1
      this.clickedMarker = this.dirs[key].markerVal
      this.showbredcrumb = false
      this.clickedMarkerKey = key
    } else
      this.clickedMarkerKey = -1
  }

  openMarkerWindow(id: any) {
    if (this.isInfoMWindowOpen(id))
      this.openedMWindow = -1;
    else
      this.openedMWindow = id; // alternative: push to array of numbers
  }

  isInfoMWindowOpen(id: any) {
    return this.openedMWindow == id; // alternative: check if id is in array
  }

  changeWeek(val:any){
    let pDay
    if(val=='left'){
      pDay = new Date(moment(this.today).subtract(1, 'days').format('YYYY-MM-DD'))
    }else{
      pDay = new Date(moment(this.today).add(1, 'days').format('YYYY-MM-DD'))
    }
    this.today = moment(pDay).format('YYYY-MM-DD')
    this.emitDateSlider.emit({locData:this.empData,time:this.today})
  }

  fromDates(val:any){
    this.today = moment(val.value).format('YYYY-MM-DD')
    this.emitDateSlider.emit({locData:this.empData,time:this.today})
  }

  getDistanceBetweenTwoPoints(cord1: any, cord2: any) {
    if (cord1.lat == cord2.lat && cord1.lng == cord2.lng) {
      return 0;
    }

    const radlat1 = (Math.PI * cord1.lat) / 180;
    const radlat2 = (Math.PI * cord2.lat) / 180;

    const theta = cord1.lng - cord2.lng;
    const radtheta = (Math.PI * theta) / 180;

    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    if (dist > 1) {
      dist = 1;
    }

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344; //convert miles to km

    return dist;
  }
  getTotalDistance(originKey:any,DestKey:any) {
    var coordinates:any = []
    for(let i=originKey;i<=DestKey;i++){
      let val = this.data.tracking_datas[i]
      coordinates.push({ lat: Number(val.latitude.toFixed(6)), lng: Number(val.longitude.toFixed(6))})
    }

    let totalDistance = 0;

    if (!coordinates) {
      return 0;
    }

    if (coordinates.length < 2) {
      return 0;
    }

    for (let i = 0; i < coordinates.length - 2; i++) {
      if (
        !coordinates[i].lng ||
        !coordinates[i].lat ||
        !coordinates[i + 1].lng ||
        !coordinates[i + 1].lat
      ) {
        totalDistance = totalDistance;
      }
      totalDistance =
        totalDistance +
        this.getDistanceBetweenTwoPoints(coordinates[i], coordinates[i + 1]);
    }
    return totalDistance.toFixed(2) + " Km";
  }
}
