import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { digitdecimalValidator, twoDigitDecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-loan-process',
  templateUrl: './loan-process.component.html',
  styleUrls: ['./loan-process.component.scss']
})
export class LoanProcessComponent implements OnInit {

  // Input
  @Input() requestitem: any;

  // Output
  @Output() successEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() editEmitter   : EventEmitter<boolean> = new EventEmitter();

  // View Status
  billLoader              = false;
  viewstatusPanel         = false;
  viewStatusData    : any = [];
  viewStatusLevelCnt: any;
  viewStatusmaxLevel: any;
  regenerate              = false;
  submitClicked           = false
  loader1                 = true;
  processBtn              = false;
  viewExistingLoan        = false
  viewExistingData  : any = []
  navData           : any = []
  fromModal               = ''
  emiCalc           : any
  listOfMonth             =
  ["January","February","March","April","May","June","July",
    "August","September","October","November","December"];
  currentYear= new Date().getFullYear();
  yearList : any = [this.currentYear,this.currentYear+1,this.currentYear+2];
  form                    = this.fb.group({
    comment          : ['', Validators.maxLength(500)],
    loan_amount      : [null],
    total_emis       : [null],
    interest_rate    : [null],
    shortfall_intrest_rate: [null],
    deduction_month      : [null, [Validators.required]],
    deduction_year       : [null, [Validators.required]]
  })

  editPanel              = false

  get f() { return this.form.controls; }

  constructor(
    private inboxservice: InboxService,
    private investmentService: InvestmentDeclerationService,
    public appservice   : AppService,
    public msgServ      : MessageService,
    private fb          : FormBuilder,
    public cd           : ChangeDetectorRef,
    private notifyServ  : NotificationService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    this.loader1       = true;
    setTimeout(() => {
      this.loader1     = false;
    }, 2000);
  }

  // Existing loan details side panel
  existingFullData: any = []
  existingLoanData(){
    this.viewExistingLoan = true
    this.billLoader = true
    let empId = this.requestitem?.requests?.from_person?.id
    this.inboxservice.getExistingLoan({'employee_id':empId,'employee_request_id' : this.requestitem?.request_data?.id}).subscribe((res:any)=>{
      let response = res?.data
      this.navData = response.map((item:any)=>{
        return {
          'loan_code' : item.loan_details.loan_code,
          'loan_id' : item.loan_details.id
        }
      })
      this.viewExistingData = response[0]?.loan_details
      this.existingFullData = response
      this.billLoader = false
    })
  }

  panelClose(val:any){
    this.viewExistingLoan = val
  }

  // Calculate EMI details side panel
  calcEmiDetails(){
    let loan_amount = this.getFormValue('loan_amount', true);
    let total_emis = this.getFormValue('total_emis', true);
    let interest_rate = this.getFormValue('interest_rate', true);
    let shortfall_intrest_rate = this.getFormValue('shortfall_intrest_rate', true);
    let body :any = {
      'loan_type_id'          : this.requestitem?.requests.loan_type_details?.id,
      'interest_type'         : this.requestitem?.requests.loan_type_details?.interest_type,
      'loan_amount'           : loan_amount,
      'no_emis'               : total_emis,
      'interest_rate'         : interest_rate,
      'shortfall_intrest_rate': shortfall_intrest_rate
    }
    if(this.getFormValid()){
      this.investmentService.calculateEMI(body).subscribe((res:any)=>{
        this.emiCalc = res?.data
      })
    }
  }

  // Submission for recalc data & deduction month change
  isErr : boolean = false
  submitModalData(from:any){
    this.submitClicked = true
    let body :any = {
      'loan_amount': this.getFormValue('loan_amount', true),
      'no_emis': this.getFormValue('total_emis', true),
      'interest_rate': this.getFormValue('interest_rate', true),
      'shortfall_intrest_rate': this.getFormValue('shortfall_intrest_rate', true)
    };
    if(from == 'deduction_month'){
      body = {
      'deduction_month'       : this.getFormValue('deduction_month'),
      'deduction_year'        : this.getFormValue('deduction_year')
      }
    }
    this.inboxservice.editModalUpdates(this.requestitem?.id,body).subscribe((res:any)=>{
      if(from != 'deduction_month'){
        this.editEmitter.emit(true)
        this.notifyServ.handleSuccessNotification("Updated successfully.","Success")
      }
      this.isErr = false
      this.submitClicked = false
    },(err)=>{
      this.submitClicked = false
      if(from == 'deduction_month'){
        this.isErr = true
      }
    })
    this.closeModal();
  }

  // Deduction
  deductChange(){
    if((this.form.get('deduction_year')?.value != null && this.form.get('deduction_year')?.value != '') && (this.form.get('deduction_month')?.value != null && this.form.get('deduction_month')?.value != '')){
      this.submitModalData('deduction_month');
    }
  }

  // Reuse the code
  getFormValue(formControlName: string, isNumber: boolean = false) {
    const value = this.form.get(formControlName)?.value;
    if (value != null && value !== '') {
      return isNumber ? Number(value) : value;
    }
    return isNumber ? 0 : '';
  }

  // Recalc side panel setup
  editModal(){
    this.form.get('loan_amount')?.setValidators([Validators.max(this.requestitem?.requests?.amount_limit),Validators.required, Validators.min(1),Validators.pattern('^[0-9]*$')])
    this.form.get('total_emis')?.setValidators([Validators.max(this.requestitem?.requests?.loan_type_details?.max_number_emis),Validators.required, Validators.min(1),Validators.pattern('^[0-9]*$')])
    this.form.get('interest_rate')?.setValidators([Validators.max(this.requestitem?.requests?.loan_type_details?.interest_rate),Validators.required,twoDigitDecimalValidator()])
    this.form.get('shortfall_intrest_rate')?.setValidators([Validators.max(this.requestitem?.requests?.loan_type_details?.interest_penality),Validators.required,digitdecimalValidator()])
    this.form.get('loan_amount')?.setValue(this.requestitem?.requests?.new_principle_amount)
    this.form.get('total_emis')?.setValue(this.requestitem?.requests?.new_no_emis)
    this.form.get('interest_rate')?.setValue(this.requestitem?.requests?.new_interest_rate)
    this.form.get('shortfall_intrest_rate')?.setValue(this.requestitem?.requests?.new_shortfall_interest_rate)
    if(!this.requestitem?.requests?.can_edit_loanInterest_by_owner){
      this.form.get('interest_rate')?.disable()
      this.form.get('shortfall_intrest_rate')?.disable()
    }
    if(!this.requestitem?.requests?.can_edit_loanAmount_emi_by_owner){
      this.form.get('loan_amount')?.disable()
      this.form.get('total_emis')?.disable()
    }
    this.form.updateValueAndValidity();
    this.editPanel = true
    setTimeout(()=>{
     this.calcEmiDetails()
    },1000)
  }

  // Recalc side panel close & clea validators
  closeModal(){
    let controls = ['loan_amount','total_emis','interest_rate','shortfall_intrest_rate']
    controls?.forEach((control:any)=>{
      this.form.get(control)?.setValue(null)
      this.form.get(control)?.clearValidators();
      this.form.get(control)?.updateValueAndValidity();
    })
    this.editPanel = false
  }

  // Form valid for edit panel
  getFormValid() {
    let valid = true;
    if (this.requestitem?.requests?.can_edit_loanAmount_emi_by_owner) {
      if (!(this.form.get('loan_amount')?.valid && this.form.get('loan_amount')?.value != null &&
            this.form.get('total_emis')?.valid && this.form.get('total_emis')?.value != null)) {
        valid = false;
      }
    }
    if (this.requestitem?.requests?.can_edit_loanInterest_by_owner) {
      if (!(this.form.get('interest_rate')?.valid && this.form.get('interest_rate')?.value != null &&
            this.form.get('shortfall_intrest_rate')?.valid && this.form.get('shortfall_intrest_rate')?.value != null)) {
        valid = false;
      }
    }
    return valid;
  }


  // Final Process Btn setup
  processFn(){
    if(this.form.get('deduction_month')?.value == null || this.form.get('deduction_year')?.value == null){
      this.form.get('deduction_month')?.markAsTouched();
      this.form.get('deduction_year')?.markAsTouched();
      this.submitClicked = false
      return
    }
    this.processBtn = true
    this.inboxservice.updateInboxItem(this.requestitem['id'],{
      'approved_status':'Approved',
      'comment': this.form.get('comment')?.value
    }).subscribe((res: any)=>{
      this.successEmitter.emit(true);
    },(err => {
      this.processBtn = false
    }))
  }

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let status_value;
    let dataArray: any = [];

    if (action == 3) {
      if (type == 1) {
        status_value = "Approved"
      } else {
        status_value = "Rejected"
      }
      dataArray = {
        "approved_status": status_value,
        "comment": comment_value,

      };
    } else if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
      })
    }
  }

  closegen(bool:any){
    this.regenerate = bool;
  }

  // View Status
  requestViewStatus() {
    this.viewstatusPanel = true
    this.billLoader = true;
    this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) => {
      this.viewStatusData = res;
      this.billLoader = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
    });
  }

  notanumber(val: any) {
    if (typeof +val === "number" && !isNaN(+val)) return null;
    else return true;
  }
}
