import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-table-input-popover',
  templateUrl: './table-input-popover.component.html',
  styleUrls: ['./table-input-popover.component.scss']
})
export class TableInputPopoverComponent implements OnInit {
  @Input() infoCheck: any;
  @Input() infoData: any;
  @Input() errorCheck: any;
  @Input() errorData: any;
  constructor() { }

  ngOnInit(): void {
  }

}
