<div class="container-fluid p-32 form-container" [formGroup]="formGp" autocomplete="off">
    <div class="row ">
        <p class="text-uppercase fw-500">Assign shift<i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon ms-8"
                ngbPopover="Assigning a shift will override any existing shifts and week offs defined in the shift configuration and week off policy. Please select the date range for which you want to assign the shifts and week-offs in the fields below."
                popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i></p>
    </div>
    <div class="row row-24 mb-24">
        <div class="col-6">
            <label for="from" class="form-label required">From </label>
            <div class=" form-icon icon-right"
                [ngClass]="{ 'is-invalid': (submitted ||f.from.touched || f.from.dirty) && f.from.errors}">
                <input (click)="datePicker1.open()" (dateInput)="fromDate()" id="from" autocomplete="off" [min]="minDate"
                    placeholder="{{this.messageService.selectplaceholddisplay('date')}}" class="form-control"
                    [ngClass]="{ 'is-invalid': (submitted || f.from.touched || f.from.dirty) && f.from.errors}" matInput
                    formControlName="from" [matDatepicker]="datePicker1" (keydown)="disableDate()"><i
                    class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                <mat-datepicker at-datepicker touchUi="false" #datePicker1></mat-datepicker>
            </div>
            <div *ngIf="(submitted || f.from.touched || f.from.dirty) && f.from.errors" class="invalid-feedback">
                <div *ngIf="f.from.errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
            </div>
        </div>
        <div class="col-6">
            <label for="to" class="form-label required">To </label>
            <div class=" form-icon icon-right"
                [ngClass]="{ 'is-invalid': (submitted || f.to.touched || f.to.dirty) && f.to.errors }">
                <input (click)="datePicker2.open()" (dateInput)="f.from.value && toDate()" id="to" autocomplete="off" [matDatepickerFilter]="dobFilter"
                    placeholder="{{this.messageService.selectplaceholddisplay('date')}}" class="form-control"
                    [ngClass]="{ 'is-invalid': (submitted || f.to.touched || f.to.dirty) && f.to.errors }" matInput
                    formControlName="to" [min]="f.from.value" [max]="maxValue" [matDatepicker]="datePicker2"
                    (keydown)="disableDate()"><i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
            </div>
            <div *ngIf="(submitted || f.to.dirty || f.to.touched) && f.to.errors" class="invalid-feedback">
                <div *ngIf="f.to.errors">{{this.messageService.validationDisplay('required')}}</div>
            </div>
        </div>
        <app-table-loader *ngIf="loader"></app-table-loader>
        <div class="col-12" *ngIf="!loader && this.shiftDayBetween.length!=0">
            <div class="card card-c2 overflow-hidden">
                <div class="table-responsive">
                    <table class="table table-striped form-table table-sm td-12 th-12 td-white-space" id="tab">
                        <thead>
                            <tr>
                                <th scope="col" class="form-cell" width="1%">
                                    <input class="form-check-input m-0" type="checkbox" value=""
                                        (click)="checkAll($event)" [checked]="allCheck()">
                                </th>
                                <th class="fw-600 col-3" scope="col">DAYS</th>
                                <th class="fw-600 col-3 required" scope="col">shift</th>
                                <th class="fw-600 col-3" scope="col">Weekly off</th>
                            </tr>
                        </thead>
                        <tbody formArrayName="assign_shift_date">
                            <tr *ngFor="let shift of controls().controls; let $index = index" [formGroupName]="$index"
                                id="row{{$index+1}}">
                                <td class="form-cell">
                                    <input class="form-check-input m-0" type="checkbox" value=" "
                                        formControlName="checkedValue" [checked]=""
                                        (click)='individualCheck($event,$index)'>
                                </td>
                                <td scope="col">{{shiftDayBetween[$index]['dateDisplayFormat'] }}</td>
                                <td class="form-cell" scope="col" [id]="$index">
                                    <!-- <ng-select class="form-ngselect"
                                        placeholder="{{this.messageService.selectplaceholddisplay('shift')}}"
                                        appendTo="body" formControlName="shift"
                                        (change)="selectedFirst($event,$index+1)"
                                        [ngClass]="{ 'is-invalid': (submitted || this.controls().controls[$index].get('shift')?.touched) && this.controls().controls[$index].get('shift')?.errors}">
                                        <ng-option *ngFor="let shift of shiftDropdown"
                                            [value]="shift.id">{{shift.shift_name+ " ("+shift?.shift_code+')'}}
                                            ({{this.appService.timeFormatDisplay(shift?.shift_start_time)}} -
                                            {{this.appService.timeFormatDisplay(shift?.shift_end_time)}})</ng-option>
                                    </ng-select>
                                    <div *ngIf="((submitted || this.controls().controls[$index].get('shift')?.touched)) && this.controls().controls[$index].get('shift')?.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="this.controls().controls[$index].get('shift')?.errors">
                                            {{this.messageService.validationDisplay('required')}}</div>
                                    </div> -->
                                    <div
                                      class="form-icon"  [ngClass]="{ 'icon-right icon-right-2': (submitted || this.controls().controls[$index].get('shift')?.touched) && this.controls().controls[$index].get('shift')?.errors}">
                                      <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('shift')}}" appendTo="body"
                                        formControlName="shift" (change)="selectedFirst($event,$index+1)"
                                        [ngClass]="{ 'is-invalid': (submitted || this.controls().controls[$index].get('shift')?.touched) && this.controls().controls[$index].get('shift')?.errors}">
                                        <ng-option *ngFor="let shift of shiftDropdown" [value]="shift.id">{{shift.shift_name+ " ("+shift?.shift_code+')'}}
                                          ({{this.appService.timeFormatDisplay(shift?.shift_start_time)}} -
                                          {{this.appService.timeFormatDisplay(shift?.shift_end_time)}})</ng-option>
                                      </ng-select>
                                      <app-table-input-popover [errorCheck]="(submitted || this.controls().controls[$index].get('shift')?.touched) && this.controls().controls[$index].get('shift')?.errors"
                                        [errorData]="this.messageService.validationDisplay('required')"></app-table-input-popover>
                                    </div>
                                </td>
                                <td class="form-cell" scope="col">
                                    <ng-select class="form-ngselect"
                                        placeholder="{{this.messageService.selectplaceholddisplay('weekoff')}}"
                                        appendTo="body" formControlName="weekoff_day" [items]="weekoffDropdown"
                                        bindLabel="name" bindValue="value">
                                        <!-- <ng-option *ngFor="let weekoff of weekoffDropdown" [value]="weekoff.name">{{weekoff.name}}</ng-option> -->
                                    </ng-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<footer class="submit-footer">
    <button class="btn btn-primary btn-sm ms-auto"  [disabled]="!formGp.valid || init" (click)="validateAssign()">NEXT</button>
</footer>


<div class="modal  modal-alert {{ copyData == true ? 'show' : '' }}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header gap-16">
                <i class="icon-info-circle text-warning"></i>
                <h4 class="modal-title">Copy shift to all dates?</h4>
            </div>
            <div class="modal-body">
                <p class="mb-0">
                    The shift you had selected will reflect to all the dates
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm fw-500 text-uppercase"
                    (click)="copyData = false">
                    Cancel
                </button>
                <button type="button" class="btn btn-sm fw-500 text-uppercase btn-primary"
                    (click)="copyIt()">YES</button>
            </div>
        </div>
    </div>
</div>
