<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='addadvance === true'>
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <!-- <h5 *ngIf="from != 'expenseAdvanceRequest'">{{header}} Expense Advance</h5> -->

            <h5 class="m-0 text-trim w-50">Add New Advance
              <span *ngIf="from=='behalf'" title="{{requestFor}}">
                {{' Of '+ requestFor}}
              </span>
            </h5>

            <a class="toggle-panel" (click)="closefn()"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
              <div class="row row-16 ">
                <div class="col-12">
                  <p class="text-uppercase fw-500 mb-0">ADVANCe DETAILS </p>
                </div>
                <div *ngIf="from != 'expenseAdvanceRequest'" class="col-12">
                  <div role="alert" class="alert alert-warning py-10 mb-0 pe-16 show">
                    <p class="mb-0 fs-14 hstack "> <span class="fw-600 me-1">Note : </span> Total expense advance outstanding amount : {{outstandingamount>0?(outstandingamount | currency : currency):'0'}}
                    </p>
                    <ng-container *ngIf="outstandingamount>0">
                    <span class="ms-auto link-primary1 fw-500" (click)="viewdetailsout=true">View details </span>
                    <div class="fs-9 pointer"></div>
                    </ng-container>
                  </div>
                </div>
                <div class="col-12">
                  <label for="" class="form-label required">Expense advance amount </label>
                        <input type="text" formControlName="amount" class="form-control"
                        placeholder="{{this.messageService.placeholderdisp('Expense Advance Amount')}}"
                        [ngClass]="{ 'is-invalid': (submitted || f.amount?.touched || f.amount?.dirty) &&  f.amount?.errors }" (keyup)="validateamountfn()">
                        <div *ngIf="(submitted || f.amount?.touched || f.amount?.dirty) && f.amount?.errors " class="invalid-feedback">
                            <div *ngIf="f.amount?.errors?.required">
                                {{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="f.amount?.errors?.pattern">
                                {{this.messageService.validationDisplay('pattern')}}</div>
                            <div *ngIf="!(f.amount?.errors?.pattern) && f.amount?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','6')}}</div>
                            <div *ngIf="!f.amount?.errors?.pattern && f.amount?.errors?.max && !exceedmsg">
                              {{this.messageService.fieldlengthvalidation('amount',amountlimit)}}
                            </div>
                            <div *ngIf="!f.amount?.errors?.pattern && f.amount?.errors?.max && exceedmsg">
                              You have reached your advance limit.
                            </div>
                        </div>
                </div>
                <div class=" col-12 form-row">
                  <label for="" class="form-label required">Purpose </label>

                      <textarea type="text" class="form-control" formControlName="purpose" rows="4"  placeholder="{{this.messageService.placeholderdisp('Purpose here...')}}" [ngClass]="{ 'is-invalid': (submitted ||f.purpose?.touched || f.purpose?.dirty) &&  f.purpose?.errors}"></textarea>
                      <!-- <input type="text" formControlName="purpose" class="form-control" placeholder="{{this.messageService.placeholderdisp('Purpose here...')}}" [ngClass]="{ 'is-invalid': (submitted ||f.purpose?.touched || f.purpose?.dirty) &&  f.purpose?.errors}"> -->
                        <div *ngIf="(submitted || f.purpose?.touched || f.purpose?.dirty) && f.purpose?.errors" class="invalid-feedback">
                          <div *ngIf="f.purpose?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                            <div *ngIf="!f.purpose?.errors?.pattern && f.purpose?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}
                            </div>
                            <div *ngIf="f.purpose?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                        </div>
                </div>
                <div class="col-12">
                  <label for="" class="form-label required">Required date </label>
                    <div class="input-group form-icon icon-right"
                        [ngClass]="{ 'is-invalid': (submitted ||f.expense_date?.touched || f.to?.touched) && f.expense_date?.errors}">
                        <input (click)="datePicker1.open()" (dateInput)="expenseDate($event)"
                            placeholder="{{this.messageService.selectplaceholddisplay('date')}}" class="form-control"
                            [ngClass]="{ 'is-invalid': (submitted || f.expense_date?.touched || f.to?.touched) && f.expense_date?.errors}" matInput [matDatepickerFilter]="dateFilter"
                            formControlName="expense_date" [matDatepicker]="datePicker1" (keydown)="disableDate()"><i
                            class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                        <mat-datepicker at-datepicker touchUi="false" #datePicker1></mat-datepicker>
                     </div>
                         <div *ngIf="(submitted || f.expense_date?.touched || f.to?.touched) && f.expense_date?.errors"     class="invalid-feedback">
                             <div *ngIf="f.expense_date?.errors?.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                        </div>
                </div>
                <!-- <div class="col-12">
                  <label for="" class="form-label required">Apply to Trip </label>
                  <ng-select class="form-ngselect" placeholder="Select Trip"></ng-select>
                </div> -->
                <div class="col-12">
                    <label class="form-label  ">Add attachment</label>
                    <label class="upload-btn " *ngIf="(myForm.get('attachment')?.value == '' || myForm.get('attachment')?.value == null)"
                        [ngClass]="{ 'is-invalid': (fileError == true)  }">
                        <div class="upload-text">Upload documents <span class="text-primary">here
                                <app-file-upload [crop]="false" [round]="false" [id]="'attachment'" [width]="504"
                                    (dataURL)="getURL($event)" (fullfile)="getFilename($event)" (loader)="getloader($event)"
                                    [FileType]="'.png,.jpeg,.jpg,.docx,.doc,.dotx,.pdf'" (fileformat)="getfileformat($event)"
                                    (validsize)="getvalidsize($event)"></app-file-upload></span>
                            <div class="fs-12 text-light-500 mt-2">Supports PDF, DOC, IMG</div>
                        </div>


                        <input type="file" class="form-control" placeholder="" hidden>

                    </label>
                    <div *ngIf="(fileError == true)" class="invalid-feedback">
                        <div>{{fileErrorMsg}}</div>
                    </div>
                    <div class="card-body px-0 py-16" >
                      <div class="row row-16">
                        <div class="col-md-6">
                           <!-- <div class="card card-c2 bg-secondary" *ngIf="myForm.get('attachment')?.value !='' &&  myForm.get('attachment')?.value != null && !docLoader">
                        <div class="d-flex p-24">
                            <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                            <span class="text-trim fw-500">{{docName}}</span>
                            <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                            <span class="link-danger fw-500 flex-shrink-0" (click)="deleteProfilePic($event)"><i  class="icon-trash ms-8 "></i></span>
                        </div>
                           </div> -->

                           <div *ngIf="myForm.get('attachment')?.value !='' &&  myForm.get('attachment')?.value != null && !docLoader" class="card card-c2  bg-secondary" style="width:16.375rem;">
                            <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                              <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                              <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                <span class="tips text-uppercase  fw-700 text-trim">{{this.appService.getDocName(myForm.get('attachment')?.value)}}</span>
                                <span class="tips-helper ">{{this.appService.getDocName(myForm.get('attachment')?.value)}}</span>
                                <div class="d-flex gap-8">
                                  <div class="link-primary" (click)="viewDocument(myForm.get('attachment')?.value)">
                                    <i class="bi bi-eye me-1"></i>View
                                  </div>
                                  <div class="link-primary" (click)="this.appService.downloadFile(myForm.get('attachment')?.value,this.appService.getDocName(myForm.get('attachment')?.value))">
                                    <i class="bi bi-download me-1"></i>Download
                                  </div>
                                </div>
                              </div>
                              <i class="icon-trash  ms-auto flex-center link-danger" (click)="deleteProfilePic($event)"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="  flex-center text-center"
                                *ngIf="docLoader" >
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        <footer class="side-panel-footer">
            <!-- <button *ngIf="from != 'expenseAdvanceRequest'" type="submit" class="  btn btn-outline-primary text-uppercase btn-sm" (click)="closefn()">
                Cancel
            </button> -->
            <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
                [disabled]='!myForm.valid || disable'>
                SUBMIT
            </button>
        </footer>
    </form>
</div>

<app-advance-outstanding-detail-view *ngIf="viewdetailsout" [viewdetailsout]="viewdetailsout" [currency]="currency" (viewpanelclose)="closeoutstandview($event)"> </app-advance-outstanding-detail-view>

<!-- un supported view format alert -->
<app-info-popup *ngIf="alertToggle" (modal)="alertToggle = false" [infoToggle]="alertToggle" [infoMsg]="alertMsg"></app-info-popup>

<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="modalToggle = false" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>
