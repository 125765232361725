import { Component, OnInit,ViewChild,ChangeDetectorRef} from '@angular/core';
import { AppService } from 'src/app/app.global';
import {  Router } from '@angular/router';
import { EmployeeDirectoryService } from 'src/app/@core/services/employee-directory.service';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { MessageService } from 'src/app/message.global';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FormBuilder } from '@angular/forms';





@Component({
  selector: 'app-employee-directory',
  templateUrl: './employee-directory.component.html',
  styleUrls: ['./employee-directory.component.scss']
})
export class EmployeeDirectoryComponent implements OnInit {
  viewDetail = false;
  permissions:any = [];
  count :any
  loader = true;
  empdirectorylist:any[] = [];
  empdirectoryarray : any[] =[]
  emplist:any[] = [];
  nodata  = false;
  listlength : any
  infinityloader  = false;
  defaultlimit:any      = 40;
  offset                = 0;
  direction             = '';
  searchlistdata:any = [];
  searchKeyword="";
  filterdata:any = "";
  empview : any;
  loader1 : any;

  selectdata :any;
  empdetail : any;
  filter  = false;
  repotmanager : any;
  funcmanger : any;
  fullname : any;
  desig : any;
  emcode : any
  pimage : any;
  colorcode : any;
  applyfilter = false


  @ViewChild(SearchbarComponent) child:any;

  searchkey = this.messageService.searchdisplay('Employee');
  filterForm                = this.formBuilder.group({
    company               : '',
    bu                    : '',
    department            : '',
    designation           : '',
    grade                 : '',
    emp_status            : '',
    emp_type              : '',
    branch                : '',
    repomanager           :'',
    funmanager            :'',
    sortby                : ['All'],
  }
);
from = 'Employee_directory';
  confirm: any;
  confirmdate: any;
  list_flag: boolean = true;
  sLoading = false

  constructor(public appService: AppService,
    public router: Router,
    public breakpointObserver: BreakpointObserver,
    public employeedirectoryService:EmployeeDirectoryService,public messageService : MessageService,private formBuilder: FormBuilder,private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.getPermission();

    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;
      }
    });
    this.showDataItems();
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }


  showDataItems(){
  if(this.applyfilter==false){

    this.loader = true;
    // this.empdirectoryarray =[]

    this.employeedirectoryService.employeeDirectorylist(this.defaultlimit,this.offset).subscribe((res: any) => {
      if (res.results.length == 0) {
        this.listlength  = 0;
        this.nodata = (this.offset == 0) ? true :false;
      }else{
        this.nodata = false
        this.listlength   = res.results.length;
        this.count = res.count;
        if(this.offset == 0){
          this.empdirectoryarray = [];
        }
        this.empdirectorylist = res.results
      }
      this.infinityloader = false;
      this.addItems(this.listlength);
      this.sLoading = false;
      this.loader = false;
    });
  }
  else
  this.applyfilterfn()



  }
  addItems(itemlist:any){
    for (let i = 0; i < itemlist; ++i) {
      this.empdirectoryarray.push(this.empdirectorylist[i]);
    }
    // this.getSearchData(this.empdirectoryarray)
  }

  // getSearchData(data:any){
  //   this.searchlistdata = []
  //   for(let i=0;i<data.length;i++){

  //       if(data[i]['first_name'] != null)
  //       data[i]['name'] = data[i]['first_name']+" ";
  //       if(data[i]['middle_name'] != null)
  //       data[i]['name'] += data[i]['middle_name']+" ";
  //       if(data[i]['last_name'] != null)
  //       data[i]['name']   += data[i]['last_name'];
  //       data[i]['name']   +=" ("+data[i]['employee_code']+")";
  //       data[i]['image']   = data[i]['profile_image'];

  //     }
  //     this.searchlistdata = data;
  // }

getfilterdata(){
  if(this.filterdata==null || this.filterdata==undefined || this.filterdata=='')
  this.filterdata = 'employment_status__in=Probation,Confirmed,Resigned'
  this.employeedirectoryService.employeegetfilterdata(this.defaultlimit,this.offset,this.searchKeyword,this.filterdata).subscribe((resp: any)=> {
    if(resp.results.length==0){
      this.nodata = true
      this.count = 0
    }else{
      this.nodata = false
      this.empdirectoryarray = resp.results;
      this.count = resp.count;
    //   for(let i=0;i<resp.results.length;i++){
    //     if(this.empdirectoryarray[i]['first_name'] != null)
    //     this.empdirectoryarray[i]['name'] = this.empdirectoryarray[i]['first_name']+" ";
    //     if(this.empdirectoryarray[i]['middle_name'] != null)
    //     this.empdirectoryarray[i]['name'] += this.empdirectoryarray[i]['middle_name']+" ";
    //     if(this.empdirectoryarray[i]['last_name'] != null)
    //     this.empdirectoryarray[i]['name']   += this.empdirectoryarray[i]['last_name'];
    //     this.empdirectoryarray[i]['name']   +=" ("+this.empdirectoryarray[i]['employee_code']+")";
    //     this.empdirectoryarray[i]['image']   = this.empdirectoryarray[i]['profile_image'];
    //   }
    }
    // this.searchlistdata = this.empdirectoryarray;
    this.sLoading = false;
    this.loader = false
  });

}

/********* INIFNITY SCROLL FUNCTION *******************/
 onScrollDown() {
  this.infinityloader = true;
  this.offset            += this.defaultlimit;
  if(this.listlength){
    this.showDataItems();
    this.loader       = false;
    this.direction    = "down";
  }else{
    this.infinityloader = false;
  }
}
searchresults(event:any){

  this.offset           = 0;

  if(event == ""){
    this.searchKeyword  = "";
    if(this.applyfilter==false)
    this.empdirectoryarray = []
    this.showDataItems();
  }
  else{
  this.loader = true;
  this.empdirectoryarray =[]
    this.searchKeyword  = event
    this.employeedirectoryService.employeeDirectorysearch(event).subscribe((res: any) => {
      this.empdirectoryarray = res;
      this.count = res.length;
      this.loader = false;
    });
  }
}

searchemp(searchKeyword:any){
  this.sLoading = true
  this.loader = true;
  this.offset           = 0;
  this.empdirectoryarray = [];
  this.searchKeyword = searchKeyword;
  if(searchKeyword != ""){
    this.getfilterdata();
  }

}

viewemployee(id:any,fname:any,lname:any,mname:any,desig:any,empcode:any,colorcode:any,pimage:any){
  if(this.permissions.v){
    this.viewDetail = true;
    this.loader1 = true;
    this.employeedirectoryService.employeeDirectoryview(id).subscribe((res: any) => {
      this.empdetail = res;
      this.fullname = fname+" "+(mname!=null?mname+" ":" ")+lname
      this.desig = desig;
      this.emcode = empcode;
      this.colorcode = colorcode;
      this.pimage = pimage;

      this.confirm = Object.keys(this.empdetail)

      for(let i=0;i<this.confirm.length;i++){
        if(this.confirm[i] == 'date_of_confirmation' )
          this.confirmdate = 'date_of_confirmation'
        if(this.confirm[i] == 'reporting_manager' )
          this.repotmanager = 'reporting_manager'
        if(this.confirm[i] == 'functional_manager' )
          this.funcmanger = 'functional_manager'
      }
      this.loader1 = false;
    })
}
}

filterclick(){
  this.filter = true;
 }
 closefn(daata:any){
  this.filter = daata;
 }

 resultfilterfn(data:any){
  this.filterdata = data;
  this.offset = 0;
  this.applyfilterfn();

}
resetCalled(){
  this.applyfilter  = true;

}
applyfilterfn(){
  this.loader = true;
  this.applyfilter  = true;
  this.nodata       = false;
  // this.searchKeyword  = "";
  this.searchlistdata = []

  this.employeedirectoryService.employeeDirectoryfilter(this.filterdata).subscribe((res: any) => {
    // this.searchKeyword  = "";
    this.empdirectoryarray = res;
    this.count = res.length;
    this.nodata = res.length==0?true:false;
    this.sLoading = false;
    this.loader = false;
    // for(let i=0;i<this.count;i++){

    //   if(this.empdirectoryarray[i]['first_name'] != null)
    //   this.empdirectoryarray[i]['name'] = this.empdirectoryarray[i]['first_name']+" ";
    //   if(this.empdirectoryarray[i]['middle_name'] != null)
    //   this.empdirectoryarray[i]['name'] += this.empdirectoryarray[i]['middle_name']+" ";
    //   if(this.empdirectoryarray[i]['last_name'] != null)
    //   this.empdirectoryarray[i]['name']   += this.empdirectoryarray[i]['last_name'];
    //   this.empdirectoryarray[i]['name']   +=" ("+this.empdirectoryarray[i]['employee_code']+")";
    //   this.empdirectoryarray[i]['image']   = this.empdirectoryarray[i]['profile_image'];

    //   if(this.count==i+1 && this.searchKeyword!='')
    //   this.child.clearBar();

    // }
    // this.searchlistdata = this.empdirectoryarray;

    // this.changeDetectorRef.detectChanges();
  });
  this.filter = false;


}
}
