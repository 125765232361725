<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='viewDetail === true'>
  <form class="side-panel-container" [formGroup]="loanSummaryForm" autocomplete="off">
      <header class="side-panel-head">
          <h5>Filters</h5>
          <a class="toggle-panel" (click)="close()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div #scrollElement class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
              <div class="row row-16 mb-16 " [ngClass]="{ 'is-invalid': (submitted) &&  f.request_date.errors}">
                  <div class="col-12 form-row">
                      <p class="text-uppercase fw-500 mb-8">Request date</p>
                  </div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input name="request_date" type="radio" id="request_date1" class="form-check-input  "
                              formControlName="request_date" value="last_month"
                              (change)="radioBtnChangeFunction($event,'request_date','last_month','sm_specific_month','sm_specific_year');">
                          <label for="request_date1" class="form-check-label">Last month</label>
                      </div>
                  </div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input name="request_date" type="radio" id="request_date2" class="form-check-input "
                              formControlName="request_date" value="this_month"
                              (change)="radioBtnChangeFunction($event,'request_date','this_month','sm_specific_month','sm_specific_year');">
                          <label for="request_date2" class="form-check-label">This month</label>
                      </div>
                  </div>
                  <div class="col-12 form-row">
                      <div class="form-check">
                          <input name="request_date" type="radio" id="request_date3" class="form-check-input "
                              formControlName="request_date" [value]="true"
                              (change)="radioBtnChangeFunction($event,'request_date',true,'sm_specific_month','sm_specific_year');">
                          <label for="request_date3" class="form-check-label">Specific month</label>
                      </div>
                  </div>
                  <ng-container *ngIf="loanSummaryForm.value.request_date==true">
                    <div class="col-6">
                      <div class=" form-row" [ngClass]="{ 'is-invalid': (submitted || f?.sm_specific_year?.touched || f?.sm_specific_year?.dirty) &&  f.sm_specific_year.errors}">
                        <label for="" class="form-label">Year </label>
                        <ng-select class="form-ngselect  fs-14"
                        formControlName="sm_specific_year" [placeholder]="year_placeholder" [ngClass]="{ 'is-invalid': (submitted || f?.sm_specific_year?.touched || f?.sm_specific_year?.dirty) &&  f.sm_specific_year.errors}">
                          <ng-option *ngFor="let yData of Years"
                            [value]="yData">{{yData}}</ng-option>
                        </ng-select>
                      </div>
                      <div *ngIf="(submitted || f?.sm_specific_year?.touched || f?.sm_specific_year?.dirty)&& f?.sm_specific_year?.errors" class="invalid-feedback">
                        <div *ngIf="f?.sm_specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.sm_specific_month?.touched || f?.sm_specific_month?.dirty) &&  f?.sm_specific_month?.errors}">
                        <label for="" class="form-label">Month </label>
                        <app-master-select class="config-app-master" style="width:9rem"
                        [placeholder]="month_placeholder"
                            id="month" [isrequired]="false" [selectedItem]="loanSummaryForm.value.sm_specific_month"
                          [type]="attendListOfMonth" formControlName="sm_specific_month" [(ngModel)]="f.sm_specific_month.value"
                             [ngClass]="{ 'is-invalid': (submitted || f?.sm_specific_month?.touched || f?.sm_specific_month?.dirty) &&  f?.sm_specific_month?.errors}"> </app-master-select>
                      </div>
                      <div *ngIf="(submitted || f?.sm_specific_month?.touched || f?.sm_specific_month?.dirty)&& f?.sm_specific_month?.errors" class="invalid-feedback">
                        <div *ngIf="f?.sm_specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

              </div>
              <div *ngIf="(submitted) && f.request_date.errors" class="invalid-feedback">
                  <div *ngIf="f.request_date.errors.required">
                      {{this.messageService.validationDisplay('required')}}</div>
              </div>
              <div class="row row-16 mb-16 " [ngClass]="{ 'is-invalid': (submitted) &&  f.approve_date.errors}">
                <div class="col-12 form-row">
                    <p class="text-uppercase fw-500 mb-8">Approval date</p>
                </div>
                <div class="col-12 form-row">
                    <div class="form-check">
                        <input name="approve_date" type="radio" id="approval_date1" class="form-check-input  "
                            formControlName="approve_date" value="last_month"
                            (change)="radioBtnChangeFunction($event,'approve_date','last_month','specific_month','specific_year');">
                        <label for="approval_date1" class="form-check-label">Last month</label>
                    </div>
                </div>
                <div class="col-12 form-row">
                    <div class="form-check">
                        <input name="approve_date" type="radio" id="approval_date2" class="form-check-input "
                            formControlName="approve_date" value="this_month"
                            (change)="radioBtnChangeFunction($event,'approve_date','this_month','specific_month','specific_year');">
                        <label for="approval_date2" class="form-check-label">This month</label>
                    </div>
                </div>
                <div class="col-12 form-row">
                    <div class="form-check">
                        <input name="approve_date" type="radio" id="approval_date3" class="form-check-input "
                            formControlName="approve_date" [value]="true"
                            (change)="radioBtnChangeFunction($event,'approve_date',true,'specific_month','specific_year');">
                        <label for="approval_date3" class="form-check-label">Specific month</label>
                    </div>
                </div>
                <ng-container *ngIf="loanSummaryForm.value.approve_date==true">
                  <div class="col-6">
                    <div class=" form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                      <label for="" class="form-label">Year </label>
                      <ng-select class="form-ngselect  fs-14"
                      formControlName="specific_year" [placeholder]="year_placeholder" [ngClass]="{ 'is-invalid': (submitted || f?.specific_year?.touched || f?.specific_year?.dirty) &&  f.specific_year.errors}">
                        <ng-option *ngFor="let yData of Years"
                          [value]="yData">{{yData}}</ng-option>
                      </ng-select>
                    </div>
                    <div *ngIf="(submitted || f?.specific_year?.touched || f?.specific_year?.dirty)&& f?.specific_year?.errors" class="invalid-feedback">
                      <div *ngIf="f?.specific_year?.errors?.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-row" [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}">
                      <label for="" class="form-label">Month </label>
                      <app-master-select class="config-app-master" style="width:9rem"
                      [placeholder]="month_placeholder"
                          id="month" [isrequired]="false" [selectedItem]="loanSummaryForm.value.specific_month"
                        [type]="attendListOfMonth" formControlName="specific_month" [(ngModel)]="f.specific_month.value"
                         [ngClass]="{ 'is-invalid': (submitted || f?.specific_month?.touched || f?.specific_month?.dirty) &&  f?.specific_month?.errors}"> </app-master-select>
                    </div>
                    <div *ngIf="(submitted || f?.specific_month?.touched || f?.specific_month?.dirty)&& f?.specific_month?.errors" class="invalid-feedback">
                      <div *ngIf="f?.specific_month?.errors?.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                    </div>
                  </div>
                </ng-container>
            </div>
            <div class="col-12 form-row mb-2">
              <label for="" class="form-label">Loan type </label>
              <ng-select placeholder="{{this.messageService.selectplaceholddisplay('loan type')}}" [ngClass]="{ 'is-invalid': (submitted || f.loan_type.touched || f.loan_type.dirty) &&  f.loan_type.errors}"
                  formControlName="loan_type" [items]="loanTypeDrop" [multiple]="true" groupBy="selectedAllGroup"
                  [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="loan_type"
                  [closeOnSelect]="false" bindValue="loan_type" class="multiselect" [loading]="loanTypeLoader">
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{standalone: true}" /> Select All
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!loanTypeLoader">
                      <div class="ng-value" *ngIf="loanSummaryForm.value.loan_type?.length ">
                          <span class="ng-value-label">{{loanSummaryForm.value.loan_type?.length }} loan type
                              selected</span>
                      </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center" >
                          <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                              [ngModelOptions]="{ standalone : true }" />

                          <span class="">{{ (item.loan_type?.length>15)? (item.loan_type |
                              slice:0:15)+'..':(item.loan_type) }}</span>
                      </div>
                  </ng-template>
                  <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="loanTypeLoader">
                    <div class="loading-text">Loading...</div>
                  </ng-template>
              </ng-select>
              <div *ngIf="(submitted || f.loan_type.touched || f.loan_type.dirty) && f.loan_type.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.loan_type.errors.required">
                      {{this.messageService.validationDisplay('required')}}</div>
              </div>
              <ul class="selected-values my-16" *ngIf="loanSummaryForm.value.loan_type?.length != 0 && !loanTypeLoader">
                  <li class="ng-value" *ngFor="let item of loanSummaryForm.value.loan_type,let ki =index">
                      <ng-container *ngFor="let insideitem of loanTypeDrop, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['loan_type']==item">
                              <div class="d-inline-flex align-items-center">
                                  <span class=""> {{ (insideitem['loan_type'].length>25)?
                                      (insideitem['loan_type'] |
                                      slice:0:25)+'..':(insideitem['loan_type']) }}</span>
                              </div>
                          </span>
                      </ng-container>
                      <span  class="ng-value-icon right"
                          (click)="clearcommonForm('loan_type',ki)" aria-hidden="true">×</span>
                  </li>
              </ul>
            </div>
            <div class="col-12 form-row mb-2">
              <label for="" class="form-label">Interest type </label>
              <ng-select placeholder="{{this.messageService.selectplaceholddisplay('interest type')}}" [ngClass]="{ 'is-invalid': (submitted || f.interest_type.touched || f.interest_type.dirty) &&  f.interest_type.errors}"
                  formControlName="interest_type" [items]="interestDrop" [multiple]="true" groupBy="selectedAllGroup"
                  [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name"
                  [closeOnSelect]="false" bindValue="id" class="multiselect">
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{standalone: true}" /> Select All
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="loanSummaryForm.value.interest_type?.length ">
                          <span class="ng-value-label">{{loanSummaryForm.value.interest_type?.length }} interest type
                              selected</span>
                      </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center" >
                          <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                              [ngModelOptions]="{ standalone : true }" />

                          <span class="">{{ (item.name?.length>15)? (item.name |
                              slice:0:15)+'..':(item.name) }}</span>
                      </div>
                  </ng-template>
                  <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" >
                    <div class="loading-text">Loading...</div>
                  </ng-template>
              </ng-select>
              <div *ngIf="(submitted || f.interest_type.touched || f.interest_type.dirty) && f.interest_type.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.interest_type.errors.required">
                      {{this.messageService.validationDisplay('required')}}</div>
              </div>
              <ul class="selected-values my-16" *ngIf="loanSummaryForm.value.interest_type?.length != 0">
                  <li class="ng-value" *ngFor="let item of loanSummaryForm.value.interest_type,let ki =index">
                      <ng-container *ngFor="let insideitem of interestDrop, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              <div class="d-inline-flex align-items-center">
                                  <span class=""> {{ (insideitem['name'].length>25)?
                                      (insideitem['name'] |
                                      slice:0:25)+'..':(insideitem['name']) }}</span>
                              </div>
                          </span>
                      </ng-container>
                      <span  class="ng-value-icon right"
                          (click)="clearcommonForm('interest_type',ki)" aria-hidden="true">×</span>
                  </li>
              </ul>
            </div>
            <div class="col-12 form-row mb-5">
              <label for="" class="form-label required">Loan status</label>
              <ng-select placeholder="{{this.messageService.selectplaceholddisplay('loan status')}}" [ngClass]="{ 'is-invalid': (submitted || f.loan_status.touched || f.loan_status.dirty) &&  f.loan_status.errors}"
                  formControlName="loan_status" [items]="loanStatus" [multiple]="true" groupBy="selectedAllGroup"
                  [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name"
                  [closeOnSelect]="false" bindValue="id" class="multiselect">
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                          [ngModelOptions]="{standalone: true}" /> Select All
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngIf="loanSummaryForm.value.loan_status?.length ">
                          <span class="ng-value-label">{{loanSummaryForm.value.loan_status?.length }} loan status
                              selected</span>
                      </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center" >
                          <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected"
                              [ngModelOptions]="{ standalone : true }" />

                          <span class="">{{ (item.name?.length>15)? (item.name |
                              slice:0:15)+'..':(item.name) }}</span>
                      </div>
                  </ng-template>
                  <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" >
                    <div class="loading-text">Loading...</div>
                  </ng-template>
              </ng-select>
              <div *ngIf="(submitted || f.loan_status.touched || f.loan_status.dirty) && f.loan_status.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.loan_status.errors.required">
                      {{this.messageService.validationDisplay('required')}}</div>
              </div>
              <ul class="selected-values my-16" *ngIf="loanSummaryForm.value.loan_status?.length != 0">
                  <li class="ng-value" *ngFor="let item of loanSummaryForm.value.loan_status,let ki =index">
                      <ng-container *ngFor="let insideitem of loanStatus, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              <div class="d-inline-flex align-items-center">
                                  <span class=""> {{ (insideitem['name'].length>25)?
                                      (insideitem['name'] |
                                      slice:0:25)+'..':(insideitem['name']) }}</span>
                              </div>
                          </span>
                      </ng-container>
                      <span  class="ng-value-icon right"
                          (click)="clearcommonForm('loan_status',ki)" aria-hidden="true">×</span>
                  </li>
              </ul>
            </div>
              <app-ou-filters [ouFilterForm]="ouFilterForm" [viewDetail]="viewDetail"></app-ou-filters>
          </div>
      </div>
      <footer class="side-panel-footer">
          <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetPage();resetFilterFunction()">
              reset
          </button>
          <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="resetPage();submitLoanSummary()">
              Apply
          </button>
      </footer>
  </form>
</div>
