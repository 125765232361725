import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { NotificationService } from 'src/app/@core/services/notification.service';

@Component({
  selector: 'app-final-settlement-other-payments',
  templateUrl: './final-settlement-other-payments.component.html',
  styleUrls: ['./final-settlement-other-payments.component.scss']
})
export class FinalSettlementOtherPaymentsComponent implements OnInit {

  @Input() empId:any;
  @Input() otherPaymentsData:any;
  @Input() payrollStatus:any;
  @Input() inboxId:any;

  @Output() submitmethod   = new EventEmitter();
  @Output() warningMsg     = new EventEmitter();
  @Output() loanWarnMsg    = new EventEmitter()

  submitted                = false;
  saveClicked              = false;
  otherComponentsArray:any = [];
  currency:any;
  @Input() resignationDetails: any;

  constructor(
    public finalSettlementService: FinalSettlementService,
    public router: Router,
    public route:ActivatedRoute,
    public appService: AppService,
    public messageService: MessageService,
    public fb:FormBuilder,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    if(this.componentArray()?.length==0){
      this. addComponentArray();

    }

  }

  @Input()formGp : FormGroup = this.fb.group({
    components  : []
  })

  componentArray() : FormArray {
    return this.formGp.get("components") as FormArray
  }

  newComponentArray(): FormGroup {

    return this.fb.group({
      "pay_component"     : [null,[Validators.required,digitdecimalValidator()]],
      "pay_variable"      : [null,[Validators.required,digitdecimalValidator()]],
      "comments"          : [null,[Validators.maxLength(500)]],
      "no_of_days"        : [null],
      "component_name"    : [null],
      "formula"           : [null],
      "payable_amount"    : [null,[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
      "encashment_amount" : [null],
      "pay_component_type": [null],
      "exception_amount"  : [null]

    })
  }

  addComponentArray() {
    this.componentArray().push(this.newComponentArray());
  }

  removeAllcomponentArray(){
    if(this.formGp.value.components?.length > 0){
      while(this.formGp.value.components?.length !== 0){
        this.componentArray().removeAt(0);
      }
    }
  }

  removeComponentArray(i:number) {
    this.componentArray().removeAt(i);
  }

  componentChangeFunction(component_id:any,index:any){
    this.finalSettlementService.getComponentData(this.empId, component_id.id).subscribe((res: any) => {
      this.currency = res[0]?.currency;
      (<FormArray>this.formGp.get("components")).at(index)?.get('formula')?.setValue(res[0]?.formula);
      (<FormArray>this.formGp.get("components")).at(index)?.get('pay_variable')?.setValue(res[0]?.pay_variable);
      (<FormArray>this.formGp.get("components")).at(index)?.get('payable_amount')?.setValue(res[0]?.payable_amount);
      (<FormArray>this.formGp.get("components")).at(index)?.get('pay_component_type')?.setValue(res[0]?.pay_component_type);
      if(res[0]?.pay_component_type == 'Leave Encashment'){
        (<FormArray>this.formGp.get("components")).at(index)?.get('encashment_amount')?.setValue(res[0]?.amount);
        (<FormArray>this.formGp.get("components")).at(index)?.get('no_of_days')?.setValue(res[0]?.no_of_days);
      }

        // Validation start
        (<FormArray>this.formGp.get("components")).at(index).get('pay_component')?.setValidators([Validators.required,digitdecimalValidator()]);

        (<FormArray>this.formGp.get("components")).at(index).get('amount')?.setValidators([Validators.required,digitdecimalValidator()]);
        if(res[0]?.pay_component_type == 'Leave Encashment'){

          (<FormArray>this.formGp.get("components")).at(index).get('encashment_amount')?.setValidators([Validators.required,digitdecimalValidator()]);
          (<FormArray>this.formGp.get("components")).at(index).get('no_of_days')?.setValidators([Validators.required]);
          (<FormArray>this.formGp.get("components")).at(index).get('exception_amount')?.setValidators([Validators.required,digitdecimalValidator()]);

          (<FormArray>this.formGp.get("components")).at(index).get('encashment_amount')?.updateValueAndValidity();
          (<FormArray>this.formGp.get("components")).at(index).get('no_of_days')?.updateValueAndValidity();
          (<FormArray>this.formGp.get("components")).at(index).get('pay_variable')?.clearValidators();
          (<FormArray>this.formGp.get("components")).at(index).get('pay_variable')?.updateValueAndValidity();
          (<FormArray>this.formGp.get("components")).at(index).get('exception_amount')?.updateValueAndValidity();

        }else{
          (<FormArray>this.formGp.get("components")).at(index).get('pay_variable')?.setValidators([Validators.required,digitdecimalValidator()]);
          (<FormArray>this.formGp.get("components")).at(index).get('exception_amount')?.clearValidators();

          (<FormArray>this.formGp.get("components")).at(index).get('pay_variable')?.updateValueAndValidity();
          (<FormArray>this.formGp.get("components")).at(index).get('exception_amount')?.updateValueAndValidity();
        }
        (<FormArray>this.formGp.get("components")).at(index).get('pay_component')?.updateValueAndValidity();

        (<FormArray>this.formGp.get("components")).at(index).get('amount')?.updateValueAndValidity();
        // Validation end

        this.checkLoanType()
    });
  }

  checkLoanType(): boolean {
    return this.componentArray().getRawValue().some((item: any) => item?.pay_component_type === 'Loan');
  }

  saveComponents(){
    if(this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish'){
      this.warningMsg.emit();
    }else if(this.checkLoanType()){
      this.loanWarnMsg.emit()
    }else{
      this.otherComponentsArray = [];
      this.submitted = true;
      if (this.formGp.invalid) {
        return;
      }

      this.saveClicked = true;

      if(this.formGp.value.components?.length > 0){
        for(let i=0;i<this.formGp.value.components?.length;i++){
          this.otherComponentsArray.push({
            "paycomponent"    : (<FormArray>this.formGp.get("components")).at(i).get('pay_component')?.value,
            "amount"          : (<FormArray>this.formGp.get("components")).at(i).get('pay_component')?.value == 'Leave Encashment' ?  Number((<FormArray>this.formGp.get("components")).at(i).get('encashment_amount')?.value) : Number((<FormArray>this.formGp.get("components")).at(i).get('pay_variable')?.value),
            "comments"        : (<FormArray>this.formGp.get("components")).at(i).get('comments')?.value,
            "no_of_days"      : (<FormArray>this.formGp.get("components")).at(i).get('no_of_days')?.value == null ? 0 : Number((<FormArray>this.formGp.get("components")).at(i).get('no_of_days')?.value),
            "payable_amount"  :  Number((<FormArray>this.formGp.get("components")).at(i).get('payable_amount')?.value),
            "exception_amount": Number((<FormArray>this.formGp.get("components")).at(i).get('exception_amount')?.value),
            "payable_exception_amount" :  Number((<FormArray>this.formGp.get("components")).at(i).get('exception_amount')?.value)

          });
        }
      }

      this.finalSettlementService.finalSettlementSave({"step_name": 'other_payments',"component_type": "other","employee": this.empId,"other_components": this.otherComponentsArray} ,this.inboxId).subscribe((res: any) => {

        this.saveClicked = false;
        // this.getReimbursementComponents();

        if(res?.status == 1)
          this.notificationService.handleSuccessNotification("Created successfully.","Success");

      },(error)=>{
        this.saveClicked = false;
      });
    }
  }

  submitOtherPayments(){
    this.submitmethod.emit();
  }

  getNoticePayAndClearence(){

    this.finalSettlementService.getResignationDetails(this.empId, 'other_payments',this.resignationDetails?.value?.pay_month,this.resignationDetails?.value?.pay_year,this.inboxId).subscribe((res: any) => {
      for(let i=0; i< this.componentArray().length; i++){
        this.componentArray().removeAt(i)
      }
      if(res?.data?.length == 0){
        this.addComponentArray();
        }else{
        for(let i=0; i<res?.data?.length; i++){
          this.componentArray().removeAt(i)
          this.addComponentArray();
        }
        (<FormArray>this.formGp?.get("components")).reset(res?.data);
      }

    });


  }

}
