<ng-container *ngIf="infoCheck || errorCheck">
  <i class="bi bi-info-circle icon-end-2 fs-14 tooltip-icon {{(errorCheck) ?'text-danger':'text-accent4 '}}" [ngbPopover]="popContent"
    popoverClass="popover-default " container="body" triggers="hover"></i>
  <ng-template #popContent>
    <div class="card card-c2 border-0">
      <div class="card-body px-0 py-8 " *ngIf="(infoCheck)">
        <div role="alert" class="alert alert-bg-white  alert-info py-0 pe-16 mb-0  show">
          <p class="mb-0  hstack lh-base">{{infoData}}</p>
        </div>
      </div>
      <div class="card-body px-0 py-8" *ngIf="(errorCheck)">
        <div role="alert" class="alert alert-danger py-10 pe-16 mb-0  show">
          <p class="mb-0   hstack lh-base">{{errorData}}</p>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
