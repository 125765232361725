<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='loanView === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details </h5>
      <a class="toggle-panel" (click)="closeFunction()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0">LOAN request</p>
          </div>
          <app-view-loader *ngIf="loader"></app-view-loader>
          <div class="col-12" *ngIf="!loader">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12 align-items-center d-flex">
                  <p class="title mb-8">LOAN request</p>
                  <span class="badge ms-auto py-1 px-3" [ngClass]="{'badge-warning': loanData?.status === 'Pending','badge-success': loanData?.status === 'Accepted','badge-danger': loanData?.status === 'Rejected'}">{{loanData?.status}}</span>
                </div>
                <div class="col-md-6 col-12">
                  <div class="detail-title">Loan type</div>
                  <div class="detail-desc">{{loanData?.loan_type_details?.loan_type}}</div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="detail-title">Interest type</div>
                  <div class="detail-desc">{{loanData?.loan_type_details?.interest_type}}</div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="detail-title">Maximum amount</div>
                  <div class="detail-desc">{{loanData?.amount_limit | currency : currency}}</div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="detail-title">Maximum EMI</div>
                  <div class="detail-desc">{{loanData?.loan_type_details?.max_number_emis}}</div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Eligibility</div>
                  <div class="detail-desc">{{loanData?.loan_type_details?.loan_eligibility_days}} Days from Date of Joining</div>
                </div>
                <div class="col-sm-4 col-md-6 col-12">
                  <div class="detail-title">Interest penalty on payment shortfall rate%</div>
                  <div class="detail-desc">{{loanData?.loan_type_details?.interest_penality}}</div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="detail-title">Loan amount</div>
                  <div class="detail-desc">{{loanData?.loan_amount | currency : currency}}</div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="detail-title">Number of EMI</div>
                  <div class="detail-desc">{{loanData?.no_emis}}</div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="detail-title">Loan required date</div>
                  <div class="detail-desc">{{loanData?.required_date ? this.appServ.dateFormatDisplay(loanData?.required_date) : '-'}}</div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Remarks</div>
                  <div class="detail-desc">{{loanData?.reason == null ? '-' : loanData?.reason}}</div>
                </div>
                <div class="col-sm-6 col-12" *ngIf="loanData?.status == 'Cancelled'">
                  <div class="detail-title">Reason for cancellation</div>
                  <div class="detail-desc">{{loanData?.remark}}</div>
                </div>
                <div class="col-12">
                  <div class="detail-title">Document</div>
                  <div class="detail-desc" *ngIf="loanData?.document == ''">-</div>
                  <div  style="width:12.9375rem;" *ngIf="loanData?.document != ''">
                    <div class="p-12 bg-secondary radius-8 d-flex align-items-center">
                      <i class="bi bi-file-earmark-text-fill me-16 flex-center text-accent2 fs-40"></i>
                      <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                        <span class="tips text-uppercase fs- fw-700 text-trim" *ngIf="loanData?.document != ''">{{ this.appServ.getDocName(loanData?.document) }}</span>
                        <span class="tips-helper " *ngIf="loanData?.document != ''">{{ this.appServ.getDocName(loanData?.document) }}</span>
                        <div class="d-flex gap-8">
                          <div class="link-primary" (click)="viewDocument(loanData?.document)">
                            <i class="bi bi-eye me-1"></i>View
                          </div>
                          <div class="link-primary" (click)="this.appServ.downloadFile(loanData?.document,getDocName(loanData?.document)?.split('.')[0])">
                            <i class="bi bi-download me-1"></i>Download
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer" *ngIf="loanData?.status == 'Pending'">
      <button type="submit" class="ms-auto btn btn-outline-danger text-uppercase btn-sm" (click)="cancelRequest()">
          CANCEL REQUEST
      </button>
    </footer>
  </div>
</div>


<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Activities</h5>
            <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
        </header>

        <app-view-status [loader]="statusLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData" [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"></app-view-status>

    </form>
  </div>
</ng-container>
<!-- end view status panel -->

<!-- Rquest cancel section -->
<app-cancel-request-modal [requestCancel]="requestCancel" [requestName]="'Loan'" [requestId]="requestId" [requestStatus]="loanData?.status" (modal)="closeCancelModal($event)"></app-cancel-request-modal>

<!-- un supported view format alert -->
<app-alert-toggle *ngIf="alertToggle==true" (modals)="closed($event)" [alertToggle]="alertToggle"
  [alertMsg]="investalertMsg"></app-alert-toggle>

<!-- Viewing the Document -->
<app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"
  [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
