import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import * as moment from 'moment';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { SalaryStructureService } from 'src/app/@core/services/salary-structure';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-common-employee-filters',
  templateUrl: './common-employee-filters.component.html',
  styleUrls: ['./common-employee-filters.component.scss']
})
export class CommonEmployeeFiltersComponent implements OnInit {

  // Inputs
  @Input() filter      : any;
  @Input() isMemorized = false;
  @Input() filterForm  : any = [];
  @Input() from        : any;
  // @Input() inputFormat : any

  // Output
  @Output() filterOutput = new EventEmitter();
  @Output() dropOutput = new EventEmitter();
  @Output() resetCalled  = new EventEmitter();
  @Output() filterClose  = new EventEmitter();

  // Variable Initializations
  sort_by:any =[
    'All',
    'Latest',
    'Alphabetically (A-Z)',
    'Alphabetically (Z-A)'
  ];
  sort_by_employee:any =[
    'All',
    'Latest',
    'Alphabetically (A-Z)',
    'Alphabetically (Z-A)',
    'Active First',
    'Inactive First'
  ];
  sort_by_employee_directory:any =[
    'All',
    'Latest',
    'Alphabetically (A-Z)',
    'Alphabetically (Z-A)',
    'Active First',

  ];

  exit_date_type        : any = [{id:'leaving_date',name:'Leaving Date'},{id:'settlement_date',name:'Final Settlement Date'}]
  submitted             = false;
  filterSubmit          = false;
  dataFilter            : any;
  ordering              : any;
  disabled              = false;
  collapsedclass1       = false;
  collapsedclass2       = false;
  collapsedclass3       = false;
  collapsedclass4       = false;
  collapsedclass5       = false;
  activeidcls           : any;
  activeidcls1          : any;
  activeidcls2          : any;
  activeidcls3          : any;
  activeidcls4          : any;
  dataFilterArr         : any;
  dateFormat            : any;
  emptypekey = this.messageService.selectplaceholddisplay('Employment type');
  empstakey  = this.messageService.selectplaceholddisplay('Employment status');
  companyDD :any =  [{
    id            : 0,
    company_name  : ''
  }];

  buDD = [{
    id          : 0,
    name        : ''
  }];

  DepDD = [{
    id          : 0,
    name        : ''
  }];

  DesignationDD = [{
    id          : 0,
    name        : ''
  }];

  BranchDD = [{
    id          : 0,
    name        : ''
  }];

  gradeDD = [{
    id           : 0,
    name         : ''
  }];

  managerDD               : any = [];
  funmanagerDD            : any = [];

  emptypeDD = [
    {id:"Contractual",    name:"Contractual"},
    {id:"Consultant",    name:"Consultant"},
    {id:"Permanent",    name:"Permanent"},
    {id:"Trainee",    name:"Trainee"},
    {id:"Wages",    name:"Wages"},
  ];
  empstatusDD = [
    {id:0,    name:"Probation"},
    {id:1,    name:"Confirmed"},
    {id:2,    name:"Relieved"},
    {id:3,    name:"Resigned"},
    {id:4,    name:"Settled"},
    {id:5,    name:"Terminated"},
    {id:6,    name:"Retired"},
    { id:7,  name:"NA"},
  ];

  // Date Filter
  fromDateFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  // Form Shorthand
  get f() {return  this.filterForm.controls; }

  constructor(
    public messageService   : MessageService,
    public salaryService    : SalaryStructureService,
    private employeeService : EmployeeService,
    public appService       : AppService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats
  ) { }

  ngOnInit(): void {
    this.getCompanyList();
    this.getBuList();
    this.getDepList();
    this.getDesignationList();
    this.getBranchList();
    this.getGradeList();
    this.getManagerList();
    this.collapsedClsFn();
    this.selectAllForDropdownItems(this.empstatusDD);
    this.selectAllForDropdownItems(this.emptypeDD);
    this.getDateFormat();
    let data = ['Probation','Confirmed','Resigned']
    if((this.from == 'EmployeeAttendance' || this.from == 'EmployeeShift' || this.from == 'EmployeeLeave' || this.from == 'myteam') && !this.isMemorized){
      this.filterForm.get('emp_status').setValue(data);
      this.filterForm.get('sortby').setValue('Alphabetically (A-Z)');
      this.collapsedclass3 = false
      this.collapsedclass1 = false
      this.activeidcls2 = 'ngb-panel-2';
      this.activeidcls = 'ngb-panel-0'
    }
  }

  // Oninit Fn Definitions
  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appService.getdatepickerformat();
    this.dateFormat = this.appService.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == '' || this.dateFormat == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  getCompanyList(){
    this.employeeService.getCompanyList().subscribe((res: any) => {
      this.companyDD = res;
      this.selectAllForDropdownItems(this.companyDD);
    });
  }

  getBuList(){
    this.employeeService.getBuList().subscribe((res: any) => {
      this.buDD = res;
      this.selectAllForDropdownItems(this.buDD);
    });
  }

  getDepList(){
    this.employeeService.getDepartmentList().subscribe((res: any) => {
      this.DepDD = res;
      this.selectAllForDropdownItems(this.DepDD);
    });
  }

  getDesignationList(){
    this.employeeService.getDesignationList().subscribe((res: any) => {
      this.DesignationDD = res;
      this.selectAllForDropdownItems(this.DesignationDD);
    });
  }

  getGradeList(){
    this.employeeService.getGradeList().subscribe((res: any) => {
      this.gradeDD = res;
      this.selectAllForDropdownItems(this.gradeDD);
    });
  }

  getBranchList(){
    this.employeeService.getBranchList().subscribe((res: any) => {
      this.BranchDD = res;
      this.selectAllForDropdownItems(this.BranchDD);
    });
  }

  getManagerList(){
    this.employeeService.managerList().subscribe((res: any) => {
     for(let i=0;i<res.length;i++){
        res[i]['name'] = res[i]['first_name'];
        if(res[i]['first_name'] != null)
        res[i]['name'] = res[i]['first_name']+" ";
        if(res[i]['middle_name'] != null)
        res[i]['name'] += res[i]['middle_name']+" ";
        if(res[i]['last_name'] != null)
        res[i]['name']   += res[i]['last_name'];
        res[i]['name']   +=" ("+res[i]['employee_code']+")";
      }
      this.managerDD = res;
      this.funmanagerDD =res;
      this.selectAllForDropdownItems(this.managerDD);
    });
  }

  // Oninit Fn Definitions Ends

  // Common Fn for Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  // Disable Date
  disableDate(){
    return false;
  }

  // Filter Design Setups
  collapsedClsFn(){
    if(((this.filterForm.controls.company.value == '') || (this.filterForm.controls.company.value == null)) && (this.filterForm.controls.bu.value == '') && (this.filterForm.controls.department.value == '') && (this.filterForm.controls.designation.value == '') && (this.filterForm.controls.grade.value == '') && (this.filterForm.controls.branch.value == '')) {
      this.collapsedclass2 = true;
      this.activeidcls1 = '';
    }else{
      this.activeidcls1 = 'ngb-panel-1';
    }

    if((this.filterForm.controls.emp_status.value == '') && (this.filterForm.controls.emp_type.value == '')) {
      this.collapsedclass3 = true;
      this.activeidcls2 = '';
    }else{
      this.activeidcls2 = 'ngb-panel-2';
    }

    if((this.filterForm.controls.repomanager.value == '') && (this.filterForm.controls.funmanager.value == '')){
      this.collapsedclass4 = true;
      this.activeidcls3 = '';
    }else{
      this.activeidcls3 = 'ngb-panel-3';
    }

    if((this.from == 'FandF' && this.filterForm.controls.exit_date_type.value == null)){
      this.collapsedclass5 = true;
      this.activeidcls4 = '';
    }else{
      this.activeidcls4 = 'ngb-panel-4';
    }

    this.collapsedclass1 = true
    this.activeidcls = 'ngb-panel-0';
  }

  // Reset Filter
  resetFilterFunction(){
    this.filterForm.patchValue({
      company: '',
      department: '',
      bu: '',
      designation: '',
      grade: '',
      branch: '',
      emp_status: '',
      emp_type: '',
      repomanager: '',
      funmanager: '',
      from_date: '',
      to_date: '',
      expense_date_from: '',
      expense_date_to: ''
    });
    this.filterForm.get('sortby')?.setValue('Latest');
    this.filterForm.get('exit_date_type')?.setValue(null);
    this.ordering = ''
    if(this.from == 'EmployeeAttendance' || this.from == 'EmployeeShift' || this.from == 'EmployeeLeave' || this.from == 'myteam'){
      this.filterForm.get('sortby').setValue('Alphabetically (A-Z)');
    }
    this.resetCalled.emit(true)
    this.applyFilter();
  }

  // Close Button
  closefn(){
    this.filter = false;
    this.filterClose.emit(this.filter);
    this.filterForm.get('company')?.setValue(this.filterForm.controls.company.value);
  }

  // Apply Button
  applyFilter(){
    this.collapsedClsFn();
    this.submitted      = true;
    this.filterSubmit   = true;
    if (this.filterForm.invalid) {
      this.filterSubmit = false;
      return;
    }
    // Setting variable for form
    let directoryData = ['Probation','Confirmed','Resigned']
    let company     = this.filterForm.controls.company.value;
    let bu          = this.filterForm.controls.bu.value;
    let dept        = this.filterForm.controls.department.value;
    let desig       = this.filterForm.controls.designation.value;
    let grade       = this.filterForm.controls.grade.value;
    let emp_status  = this.from!='Employee_directory'?this.filterForm.controls.emp_status.value:directoryData;
    if(this.from =='salary_unassigned' && this.filterForm.controls.emp_status.value =='')
      emp_status    = ['Probation','Confirmed','Resigned']
    let emp_type    = this.filterForm.controls.emp_type.value;
    let branch      = this.filterForm.controls.branch.value;
    let repomanager = this.filterForm.controls.repomanager.value;
    let funmanager  = this.filterForm.controls.funmanager.value;
    // Sorting Config
    this.ordering = this.getOrdering(this.filterForm.controls.sortby.value, this.from);
    // Extra Date Filter
    let selectedDate:any='';
    if(this.filterForm.value.expense_date_from!=null && this.filterForm.value.expense_date_to!=null  && this.filterForm.value.expense_date_from!='' && this.filterForm.value.expense_date_to!=''){
      let start         = moment(this.filterForm.value?.expense_date_from,this.dateFormat).format('YYYY-MM-DD');
      let end           = moment(this.filterForm.value?.expense_date_to,this.dateFormat).format('YYYY-MM-DD');
      if(this.from == 'advancehr'){
        selectedDate          = [start,end];
      }else if(this.from == 'expensehr'){
        selectedDate          = 'from_date='+start+'&to_date='+end;
      }
    }
    // Formatting for api
    let dataFilterArr;
    // Employee Profile, Directory, Live Location, Assign Geo, Invite Employees
    if (['Employee', 'Employee_directory', 'Map', 'EmployeeAssign', 'send-new-user'].includes(this.from)) {
      dataFilterArr =  `company__in=${company}&businessunit__in=${bu}&department__in=${dept}&designation__in=${desig}&branch__in=${branch}&grade__in=${grade}&employment_type__in=${emp_type}&employment_status__in=${emp_status}&reporting_manager__in=${repomanager}&functional_manager__in=${funmanager}${this.ordering}`;
      if(this.from == 'Map'){
        dataFilterArr =  `company__in=${company}&businessunit__in=${bu}&department__in=${dept}&designation__in=${desig}&branch__in=${branch}&grade__in=${grade}&employment_type__in=${emp_type}&employment_status__in=${emp_status}&reporting_manager__in=${repomanager}&functional_manager__in=${funmanager}`;
      }
    // Employee Attendance, HR Letter, Assign Shift, Leave Balance, My Team
    } else if (['EmployeeAttendance', 'Employehrletter', 'EmployeeShift', 'EmployeeLeave', 'myteam'].includes(this.from)) {
      dataFilterArr = `company=[${company}]&businessunit=[${bu}]&department=[${dept}]&designation=[${desig}]&grade=[${grade}]&branch=[${branch}]&employment_status=${emp_status?.length === 0 ? '[]' : JSON.stringify(emp_status)}&employment_type=${emp_type?.length === 0 ? '[]' : JSON.stringify(emp_type)}&reporting_manager=[${repomanager}]&functional_manager=[${funmanager}]${this.ordering}`;
      let dataDropdown = `company__in=${company}&businessunit__in=${bu}&department__in=${dept}&designation__in=${desig}&branch__in=${branch}&grade__in=${grade}&employment_type__in=${emp_type}&employment_status__in=${emp_status}&reporting_manager__in=${repomanager}&functional_manager__in=${funmanager}${this.ordering}`;
      this.dropOutput.emit(dataDropdown);
    // Expense
    } else if (this.from === 'expensehr') {
      dataFilterArr = `company=[${company}]&businessunit=[${bu}]&department=[${dept}]&designation=[${desig}]&grade=[${grade}]&branch=[${branch}]&employment_status=${emp_status?.length === 0 ? '[]' : JSON.stringify(emp_status)}&employment_type=${emp_type?.length === 0 ? '[]' : JSON.stringify(emp_type)}&reporting_manager=[${repomanager}]&functional_manager=[${funmanager}]${this.ordering}`;
      if (selectedDate) {
        dataFilterArr += `&${selectedDate}`;
      }
    // Advance Expense
    } else if (this.from === 'advancehr') {
      dataFilterArr = `company=[${company}]&businessunit=[${bu}]&department=[${dept}]&designation=[${desig}]&grade=[${grade}]&branch=[${branch}]&employment_status=${emp_status?.length === 0 ? '[]' : JSON.stringify(emp_status)}&employment_type=${emp_type?.length === 0 ? '[]' : JSON.stringify(emp_type)}&reporting_manager=[${repomanager}]&functional_manager=[${funmanager}]&expense_date=${selectedDate != null && selectedDate != "" ? JSON.stringify(selectedDate) : JSON.stringify([])}`;
    // Salary Structure &&  Final Settlement
    } else if (this.from === 'salary' || this.from === 'salary_unassigned' || this.from === 'FandF') {
      dataFilterArr = `employee__company__in=${company}&employee__businessunit__in=${bu}&employee__department__in=${dept}&employee__designation__in=${desig}&employee__branch__in=${branch}&employee__grade__in=${grade}&employee__employment_type__in=${emp_type}&employee__employment_status__in=${emp_status}&employee__reporting_manager__in=${repomanager}&employee__functional_manager__in=${funmanager}${this.ordering}`;
      if (this.from === 'FandF') {
        let start = this.filterForm.value.from_date
        let end = this.filterForm.value.to_date
        dataFilterArr += `&exit_date_type=${this.filterForm.value.exit_date_type != null ? this.filterForm.value.exit_date_type : ''}${(start != null && start != '' && end != null && end != '')? `&from_date=${this.appService.dateFormatConvert(this.filterForm.value.from_date)}&to_date=${this.appService.dateFormatConvert(this.filterForm.value.to_date)}` : ''}`
      }
    }
    // Emit to parent
    this.filterOutput.emit(dataFilterArr);
  }

  getOrdering(sortby:any, from:any) {
    const isSpecialCase = ['Employee', 'EmployeeAttendance', 'EmployeeShift', 'EmployeeLeave', 'Map', 'EmployeeAssign','send-new-user','myteam','Employehrletter'].includes(from)
    let ordering = ''
    switch (sortby) {
      case 'Alphabetically (A-Z)':
        ordering = '&ordering=user__first_name';
        if(this.from == 'salary' || this.from == 'salary_unassigned' || this.from == 'FandF'){
          ordering = '&ordering=employee__user__first_name'
        }
        break;
      case 'Alphabetically (Z-A)':
        ordering = '&ordering=-user__first_name';
        if(this.from == 'salary' || this.from == 'salary_unassigned' || this.from == 'FandF'){
          ordering = '&ordering=-employee__user__first_name'
        }
        break;
      case 'Latest':
        ordering = '&ordering=-modified_at';
        if (isSpecialCase) {
          ordering = '&ordering=-created_at';
        }
        break;
      case 'All':
        ordering = '&ordering=-modified_at';
        if (from === 'Employehrletter') {
          ordering = '&ordering=-created_at';
        }else if ( from == 'myteam'){
          ordering = '&ordering=user__first_name';
        }
        break;
      case 'Active First':
        ordering = '&ordering=is_active';
        break;
      case 'Inactive First':
        ordering = '&ordering=-is_active';
        break;
      default:
        ordering = '';
        break;
    }
    return ordering;
  }

  // Close button after selection
  clearcommonForm(item:any,index:number){
    if(item == "company"){
      let daata = this.filterForm.value.company;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        company: daata
      });
    } else if(item == "bu"){
      let daata = this.filterForm.value.bu;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        bu: daata
      });
    } else if(item == "department"){
      let daata = this.filterForm.value.department;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        department: daata
      });
    } else if(item == "designation"){
      let daata = this.filterForm.value.designation;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        designation: daata
      });
    } else if(item == "grade"){
      let daata = this.filterForm.value.grade;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        grade: daata
      });
    } else if(item == "branch"){
      let daata = this.filterForm.value.branch;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        branch: daata
      });
    }else if(item == "emp_status"){
      let daata = this.filterForm.value.emp_status;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        emp_status: daata
      });
    }else if(item == "emp_type"){
      let daata = this.filterForm.value.emp_type;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        emp_type: daata
      });
    }else if(item == "repomanager"){
      let daata = this.filterForm.value.repomanager;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        repomanager: daata
      });
    }else if(item == "funmanager"){
      let daata = this.filterForm.value.funmanager;
      daata.splice(index, 1);
      this.filterForm.patchValue({
        funmanager: daata
      });
    }
  }
}
