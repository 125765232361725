import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild,Input } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { EsichellangenerateService } from 'src/app/@core/services/esi-chellan-generation.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { Subject } from 'rxjs';
import { delay,switchMap  } from 'rxjs/operators';
@Component({
  selector: 'app-challan-generation-employee-state-insurence',
  templateUrl: './challan-generation-employee-state-insurence.component.html',
  styleUrls: ['./challan-generation-employee-state-insurence.component.scss']
})
export class ChallanGenerationEmployeeStateInsurenceComponent implements OnInit {

  generateChallan = false;
  activeTab2 = 'challan';
  loader                  = true;
  nodata                  = false;
  // status                  = true;
  loader1                 = false;
  // offset                  = 0;
  permissions:any;
  selectedMonth:any = 'April'
  selectedYear:any = Number(this.appService.getFinancialYear());
  challanList:any          = [];
  buttonLabel:any;
  generateViewData:any;
  groupName:any;
  employeeChallan:any;
  btnDisable           = false;
  esiId:any;
  excelDownloadData:any     = [];
  fileType                  = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension             = '.xlsx';
  searchData : Subject<void> = new Subject();
  currency : any
  @Input() fyArray:any;
  @Input() fMonth:any;
  @Input() changedYear:any;

    constructor(
    public breakpointObserver: BreakpointObserver,
    private EsichellangenerateService:EsichellangenerateService,
    public router: Router,
    public appService:AppService,
    private notificationService: NotificationService,
    public messageService : MessageService,
  ) { }

  ngOnInit(): void {
    // this.getPermission();
    this.searchData.pipe(delay(100),
    switchMap(() => this.activeTab2 == 'challan' ?  this.EsichellangenerateService.getesichellanData(this.selectedMonth,Number(this.selectedYear)) : this.EsichellangenerateService.getesichellangeneratedData(this.selectedMonth, Number(this.selectedYear)))).subscribe((res:any)=>{
      this.challanList    = [];
      if(this.activeTab2 == 'challan'){
          this.nodata       = res?.data?.length == 0 ? true : false;
          this.challanList  = res?.data;
      }else{
          this.nodata       = res?.length == 0 ? true : false;
          this.challanList  = res;
      }
      this.loader       = false;
    });

    this.esichellanData();
   }

   ngOnChanges(){
    if(this.changedYear != undefined){
      this.loader         = true;
      if(this.selectedMonth == 'January' || this.selectedMonth == 'February' || this.selectedMonth == 'March')
          this.selectedYear = Number(this.changedYear) + 1;
      else
       this.selectedYear = this.changedYear;

    this.searchData.next();
    }
  }

  challanMonthYear(month:any,year:any){
    this.loader         = true;
    this.fMonth        = month;
    this.selectedMonth = month;
    this.selectedYear  = year;
    this.searchData.next();
  }

   getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  tabClick(from:any){
    this.activeTab2 = from;
    this.searchData.next();
  }

  esichellanData(){

    if(this.selectedMonth != '' && this.selectedMonth != null && this.selectedYear != '' && this.selectedYear != null){
      this.loader         = true;
      this.challanList    = [];
      if(this.activeTab2 == 'challan'){
        this.EsichellangenerateService.getesichellanData(this.selectedMonth,Number(this.selectedYear))
        .subscribe((res: any) => {
          this.nodata       = res?.data?.length == 0 ? true : false;
          this.challanList  = res?.data;
          this.loader       = false;
        })
      }else{
        this.EsichellangenerateService.getesichellangeneratedData(this.selectedMonth,Number(this.selectedYear))
        .subscribe((res: any) => {
          this.nodata       = res?.length == 0 ? true : false;
          this.challanList  = res;
          this.loader       = false;
        })
      }
    }
  }

  generateViewChallan(data:any,val:any,name:any,empChallan:any,deleteitem:any,currency:any){
    if(!deleteitem){
      this.notificationService.handleErrorNotification("The challan details for respective group are not created.","Error");
    }else{
    this.loader1          = true;
    this.generateChallan  = true;
    this.buttonLabel      = val == false ? 'Generate' : 'Details';
    this.generateViewData = data;
    this.currency = currency
    this.groupName        = name;
    this.employeeChallan  = empChallan;
    this.loader1          = false;
   }
   }

  generateEsiChallan(){
    if(this.selectedMonth != '' && this.selectedMonth != null && this.selectedYear != '' && this.selectedYear != null){

      this.btnDisable = true;
      this.EsichellangenerateService.generateesiChallan({'employee_challan': this.employeeChallan, 'month': this.selectedMonth, 'year': Number(this.selectedYear)})
      .subscribe((res: any) => {
        this.esichellanData();
        this.generateChallan = false;
        this.btnDisable      = false;
      },
      (error) => {
        this.btnDisable = false;
      });
    }
  }

  regenerateview(id:any,grp:any,data:any,deleteitem:any,currency:any){
    if(!deleteitem){
      this.notificationService.handleErrorNotification("The challan details for respective group are not created.","Error");
    }else{
      this.loader1          = true;
      this.generateChallan  = true;
      this.buttonLabel      = 'Regenerate'
      this.groupName        = grp;
      this.esiId            = id;
      this.currency = currency
      this.EsichellangenerateService.getregeneratechellanData(id)
      .subscribe((res: any) => {
        this.generateViewData = res;
        this.loader1          = false;
      });
    }
  }

  regenerateEsiChallan(){
    if(this.selectedMonth != '' && this.selectedMonth != null && this.selectedYear != '' && this.selectedYear != null){

      this.btnDisable = true;
      this.EsichellangenerateService.regenerateesiChallan({'employee_challan': this.esiId})
      .subscribe((res: any) => {
        this.esichellanData();
        this.generateChallan = false;
        this.btnDisable      = false;
      },
      (error) => {
        this.btnDisable = false;
      });
    }
  }

  downloadchellan(data:any){

    let exportDataDownload:any = [];
    if(data?.length != 0){
      for(let i=0;i<data?.length;i++){
        exportDataDownload.push({
          'IP Number'                       : data[i]['ip_address'],
          'IP Name'                         : data[i]['ip_name'],
          'No. of days for which wages paid/payable during the month'
                                            : data[i]['payable_days'],
          'Total Monthly wages'             : data[i]['total_monthly_wages'],
          'Reason code for zero working days(numeric only:provide 0 for all other reasons)'
                                            : data[i]['reason'],
          'Last Working day'                : data[i]['last_working_day']
        })
      }
      if(exportDataDownload?.length !=0){
        this.exportExcel(exportDataDownload, 'ESI ECR');
      }
    }
  }
  public exportExcel(jsonData: any[], fileName: string): void {
     const ws: XLSX.WorkSheet  = XLSX.utils.json_to_sheet(jsonData);
     const wb: XLSX.WorkBook   = { Sheets: { 'data': ws }, SheetNames: ['data'] };
     const excelBuffer: any    = XLSX.write(wb,{ bookType: 'xlsx', type: 'array'});
     this.saveExcelFile(excelBuffer, fileName);
  }
  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

}
