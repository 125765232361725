<div class="container-fluid px-32 py-24">
  <div class="row row-16">
    <div class="col-12 d-flex gap-8  align-items-center">
      <span class="text-light-500 fs-12 text-nowrap">{{requestitem?.created_display}}</span>
      <button class="btn-reset btn-star ms-auto text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
      (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
        <i class="bi toggle-star"></i>
      </button>
    </div>
    <div class="col-12 mt-8 card-ribbon">
      <div class="w-100 d-flex align-items-center gap-8 card-ribbon">
        <h5 class="mb-8">Loan Process</h5>
        <!-- <div class="d-flex align-items-center ms-auto gap-8">
          <span class="link-primary fs-14" (click)="requestViewStatus()">View Status</span>
        </div> -->
      </div>
      <span class="badge-custom {{requestitem.request_type_color_code}}">Loan Process </span>
      <span *ngIf="requestitem.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
      class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
    </div>
    <app-progressbar [requestitem]="requestitem"></app-progressbar>
    <div class="col-12">
      <p class="mb-0 fs-14" [innerHTML]="requestitem?.message"></p>
    </div>
    <app-view-loader *ngIf="loader1"></app-view-loader>
    <ng-container *ngIf="!loader1">
      <div class="col-12" >
        <div class="card details-card indicater1 shadow-0 px-24 pb-24 pt-16">
          <div class="row row-16">
            <div class="col-12">
              <p class="title mb-8">Employee Details</p>
            </div>
            <div class="col-sm-4 col-md-3">
              <div class="detail-title">Employee name</div>
              <div class="detail-desc">{{requestitem?.requests?.from_person?.first_name +" " +(requestitem?.requests?.from_person?.middle_name != null? requestitem?.requests?.from_person?.middle_name: "") +" " +requestitem?.requests?.from_person?.last_name}}</div>
            </div>
            <div class="col-sm-4 col-md-3">
              <div class="detail-title">Employee code</div>
              <div class="detail-desc">{{requestitem?.requests?.from_person?.employee_code}}</div>
            </div>
            <div class="col-sm-4 col-md-3">
              <div class="detail-title">Department</div>
              <div class="detail-desc">{{requestitem?.requests?.from_person?.department}}</div>
            </div>
            <div class="col-sm-4 col-md-3">
              <div class="detail-title">Designation</div>
              <div class="detail-desc">{{requestitem?.requests?.from_person?.designation}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card details-card indicater1 shadow-0 px-24 pb-24 pt-16">
          <div class="row row-16">
            <div class="col-12 d-flex justify-content-between">
              <p class="title mb-8">Loan request details</p>
              <div class="d-flex ms-auto align-items-center gap-8">
                <a class="ms-auto fs-12 fw-500 link-primary1" (click)="existingLoanData()" *ngIf="requestitem?.requests?.total_loans != 0">View existing loan details</a>
                <span class="text-light-400" *ngIf="requestitem?.requests?.total_loans != 0 && !requestitem.requests.loan_process">|</span>
                <span class="link-primary1 text-nowrap fs-12 fw-500" (click)="editModal()" *ngIf="!requestitem?.requests?.loan_process"><i class="icon-pencil-square me-1"></i>Edit</span>
              </div>
            </div>
            <div class="col-md-3 col-sm-4 col-12">
              <div class="detail-title">Loan amount</div>
              <div class="detail-desc">{{requestitem?.requests?.loan_amount | currency : this.requestitem?.requests?.currency}}</div>
            </div>
            <div class="col-md-3 col-sm-4 col-12">
              <div class="detail-title">Loan type</div>
              <div class="detail-desc">{{requestitem?.requests?.loan_type_details?.loan_type}}</div>
            </div>
            <div class="col-md-3 col-sm-4 col-12">
              <div class="detail-title">Number of EMI</div>
              <div class="detail-desc">{{requestitem?.requests?.no_emis}}</div>
            </div>
            <div class="col-md-3 col-sm-4 col-12">
              <div class="detail-title">Interest rate</div>
              <div class="detail-desc">{{requestitem?.requests?.loan_type_details?.interest_rate}}%</div>
            </div>
            <div class="col-md-3 col-sm-4 col-12">
              <div class="detail-title">Interest type</div>
              <div class="detail-desc">{{requestitem?.requests?.loan_type_details?.interest_type}}</div>
            </div>
            <div class="col-md-3 col-sm-4 col-12">
              <div class="detail-title">Loan required date</div>
              <div class="detail-desc">{{requestitem?.requests?.required_date != null ? this.appservice.dateFormatDisplay(requestitem?.requests?.required_date) : '-'}}
              </div>
            </div>
            <div class=" col-12 col-md-6 col-sm-4">
              <div class="detail-title">Interest penalty on payment shortfall rate%</div>
              <div class="detail-desc">{{requestitem?.requests?.loan_type_details?.interest_penality}}</div>
            </div>
            <div class="col-12">
              <div class="card details-card indicater1 shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16">
                  <div class="col-12">
                    <p class="title mb-8">LOAN repayment details</p>
                  </div>
                  <div class="col-12">
                    <div class="hstack p-16 bg8 fw-500 radius-6">
                      <span>Monthly EMI</span>
                      <span class="ms-auto">{{requestitem?.requests?.new_monthly_emi | currency : this.requestitem?.requests?.currency}}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="hstack p-16 bg8 fw-500 radius-6">
                      <span>Principal amount</span>
                      <span class="ms-auto">{{requestitem?.requests?.new_principle_amount | currency : this.requestitem?.requests?.currency}}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="hstack p-16 bg8 fw-500 radius-6">
                      <span>Total interest</span>
                      <span class="ms-auto">{{requestitem?.requests?.new_total_interest | currency : this.requestitem?.requests?.currency}}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="hstack p-16 bg8 fw-500 radius-6">
                      <span>Total amount to be paid</span>
                      <span class="ms-auto">{{requestitem?.requests?.new_total_amount_to_be_paid | currency : this.requestitem?.requests?.currency}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card details-card indicater1 shadow-0 px-24 pb-24 pt-16">
          <div class="row row-16">
            <div class="col-12">
              <p class="title mb-8">EMI start month</p>
            </div>
            <div class="col-12" *ngIf="!requestitem?.requests.loan_process;else deductionVal">
              <form [formGroup]="form" autocomplete="off" class="row row-16">
                <div class="col-6">
                  <label for="month" class="form-label required">Deduction start month</label>
                  <ng-select class="form-ngselect" placeholder="{{this.msgServ.selectplaceholddisplay('month')}}" formControlName="deduction_month" (change)="deductChange()"
                  [ngClass]="{'is-invalid' : (f.deduction_month.touched || f.deduction_month.dirty) && f.deduction_month.errors}">
                    <ng-option *ngFor="let month of listOfMonth" [value]="month">{{month}}</ng-option>
                  </ng-select>
                  <div class="invalid-feedback" *ngIf="(f.deduction_month.touched || f.deduction_month.dirty) && f.deduction_month.errors">
                    <div *ngIf="f.deduction_month?.errors?.required">
                      {{this.msgServ.validationDisplay('required')}}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <label for="month" class="form-label required">Deduction start year</label>
                  <ng-select class="form-ngselect" placeholder="{{this.msgServ.selectplaceholddisplay('year')}}" formControlName="deduction_year" (change)="deductChange()"
                  [ngClass]="{'is-invalid' : (f.deduction_year.touched || f.deduction_year.dirty) && f.deduction_year.errors}">
                    <ng-option *ngFor="let month of yearList" [value]="month">{{month}}</ng-option>
                  </ng-select>
                  <div class="invalid-feedback" *ngIf="(f.deduction_year.touched || f.deduction_year.dirty) && f.deduction_year.errors">
                    <div *ngIf="f.deduction_year?.errors?.required">
                      {{this.msgServ.validationDisplay('required')}}
                    </div>
                  </div>
                </div>
              </form>
              </div>
              <ng-template #deductionVal>
                <div class="col-6">
                  <div class="detail-title">Deduction start month</div>
                  <div class="detail-desc">{{requestitem?.requests?.deduction_month}}</div>
                </div>
                <div class="col-6">
                  <div class="detail-title">Deduction start year</div>
                  <div class="detail-desc">{{requestitem?.requests?.deduction_year}}</div>
                </div>
              </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="mt-20" [formGroup]="form" *ngIf="!requestitem?.achieved_status">
      <div class="col-12">
        <textarea name="comment" id="comment" rows="3" placeholder="Write comment here..."
          class="form-control mb-8 textarea-comment" formControlName="comment"
          [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
        <div *ngIf="f.comment.errors" class="invalid-feedback">
          <div *ngIf="f.comment.errors?.maxlength">{{this.msgServ.fieldlengthvalidation('word','500')}}
          </div>
        </div>
      </div>
    </div>
    <div class="sticky-footer" *ngIf="!requestitem?.achieved_status">
      <div class="col-12 d-flex gap-16  py-16  position-sticky bottom-0 bg-white" style="z-index: 11;">
        <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400 ms-auto" [disabled]="processBtn || !form.valid || isErr"
          (click)="processFn()">Process</button>
      </div>
    </div>
  </div>
</div>

<!-- View Status -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"></app-view-status>
    </form>
  </div>
</ng-container>

<!-- View Existing Loan Details -->
<app-view-loan-details *ngIf="viewExistingLoan" [from]="'loanInbox'" [fullData]="existingFullData" [viewDetails]="viewExistingLoan" [navData]="navData" [loanData]="viewExistingData" (close)="panelClose($event)" [loader]="billLoader"></app-view-loan-details>

<!-- Edit Panel -->
<div class="side-panel " style="--sidepanel-width:49rem;"
[class.side-pane-active]='editPanel === true'>
  <form class="side-panel-container" [formGroup]="form" autocomplete="off">

      <header class="side-panel-head">
        <h5>Edit </h5>
        <a class="toggle-panel" (click)="closeModal()"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
        <div class="container-fluid p-32">
          <div class="row row-16">
            <div class="col-12">
              <p class="text-uppercase fw-500 mb-0">Update details</p>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16 ">
                  <div class="col-12">
                    <p class="title mb-0">Add details</p>
                  </div>
                  <div class="col-6">
                    <label for="loan" class="form-label required">Loan Amount</label>
                    <input type="text" class="form-control" autocomplete="off" placeholder="{{this.msgServ.placeholderdisp('amount')}}" formControlName="loan_amount" name="loan_amount" (keyup)="calcEmiDetails()"
                    [ngClass]="{'is-invalid' : (f.loan_amount.touched || f.loan_amount.dirty) && f.loan_amount.errors }" />
                    <div *ngIf="(f.loan_amount.touched || f.loan_amount.dirty) && f.loan_amount.errors"
                    class="invalid-feedback">
                      <div *ngIf="f.loan_amount.errors.required">{{this.msgServ.validationDisplay('required')}}</div>
                      <div *ngIf="f.loan_amount.hasError('min') && !(f.loan_amount.errors?.pattern)">
                        Loan amount cannot be zero.
                      </div>
                      <div *ngIf="f.loan_amount.hasError('max') && !(f.loan_amount.errors?.pattern)">
                        The loan amount can't be more than the defined maximum loan amount for this loan type.
                      </div>
                      <div *ngIf=" f.loan_amount.errors?.pattern || (notanumber(f.loan_amount.errors?.pattern?.actualValue))">
                        {{this.msgServ.validationDisplay('pattern')}}</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="loan" class="form-label required">Number of EMI</label>
                    <input type="text" class="form-control" autocomplete="off" placeholder="{{this.msgServ.placeholderdisp('emi')}}" formControlName="total_emis" name="total_emis" (keyup)="calcEmiDetails()"
                    [ngClass]="{'is-invalid' : (f.total_emis.touched || f.total_emis.dirty) && f.total_emis.errors }" />
                    <div *ngIf="(f.total_emis.touched || f.total_emis.dirty) && f.total_emis.errors"
                    class="invalid-feedback">
                      <div *ngIf="f.total_emis.errors.required">{{this.msgServ.validationDisplay('required')}}</div>
                      <div *ngIf="f.total_emis.hasError('min') && !(f.total_emis.errors?.pattern)">
                        Number of EMI cannot be zero.
                      </div>
                      <div *ngIf="f.total_emis.hasError('max') && !(f.total_emis.errors?.pattern)">
                        You cannot exceed the limit set for maximum number of EMIs for this loan type.
                      </div>
                      <div *ngIf=" f.total_emis.errors?.pattern || (notanumber(f.total_emis.errors?.pattern?.actualValue))">
                        {{this.msgServ.validationDisplay('pattern')}}
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="loan" class="form-label required">Interest penalty on payment shortfall rate%</label>
                    <input type="text" class="form-control" autocomplete="off" placeholder="{{this.msgServ.placeholderdisp('rate')}}" formControlName="shortfall_intrest_rate" name="shortfall_intrest_rate" (keyup)="calcEmiDetails()"
                    [ngClass]="{'is-invalid' : (f.shortfall_intrest_rate.touched || f.shortfall_intrest_rate.dirty) && f.shortfall_intrest_rate.errors }" />
                    <div *ngIf="(f.shortfall_intrest_rate.touched || f.shortfall_intrest_rate.dirty) && f.shortfall_intrest_rate.errors"
                    class="invalid-feedback">
                      <div *ngIf="f.shortfall_intrest_rate?.errors?.required">
                          {{this.msgServ.validationDisplay('required')}}
                      </div>
                      <div *ngIf="f.shortfall_intrest_rate?.errors?.pattern || (notanumber(f.shortfall_intrest_rate?.errors?.pattern?.actualValue))">
                        {{this.msgServ.validationDisplay('pattern')}}
                      </div>
                      <div *ngIf="f.shortfall_intrest_rate.hasError('max') && !(f.shortfall_intrest_rate.errors?.pattern)">
                        {{this.msgServ.fieldlengthvalidation('value',this.requestitem?.requests?.loan_type_details?.interest_penality)}}
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="loan" class="form-label required">Interest rate</label>
                    <input type="text" class="form-control" autocomplete="off" placeholder="{{this.msgServ.placeholderdisp('rate')}}" formControlName="interest_rate" name="interest_rate" (keyup)="calcEmiDetails()"
                    [ngClass]="{'is-invalid' : (f.interest_rate.touched || f.interest_rate.dirty) && f.interest_rate.errors }" />
                    <div *ngIf="(f.interest_rate.touched || f.interest_rate.dirty) && f.interest_rate.errors"
                    class="invalid-feedback">
                      <div *ngIf="f.interest_rate?.errors?.required">
                          {{this.msgServ.validationDisplay('required')}}
                      </div>
                      <div *ngIf="f.interest_rate?.errors?.pattern || (notanumber(f.interest_rate?.errors?.pattern?.actualValue))">
                        {{this.msgServ.validationDisplay('pattern')}}
                      </div>
                      <div *ngIf="f.interest_rate.hasError('max') && !(f.interest_rate.errors?.pattern)">
                        {{this.msgServ.fieldlengthvalidation('value',this.requestitem?.requests?.loan_type_details?.interest_rate)}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                <div class="row row-16">
                  <div class="col-12">
                    <p class="title mb-8">LOAN repayment details</p>
                  </div>
                  <div class="col-12">
                    <div class="hstack p-16 bg8 fw-500 radius-6">
                      <span>Monthly EMI</span>
                      <span class="ms-auto">{{(emiCalc?.monthly_emi == undefined ? 0 : emiCalc?.monthly_emi) | currency : this.requestitem?.requests?.currency}}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="hstack p-16 bg8 fw-500 radius-6">
                      <span>Principal amount</span>
                      <span class="ms-auto">{{(emiCalc?.principle_amount == undefined ? 0 : emiCalc?.principle_amount) | currency : this.requestitem?.requests?.currency}}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="hstack p-16 bg8 fw-500 radius-6">
                      <span>Total interest</span>
                      <span class="ms-auto">{{(emiCalc?.total_interest == undefined ? 0 : emiCalc?.total_interest) | currency : this.requestitem?.requests?.currency}}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="hstack p-16 bg8 fw-500 radius-6">
                      <span>Total amount to be paid</span>
                      <span class="ms-auto">{{(emiCalc?.total_amount_to_be_paid == undefined ? 0 : emiCalc?.total_amount_to_be_paid) | currency : this.requestitem?.requests?.currency}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="side-panel-footer">
          <button type="submit" class="btn btn-primary btn-sm text-uppercase ms-auto" (click)="submitModalData('')" [disabled]="submitClicked || !getFormValid()">submit</button>
        </footer>
      </div>

  </form>
</div>
