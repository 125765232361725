import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
@Component({
  selector: 'app-leave-approval-inbox',
  templateUrl: './leave-approval-inbox.component.html',
  styleUrls: ['./leave-approval-inbox.component.scss']
})
export class LeaveApprovalInboxComponent implements OnInit {

  @Input() from = 'inbox';
  timeOffTrend = false;
  leaveBalance = false;
  loader1 = true;

  @Input() requestitem: any;
  @Output() cancelEmitter: EventEmitter<boolean> =  new EventEmitter();
  @Output() successEmitter: EventEmitter<boolean> = new EventEmitter();

  // View Status
  billLoader = false;
  viewstatusPanel = false;
  viewStatusData: any = [];
  viewStatusLevelCnt: any;
  viewStatusmaxLevel: any;
  sendReminder = false
  constructor(
    private inboxservice: InboxService,
    private investmentService: InvestmentDeclerationService,
    private formBuilder: FormBuilder,
    private fb: FormBuilder,
    public appservice: AppService,
    public messageService: MessageService,
    private notificationService:NotificationService,
    private activityService:ManageActivityService
  ) { }

  leave_data: any;
  loader = true;

  leaveType: any = [];
  yearList: any = []
  currentYear = new Date().getFullYear()
  selectedYear: any;
  selectedYear1: any = 'All leave types';
  leave_type: any;

  modalToggle = false;
  currentDocName:any = "";
  currentExtension = "";
  allowedExtension = "";
  investalertMsg: any = '';
  alertToggle = false;
  fileName: any;
  disabled = false;
  nullStatus = false;
  laeveBalanceDate: any = "";
  leaveTypeBalanceDataTile: any[] = [];
  leaveBalanceTable: any[] = [];
  compOffData: any = [];
  lastDate: any = new Date(this.currentYear, 11, 31);

  /********* TIME OFF TREND GRAPH VARIABLES ****************/
  // view: any = [700, 400];
  multi: any = [];

  warningMsgToggle: any = false;
  warningMsg: any = '';


  barchart:any               = [];
  barChartXAxisLabels:any    = [];
  barChartYAxisLabel:any;
  barchartLoader             = false;

  onSelect(event:any) {
  }

  ngOnChanges(){
    this.loader1       = true;
      setTimeout(() => {
        this.loader1       = false;
      }, 2000);
    }

  ngOnInit(): void {

    this.sendReminder = this.from=='behalf'?true:false
    // Comp off data construction
    setTimeout(() => {
      this.validation();
      if (this.requestitem?.leave_data?.related_compoff_data?.length > 0) {
        for (let i = 0; i < this.requestitem?.leave_data?.related_compoff_data?.length; i++) {

          let attendance_date = this.appservice.dateFormatDisplay(this.requestitem?.leave_data?.related_compoff_data?.[i]?.attendance_date);
          let eligible_compoff = this.requestitem?.leave_data?.related_compoff_data?.[i]?.eligible_compoff == 'Full Day' ? '1 day' : '0.5 day';

          this.compOffData?.push(attendance_date + ' - ' + eligible_compoff);

        }
      }
    }, 1000);

  }

  form = this.fb.group({
    read_status: [true, Validators.required],
    star: [false, Validators.required],
    approved_status: [false, Validators.required],
    comment: ['', Validators.maxLength(500)],
  })

  trendsForm = this.formBuilder.group({
    leave_type: [''],
  })

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let dataArray: any = [];
    if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
        if (res) {
          Object.entries(dataArray).forEach(([key, value]) => {
            if (this.requestitem['id'] == id) {
              this.requestitem[key] = value;
            }
          })
        }
      })
    }
  }

  refreshInbox(event: boolean) {
    if (event) {
      this.successEmitter.emit(event);
    }
  }

  requestViewStatus() {
    this.viewstatusPanel = true
    this.billLoader = true;
    this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) => {
      this.viewStatusData = res;
      this.billLoader = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
    });
  }

  timeOffTrendFunction() {
    this.getLeaveType();
    this.yearListCreate();
    this.selectCurrentYear()
    // this.timeOffTrend = true;
    this.getTimeOffTrendData();
  }

  getLeaveType() {

    if(this.from=='behalf'){
      this.activityService.getleavetypeList(this.requestitem?.leave_data.employee).subscribe((res: any) => {
        this.leaveType = res;
      })
    }
    else{
		this.inboxservice.getleavetypeList(this.requestitem?.from_person?.id,this.requestitem?.id).subscribe((res: any) => {
      this.leaveType = res;
      })
    }
  }

  yearListCreate() {
    this.yearList = [];
    var i: number;
    for (i = this.currentYear; i >= 2020; i--) {
      this.yearList.push(String(i))
    }
  }

  selectCurrentYear() {
    this.selectedYear = this.currentYear
    this.yearChange(this.selectedYear)
  }

  getTimeOffTrendData(){
    this.barchartLoader = true;
    let fromPerson = this.from=='behalf'?this.requestitem?.leave_data.employee:this.requestitem?.from_person?.id

    this.investmentService.timeOffTrend({'employee' : fromPerson,'leave_type' : this.leave_type,'year' : Number(this.selectedYear)}).subscribe((res: any) => {
      this.multi      = res?.data;
      if(this.multi?.length > 0)
       this.barChartDataConstruction(this.multi)
      this.nullStatus = res?.null_status;
    });
  }

  yearChange(val: any) {
    this.selectedYear = val;
    this.getTimeOffTrendData();
  }

  leaveTypeChange(val: any) {
    this.leave_type = val;
    this.selectedYear1 = val;
    this.getTimeOffTrendData();
  }

  dateFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 5, 0, 1),
    new Date(new Date().getFullYear() + 5, 11, 31)]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  disableDate() {
    return false;
  }

  dateChangeFunction(e: any) {
    this.laeveBalanceDate = this.appservice.dateFormatConvert(e.target.value);
    this.leaveBalanceFunction();

  }

  leaveBalanceFunction() {
    this.leaveBalance = true;
    this.loader = true;

    if (this.laeveBalanceDate == "") {
      this.laeveBalanceDate = this.appservice.dateFormatConvert(new Date());
    }
    let fromPerson = this.from=='behalf'?this.requestitem?.leave_data.employee:this.requestitem?.from_person?.id
    this.investmentService.leaveTransaction(fromPerson, this.laeveBalanceDate).subscribe((res: any) => {
      this.loader = false;
      this.leaveBalanceTable = res?.data?.leave_transaction_data;
      this.leaveTypeBalanceDataTile = res?.data?.leave_balance_data
        ;
    })

  }

  /********* VIEW DOCUMENT FUNCTION ****************/
  viewDocument(filename: any) {
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle         = true;
      this.investalertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  // Date Format for FULL day
  fullday(datas:any) {
    let data : any = [];
    if(datas?.length > 0 && datas != null && datas != undefined){
      for(let i=0;i<datas?.length;i++){
         data?.push(this.appservice.dateFormatDisplay(datas[i]))
      }
    }

    return data?.reverse();
  }
  // End

  // Date Format for HALF day
  halfday(datas:any,fromportion:any,toportion:any) {
    let data : any = [];
    if(datas?.length > 0 && datas != null && datas != undefined){

      for(let i=0;i<datas?.length;i++){
         data?.push(this.appservice.dateFormatDisplay(datas[i]))
      }
      data = data?.reverse();
      if(data?.length > 1){
        data[0] = data[0]+' ('+fromportion+')';
        data[1] = data[1]+' ('+toportion+')';
        }else{
          if(fromportion == 'Full Day')
            data[0] = data[0]+' ('+toportion+')';
          else
            data[0] = data[0]+' ('+fromportion+')';
        }
    }
    return data;
  }
  // End

  // Modal Close
  close(bool: any) {
    this.modalToggle = bool;
  }

  // Modal close for Invalid Extension
  closed(bool: any) {
    this.alertToggle = bool
  }

  get f() { return this.form.controls; }

  confirm(status: any) {
    if (status == 'Approved') {
      this.form.controls['approved_status'].setValue('Approved');
    } else {
      this.form.controls['approved_status'].setValue('Rejected');
    }
    if (this.form['controls'].comment.valid) {
      if(this.from=='behalf'){
        let data                = this.form.value
        data['request_id']      = this.requestitem?.request_data?.id
        this.successEmitter.emit(data);
        this.form.get('comment')?.reset('')

      }
      else{

        this.inboxservice.updateInboxItem(this.requestitem?.id, this.form.value).subscribe((res) => {
          if (res) {
            this.successEmitter.emit(true);
          }
        })
      }
    }
  }

  // Validation fot OT and Comp off
  validation() {
    if (this.requestitem?.leave_data?.invalid_dates?.length > 0) {
      this.warningMsgToggle = true;
      for (let i = 0; i < this.requestitem?.leave_data?.invalid_dates?.length; i++) {
        this.requestitem.leave_data.invalid_dates[i] = this.appservice.dateFormatDisplay(this.requestitem?.leave_data?.invalid_dates[i])
      }
      if (this.requestitem?.leave_data?.invalid_reason == 'Overtime approved & attendance processed'){
        this.warningMsg = "Overtime is already processed for the selected dates" + ' (' + this.requestitem?.leave_data?.invalid_dates?.join(', ') + ')' + ". You cannot approve the leave request for these dates.";
      }

    } else {
      this.warningMsgToggle = false;
      this.warningMsg = '';
    }
  }
  // End

  // Bar chart
  barChartDataConstruction(data:any){

    let approved:any         = [];
    let pending:any          = [];
    let rejected:any         = [];
    let cancelled:any         = [];
    this.barChartXAxisLabels = [];
    this.barChartYAxisLabel  = 'Days';
    this.barchart            = [];
    data.forEach((item:any) => {
      this.barChartXAxisLabels?.push(item?.name)
      item?.series.forEach((item1:any) => {
          if(item1?.name == "Accepted"){
            approved.push(item1?.value)
          }else if(item1?.name == "Pending"){
            pending.push(item1?.value)
          }else if(item1?.name == "Cancelled"){
            cancelled.push(item1?.value)
          }else{
            rejected.push(item1?.value)
          }
      });
    });

    this.barchart.push({label: "Accepted", data: approved, backgroundColor: '#009722', barThickness: 10 },{label: "Pending", data: pending, backgroundColor: '#FFC107', barThickness: 10 },{label: "Rejected", data: rejected, backgroundColor: '#DC3545', barThickness: 10 },{label: "Cancelled", data: cancelled, backgroundColor: '#7d92a1', barThickness: 10 })

    if(this.barchart?.length > 0)
      this.timeOffTrend = true;
    this.barchartLoader = false;

  }


  // End

}
