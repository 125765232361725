import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { MessageService } from 'src/app/message.global';
import { NotificationService } from 'src/app/@core/services/notification.service';

@Component({
  selector: 'app-holiday-inbox',
  templateUrl: './holiday-inbox.component.html',
  styleUrls: ['./holiday-inbox.component.scss']
})
export class HolidayInboxComponent implements OnInit {

  loader = true;
  @Input() requestitem:any;
  @Input() from:any;
  sendReminder = false
  form = this.fb.group({
    read_status: [true,Validators.required],
    star: [false,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })

  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();
  @Output() cancelEmitter: EventEmitter<boolean> =  new EventEmitter();

  billLoader                      = false;
  viewstatusPanel                 = false;
  viewStatusData:any              = [];
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;

  constructor(
    private inboxservice: InboxService,
    private fb: FormBuilder,public messageService : MessageService,
    private investmentService:InvestmentDeclerationService,

  ) { }

  ngOnInit(): void {
    this.sendReminder = this.from=='behalf'?true:false
   }

  loadData(){
    this.loader = true;
    setTimeout(() => {
      if(this.requestitem.holiday_name){
        this.loader = false;
      }
    }, 1000);
  }

  performAction(action:any,type:any=0,id:any,comment_value:any='',read_status:any=true,star:any=true){
    let dataArray:any = [];
    if(action == 1){
      if(read_status == false){
        read_status = true;
        dataArray = {
          "read_status": read_status,
       };
      }
    }else if(action == 2){
      if(star == false){
        star = true;
      }else{
        star = false;
      }
      dataArray = {
        "star": star,
     };
    }
    if(dataArray.length != 0){
      this.inboxservice.updateInboxItem(id,dataArray).subscribe((res: any) =>{
        if(res){
          Object.entries(dataArray).forEach(([key, value]) => {
              if(this.requestitem['id'] == id){
                this.requestitem[key] = value;
              }
          })
        }
      })
    }
  }

  get f() {return  this.form.controls; }

  confirm(status:any){
    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }

    if(this.form['controls'].comment.valid){
      if(this.from=='behalf'){
        let data                = this.form.value
        data['request_id']      = this.requestitem?.request_data?.id
        this.successEmitter.emit(data);

      }
      else{
        this.inboxservice.updateInboxItem(this.requestitem.id,this.form.value).subscribe((res)=>{
          if(res){
            this.successEmitter.emit(true);
          }
        })
      }
      this.form.get('comment')?.reset('')

    }

  }
  cancelFinalRequest(data:any){
    this.cancelEmitter.emit(data);


  }
  /******** REQUEST VIEW STATUS  ****/
  requestViewStatus(){

    this.viewstatusPanel  = true
    this.billLoader       = true;

     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.billLoader         = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
  }
}
