
  <div class="container-fluid p-32 form-container" *ngIf="permissions">

    <div class="row row-24 ">
      <div class="col-12 d-flex flex-wrap align-items-start">
        <div class="mb-0 fw-500 text-uppercase">Arrear - {{month}} {{year}}</div>
        <div class="d-flex justify-content-end align-items-center ms-auto flex-wrap gap-16">

            <div style="min-width:22.688rem;">
              <div class="form-icon2">
                <i class="icon-search icon-left fs-14"></i>
                <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  " name="searchString" [(ngModel)]="searchString"  (keyup)="page=1" autocomplete="off"/>
              </div>
          </div>
          <div ngbDropdown class="d-inline-block" (click)="filter  = true">
            <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                <i class="icon-filter-left fs-13"></i>
            </button>
         </div>
            <button *ngIf="permissions?.a && status=='Completed'" class="btn flex-shrink-0 btn-outline-primary radius-2 fs-10 fw-700 px-4 text-uppercase" (click)="showSidePanel=true;clearData()">Import </button>
            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" [checked]='status=="Pending"' (click)='status="Pending";searchString="";getPendingArrearData();' [disabled]="loader">
                <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio1">PENDING</label>
                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" [checked]='status=="Ignored"' (click)='status="Ignored";searchString="";getPendingArrearData();' [disabled]="loader">
                <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio2">IGNORED</label>
                <input type="radio" class="btn-check"  name="btnradio" id="btnradio3" autocomplete="off" [checked]='status=="Completed"'  (click)='status="Completed";searchString="";getCompletedArrearData();' [disabled]="loader">
                <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio3">COMPLETED</label>
              </div>
        </div>
    </div>


        <div class="col-12">
            <div class="card card-c2 p-0">
              <app-table-loader *ngIf="loader"></app-table-loader>
                <div *ngIf="!loader" class="table-responsive radius-4">
                    <table *ngIf="status=='Pending' || status=='Ignored'"  class="table  sticky-header td-white-space td-fs-14
                        tr-fs-14  table-sm form-table  td-12  table-striped sticky-first-col sticky-last-col">
                        <thead>
                            <tr>
                               <th class="form-cell" *ngIf="status!='Ignored'">
                                    <input class="form-check-input m-0" type="checkbox" value=""  (click)="checkall($event)" [checked]="checkallSelected()">
                                </th>
                                <th class="fw-600" scope="col">EMP CODE</th>
                                <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                                <th class="fw-600" scope="col">DEPARTMENT</th>
                                 <th class="fw-600" scope="col">DESIGNATION</th>
                                <th class="fw-600" scope="col">ARREAR MONTH</th>
                            </tr>
                        </thead>
                        <tbody class="height-full">
                            <ng-container *ngIf="processList?.length==0">
                              <tr>
                                <td colspan="10">

                                  <div class="card card-c2 flex-center p-16 bg-dark-500">
                                    <span class="fw-500 fs-14">No data found</span>
                                  </div>
                                </td>
                              </tr>
                            </ng-container>

                            <tr *ngFor="let item of $any(processList|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                              <td class="form-cell" *ngIf="status!='Ignored'">
                                <input class="form-check-input m-0" type="checkbox"  [(ngModel)]="item.checked">
                              </td>

                              <td>{{item?.employee_code}}</td>
                              <td class="text-trim w-15ch" title="{{item.name}}" data-toggle="tooltip">{{item?.name}}</td>
                              <td>{{item?.department}}</td>
                              <td>{{item?.designation}}</td>
                              <td>{{item?.lop_month}}</td>

                            </tr>
                        </tbody>
                    </table>
                    <table *ngIf="status=='Completed'"  class="table  td-12  sticky-header td-white-space td-fs-14
                        tr-fs-14  table-sm form-table  table-striped sticky-first-col sticky-last-col">
                        <thead>
                            <tr> <th class="form-cell">
                                    <input class="form-check-input m-0" type="checkbox" value=""  (click)="checkall($event)" [checked]="checkallSelected()">
                                </th>
                                <th class="fw-600" scope="col">EMP CODE</th>
                                <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                                 <th class="fw-600" scope="col">DEPARTMENT</th>
                                 <th class="fw-600" scope="col">Designation</th>
                                <th class="fw-600" scope="col">Arrear month</th>
                                <th class="fw-600" scope="col">Arrear days</th>

                            </tr>
                        </thead>
                        <tbody class="height-full">
                          <ng-container *ngIf="historyData.length==0 ">
                          <tr>
                            <td colspan="13">

                              <div class="card card-c2 flex-center p-16 bg-dark-500">
                                <span class="fw-500 fs-14">No data found</span>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                          <tr *ngFor="let item of $any(historyData|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                              <td class="form-cell">
                                  <input class="form-check-input m-0" type="checkbox"  [(ngModel)]="item.checked" >
                              </td>
                              <td >{{item?.employee_code}}</td>
                              <td class="text-trim w-15ch" title="{{item.name}}" data-toggle="tooltip">{{item?.name}}</td>
                              <td >{{item?.department}}</td>
                              <td >{{item?.designation}}</td>
                              <td >{{item?.lop_month}}</td>

                              <td>
                                <!-- <div class="form-icon icon-right">

                                  <input  type="text" class="hasError form-control {{(item?.error) ?'is-invalid':''}} " [(ngModel)]="item.arrear_days" placeholder="{{this.messageService.descriptiontext('Arrear days')}}" (keyup)="validateData(historyData.indexOf(item))"/>
                                    <div *ngIf="item?.error" class="invalid-feedback">
                                      <div>{{item?.msg}}</div>
                                    </div>
                                    <ng-container *ngIf="item?.arrear_days!=item?.original_arrear_days">

                                      <ng-template #popContent>
                                          <div class="d-flex flex-column flex-center" style="">
                                              <span class="fs-14 text-center">Actual days:{{item?.original_arrear_days}}</span>

                                          </div>
                                          </ng-template>
                                    <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent" popoverClass="popover-default popover-c2" container="body" triggers="hover"></i>
                                  </ng-container>
                                </div> -->
                                <div class="form-icon {{(item?.error)||(item?.arrear_days!=item?.original_arrear_days) ?'icon-right':''}}">
                                  <input type="text" class="hasError form-control {{(item?.error) ?'is-invalid':''}} " [(ngModel)]="item.arrear_days"
                                    placeholder="{{this.messageService.descriptiontext('Arrear days')}}"
                                    (keyup)="validateData(historyData.indexOf(item))" />
                                  <app-table-input-popover [infoCheck]="item?.arrear_days!=item?.original_arrear_days " [infoData]="'Actual days : ' + item?.original_arrear_days " [errorCheck]="item?.error " [errorData]="item?.msg "></app-table-input-popover>
                                </div>
                              </td>

                          </tr>
                  </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
    <div class="col-12" *ngIf="filteredcount()>pageSize">

      <ngb-pagination class="d-flex justify-content-end"  [pageSize]="pageSize" [collectionSize]="filteredcount()" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"></ngb-pagination>
    </div>
</div>

<footer  *ngIf="status=='Pending' " class="submit-footer  gap-16" >
  <p class="fs-14 ms-auto d-flex gap-8 mb-0" *ngIf="processList.length>0">
    <span class="">{{countSelected()}} of {{processList.length}} selected </span>
    <span *ngIf="processList.length != countSelected()" class="link-primary fw-500"  (click)="selectAll();">Select all {{processList?.length}} employees</span>
    <span *ngIf="processList.length == countSelected()" class="link-primary fw-500"  (click)="unselectAll();">Unselect all {{processList?.length}} employees</span>
</p>
  <button class="btn btn-primary btn-sm text-uppercase" [disabled]="processList.length==0 || countSelected()==0 " (click)="confirmation('process');">process arrear</button>
  <button class="btn btn-danger btn-sm text-uppercase" [disabled]="processList.length==0 || countSelected()==0 " (click)="confirmation('ignore');">Ignore arrear</button>
</footer>

<footer  *ngIf="status=='Completed' " class="submit-footer  gap-16" >
  <p class="fs-14 ms-auto d-flex gap-8 mb-0" *ngIf="historyData.length>0">
    <span class="">{{countSelected()}} of {{historyData.length}} selected </span>
    <span *ngIf="historyData.length != countSelected()" class="link-primary fw-500"  (click)="selectAll();">Select all {{historyData?.length}} employees</span>
    <span *ngIf="historyData.length == countSelected()" class="link-primary fw-500"  (click)="unselectAll();">Unselect all {{historyData?.length}} employees</span>
</p>
  <button class="btn btn-primary btn-sm text-uppercase" [disabled]="historyData.length==0 || countSelected()==0 || checkValidity()==countSelected() " (click)="confirmationOverride();">Save</button>
  <button class="btn btn-primary btn-sm text-uppercase" [disabled]="historyData.length==0 || countSelected()==0 || checkValidity()==countSelected() " (click)="confirmation('unprocess');">Unprocess arrear</button>
</footer>



<!-- Slider -->
<div class="side-panel" style="--sidepanel-width: 56.875rem;" [class.side-pane-active]='showSidePanel === true'>
<div class="side-panel-container" autocomplete="off">
  <header class="side-panel-head">
      <h5>Import Arrear - {{month}} {{year}}</h5>
      <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
  </header>
  <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 mb-24">
          <div class="col-12">
              <div class="card card-c2 p-24 ">
                  <div class="title mb-24">Arrear - {{month}} {{year}}</div>

                  <div class="row" *ngIf="fileName">
                    <div class="col-md-6">
                      <div class="card card-c2 bg-secondary  mb-24" *ngIf="fileName!=''">
                        <div class="d-flex p-24 ">
                            <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                            <span class="text-trim fw-500">{{fileName}}</span>
                            <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                            <span class="link-danger fw-500 flex-shrink-0" (click)="clearData()" ><i class="bi bi-trash ms-8 "></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                <label for="upload"  [class.disabled]="fileName != ''" style="max-width: 18.375rem;" class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}">
                    <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null" (change)="onFileChange($event)" id="upload" hidden>Import Arrear
                </label>
              <span class="invalid-feedback" *ngIf="invalidFormat">{{this.messageService.validateFileFormat('xlsx/csv')}}</span>

              </div>
          </div>
          <div class="col-12">
              <div class="card card-c2 p-24">
                  <div class="title mb-24">Instructions </div>
                  <ol class="fs-14 ps-16 mb-0 li-24">
                      <li>Download the <a class="link-primary" href="assets/docs/sample-arrear-process.xlsx"> <i class="icon-download me-1"></i> Sample Excel
                      template</a></li>
                      <li>To import the arrear details of the employees, click on the Import Arrear button.</li>
                      <li>Choose the file to be imported.</li>
                      <li>The file chosen should be in (.xls/.csv)format only.</li>

                  </ol>
              </div>
          </div>
      </div>

      </div>
  </div>
  <footer class="side-panel-footer gap-32 pt-16">
    <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || submiited" type="button" (click)="importProcessArrear();submiited=true">Upload</button>
  </footer>
</div>

</div>

<app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)" (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>


<div class="modal  modal-alert {{hasError == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Error Occured</h4>
          </div>
          <div class="modal-body">
                  <p class="mb-0">{{errorReason}}</p>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline-primary btn-sm" (click)="hasError = false">Close</button>
          </div>
      </div>
  </div>
</div>

<div class="modal  modal-alert {{confirmMsg == true ? 'show': ''}}" tabindex="-1">
<div class="modal-dialog modal-dialog-centered ">
  <div class="modal-content">
      <div class="modal-header align-items-start gap-16">
          <h4 class="modal-title">{{saveData.length}} Employees selected</h4>
          <button type="button" class="btn-close ms-auto my-0" (click)="confirmMsg = false"></button>
      </div>
      <div class="modal-body">
          <p class="mb-0">{{confirmMsgText}}</p>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
              (click)="confirmMsg = false;clearData()">Cancel</button>
          <button *ngIf="purpose=='processing'" [disabled]="saveComplete" type="button" class="btn btn-primary btn-sm text-uppercase"
              (click)="processArrear();confirmMsg = false;">Save</button>
          <button *ngIf="purpose=='override'" [disabled]="saveComplete" type="button" class="btn btn-primary btn-sm text-uppercase"
              (click)="saveArrearDay();confirmMsg = false;">Save</button>
      </div>
  </div>
</div>
</div>

<ng-container *ngIf="alertToggle == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="empalertMsg" ></app-info-popup>
</ng-container>
<app-upload-progress-bar  *ngIf=" isDataUpload !=0" [progress]="percentage| number:'1.0-0'" [iscompleted]="iscompleted" [isProgressStart]="isProgressStart" [heading]="'Uploading'" ></app-upload-progress-bar>

<app-error-download [fileName]="'Arrear Error'" [successDataCount]="successDataCount" [downloadMsg]="downloadMsg" [failedData]="exportData" [modalHeading]="'Arrear'" (closePanel)="downloadMsg = false;clearData()" [successMsg]="successMsg" [errorMsg]="errorMsg">  </app-error-download>
