import { Component, Input, Output,OnInit, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.global';

@Component({
  selector: 'app-attendance-location',
  templateUrl: './attendance-location.component.html',
  styleUrls: ['./attendance-location.component.scss']
})
export class AttendanceLocationComponent implements OnInit {

  @Input() data: any;
  @Output() closepanel = new EventEmitter();
  constructor(public appservice: AppService) { }

  ngOnInit(): void {
  }

  zoom: number = 18;
  styles: any = [{
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#0c0c0c"
      }
    ]
  }]

  openedWindow: boolean = false; 

  openWindow() {
    if (this.openedWindow)
      this.openedWindow = false;
    else
    this.openedWindow = true;
  }
  closePanel() {
    this.closepanel.emit();
  }

  
 

}
