import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { offset } from '@popperjs/core';
import { EmppShiftMapService } from 'src/app/@core/services/empp-shift-map.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { AppService } from 'src/app/app.global';
import {Location} from '@angular/common';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';

@Component({
  selector: 'app-shift-mapping-setup',
  templateUrl: './shift-mapping-setup.component.html',
  styleUrls: ['./shift-mapping-setup.component.scss']
})
export class ShiftMappingSetupComponent implements OnInit {

  activeTab                   = 1;
  title                       = 'Assign Shift'
  tab2touched    :boolean     = false
  is_active      :boolean     = true
  errorLog       :boolean     = false
  assignForm    !: FormGroup;
  assignControls : any
  companyDD      : any        = [];
  buDD           : any        = [];
  DepDD          : any        = [];
  DesignationDD  : any        = [];
  BranchDD       : any        = [];
  gradeDD        : any        = [];
  employeeDropdown:any        = [];
  empList        :any         = [];
  exportData     :any         = [];
  successMsg                  = ''
  errorMsg                    = ''

  constructor(
    public fb       : FormBuilder,
    public route    : ActivatedRoute,
    public empShift : EmppShiftMapService,
    public appServ  : AppService,
    private rs      : RoleService,
    private _location: Location,
    public router   : Router,
    private previousRouteService: PreviousRouteService,
    private notificationService:NotificationService,
  ) { }

  ngOnInit(): void {
    // Form Initiliase
    this.assignForm     = this.fb.group({
      shift_from_to_date: this.fb.group({
        from            : [null,[Validators.required]],
        to              : [null,[Validators.required]],
        assign_shift_date : this.fb.array([])
      }),
      assignshift_right : this.fb.group({
        applicable      : [false,[Validators.required]],
        assignees       : this.fb.group({
          employee: [[],[Validators.required]],
          company: [[],[Validators.required]],
          businessunit: [[],[Validators.required]],
          department: [[],[Validators.required]],
          designation: [[],[Validators.required]],
          grade: [[],[Validators.required]],
          city: [[],[Validators.required]],
          branch: [[],[Validators.required]]
        })
      })
    })

    // Form array intialise
    this.assignControls = <FormArray>this.shift_from_to_Form.controls.assign_shift_date
  }

  ngAfterViewInit(){
    this.getCompanyList();
    this.getDepList();
    this.getDesignationList();
    this.getBranchList();
    this.getGradeList();
    this.getEmployeeList()
  }

  // Company List
  getCompanyList(){
    this.rs.getCompanyList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.companyDD = res.body;
      }
    });
  }

  // Bu List
  getBUList(data:any){
    this.rs.getBuList(data).subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
        this.buDD = res.body
      }
    });
  }

  // Dept List
  getDepList(){
    this.rs.getDepartmentList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.DepDD = res.body;
      }
    });
  }

  // Designation List
  getDesignationList(){
    this.rs.getDesignationList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.DesignationDD = res.body;
      }
    });
  }

  // Branch List
  getBranchList(){
    this.rs.getBranchList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.BranchDD = res.body;
      }
    });
  }

  // Grade List
  getGradeList(){
    this.rs.getGradeList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.gradeDD = res.body;
      }
    });
  }

  // Employee List
  getEmployeeList(){
    this.rs.getempList(this.is_active).subscribe((res: any) => {
      if(res.status == 200) {
        if (res.body.length > 0) {
          for(let i=0;i<res.body.length;i++){
            res.body[i]['name']  = "";
            if(res.body[i]['first_name'] != null)
              res.body[i]['name'] += res.body[i]['first_name']+" ";
            if(res.body[i]['middle_name'] != null && res.body[i]['middle_name'] != "")
              res.body[i]['name'] += res.body[i]['middle_name']+" ";
            if(res.body[i]['last_name'] != null)
              res.body[i]['name'] += res.body[i]['last_name'];
              res.body[i]['name'] += " ("+res.body[i]['employee_code']+")";
          }
          this.employeeDropdown = res.body;
        }
      }
    });
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  get shift_from_to_Form(){
    return this.assignForm.get('shift_from_to_date') as FormGroup
  }

  get shift_assign_Form(){
    return this.assignForm.get('assignshift_right') as FormGroup
  }

  controls() : FormArray {
    return this.shift_from_to_Form.get('assign_shift_date') as FormArray
  }

  // Nav Change
  onNavChange(changeEvent : NgbNavChangeEvent){
    if(changeEvent.nextId === 2){
      let company = this.shift_assign_Form.get('assignees')?.get('company')?.value
      this.getBUList(company);
      if(this.assignForm['controls'].shift_from_to_date.invalid || this.getCheckedData().length ==0){
        changeEvent.preventDefault();
      }
    }
  }

  // Next Step
  submitMethod(activeTab: any){
    this.activeTab = activeTab;
    if (activeTab === 2) {
      let company = this.shift_assign_Form.get('assignees')?.get('company')?.value
      this.getBUList(company);
      this.tab2touched =true
    }
  }

  // Form Array
  createArray(e:any){
    e.forEach((item:any,index:any)=>{
      e[index]['dateDisplayFormat'] = this.appServ.dateFormatDisplay(item?.date)+" ("+item?.day+")"
      this.controls().push(
        this.fb.group({
          'assigndate' : [item?.day],
          'checkedValue' : [false],
          'assignday'  : [item?.date],
          'shift' : [null],
          'weekoff_day' : [null]
        })
      )
    })
    this.getCheckedData()
  }

  // Submit Form
  submitForm(){
    this.empShift.getShiftProcessData().subscribe((res:any) => {
      if(res.body.data?.percentage>0 && res.body.data?.percentage<100){
        this.notificationService.handleErrorNotification("Assigning Shift cannot be done until the existing shift assigning process is completed.","Assign Shift");
      } else {
        this.saveData()
      }
    })


  }

  saveData(){
    var shiftArr:any[]=[];
    let checkedShift = []
     checkedShift = this.getCheckedData()
     shiftArr = checkedShift.map((item:any)=>{
      delete item.checkedValue
      delete item.assigndate
      item['assignday'] = item['assignday']
      return item
    })
    let applicable = this.shift_assign_Form.get('applicable')?.value
    if(!applicable){
      this.shift_assign_Form.get('assignees')?.patchValue({
        employee : []
      })
    }else{
      this.shift_assign_Form.get('assignees')?.patchValue({
        company      : [],
        businessunit : [],
        department   : [],
        designation  : [],
        grade        : [],
        branch       : []
      })
    }
    let assignShift = [this.shift_assign_Form.get('assignees')?.value]
    let dataArray : any
    dataArray = {
      "process_date"      : this.getTodaysDate(),
      "assign_shift_date" : shiftArr,
      "assignshift_right" : assignShift
    }
    this.startProgressBar()
    this.empShift.setProcessData(this.router.url,"Assign")

    this.empShift.createShiftMap(dataArray).subscribe((res:any)=>{
    })
  }


  // Today's Date
  getTodaysDate(){
    var todays = new Date();
    var dd = String(todays.getDate()).padStart(2, '0');
    var mm = String(todays.getMonth() + 1).padStart(2, '0');
    var yyyy = todays.getFullYear();
    let today = yyyy+'-'+mm+'-'+dd
    return today
  }

  // getChecked Data
  getCheckedData(){
    return this.assignControls.value.filter((item:any)=> item.checkedValue == true)
  }

  // Valid Data
  checkValid(){
    var result = true
    if(this.shift_assign_Form.get('applicable')?.value == false){
      if(this.shift_assign_Form.get('assignees')?.get('company')?.valid == false && this.shift_assign_Form.get('assignees')?.get('businessunit')?.valid ==false && this.shift_assign_Form.get('assignees')?.get('department')?.valid ==false && this.shift_assign_Form.get('assignees')?.get('designation')?.valid ==false && this.shift_assign_Form.get('assignees')?.get('grade')?.valid ==false && this.shift_assign_Form.get('assignees')?.get('branch')?.valid ==false ){
        result = false;
      } else{
        result = true;
      }
    }else{
      if(this.shift_assign_Form.get('assignees')?.get('employee')?.valid ==false){
        result = false
      }else{
        result = true
      }
    }
    return result
  }
  backClicked() {
    if(this.previousRouteService.getPreviousUrl() == '/home' || this.previousRouteService.getPreviousUrl() == '/login')
    this.router.navigate(['employee-shift']);
    else
    this._location.back();
  }


  showProgress = false
  progPercentage:any = 0
  isProgressStart = false
  timeLeft:any = ''
  iscompleted = false
  body = ''
  successDataCount = 0
  empCntB = 0

  startProgressBar(){
    this.empShift.RUNBG = false
    let cnt = 0;
    this.empShift.setProgress(false);
    this.successDataCount = 0
    this.showProgress = true
    this.isProgressStart = true

    this.body =  "Shift data is processing..."
    this.empShift.processPercentage.subscribe(result =>{
      this.progPercentage = result.event.percentage
      this.body =  "Shift data processed for "+ result.event.uploaded_count +" out of "+result.event.employee_count+" employee(s)."
      this.successMsg = "Shift Assigned for "+ result.event.uploaded_count +" out of "+result.event.employee_count+" employee(s) successfully"
      if(result.event.percentage == 100 && cnt===0){
        this.timeLeft = ""
        this.isProgressStart = false
        this.iscompleted =true
        this.errorLog = true;
        this.successDataCount = result.event.uploaded_count
        this.empCntB = result.event.employee_count
        cnt++
          this.checkProgress()
      }
      else{
        this.convertMinSecs(result.event.time_left)
      }
    },
    (error:any)=>{
    this.closeProgress();
    this.notificationService.handleErrorNotification('Something went wrong','Error')

    })
  }
  convertMinSecs(min:any){
    let time = min*60;
    var minutes = Math.floor(time / 60);
    var seconds:any = time - minutes * 60;
    seconds = seconds.toFixed(2)
    this.timeLeft = minutes+" min "+seconds+" sec "
  }
  closeProgress(){
    this.empShift.RUNBG = true
  this.empShift.setProgress(true);
    this.isProgressStart=false;
    this.showProgress = false
  }
  checkProgress(){
    if(!this.empShift.BGPROGRESS){
      this.empShift.getShiftProcessData().subscribe((res:any) => {
        this.errorList =res.body.data;
        // this.progress = res.data.percentage;
        if( res.body.data.percentage==100){
          this.empShift.setProgress(true);
          this.errorPopup();
          this.showProgress = false
        }
      });
    }
  }

  errorList:any = []
  errorMsgA = ''
  errorPopup(){
    this.exportData = []
    let sucLen = this.errorList?.error_list[0]?.failed_employee_count?this.empCntB-this.errorList?.error_list[0]?.failed_employee_count:this.empCntB
    this.successMsg = "Shift Assigned for "+ sucLen +" out of "+this.empCntB+" employee(s) successfully"
    if(this.errorList.error_list[0]?.failed_employee_data.length>0){
      for (let i = 0; i < this.errorList.error_list[0]?.failed_employee_data.length; i++) {
        this.exportData.push({
          'Employee code':this.errorList.error_list[0]?.failed_employee_data[i]['employee'],
          'Error Date':this.errorList.error_list[0]?.failed_employee_data[i]['date'],
          'Error Description':this.errorList.error_list[0]?.failed_employee_data[i]['error']});
      }
      this.errorLog = true;
      this.errorMsgA =this.errorList?.error_list[0]?.failed_employee_count+' employee(s) have error data.'
    }
  }
}
