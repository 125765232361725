<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;" [class.side-pane-active]='reqFlag === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">

      <h5 class="m-0 text-trim w-50">Compensatory Off Request
        <span *ngIf="from=='behalf'" title="{{requestFor}}">
          {{' Of '+ requestFor}}
        </span>
      </h5>
      <a class="toggle-panel" (click)="closeFunction()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12 d-flex align-items-center">
            <div class="me-auto">
              <p class="text-uppercase fw-500 mb-0">COMPENSATORY OFF</p>
              <span class="fs-12">Employee can request for a compensatory off for the extra hours they have
                worked.</span>
            </div>
            <ng-select class="form-ngselect ms-auto fs-14" style="width:10.5rem"
              placeholder="{{this.messageService.selectplaceholddisplay('Year')}}"
              (change)="getOndutyTrendDetails($event)" [(ngModel)]="selectedYearDisp"
              [ngModelOptions]="{standalone: true}">
              <ng-option class="fs-12" *ngFor="let year of yearList" [value]="year.k">{{year.k}}</ng-option>
            </ng-select>
          </div>
          <!--No data found-->
          <app-nodata-view *ngIf="nodata && !loader;else all_data" [noDataText]="'NO DATA'" [hasPermission]="false">
          </app-nodata-view>
          <!--End-->
          <app-c1-loader *ngIf="loader"></app-c1-loader>
          <ng-template #all_data>
            <ng-container *ngIf="!loader;">
              <div class="col-12" *ngFor="let data of listData,let i = index">
                <div class="card card-c5 text-light-500">
                  <div class="card-body flex-center flex-column sq-72 bg1{{i%5}} p-0">
                    <div class="fw-500 lh-28 fs-24">{{data?.attendance_date | date : "d"}}</div>
                    <div class="fs-14 text-uppercase">{{data?.attendance_date | date : "MMM"}}</div>
                  </div>
                  <div class="card-body details-card border-0 align-items-center d-flex px-24 py-0">
                    <div class="w-100 row row-16">
                      <div class="col-6">
                        <div class="detail-title">Extra working hours</div>
                        <div class="detail-desc">{{
                          this.appservice.hhmmTimeFormat(data?.extra_hours)}} hrs</div>
                      </div>
                      <div class="col-6">
                        <div class="detail-title">Eligible comp off</div>
                        <div class="detail-desc">{{data?.eligible_compoff == 'Full Day' ? 1 : 0.5}}</div>
                      </div>
                    </div>
                    <div *ngIf="(data?.is_applied && data?.is_expired && data?.is_leave_applied) || (data?.is_applied && !data?.is_expired)" class="fw-500 fs-14 flex-center ms-8 btn-sm text-success">Request sent
                    </div>
                    <div *ngIf="(data?.is_applied && data?.is_expired && !data?.is_leave_applied) || (!data?.is_applied && data?.is_expired)" class="fw-500 fs-14 flex-center ms-8 btn-sm text-danger">Expired
                    </div>
                    <button *ngIf="(!data?.is_applied && !data?.is_expired)" class="ms-8 btn btn-primary btn-sm text-uppercase"
                      (click)="compOffReviewFunction(data?.id,data?.attendance_date)">Review</button>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Review section start -->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='compOffReview === true'>
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Compensatory Off Request</h5>
      <a class="toggle-panel" (click)="compOffReview=false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <!-- View loader start -->
        <app-view-loader *ngIf="loader1"></app-view-loader>
        <!-- View loader end -->
        <div class="row row-24 mb-24" *ngIf="!loader1">
          <div class="col-12">
            <div class="card card-c2 details-card p-16">
              <div class="row row-24">
                <div class="col-12">
                  <span class="title">Request Details</span>
                </div>
                <div class="col-md-4">
                  <div class="detail-title">Comp off date</div>
                  <div class="detail-desc">{{this.appservice.dateFormatDisplay(compOffReviewData?.attendance_date)+',
                    '+compOffReviewData?.day_of_week}}</div>
                </div>
                <div class="col-md-4">
                  <div class="detail-title">Shift</div>
                  <div class="detail-desc">
                    {{this.appservice.timeFormatDisplay(compOffReviewData?.actual_shift_details?.check_in)+'-'+this.appservice.timeFormatDisplay(compOffReviewData?.actual_shift_details?.check_out)}}
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="detail-title">Check-in time</div>
                  <div class="detail-desc">
                    {{this.appservice.timeFormatDisplay(compOffReviewData?.checkin_time)}}</div>
                </div>

                <div class="col-md-4">
                  <div class="detail-title">Check-out time</div>
                  <div class="detail-desc">
                    {{this.appservice.timeFormatDisplay(compOffReviewData?.checkout_time)}}</div>
                </div>
                <div class="col-md-4">
                  <div class="detail-title">Total working hours</div>
                  <div class="detail-desc">
                    {{this.appservice.hhmmTimeFormat(compOffReviewData?.total_work_hours)}}
                    Hours</div>
                </div>
                <div class="col-md-4">
                  <div class="detail-title">Extra working hours</div>
                  <div class="detail-desc">
                    {{this.appservice.hhmmTimeFormat(compOffReviewData?.extra_hours)}}
                    Hours</div>
                </div>
                <div class="col-md-4">
                  <div class="detail-title">Compensatory off generated</div>
                  <div class="detail-desc">{{compOffReviewData?.eligible_compoff == 'Full Day' ? '1 Day' : '0.5 Day'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button class="btn btn-outline-primary btn-sm   text-uppercase" (click)="compOffReview=false">CANCEL</button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="submitCompOffReq()">
        submit for approval
      </button>
    </footer>
  </form>
</div>
<!-- Review section end -->
