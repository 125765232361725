import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CompensatoryOffService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  getCompOffRequestList(empId:any,year:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/profile-compoff-request-list/${empId}/?year=${year}`, this.httpOption)
  }
  getCompOffRequestDetail(id:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/profile-compoff-request-detail/${id}/`, this.httpOption)
  }
  compOffRequestCreate(id:any,body: any) {
    return this.http.post(`${this.API_PATH}request/api/v1/compoff-request-create/${id}/`, body, this.httpOption)
  }
}
