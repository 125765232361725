import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { LoanProcessService } from 'src/app/@core/services/loan-process.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';

@Component({
  selector: 'app-my-loan',
  templateUrl: './my-loan.component.html',
  styleUrls: ['./my-loan.component.scss']
})
export class MyLoanComponent implements OnInit {

  // Var Initialisation
  viewDetailsPanel: boolean = false
  permissions     : any
  searchString    : any     = "";
  noDataFound     : boolean = false
  loader          : boolean = false
  filterPanel     : boolean = false
  outstandAmount  : any     = 0
  tableData       : any     = []
  currency        : any
  dataFilter      : any     = ''
  viewSpecData    : any     = []
  viewLoader      : any     = false
  searchkey       : any     = 'Search'
  isLoading       : boolean = false

  page              = 1;
  limit             = 20
  offset            = 0;
  lastPageCount     = 0;

  filterForm        = this.fb.group({
    loan_type                     : [null],
    loan_code                     : [null],
    status                        : [null],
    requested_from_date           : '',
    requested_to_date             : '',
    closed_from_date              : '',
    closed_to_date                : '',
  })

  searchData : Subject<void> = new Subject();
  @ViewChild(SearchbarComponent) search:any;

  constructor(
    private loanServ : LoanProcessService,
    public appServ   : AppService,
    public messageServ : MessageService,
    private fb       : FormBuilder
  ) { }

  ngOnInit(): void {
    this.getPermission(); // Permission
    this.getMyLoanData(); // Loan Details
    let userData = this.appServ.getEmpData() // For currency
    this.currency = userData?.currency
    // Search
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.loanServ.getMyLoanDetails(`limit=${this.limit}&offset=${this.offset}&search=${this.searchString}&${this.dataFilter}`)
    )).subscribe((result:any)=>{
      this.arrangeAPIData(result)
      this.isLoading = false
    })
  }

  // View Loan Details
  loanDetailFn(key:any){
    this.viewDetailsPanel = true;
    this.viewLoader = true
    this.loanServ.getSpecificLoan(key).subscribe((res:any)=>{
      this.viewSpecData = res?.body.data[0]
      this.viewLoader = false
    })
  }

  panelClose(val:any){
    this.viewDetailsPanel = val
  }

  // Loan Table Data
  getMyLoanData(){
    this.loader = true
    this.tableData = []
    this.loanServ.getMyLoanDetails(`limit=${this.limit}&offset=${this.offset}&${this.dataFilter}`).subscribe((res:any)=>{
      this.arrangeAPIData(res)
    })
  }

  // Common fn for api response
  arrangeAPIData(res:any){
    this.lastPageCount = res?.body?.count
    if(this.lastPageCount != 0){
      this.tableData = res?.body?.results
      this.noDataFound = false
    }else{
      this.noDataFound = true
    }
    this.outstandAmount = res?.body?.total_outstanding_balance?.total_outstanding
    this.loader = false
  }

  // Search
  searchLoan(keyWord:any){
    this.isLoading = true
    this.loader = true
    this.offset = 0
    this.tableData = []
    this.searchString = keyWord
    if(keyWord !== ''){
      this.searchData.next();
    }
  }

  searchResults(search:any){
    this.loader = true
    if(search == ''){
      this.searchString = ''
      this.offset = 0
      this.tableData = []
      this.searchData.next()
    }else{
      this.searchData.next()
    }
  }

  // Filter
  applyFilter(data:any){
    this.searchString = ''
    this.search.clearBar();
    this.dataFilter = data
    this.filterPanel = false
  }

  closeFilter(){
    this.filterPanel = false;
  }

  resetfilter(){
    this.searchString = ''
    this.search.clearBar();
    this.dataFilter = ''
  }

  // Page Change
  pageChanged(event: any) {
    this.page = event
    this.offset = (this.page-1) * this.limit;
    this.getMyLoanData();
  }

  // Permissions
  getPermission(){
    this.permissions = this.appServ.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
}
