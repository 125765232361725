import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeaveBalanceHRService {
  FILTERDATA:any;
  FILTERDROP :any;
  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };


  constructor(
    private http: HttpClient,
    public router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(  !event.url.includes('leave-balance')){
          this.setFilterData(null)
          this.setFilterDrop(null)
        }
      }
    })
   }

  // Landing Screen API
  getLandingScreenData(limit:number,offset:number,search:any,filter:any,id:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-balance-export/?limit=${limit}&offset=${offset}&search=${search}&${filter}&emp_id=${id == 0 ? '' : id}`, this.httpOption)
  }

  // Employee Data
  getSpecificEmpData(id:number){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-balance-export/?emp_id=${id}`, this.httpOption)
  }

  // Filter Data
  getFilterData(limit:number,offset:number,data:any,search:any){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-balance-export/?limit=${limit}&offset=${offset}&${data}&search=${search}`, this.httpOption)
  }

  getAllDatasLeave(limit:any,offset:any,data:any,search:any,id:any){
    if(id == 0){
      return this.http.get(`${this.API_PATH}attendance/api/v1/leave-balance-export/?limit=${limit}&offset=${offset}&${data}&search=${search}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}attendance/api/v1/leave-balance-export/?emp_id=${id}`, this.httpOption)
    }
  }

  // Edit & History View
  getEditHistoryView(empID:number,year:number,from:any){
    if(from == 'profile'){
      return this.http.get(`${this.API_PATH}attendance/api/v1/home-leave-transaction-data/?employee=${empID}&leavetype=&year=${year}`, this.httpOption)
    }else if(from == 'myteam'){
      return this.http.get(`${this.API_PATH}attendance/api/v1/team-leave-transaction-data/?employee=${empID}&leavetype=&year=${year}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}attendance/api/v1/hr-leave-transaction-data/?employee=${empID}&leavetype=&year=${year}`, this.httpOption)
    }
  }

  // With Leave ID
  getDataWithLeaveID(empID:number,leaveTypeID:number,year:number,transaction_type: any,from:any){
    if(from == 'profile'){
      return this.http.get(`${this.API_PATH}attendance/api/v1/home-leave-transaction-data/?employee=${empID}&leavetype=${leaveTypeID}&year=${year}&transaction_type=${transaction_type}`, this.httpOption)
    }else if(from == 'myteam'){
      return this.http.get(`${this.API_PATH}attendance/api/v1/team-leave-transaction-data/?employee=${empID}&leavetype=${leaveTypeID}&year=${year}&transaction_type=${transaction_type}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}attendance/api/v1/hr-leave-transaction-data/?employee=${empID}&leavetype=${leaveTypeID}&year=${year}&transaction_type=${transaction_type}`, this.httpOption)
    }
  }

  // Manual Create
  createManuallyLeave(body:any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/hr-leave-balance-create/`,body, this.httpOption)
  }

  // Import
  uploadImportData(body:any){
    return this.http.post(`${this.API_PATH}attendance/api/v1/leave-balance-import/`,body, this.httpOption)
  }

  // View Request
  leavesingleview(id: any){
    return this.http.get(`${this.API_PATH}request/api/v1/hr-single-leave-request-data/${id}/`, this.httpOption)
  }

  // Filter
  setFilterData(val:any){
    this.FILTERDATA = val
  }

  // Drop
  setFilterDrop(val:any){
    this.FILTERDROP = val
  }
}
