import { Component, OnInit } from '@angular/core';
import { FormBuilder,Validators,FormGroup } from '@angular/forms';
import { ExpenseReportRequestService } from 'src/app/@core/services/expense-report-request.service';
import { AppService } from 'src/app/app.global';
import { Router,Params,ActivatedRoute } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
@Component({
  selector: 'app-expense-reports-setup',
  templateUrl: './expense-reports-setup.component.html',
  styleUrls: ['./expense-reports-setup.component.scss']
})
export class ExpenseReportsSetupComponent implements OnInit {

  activeTab = 1;

  reportListData:any     = [];
  reportListLoader       = false;
  reportSearchData:any   = [];
  reportSearchKey:any    = "";

  advanceListData:any           = [];
  advanceListLoader             = false;
  applyAdvanceData:any          = [];
  applyAdvanceDataMainArray:any = [];

  receiptListData:any                = [];
  receiptListLoader                  = false;
  unReportedReceiptList:any          = [];
  unReportedReceiptListMainArray:any = [];
  requestFor:any = ''
  currency:any;


  tab2touched = false;
  tab3touched = false;
  tab4touched = false;
  empId:any;
  from:any

  constructor(
    private formBuilder:FormBuilder,
    private service:ExpenseReportRequestService,
    public appservice:AppService,
    public router: Router,
    private previousRouteService: PreviousRouteService,
    private activityService:ManageActivityService,
    public route:ActivatedRoute,

  ) { }

  ngOnInit(): void {
    let userData        = this.appservice.getEmpData();
    this.route.params.subscribe((params: Params) => {
      if(params['id']){
        this.empId = params['id']
        this.from  = 'behalf'
        this.activityService.employeeBasicData(this.empId).subscribe((res:any)=>{
            this.requestFor = "";
            if(res['first_name'] != null)
            this.requestFor+= res['first_name']+" ";
            if(res['middle_name'] != null)
            this.requestFor+= res['middle_name']+" ";
            if(res['last_name'] != null)
            this.requestFor+= res['last_name'];
            this.requestFor+=" ("+res['employee_code']+")"
            this.currency       = res.currency;


        })
      }
      else{

        this.from = 'expenseReportRequest'
        this.currency       = userData.currency;

      }

    })

    this.getReportList();
  }

  expenseReportForm: FormGroup = this.formBuilder.group({

    reportlist : this.formBuilder.group({
                    id               : ['',Validators.required],
                    name             : [''],
                    from_date        : [''],
                    to_date          : [''],
                    total_amount     : [''],
                    to_reimbursed    : [''],
                    status           : [''],
                    business_purpose : [''],
                    index            : [''],
    }),

  })

  get reportlistForm() {
    return this.expenseReportForm.get('reportlist') as FormGroup;
  }
  getReportList(){
    console.log("this.from",this.from)
    this.reportListLoader = true;
    this.reportListData   = [];
    this.reportSearchData = [];
    if(this.from=='behalf'){
      this.activityService.expenseReportList(this.reportSearchKey,this.empId).subscribe((res:any)=>{
        this.reportListData   = res;

      })
    }
    else{

      this.service.reportList(this.reportSearchKey).subscribe((res:any)=>{
        this.reportListData   = res;
      })
    }

    // Search drop down API
    if(this.from=='behalf'){
      this.activityService.expenseReportListDropDown(this.empId).subscribe((res:any)=>{
        this.reportSearchData = res;
        this.reportListLoader = false;
      })
    }
    else{

      this.service.reportListDropDown().subscribe((res:any)=>{
        this.reportSearchData = res;
        this.reportListLoader = false;
      })
    }
    // End
  }

  searchemit(data:any){
    this.reportSearchKey = data?.search;
    this.getReportList();
  }

  submitOne(){
    this.activeTab   = 2;
    this.tab2touched = true;
  }

  submitTwo(){
    this.activeTab   = 3;
    this.tab3touched = true;
  }

  submitThree(){
    this.activeTab   = 4;
    this.tab4touched = true;
  }

  setAdvanceAndReceipt(data:any){
    this.advanceListData = data?.advances;
    this.receiptListData = data?.receipts;
  }

  updatedadvancelist(data:any){
    this.advanceListData  = data?.advancelist;
    this.applyAdvanceData = data?.advanceadd;
    this.reportListData[this.expenseReportForm.getRawValue().reportlist['index']]['total_amount'] = data?.total_amount;
    this.reportListData[this.expenseReportForm.getRawValue().reportlist['index']]['to_reimbursed'] = data?.to_reimbursed;
  }

  unReportReceiptData(data:any){
    this.receiptListData       = data?.receiptlist;
    this.unReportedReceiptList = data?.addedreceipt;
    this.reportListData[this.expenseReportForm.getRawValue().reportlist['index']]['total_amount'] = data?.total_amount;
    this.reportListData[this.expenseReportForm.getRawValue().reportlist['index']]['to_reimbursed'] = data?.to_reimbursed;

  }

  finalSave(){
    if(this.from=='behalf'){
      this.activityService.expenseReportCreate({'expense_report':this.expenseReportForm.getRawValue().reportlist['id'], 'employee_id':this.empId}).subscribe((res:any)=>{
        this.router.navigate(['manage-activities']);

      })
    }
    else{

      this.service.createExpenseReportRequest({'expense_report':this.expenseReportForm.getRawValue().reportlist['id']}).subscribe((res:any)=>{
        if(this.previousRouteService.getPreviousUrl() == '/expense-reports')
          this.router.navigate(['expense-reports']);
        else
          this.router.navigate(['request']);
        })
    }
  }

  backClicked() {
    if(this.from=='behalf'){
      this.router.navigate(['manage-activities']);
    }
    else{

      if(this.previousRouteService.getPreviousUrl() == '/expense-reports')
      this.router.navigate(['expense-reports']);
      else
      this.router.navigate(['request']);
    }
  }

  onNavChange(changeEvent: NgbNavChangeEvent){

    if (changeEvent.nextId === 2) {
      if (this.expenseReportForm['controls'].reportlist.invalid) {
        changeEvent.preventDefault();
      }else{
        this.tab2touched =true;
      }
    }
    else  if (changeEvent.nextId === 3) {

      if (this.receiptListData?.length == 0 || this.expenseReportForm['controls'].reportlist.invalid || this.tab2touched == false) {
        changeEvent.preventDefault();
      }else{
        this.tab3touched = true;
      }
    }else  if (changeEvent.nextId === 4) {
      if (this.receiptListData?.length == 0 || this.expenseReportForm['controls'].reportlist.invalid || this.tab3touched == false) {
        changeEvent.preventDefault();
      }else{
        this.tab4touched = true;
      }
    }
  }

}
