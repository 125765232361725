import { Component, OnInit, ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { OnDutyService } from 'src/app/@core/services/on-duty.service';
import * as moment from 'moment';
import { AppService } from 'src/app/app.global';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { OdSelectDateComponent } from './od-select-date/od-select-date.component';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';
@Component({
  selector: 'app-on-duty-setup',
  templateUrl: './on-duty-setup.component.html',
  styleUrls: ['./on-duty-setup.component.scss']
})
export class OnDutySetupComponent implements OnInit {

  activeTab      = 1;
  init           = false
  listForm  : any
  empId:any;
  from:any;
  requestFor:any = ''
  constructor(
    private _location: Location,
    private fb       : FormBuilder,
    private dutyServ : OnDutyService,
    private router   : Router,
    private appServ : AppService,
    private investmentService:InvestmentDeclerationService,
    public route:ActivatedRoute,
    private authServ: AuthService,
    private activityService:ManageActivityService

    ) { }

  backClicked() {
    this.investmentService.backClicked('/on-duty-setup');
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if(params['id']){
       this.empId = params['id']
       this.from  = 'behalf'
       this.activityService.employeeBasicData(this.empId).subscribe((res:any)=>{
        this.requestFor = "";
        if(res['first_name'] != null)
        this.requestFor+= res['first_name']+" ";
        if(res['middle_name'] != null)
        this.requestFor+= res['middle_name']+" ";
        if(res['last_name'] != null)
        this.requestFor+= res['last_name'];
        this.requestFor+=" ("+res['employee_code']+")"

      })
      }
      else
        this.empId = this.authServ.isEmployeeId


    })
  }

  @ViewChild(OdSelectDateComponent) odChild !: OdSelectDateComponent;

  // Form Initialise
  onDutyForm : FormGroup = this.fb.group({
    dateForm : this.fb.array([]),
    assignDateForm : this.fb.group({
      dutyRequest  : this.fb.array([])
    })
  })

  get datesForm(){
    return this.onDutyForm.get('dateForm') as FormArray
  }

  get assignForm(){
    return this.onDutyForm.get('assignDateForm') as FormGroup
  }


  controls() : FormArray {
    return this.assignForm.get('dutyRequest') as FormArray
  }

  assignForms() :  FormArray{
    return this.assignForm.get('dutyRequest') as FormArray
  }

  // Nav Change
  onNavChange(changeEvent : NgbNavChangeEvent){
    if(changeEvent.nextId === 2){
      if(!this.validateOne() || this.invalidDates()){
        changeEvent.preventDefault();
      }
    }
  }

  // Step 1 Submit
  submitOne(){
    this.activeTab = 2
    this.validateOne();
  }

  validateOne(){
    if(this.datesForm.length !=0)
     return true
    else
     return false
  }

  invalidDates(){
    var val = false;
    if(this.odChild){
      let data =  this.odChild.invalidArrayData()
      if(data && data.length > 0){
        val = true
      }else{
        val = false
      }
    }
    return val
  }

  editDetails(val:any){
    this.datesForm.removeAt(val)
  }

  checkValid(){
    var retResult = true;
    const myForm = (<FormArray>this.assignForm.get("dutyRequest"));

    for(let k = 0; k < myForm.length; k++ ){
      this.listForm = myForm.at(k);
      if(this.listForm.controls.onduty_date.valid == false && this.listForm.controls.checkin_date.valid == false && this.listForm.controls.checkin_time.valid == false && this.listForm.controls.checkout_date.valid == false && this.listForm.controls.checkout_time.valid == false && this.listForm.controls.reason.valid == false && this.listForm.controls.hours.valid == false && this.listForm.controls.shift_mapping.valid == false) {
        retResult = false;
      }
    }
    return retResult;
  }

  convertTimeFormat(timeString:any) {
    const timeComponents = timeString.split(':').map(Number);
    const hours = timeComponents[0];
    const minutes = timeComponents[1];
    const seconds = timeComponents.length > 2 ? timeComponents[2] : 0;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  // Step 2 Submit
  submitFinal(){
    let data = this.controls().value
    data.forEach((item:any)=>{
      let checkIn = moment(item['checkin_time'],'HH:mm:ss').format('HH:mm:ss')
      let checkOut = moment(item['checkout_time'],'HH:mm:ss').format('HH:mm:ss')
      const [inHours,inMinutes] = checkIn?.split(':')
      const [outHours,outMinutes] = checkOut?.split(':')

      item['checkin_time'] = `${inHours}:${inMinutes}:00`
      item['checkout_time'] =  `${outHours}:${outMinutes}:00`
      item['checkin_date'] = moment(item['checkin_date']).format('YYYY-MM-DD')
      item['checkout_date'] = moment(item['checkout_date']).format('YYYY-MM-DD')
      item['hours'] = item['hours']+':00'


      // delete item['shift_name_code']
      // item['checkin_date'] = moment(item['checkin_date']).format('YYYY-MM-DD')
      // item['checkout_date'] = moment(item['checkout_date']).format('YYYY-MM-DD')

      // item.checkin_time = this.convertTimeFormat(item?.checkin_time);
      // item.checkout_time = this.convertTimeFormat(item?.checkout_time);
      // item.hours = this.convertTimeFormat(item?.hours);
    })

    let dataArray :any= {
      "onduty_request" : data
    }
    if(this.from=='behalf'){
      if(this.from=='behalf')
        dataArray['employee_id'] = this.empId;
        this.activityService.ondutyRequest(dataArray).subscribe((res:any)=>{
        this.router.navigate(['manage-activities'])

      })
    }
    else{
      this.dutyServ.sendRequest(dataArray).subscribe((res:any)=>{
        this.router.navigate(['request'])
      })
    }
  }
}
