import { Component, Input, OnInit } from '@angular/core';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-reimbursement-analysis',
  templateUrl: './reimbursement-analysis.component.html',
  styleUrls: ['./reimbursement-analysis.component.scss']
})
export class ReimbursementAnalysisComponent implements OnInit {

  @Input() empId:any;
  @Input() currency : any

  multi: any = [];
  yearList: any = [];
  // currentYear = new Date().getFullYear();
  currentYear = Number(this.appservice.getFinancialYear());
  selectedYearDisp = "FY "+String(this.currentYear)+" - "+String(this.currentYear+1)
  selectedYear = this.currentYear
  constructor(
    private inboxservice: InboxService,
    public messageService : MessageService,
    public appservice : AppService
  ) { }

  xAxisLabel:any = [];
  yAxisLabel: string = ''
  graphLoader             = false;
  activeItem:any = [];
  year:any=false;
  month:any=false;



  onSelect(event:any) {
    // console.log(event);
  }

  ngOnInit(): void {
    this.yearListCreate();
    this.getReimbursementTrendDetails(this.currentYear);
    this.yAxisLabel = `Amount  (${this.currency})`;
  }

  ngOnChanges(){
    // this.getReimbursementTrendDetails();
  }
  yearListCreate(){
    var i:number;
    for(i = this.currentYear; i >= 2020 ; i--){
      this.yearList.push({k:String(i),v:"FY "+String(i)+" - "+String(i+1)})
    }
  }
  getReimbursementTrendDetails(event:any){
    let nextyear = Number(event)+1
    this.graphLoader = true;
    this.selectedYearDisp = "FY "+String(event)+" - "+nextyear
    this.selectedYear = event
    if(this.empId != null && this.empId != '' && this.empId != undefined){
      this.inboxservice.getReimbursementTrend({'employee':this.empId,'year':this.selectedYear}).subscribe((res:any)=>{
        if(res){
          this.prepareTrendData(res.data)
        }
      })
    }

  }
  prepareTrendData(data:any){

    let approved:any         = [];
    let pending:any          = [];
    let rejected:any         = [];
    let cancelled:any         = [];
    this.xAxisLabel = [];

    this.multi           = [];
    data.forEach((item:any) => {
      this.xAxisLabel?.push(item?.name)
      item?.series.forEach((item1:any) => {
          if(item1?.name == "Accepted"){
            approved.push(item1?.value)
          }else if(item1?.name == "Pending"){
            pending.push(item1?.value)
          }else if(item1?.name == "Cancelled"){
            cancelled.push(item1?.value)
          }else{
            rejected.push(item1?.value)
          }
      });
    });


    this.multi.push({label: "Accepted", data: approved, backgroundColor: '#009722', barThickness: 10 },{label: "Pending", data: pending, backgroundColor: '#FFC107', barThickness: 10 },{label: "Rejected", data: rejected, backgroundColor: '#DC3545', barThickness: 10 },{label: "Cancelled", data: cancelled, backgroundColor: '#7d92a1', barThickness: 10 })
    this.graphLoader = false;


  }


}
